import React from 'react';
import {CardHeader, Grid, TextField} from "@mui/material";
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {createComplaint} from '../../../service/complaint.api';
import {errorToast, successToast, warningToast} from '../../../shared/components/AlertMessages';
import {getId} from '../../../shared/helpers/authentication';
import {ToastContainer} from 'react-toastify';
import Card from 'react-bootstrap/Card';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from "@mui/material/Stack";
import {useNavigate} from "react-router-dom";
import {FormattedMessage} from "react-intl";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const RaiseComplaint = () => {
    const member_id = getId();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            complaint_subject: '',
            complaint_description: '',
            file: null
        },
        validationSchema: Yup.object({
            complaint_subject: Yup.string().required('Complaint Subject is required'),
            complaint_description: Yup.string().required('Complaint Description is required')
        }),
        onSubmit: (values) => {
            // Handle form submission here
            const formData = new FormData();
            if (values.file === null) {
                formData.append('member_id', member_id);
                formData.append('complaint_subject', values.complaint_subject);
                formData.append('complaint_description', values.complaint_description);
            } else {
                formData.append('member_id', member_id);
                formData.append('complaint_subject', values.complaint_subject);
                formData.append('complaint_description', values.complaint_description);
                formData.append('file', values.file);
            }
            createComplaint(formData)
                .then((response) => {
                    if (response) {
                        if (response?.status_code === 200 || response?.status_code === 201) {
                            successToast(response?.message);
                            navigate('/index/member');
                        }
                    }
                })
                .catch((error) => {
                    if (error.response?.data?.status_code === 404) {
                        warningToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                    } else {
                        errorToast('something went wrong');
                    }
                });
        }
    });

    const handleFileChange = (event) => {
        formik.setFieldValue('file', event.currentTarget.files[0]);
    };

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Card>
                <form onSubmit={formik.handleSubmit}>
                    <CardHeader title=<FormattedMessage id="complaint_subject"/> className="linear-gradient"/>
                    <Card.Body>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="complaint_subject"
                                    name="complaint_subject"
                                    label="Enter your query Subject"
                                    variant="outlined"
                                    size="small"
                                    value={formik.values.complaint_subject}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.complaint_subject && Boolean(formik.errors.complaint_subject)}
                                    helperText={formik.touched.complaint_subject && formik.errors.complaint_subject}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    name="complaint_description"
                                    label="Tell me about your query in brief..."
                                    variant="outlined"
                                    fullWidth
                                    rows={4}
                                    size="small"
                                    value={formik.values.complaint_description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.complaint_description && Boolean(formik.errors.complaint_description)}
                                    helperText={formik.touched.complaint_description && formik.errors.complaint_description}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*  <Button*/}
                            {/*      component="label"*/}
                            {/*      role={undefined}*/}
                            {/*      variant="contained"*/}
                            {/*      tabIndex={-1}*/}
                            {/*      startIcon={<CloudUploadIcon />}*/}
                            {/*  >*/}
                            {/*    Upload file*/}
                            {/*    <VisuallyHiddenInput type="file" id="file" name="file" accept=".pdf,.doc,.docx,.jpg,.png" onChange={handleFileChange} />*/}
                            {/*  </Button>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Card.Body>
                    <Card.Footer>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2} justifyContent="flex-start">
                                <Button color="primary" variant="contained" type="submit">
                                    Send
                                </Button>
                            </Stack> </Grid>
                    </Card.Footer>
                </form>
            </Card>
            <ToastContainer/>
        </div>
    );
};

export default RaiseComplaint;




