import {Grid} from '@mui/material';
import React from 'react';
import Typography from "@mui/material/Typography";
import qrCode from "../../../assets/images/icons/qrCode.jpg";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {FormattedMessage} from "react-intl";

const PaymentGatewayPage = () => {

    return (
        <div>
            <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 6, sm: 12, md: 12}} className="mt-2">

                <Grid item xs={6} className="d-flex justify-content-center" style={{borderRight: '5px solid'}}>
                    <div>
                        <Typography variant="subtitle1" gutterBottom>
                            <FormattedMessage id="first_payment_text"/>
                        </Typography>

                        <span className="fw-bold">Account Name</span>
                        <p className="text-muted">Lohana Milan</p>

                        <span className="fw-bold">Account Number</span>
                        <p className="text-muted">09330100022039</p>

                        <span className="fw-bold">IFSC Code</span>
                        <p className="text-muted">BARBOUSMANP</p>

                        <span className="fw-bold">Branch</span>
                        <p className="text-muted"> Usmanpura, Ahmedabad </p>
                    </div>
                </Grid>
                <Grid item xs={6} className="d-flex justify-content-center border-start-2">
                    <div>
                        <Typography variant="subtitle1" gutterBottom>
                            <FormattedMessage id="sec_payment_text"/>
                        </Typography>
                        <img src={qrCode} alt="Logo" style={{height: "20rem"}}/>
                    </div>

                </Grid>
                <Grid item xs={12} className="d-flex justify-content-center mt-3">
                    <div>
                        <Typography variant="subtitle1" gutterBottom>
                            <FormattedMessage id="third_payment_text"/>
                            <WhatsAppIcon
                                style={{fontSize: 20, verticalAlign: 'middle', marginRight: 5, color: 'green', marginLeft:5}}/>
                            <a
                                href="https://wa.me/919512004470?" target="_blank">9512004470</a>

                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <FormattedMessage id="fourth_payment_text"/>
                        </Typography>
                        <Typography variant="body1">
                            <FormattedMessage id="fifth_payment_text"/> <a
                            href="mailto:contact@lohanamilan.in">contact@lohanamilan.in.</a>
                        </Typography>
                    </div>
                </Grid>
            </Grid>


        </div>
    );
};

export default PaymentGatewayPage;
