import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useEffect, useRef, useState } from 'react';
import { deleteEvent, viewEvent, updateEvent, searchEvent } from '../../../service/event.api';
import { successToast, errorToast, warningToast } from '../../../shared/components/AlertMessages';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmationModal from '../../../shared/components/DeleteConfirmationModal';
import { ToastContainer } from 'react-toastify';
import EditEventFormModal from '../forms/EditEventFormModal';
import { SearchText } from '../../../shared/components/SearchText';
import { FormattedMessage } from 'react-intl';
import LoadingScreen from '../../../shared/components/LoadingScreen';
import { AddButton } from '../../../shared/components/AddButton';
import CreateEvent from '../forms/CreateEvent';
import { makeStyles } from "@mui/styles";
import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import {getEventStatus} from "../../../shared/helpers/utils";
import EventModal from "../forms/EventModal";

const headCells = [
  {
    id: 'event_name',
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="event_name" />
  },
  {
    id: 'hall_name',
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="hall_name" />
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage id="date" />
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage id="event_status" />
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="action" />
  }
];
function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          View Events
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

export default function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [eventData, setEventData] = React.useState([]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = React.useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const isFirstRender = useRef(true); // Ref to track the initial render
  const [isLoading, setIsLoading] = useState(false);
  const [addEventForm, setAddEventForm] = useState(false);

  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [selectedRowForEvent, setSelectedRowForEvent] = useState(null);

  const handleEventClick = (eventData) => {
    setSelectedRowForEvent(eventData);
    setIsEventModalOpen(true);
  };
  const toggleEventForm = () => {
    setAddEventForm(!addEventForm);
  };
  // Function to handle search query change

  const handleSearchQueryChange = (event) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery); // Update the searchQuery state with the new value
    searchAndDisplayData(newSearchQuery); // Call searchAndDisplayData with the new search query
  };
  const searchAndDisplayData = (searchQuery) => {
    // setIsLoading(true);
    if (searchQuery === '' || searchQuery === undefined) {
      view(page, rowsPerPage);
    } else {
      setTimeout(() => {
        searchEvent(searchQuery)
          .then((response) => {
            setIsLoading(false);
            if (response) {
              if (response?.status_code === 200 || response?.status_code === 201) {
                setEventData(response?.data);
              }
            }
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.response?.data?.status_code === 404) {
              setEventData([]);
              setCount(0);
            } else if (error.response?.data?.status_code === 403) {
              errorToast(error.response?.data?.message);
              setEventData([]);
            } else if (error.response?.data?.status_code === 400) {
              errorToast(error.response?.data?.message);
              setEventData([]);
            } else {
              errorToast('something went wrong');
              setEventData([]);
            }
          });
      }, 400);
    }
  };

  const view = (page, rowsPerPage) => {
    setIsLoading(true);
    setTimeout(() => {
      viewEvent(page + 1, rowsPerPage)
        .then((response) => {
          if (response) {
            setIsLoading(false);
            if (response?.status_code === 200 || response?.status_code === 201) {
              setEventData(response.data?.event_list);
              setCount(response.data?.total_item);

            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response?.data?.status_code === 404) {
            setEventData([]);
            setCount(0);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast('something went wrong');
          }
        });
    }, 400);
  };
  useEffect(() => {
    if (isFirstRender.current) {
      view(page, rowsPerPage);
      isFirstRender.current = false; // Set to false after the initial render
    }
  }, [page, rowsPerPage]); // Include page and rowsPerPage in the dependency array

  const handleDeleteClick = (eventData) => {
    // Open the modal when delete icon is clicked
    setSelectedRowForDelete(eventData);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirmed = () => {
    deleteEvent(selectedRowForDelete.event_id)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);
            const updatedData = eventData.filter((row) => row.event_id !== selectedRowForDelete.event_id);
            setEventData(updatedData);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast('something went wrong');
        }
      });

    setIsDeleteModalOpen(false);
  };

  const handleEditClick = (eventData) => {
    setSelectedRowForEdit(eventData);
    setIsEditModalOpen(true);
  };

  const handleEditEvent = (editedEventData) => {
    updateEvent(editedEventData) // Replace with the actual API call
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);

            // Update the eventData state to reflect the edited data
            const updatedData = eventData.map((row) =>
              row.event_id === selectedRowForEdit.event_id ? { ...row, ...editedEventData } : row
            );
            setEventData(updatedData);
            view(page,rowsPerPage)
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast('something went wrong');
        }
      });

    setIsEditModalOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    view(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);

    // Ensure that the current page is valid with the new rowsPerPage
    const newPage = Math.floor(page * (rowsPerPage / newRowsPerPage));

    view(newPage, newRowsPerPage); // Fetch data with the new rowsPerPage
    setRowsPerPage(newRowsPerPage); // Update rowsPerPage
    setPage(newPage); // Update the page to the new calculated page
  };

  const handleAddEventClose = () => {
    setAddEventForm(false);

    view(page, rowsPerPage);
  };

  const handleClose = () => {
    setAddEventForm(false);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;
  const useStyles = makeStyles((theme) => ({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
      },
    },
  }));
  const classes = useStyles();

  const getChipColor = (status) => {
    switch (status) {
      case 'Upcoming':
        return 'primary'; // Blue color for upcoming events
      case 'On-Going':
        return 'warning'; // Yellow color for ongoing events
      case 'Completed':
        return 'success'; // Green color for completed events
      default:
        return 'default'; // Default color for other statuses
    }
  };
  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Card className="card">
          <CardHeader title=<FormattedMessage id="event_details_title" /> className="linear-gradient"
          action={
          <div className={classes.header}>
            <div className={classes.searchContainer}>
              <SearchText
                  searchQuery={searchQuery}
                  handleSearchQueryChange={handleSearchQueryChange}
              />

            </div>
            <AddButton handleName={<FormattedMessage id="create_event"/>} handleClick={toggleEventForm}
            />
          </div>
        }
          />
          <CardContent>

            <TableContainer>
              <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell key={headCell.id}>{headCell.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventData.length > 0 ? (
                      eventData.map((eventData, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={eventData.event_id}>
                        <TableCell component="th" id={labelId} scope="row" padding="none"
                                   sx={{ maxWidth: 150, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                        >

                          <a
                              href="#"
                              onClick={() => handleEventClick(eventData)}
                          >
                            {eventData.event_name}
                          </a>

                        </TableCell>
                        <TableCell>{eventData.hall_name}</TableCell>
                        <TableCell>{eventData.date}</TableCell>
                        <TableCell>
                          <Chip
                              label={getEventStatus(eventData.date).eventStatus}
                              color={getChipColor(getEventStatus(eventData.date).eventStatus)}
                              variant="outlined"
                              size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Edit" arrow>
                            <IconButton onClick={() => handleEditClick(eventData)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" arrow>
                            <IconButton color="error" onClick={() => handleDeleteClick(eventData)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                  ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No data found
                        </TableCell>
                      </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                <DeleteConfirmationModal
                  isOpen={isDeleteModalOpen}
                  onClose={() => setIsDeleteModalOpen(false)}
                  onDelete={handleDeleteConfirmed}
                />

                <EditEventFormModal
                  isOpen={isEditModalOpen}
                  onClose={() => setIsEditModalOpen(false)}
                  onEdit={handleEditEvent}
                  eventData={selectedRowForEdit}
                />
                <CreateEvent isOpen={addEventForm} onClose={handleClose} onAdd={handleAddEventClose}/>

                <EventModal
                    isOpen={isEventModalOpen}
                    onClose={() => setIsEventModalOpen(false)}
                    eventData={selectedRowForEvent}
                />
              </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[ 5, 10, 50,100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Card>
      )}
      <ToastContainer />
    </>
  );
}
