import React, {useState} from "react";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {  MenuItem, Select } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {FormattedMessage} from "react-intl";
import CancelIcon from '@mui/icons-material/Cancel';
import {useDispatch} from "react-redux";
import {setRemovedSubmemberId} from "../../../store/reducers/submemberReducer";
import 'dayjs/locale/en-gb';
import {PhoneEmailCheck} from "../../../service/member.api";
import {errorToast, warningToast} from "../../../shared/components/AlertMessages";


const SubmemberForm = ({ index, formik }) => {
  const submemberFieldName = `submembers[${index}]`;
  const submemberErrors = formik.errors.submembers?.[index] || {};
  const shouldShowErrors = formik.submitCount > 0 && !formik.isValid;
  const submemberData = formik.values.submembers[index];
  const dispatch = useDispatch();

  const [phoneError, setPhoneError] = useState('');

  const handleRemoveSubmember = () => {

    const removedSubmemberId = formik.values.submembers[index].submember_id;
    formik.setFieldValue(
        'submembers',
        formik.values.submembers.filter((_, i) => i !== index)
    );
    dispatch(setRemovedSubmemberId(removedSubmemberId));


  };

  const handleCheck = async (userData, type, user) => {
    try {
      await PhoneEmailCheck(userData, type, user).then((response) => {
        if(response) {
          if (response.data?.status_code === 200 || response.data?.status_code === 201) {
            setPhoneError('')
          }
        }
          }
      )
    } catch (error) {
      if (error.response?.data?.status_code === 404) {
        setPhoneError(error.response?.data?.message);
        warningToast(error.response?.data?.message);
      } else if (error.response?.data?.status_code === 403) {
        setPhoneError(error.response?.data?.message);
        errorToast(error.response?.data?.message);
      } else if (error.response?.data?.status_code === 400) {
        setPhoneError(error.response?.data?.message);
      } else {
        setPhoneError('Something went wrong');
        errorToast('Something went wrong');
      }
    }
  };

  const handlePhoneCheck = () => {
    const phoneValue = submemberData ? submemberData.phone : formik.values[submemberFieldName]?.phone;
    handleCheck(phoneValue, 'phone', 'submember');
  };
  return (

    <Card className="card">
      <CardContent>

      <Grid container spacing={1} key={index} >

        {index !== 0 && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CancelIcon onClick={handleRemoveSubmember} style={{color:'red'}}/>
            </Grid>
        )}
        <Grid item xs={12} sx={{ width: '100%' }}>
          <Typography variant="subtitle1">Sub-member {index + 1}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">
            <FormattedMessage id="first_name" /><span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            fullWidth
            id={`${submemberFieldName}.first_name`}
            name={`${submemberFieldName}.first_name`}
            variant="outlined"
            size="small"
            value={submemberData? submemberData.first_name :formik.values[submemberFieldName]?.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.first_name && Boolean(submemberErrors.first_name)}
            helperText={formik.touched.first_name && submemberErrors.first_name}
          />

        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">
            <FormattedMessage id="middle_name" /><span style={{ color: 'red' }}>*</span>
          </Typography>

          <TextField
            fullWidth
            id={`${submemberFieldName}.middle_name`}
            name={`${submemberFieldName}.middle_name`}
            variant="outlined"
            size="small"
            value={submemberData? submemberData.middle_name :formik.values[submemberFieldName]?.middle_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.middle_name && Boolean(submemberErrors.middle_name)}
            helperText={formik.touched.middle_name && submemberErrors.middle_name}
          />

        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">
            <FormattedMessage id="last_name" /><span style={{ color: 'red' }}>*</span>
          </Typography>

          <TextField
            fullWidth
            id={`${submemberFieldName}.last_name`}
            name={`${submemberFieldName}.last_name`}
            variant="outlined"
            size="small"
            value={submemberData? submemberData.last_name :formik.values[submemberFieldName]?.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.last_name && Boolean(submemberErrors.last_name)}
            helperText={formik.touched.last_name && submemberErrors.last_name}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">
            <FormattedMessage id="gender" /><span style={{ color: 'red' }}>*</span>
          </Typography>
          <Select
            id={`${submemberFieldName}.gender`}
            name={`${submemberFieldName}.gender`}
            value={submemberData? submemberData.gender :formik.values[submemberFieldName]?.gender}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            variant="outlined"
            size="small"
            error={shouldShowErrors && Boolean(submemberErrors.gender)}
            helperText={formik.touched.gender && submemberErrors.gender}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
          </Select>
          {shouldShowErrors && submemberErrors.gender && (
              <div style={{ color: 'red', fontSize: '12px' }}>{submemberErrors.gender}</div>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">
            <FormattedMessage id="dob" />
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DatePicker
              disableFuture
              placeholder="Date picker"
              id={`${submemberFieldName}.dob`}
              name={`${submemberFieldName}.dob`}
              // inputFormat="MM/DD/YYYY"
              value={submemberData? submemberData.dob :formik.values[submemberFieldName]?.dob}
              onChange={(value) => {
                formik.setFieldValue(`${submemberFieldName}.dob`, value);
              }}
              onBlur={formik.handleBlur}
              // sx={{ width: '100%' }} // Adjust the width as per your requirement
              sx={{
                width: '100%',
                borderRadius: '4px', // Set the border radius to make it round
                ...(formik.touched.dob && submemberErrors.dob && { border: '1px solid red' })
              }}
            />
          </LocalizationProvider>
          {shouldShowErrors && submemberErrors.dob && <div style={{ color: 'red', fontSize: '12px' }}>{submemberErrors.dob}</div>}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1"><FormattedMessage id="mobile_num" /></Typography>

          <TextField
            fullWidth
            id={`${submemberFieldName}.phone`}
            name={`${submemberFieldName}.phone`}
            variant="outlined"
            size="small"
            value={submemberData? submemberData.phone :formik.values[submemberFieldName]?.phone}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            onBlur={(e) => {
              formik.handleBlur(e);
              handlePhoneCheck(e.target.name);
            }}
            error={formik.touched.phone && Boolean(submemberErrors.phone)}
            helperText={(formik.touched.phone && submemberErrors.phone) }
          />
          {phoneError && (
            <div style={{ color: 'red', fontSize: '12px' }}>{phoneError}</div>
          )}
        </Grid>
      </Grid>
      </CardContent>
    </Card>
  );
};

export default SubmemberForm;
