import React from 'react';
import { LOCALES } from '../../i18n/locales';
import { NativeSelect } from '@mui/material';

function LanguageSelector({ currentLocale, setLocale }) {

    const handleChangeLocale = (e) => {
    setLocale(e.target.value);
  };

  return (
    <div>
      <NativeSelect style={{ marginLeft: '20px' }} id="locale" value={currentLocale} onChange={handleChangeLocale}>
        {Object.values(LOCALES).map((locale) => (
          <option key={locale} value={locale}>
            {locale}
          </option>
        ))}
      </NativeSelect>
    </div>
  );
}

export default LanguageSelector;
