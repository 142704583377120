import React from 'react';
import { Grid, Stack } from '@mui/material';
import TicketAllocation from './forms/TicketAllocation';

export const AllocateTicket = () => {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{mb: {xs: -0.5, sm: 0.5}}}>
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <TicketAllocation />
            </Grid>
        </Grid>
    )
}
