import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useCallback, useEffect, useRef, useState } from 'react';
import { errorToast, successToast, warningToast } from '../../../shared/components/AlertMessages';
import { ToastContainer } from 'react-toastify';
import { SearchText } from '../../../shared/components/SearchText';
import DeleteIcon from '@mui/icons-material/Delete';
import { getNonVerifySubmembers, searchSubmember, VerifySubMember } from '../../../service/submember.api';
import { getId } from '../../../shared/helpers/authentication';
import { FormattedMessage } from 'react-intl';
import LoadingScreen from '../../../shared/components/LoadingScreen';
import { CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import ViewSubmemberDetailsModal from '../forms/ViewSubmemberDetailsModal';
import Chip from '@mui/material/Chip';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="member_name_column" />
  },

  {
    id: 'submember',
    numeric: false,
    disablePadding: false,
    label: <FormattedMessage id="submember_name" />
  },
  {
    id: 'payment_status',
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="payment_status" />
  },

  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="action" />
  }
];

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          View Events
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

export default function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [submemberData, setSubMemberData] = React.useState([]);
  const [count, setCount] = useState(0);

  const [searchQuery, setSearchQuery] = useState('');
  const isFirstRender = useRef(true); // Ref to track the initial render
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowForSubMember, setSelectedRowForSubMember] = useState(null);
  const [isSubMemberModalOpen, setIsSubMemberModalOpen] = useState(false);

  const member_id = getId();

  const handleSearchQueryChange = (event) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery); // Update the searchQuery state with the new value
    searchAndDisplayData(newSearchQuery); // Call searchAndDisplayData with the new search query
  };
  const searchAndDisplayData = (searchQuery) => {
    // setIsLoading(true);
    if (searchQuery === '' || searchQuery === undefined) {
      view(page, rowsPerPage);
    } else {
      setTimeout(() => {
        searchSubmember(searchQuery, member_id)
          .then((response) => {
            if (response) {
              setIsLoading(false);
              if (response?.status_code === 200 || response?.status_code === 201) {
                // successToast(response?.message);
                setSubMemberData(response?.data);
              }
            }
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.response?.data?.status_code === 404) {
              setSubMemberData([]);
              setCount(0);
            } else if (error.response?.data?.status_code === 403) {
              errorToast(error.response?.data?.message);
              setSubMemberData([]);
            } else if (error.response?.data?.status_code === 400) {
              errorToast(error.response?.data?.message);
              setSubMemberData([]);
            } else {
              errorToast('something went wrong');
              setSubMemberData([]);
            }
          });
      }, 400);
    }
  };

  const view = useCallback(
    (page, rowsPerPage) => {
      setIsLoading(true);
      setTimeout(() => {
        getNonVerifySubmembers(page + 1, rowsPerPage)
          .then((response) => {
            if (response) {
              setIsLoading(false);
              if (response?.status_code === 200 || response?.status_code === 201) {
                setSubMemberData(response.data?.submember_list);
                setCount(response.data?.total_item);
              }
            }
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.response?.data?.status_code === 404) {
              setSubMemberData([]);
              setCount(0);
            } else if (error.response?.data?.status_code === 403) {
              errorToast(error.response?.data?.message);
            } else if (error.response?.data?.status_code === 400) {
              errorToast(error.response?.data?.message);
            } else {
              errorToast('something went wrong');
            }
          });
      }, 400);
    },
    [member_id, setSubMemberData, setCount]
  );

  const handleAccept = (selectedMember) => {
    let data = {
      sub_member_id: selectedMember.submember_id,
      is_verified: true
    };
    VerifySubMember(data)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);
            // const updatedData = submemberData.map((row) =>
            //     row.submember_id === selectedMember.submember_id ? { ...row, sub_member_payment_status: true } : row
            // );
            const updatedData = submemberData.filter((row) => row.submember_id !== selectedMember.submember_id);
            setSubMemberData(updatedData);
            view(page, rowsPerPage);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast('something went wrong');
        }
      });
  };

  useEffect(() => {
    if (isFirstRender.current) {
      view(page, rowsPerPage);
      isFirstRender.current = false; // Set to false after the initial render
    }
  }, [page, rowsPerPage, view]); // Include page and rowsPerPage in the dependency array

  const handleChangePage = (event, newPage) => {
    view(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);

    // Ensure that the current page is valid with the new rowsPerPage
    const newPage = Math.floor(page * (rowsPerPage / newRowsPerPage));

    view(newPage, newRowsPerPage); // Fetch data with the new rowsPerPage
    setRowsPerPage(newRowsPerPage); // Update rowsPerPage
    setPage(newPage); // Update the page to the new calculated page
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const handleSubMemberClick = (submemberData) => {
    setSelectedRowForSubMember(submemberData);
    setIsSubMemberModalOpen(true);
  };
  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Card className="card">
          <CardHeader
            title={<FormattedMessage id="manage_submembers" />}
            className="linear-gradient"
            action={
              <div>
                <div>
                  <SearchText searchQuery={searchQuery} handleSearchQueryChange={handleSearchQueryChange} />
                </div>
              </div>
            }
          ></CardHeader>
          <CardContent>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id}>{headCell.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submemberData.length > 0 ? (
                      submemberData.map((submemberData, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={submemberData.submember_id} sx={{ cursor: 'pointer' }}>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {submemberData.member_name}
                        </TableCell>
                        <TableCell>
                          {' '}
                          {`${submemberData.first_name} ${submemberData.middle_name} ${submemberData.last_name}`}{' '}
                          <Tooltip title="Show Details" arrow>
                            <IconButton color="primary" onClick={() => handleSubMemberClick(submemberData)}>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {submemberData.is_verified === false ? (
                            <Chip label="Waiting for Approval" color="warning" size="small" />
                          ) : (
                            <Chip label="Pending" color="success" size="small" />
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => handleAccept(submemberData)}
                            disabled={submemberData.is_verified}
                          >
                            Approve
                          </Button>
                          <Button type="submit" variant="outlined"  size="small"
                                  color="error" style={{ marginLeft: '10px' }}>
                            Reject
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                    ) : (
                    <TableRow>
                    <TableCell colSpan={6} align="center">
                  No data found
                </TableCell>
              </TableRow>
              )}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <ViewSubmemberDetailsModal
                  isOpen={isSubMemberModalOpen}
                  onClose={() => setIsSubMemberModalOpen(false)}
                  submemberData={selectedRowForSubMember}
                />
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[ 5, 10, 50,100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Card>
      )}
      <ToastContainer />
    </>
  );
}
