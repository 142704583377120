import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function GroupCard({title,onParticipateClick,link}) {
    return (
        <Card sx={{ minWidth: 150 }}>
            <CardContent>

                <Typography variant ="subtitle1" sx={{ fontSize: 14 }} color="text.black" gutterBottom>
                    {title}
                </Typography>
                <Box display="flex" alignItems="center">
                    <Button size="small" onClick={onParticipateClick} >{link}</Button>
                    </Box>

            </CardContent>

        </Card>
    );
}