import {Route, Routes, useLocation} from "react-router-dom";
import PublicHeader from "./PublicHeader";
import PaymentDetails from "../../pages/payment/PaymentDetails";
import UpdateMemberForm from "../../pages/member/forms/UpdateMemberForm";
import Unauthorized from "../../pages/authentication/auth-forms/Unauthorized";
import ExpiredMembership from "../../pages/authentication/auth-forms/ExpiredMembership";
import PaymentGateway from "../../pages/payment/PaymentGateway";
import UpdateRegister from "../../pages/member/UpdateRegister";
import TicketGenerator from "../../pages/ticket/TicketGenerator";
import TicketUnauthorizedPage from "../../pages/ticket/TicketUnauthorizedPage";
import RegistrationClosedPage from "../../pages/member/forms/RegistrationClosedPage";
import * as React from "react";
import RegisterComponent from "../../pages/register/RegisterComponent";

const PublicLayout = () => {
    const location = useLocation();


    return (
        <>
            {!location.pathname.includes("/p") && <PublicHeader/>}
            <Routes>
                <Route
                    path="/new-registration"
                    element={<RegisterComponent/>}
                />
                <Route path="/payment/details" element={<PaymentDetails/>}/>
                <Route
                    path="/renew-membership/details"
                    element={<UpdateMemberForm/>}
                />
                <Route path="/user/unverified" element={<Unauthorized/>}/>
                <Route path="/user/expired" element={<ExpiredMembership/>}/>
                <Route path="/payment/gateway" element={<PaymentGateway/>}/>
                <Route path="/update/register" element={<UpdateRegister/>}/>
                <Route path="/p" element={<TicketGenerator/>}/>
                <Route path="/ticket/unauthorized" element={<TicketUnauthorizedPage/>}/>
                <Route path="/close-registration" element={<RegistrationClosedPage/>}/>

            </Routes>
        </>
    );
};

export default PublicLayout;