import React, {useState} from 'react';
import {Box, Grid} from "@mui/material";
import Row from "./Row";
import Stage from "./Stage";
import './hall.css';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

const ThakorBhaiHall = ({seatingData}) => {

    // seatingData.sort((a, b) => b.row_name.localeCompare(a.row_name));

    seatingData.sort((a, b) => {
        const getRowValue = (rowName) => {
            let value = 0;
            for (let i = 0; i < rowName.length; i++) {
                value = value * 26 + (rowName.charCodeAt(i) - 64); // Assuming uppercase letters only
            }
            return value;
        };

        const rowValueA = getRowValue(a.row_name);
        const rowValueB = getRowValue(b.row_name);

        if (rowValueA !== rowValueB) {
            return rowValueB - rowValueA;
        } else {
            // If row names are the same, sort by row name length
            return b.row_name.length - a.row_name.length;
        }
    });

    const reservedSeats = seatingData.filter(seat => seat.reserved_from !== '' && seat.reserved_to !== '');

    const [zoomLevel, setZoomLevel] = useState(1);

    const handleZoomIn = () => {
        setZoomLevel(zoomLevel + 0.1);
    };

    const handleZoomOut = () => {
        if (zoomLevel > 0.2) {
            setZoomLevel(zoomLevel - 0.1);
        }
    };
    return (
        <>
                <Box display="flex" justifyContent="right"
                     style={{marginBottom: "6px"}}>
                    <IconButton color="primary" size="small" onClick={handleZoomIn}>
                        <ZoomInIcon/>
                    </IconButton>

                    <IconButton color="primary" size="small"  onClick={handleZoomOut} style={{marginLeft:"6px"}}>
                        <ZoomOutIcon/>
                    </IconButton>

                </Box>
        <div className="card-hall" >
            <div className="card-content" style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top center' }}>
            <Box display="flex" justifyContent="center" style={{marginTop: "20px"}}>
                <Box display="flex" justifyContent="center" style={{marginBottom: "20px"}}>
                    <Box width={20} height={20} bgcolor="lightBlue" marginRight={1}/>
                    <Typography variant="body1" color="textSecondary">
                        VIP seats
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center" style={{marginBottom: "20px"}}>
                    <Box width={20} height={20} bgcolor="lightgreen" marginRight={1} style={{marginLeft: "20px"}}/>
                    <Typography variant="body1" color="textSecondary">
                        Non-VIP seats
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center" style={{marginBottom: "20px"}}>
                    <Box width={20} height={20} bgcolor="lightgray" marginRight={1} style={{marginLeft: "20px"}}/>
                    <Typography variant="body1" color="textSecondary">
                        Reserved seats
                    </Typography>
                </Box>
            </Box>


                <div>
                    <Grid container className="seat-container">
                    {/*<Grid container className="seat-container" >*/}

                    <div>
                        {/* Group the seating data by row name */}
                    {Object.values(seatingData.reduce((acc, obj) => {
                        const key = obj.row_name;
                        if (!acc[key]) acc[key] = [];
                        acc[key].push(obj);
                        return acc;
                    }, {})).map((rowData, index) => (
                        <RowGroup key={index} rowData={rowData} reservedSeats={reservedSeats} />
                    ))}
                </div>

                <div className="stage">
                    <Stage/>
                </div>
            </Grid>
                </div>
            </div>
        </div>

        </>
    );
};

const RowGroup = ({rowData, reservedSeats}) => {
    return (
        <div className="row-group" >
            {rowData.map((row, index) => (
                <Row
                    key={index}
                    rowLetter={row.row_name}
                    column={row.column_name}
                    startIndex={parseInt(row.column_from)}
                    seatsPerSection={parseInt(row.column_to) - parseInt(row.column_from) + 1}
                    isFirstRow={index === 0}
                    reservedSeats={reservedSeats}
                    seatingData={rowData}


                />
            ))}
        </div>
    );
};

export default ThakorBhaiHall;
