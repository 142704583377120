import { getCookie, removeCookie, setCookie } from './utils';
import { cookieEnum } from '../../enums/cookie.enum';
import decode from 'jwt-decode';
import { Refresh } from '../../service/login.api';
// import {Refresh} from "../../service/login.api";

async function getAccessToken() {
  const accessToken = await getCookie(cookieEnum.CSRF);
  const decoded = decode(accessToken);
  const accessTokenExpiration = new Date(parseInt(decoded.exp * 1000)),
    isAccessTokenExpired = accessTokenExpiration < new Date() || Date.now() + 300000 >= accessTokenExpiration;

  if (isAccessTokenExpired) {
    const RefreshToken = await refreshAccessToken();
    let data = {
      refresh_token: RefreshToken
    };
    const newAccessToken = await Refresh(data);
    removeCookie(cookieEnum.CSRF);
    setCookie(cookieEnum.CSRF, newAccessToken.data.data.Access_Token);
    return newAccessToken.data.data.Access_Token;
  } else {
    return accessToken;
  }
}

function getRole() {
  const role = getCookie(cookieEnum.ROLE);
  return role;
}

function getId() {
  const id = getCookie(cookieEnum.USER);
  return id;
}

async function refreshAccessToken() {
  const token = await getCookie(cookieEnum.REFRESH);
  return token;
}

export function isLoggedIn() {
  const role = getRole();
  return role;
}
export { getAccessToken, refreshAccessToken, getRole, getId };
