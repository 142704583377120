import React, {useState} from 'react';
import Button from '@mui/material/Button';
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from '@mui/icons-material/Send';
import {ConfirmBooking} from "../../../service/event.api";
import {errorToast, successToast, warningToast} from "../../../shared/components/AlertMessages";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from "@mui/material/Tooltip";

export const ConfirmBookingModal = ({isOpen, onClose, event_id, group_id,seatingData,bookingHistory,allocationData,handleAllocationModalClose}) => {
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const navigate = useNavigate();

    const [error, setError] = useState('');

    const handleConfirmClick = () => {
        if (!isChecked1 && !isChecked2) {
            setError('Please select at least one option.');
            return;
        }
        let data;
        if (bookingHistory) {
            data = {
                is_saved: false,
                is_confirmed_booked: true,
                event_id: event_id,
                group_id: group_id,
                is_sms_send: isChecked1,
                is_whatsapp_send: isChecked2,
                confirm_seat_json: [seatingData[0]],
                event_booking_id: allocationData.event_booking_id
            };
        } else {
            data = {
                is_saved: false,
                is_confirmed_booked: true,
                event_id: event_id,
                group_id: group_id,
                is_sms_send: isChecked1,
                is_whatsapp_send: isChecked2,
                confirm_seat_json: [seatingData[0]]
            };
        }

        ConfirmBooking(data)
            .then((response) => {
                if (response) {
                    if (response.status_code === 200 || response.status_code === 201) {
                        successToast(response?.message)
                        if(bookingHistory){
                            onClose();
                            handleAllocationModalClose();
                        }
                        else{
                            setTimeout(() => {
                                navigate('/admin/booking/history');
                            }, 1000);
                        }

                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            })

    };
    const handleCheckboxChange = (e, checkboxNum) => {
        if (checkboxNum === 1) {
            setIsChecked1(e.target.checked);
        } else if (checkboxNum === 2) {
            setIsChecked2(e.target.checked);
        }
        setError('');
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <DialogTitle className="linear-gradient font-weight-bolder">
                Confirm Booking
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent dividers sx={{padding: '20px', textAlign: 'center'}}>

                <Checkbox checked={isChecked1} onChange={(e) => handleCheckboxChange(e, 1)}/>
                <label className="me-5">
                    Send ticket in mobile SMS
                    <sup>
                        <Tooltip title="(₹0.25 per message)">
                            <InfoIcon fontSize="small" color="warning"/>
                        </Tooltip>
                    </sup>
                </label>


                <Checkbox checked={isChecked2} onChange={(e) => handleCheckboxChange(e, 2)}/>
                <label>
                    Send ticket in WhatsApp
                    <sup>
                        <Tooltip title="(₹0.25 per message)">
                            <InfoIcon fontSize="small" color="warning"/>
                        </Tooltip>
                    </sup>
                </label>

                {error && (
                    <Typography color="error" variant="body2" gutterBottom>
                        {error}
                    </Typography>
                )}

                <div className="mt-5 text-danger text-start d-flex">
                    <b>Note: </b>
                    <div className="ms-2">
                        After clicking 'Send', member's seat is Confirmed after that You will not be able to change, and
                        a ticket will be sent to Members.
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{justifyContent: "end"}}>
                <Button color="primary" variant="contained" type="submit" onClick={handleConfirmClick}
                        style={{margin: 8}}>
                    <SendIcon fontSize="small" className="me-2"/>
                    Send Invitation
                </Button>
                <Button onClick={onClose} variant="outlined" style={{margin: 8}}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

