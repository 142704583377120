import React from 'react';
import MembershipDetailsPage from './forms/MembershipDetailsPage';
import {Grid} from "@mui/material";

const MembershipDetails = () => (
    <Grid item xs={12}>

        <MembershipDetailsPage/>
    </Grid>
);

export default MembershipDetails;
