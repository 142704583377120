import {request, requestLogin} from "./common.service";

export function GetRegistrationStatus() {
    return requestLogin({
        url: '/setting/get-registration-status',
        method: 'GET'
    });
}

export function UpdateRegistrationStatus(values) {
    return request({
        url: '/setting/update-registration-status',
        method: 'PUT',
        body: values
    });
}