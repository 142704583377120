import Cookies from 'universal-cookie';
import { cookieEnum } from '../../enums/cookie.enum';
import {appConfigs} from "../../config/envs/app.config";
import moment from 'moment';

const cookie = new Cookies();

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Get individual date and time components
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // Format the date and time
  const formattedDate = `${month}/${day}/${year}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return `${formattedDate}   ${formattedTime}`;
};

export const formatBirthDate = (dateString) => {
  // Parse the given date string into a Date object
  const dateObject = new Date(dateString);

  // Extract month, day, and year components
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getDate().toString().padStart(2, '0');
  const year = dateObject.getFullYear();

  // Format the components into "MM/DD/YYYY" format
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
};

function setCookie(name, value) {
  cookie.set(name, value, { domain: 'localhost', path: '/' });
  cookie.set(name, value, {domain: appConfigs.REACT_APP_DOMAIN, path: '/'});
}

function getCookie(name) {
  return cookie.get(name);
}

function removeCookie(name) {
    cookie.remove(name, {domain: 'localhost', path: '/'});
    cookie.remove(name, {domain: appConfigs.REACT_APP_DOMAIN, path: '/'});
}

function removeLoginData() {
  removeCookie(cookieEnum.CSRF);
  removeCookie(cookieEnum.REFRESH);
  removeCookie(cookieEnum.ROLE);
  removeCookie(cookieEnum.USER);
  removeCookie(cookieEnum.FIRST_NAME);
  removeCookie(cookieEnum.LANGUAGE);
  removeCookie(cookieEnum.ORDER);
}

function capitalizeName(name) {
  return name.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

function getEventStatus(eventDateTime) {
  const currentDateTime = moment(); // Get current date and time

  const eventDate = moment(eventDateTime, 'MM/DD/YYYY HH:mm:ss');

  let eventStatus;
  if (currentDateTime.isBefore(eventDate)) {
    eventStatus = 'Upcoming';
  } else if (currentDateTime.isSameOrAfter(eventDate.add(2, 'hours'))) {
    eventStatus = 'Completed';
  } else {
    eventStatus = 'On-Going';
  }

  return {eventStatus};
}


// function removeLogout
export { setCookie, getCookie, removeCookie, removeLoginData ,capitalizeName,getEventStatus};
