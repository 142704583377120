import { request, requestLogin } from './common.service';
export function createGroup(values) {
  return request({
    url: '/admin/create-group',
    method: 'POST',
    body: values
  });
}

export function viewGroup(page_number, items_per_page) {
  return request({
    url: `/admin/get-all-group?page_number=${page_number}&items_per_page=${items_per_page}`,
    method: 'GET'
  });
}

export function deleteGroup(group_id) {
  return request({
    url: `/admin/delete-group?group_id=${group_id}`,
    method: 'DELETE'
  });
}

export function updateGroup(values) {
  return request({
    url: '/admin/update-group',
    method: 'PUT',
    body: values
  });
}

export function searchGroup(searching_data) {
  return request({
    url: `/admin/search-group?searching_data=${searching_data}`,
    method: 'GET'
  });
}

export function getGroups() {
  return requestLogin({
    url: '/admin/get-all-group',
    method: 'GET'
  });
}

export function filterMembersByGroup(group_id,page_number, items_per_page) {
  return request({
    url: `/user/filter-by-group?group_id=${group_id}&page_number=${page_number}&items_per_page=${items_per_page}`,
    method: 'GET'
  });
}

export function updateGroupType(values) {
  return request({
    url: '/user/update-group-type',
    method: 'PUT',
    body: values
  });
}
