import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  identifier: null,
  responseToken: null,
  showOtpModal: false
};

const memberReducer = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setIdentifier(state, action) {
      state.identifier = action.payload;
    },
    setResponseToken(state, action) {
      state.responseToken = action.payload;
    },
    setShowOtpModal(state, action) {
      state.showOtpModal = action.payload;
    }
  }
});

export default memberReducer.reducer;

export const { setIdentifier, setShowOtpModal, setResponseToken } = memberReducer.actions;
