import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from "@mui/material";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { getRole } from '../../../../shared/helpers/authentication';
import logo from "../../../../assets/images/icons/logo.png";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const role = getRole();
  const destinationLink = role === 'member' ? '/index/member' : role === 'admin' ? '/admin/dashboard' : '/login';

  return (
    // only available in paid version
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Link to={destinationLink} style={{ textDecoration: 'none' }}>

        <img src={logo} alt="Logo" style={{ width: 40, height: 40, marginRight: 16 }} />
        </Link>
        <Link to={destinationLink} style={{ textDecoration: 'none' }}>
          <Typography variant="h4" color="#1E90FF">
            <FormattedMessage id="event_management" />
          </Typography>
        </Link>
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
