import React from 'react';
import { Grid, Stack } from '@mui/material';
import MemberRegisteration from './forms/MemberRegisteration';

const RegisterMember = () => (
  <Grid container spacing={10}>
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}></Stack>
    </Grid>

    <Grid item xs={12}>
      <MemberRegisteration />
    </Grid>
  </Grid>
);

export default RegisterMember;
