import { createSlice } from '@reduxjs/toolkit';
import { LOCALES } from '../../i18n/locales';
import { getCookie } from '../../shared/helpers/utils';
import { cookieEnum } from '../../enums/cookie.enum';

// initial state
const initialState = {
  selectedLocale: getCookie(cookieEnum.LANGUAGE) || LOCALES.ENGLISH // Set your default locale here
};

// ==============================|| SLICE - MENU ||============================== //

const localeReducer = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setSelectedLocale(state, action) {
      state.selectedLocale = action.payload;
    }
  }
});

export default localeReducer.reducer;

export const { setSelectedLocale } = localeReducer.actions;
