import {Button, CardHeader, Chip, Grid} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from "react-intl";
import {getId} from "../../../shared/helpers/authentication";
import {errorToast, warningToast} from "../../../shared/components/AlertMessages";
import {downloadInvoice, viewMembershipDetails} from "../../../service/member.api";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import DownloadIcon from "@mui/icons-material/Download";
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import logo from "./../../../assets/images/icons/logo.png";
import html2pdf from "html2pdf.js";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";



const MembershipDetailsPage = () => {

    const member_id = getId();
    const pdfRef = useRef(null);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [membershipDetails, setMembershipDetails] = useState([]);

    const view = () => {
        setIsLoading(true);
        setTimeout(() => {
            viewMembershipDetails(member_id)
                .then((response) => {
                    if (response) {
                        setIsLoading(false);
                        if (
                            response?.status_code === 200 ||
                            response?.status_code === 201
                        ) {
                            setMembershipDetails(response?.data)
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.response?.data?.status_code === 404) {
                        warningToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                    } else {
                        errorToast("something went wrong");
                    }
                });
        }, 400);
    };

    useEffect(() => {
        view();
        handleDownloadInvoice();
    }, []);

    const handleDownloadInvoice = async () => {
        // try {
        await downloadInvoice(member_id)
            .then(response => {
                setPaymentDetails(response.data);

            }).catch(error => console.error('Error downloading the file:', error));
    }

    const generatePDF = () => {
        const element = document.getElementById("dev");
        const options = {
            margin: [0, 0, 0, 12],
            filename: "lohana-milan-payment-details.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        // Clone the element
        const clonedElement = element.cloneNode(true);

        // Change display of cloned element
        clonedElement.style.display = "block";

        // Remove the original element from the document
        element.parentNode.removeChild(element);

        // Generate PDF from the cloned element
        html2pdf().from(clonedElement).set(options).save();

        // Restore the original element
        document.body.appendChild(element);
    };
    return (
        <>
            {
                isLoading ? (
                    <LoadingScreen/>
                ) : (
                    <Card className="card">
                        <CardHeader title=<FormattedMessage id="payment_details"/> className="linear-gradient"
                        action={
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={generatePDF} // Call handleDownloadInvoice when button is clicked

                        >
                            Download Invoice
                            <DownloadIcon/>

                        </Button>
                    }
                        />
                        <CardContent>

                            <Grid container columns={{xs: 4, md: 12}}>

                                <Grid item xs={4}>
                                    <span className="fw-bold"><FormattedMessage id="name"/></span>
                                    <p className="text-muted">{membershipDetails.Name}</p>
                                </Grid>

                                <Grid item xs={4}>
                                    <span className="fw-bold"><FormattedMessage id="payment_status"/></span>
                                    <p className="text-muted">
                                        <Chip
                                            color={membershipDetails.member_payment_status ? "success" : "error"}
                                            label={membershipDetails.member_payment_status ? "Complete" : "Pending"}
                                            disabled={false}
                                            size="small"
                                        />
                                    </p>
                                </Grid>

                                {/* Email */}
                                <Grid item xs={4}>
                                    <span className="fw-bold"><FormattedMessage id="email"/></span>
                                    <p className="text-muted">{membershipDetails.email} </p>
                                </Grid>


                                <Grid item xs={4}>
                                    <span className="fw-bold"><FormattedMessage id="mobile_num"/></span>
                                    <p className="text-muted">{membershipDetails.phone}</p>
                                </Grid>

                                <Grid item xs={4}>
                                    <span className="fw-bold"><FormattedMessage id="date"/></span>
                                    <p className="text-muted">{membershipDetails.dob}</p>
                                </Grid>

                                <Grid item xs={4}>
                                    <span className="fw-bold"><FormattedMessage id="membership_type"/></span>
                                    <p className="text-muted">
                                        <Chip
                                            color="primary"
                                            label={membershipDetails.member_type_id}
                                            disabled={false}
                                            size="small"
                                            className="linear-gradient"
                                        />
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <span className="fw-bold"><FormattedMessage id="method"/></span>
                                    <p className="text-muted">{membershipDetails.payment_type}</p>
                                </Grid>

                                <Grid item xs={4}>
                                    <span className="fw-bold"><FormattedMessage id="no_of_members"/></span>
                                    <p className="text-muted">{membershipDetails.total_submembers + 1}</p>
                                </Grid>

                                <Grid item xs={4}>
                                    <span className="fw-bold"><FormattedMessage id="amount"/></span>
                                    <p className="text-muted">{membershipDetails.payment_amount}</p>
                                </Grid>

                                <Grid item xs={4}>
                                    <span className="fw-bold"><FormattedMessage id="transac_id"/></span>
                                    <p className="text-muted">{membershipDetails.transaction_id || (<HorizontalRuleIcon/>)}</p>
                                </Grid>
                            </Grid>


                            <div className="card" style={{display:'none'}}>
                                <body
                                    style={{
                                        background: "#e2e1e0",
                                        fontFamily: "Open Sans, sans-serif",
                                        fontSize: "100%",
                                        fontWeight: "400",
                                        lineHeight: "1.4",
                                        color: "#000",
                                        padding: "1px"
                                    }}>

                                <div ref={pdfRef} id='dev' style={{
                                    maxWidth: "670px",
                                    margin: "50px auto 10px",
                                    backgroundColor: "#fff",
                                    padding: "50px",
                                    borderRadius: "3px",
                                    boxShadow:
                                        "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
                                    borderTop: "solid 10px red",
                                    display: "none"
                                }}>
                                    <table

                                    >
                                        <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                }}
                                                colSpan="3"
                                            >
                                                <img
                                                    style={{maxWidth: "60px", height: "60px"}}
                                                    src={logo}
                                                    alt="Lohana Milan"
                                                />
                                                <h4
                                                    style={{
                                                        fontSize: "xx-large",
                                                        margin: "0px 10px",
                                                        color: "red",
                                                        minWidth: "250px",
                                                    }}
                                                >
                                                    Lohana Milan
                                                </h4>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td style={{height: "35px"}}></td>
                                        </tr>
                                        <tr>
                                            <td
                                                colSpan="2"
                                                style={{border: "solid 1px #ddd", padding: "10px 20px"}}
                                            >
                                                <h4>Payment Details</h4>
                                                <p style={{fontSize: "14px", margin: "0 0 6px 0"}}>
                  <span
                      style={{
                          fontWeight: "bold",
                          display: "inline-block",
                          minWidth: "146px",
                      }}
                  >
                    Date
                  </span>{" "}
                                                    {paymentDetails.payment_date}
                                                </p>
                                                <p style={{fontSize: "14px", margin: "0 0 6px 0"}}>
                  <span
                      style={{
                          fontWeight: "bold",
                          display: "inline-block",
                          minWidth: "146px",
                      }}
                  >
                    Type
                  </span>{" "}
                                                    {paymentDetails.payment_type}
                                                </p>
                                                <p style={{fontSize: "14px", margin: "0"}}>
                  <span
                      style={{
                          fontWeight: "bold",
                          display: "inline-block",
                          minWidth: "146px",
                      }}
                  >
                    Amount
                  </span>{" "}
                                                    ₹ {paymentDetails.payment_amount}
                                                </p>

                                                <p style={{fontSize: "14px", margin: "0 0 6px 0"}}>
                  <span
                      style={{
                          fontWeight: "bold",
                          display: "inline-block",
                          minWidth: "150px",
                      }}
                  >
                    Status
                  </span>
                                                    <b
                                                        style={{
                                                            color: "green",
                                                            fontWeight: "normal",
                                                            margin: "0",
                                                        }}
                                                    >
                                                        {paymentDetails.payment_status}
                                                    </b>
                                                </p>
                                                <p style={{fontSize: "14px", margin: "0"}}>
                  <span
                      style={{
                          fontWeight: "bold",
                          display: "inline-block",
                          minWidth: "146px",
                      }}
                  >
                    Receipt/Transaction Id
                  </span>{" "}
                                                    {paymentDetails.transaction_id || <HorizontalRuleIcon/>}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{width: "50%", padding: "20px 20px 0px 20px", verticalAlign: "top"}}
                                            >
                                                <p
                                                    style={{
                                                        margin: "0 0 10px 0",
                                                        padding: "0",
                                                        fontSize: "14px",
                                                    }}
                                                >
                  <span
                      style={{
                          display: "block",
                          fontWeight: "bold",
                          fontSize: "13px",
                      }}
                  >
                    Name
                  </span>{" "}
                                                    {paymentDetails.name}
                                                </p>
                                                <p
                                                    style={{
                                                        margin: "0 0 10px 0",
                                                        padding: "0",
                                                        fontSize: "14px",
                                                    }}
                                                >
                  <span
                      style={{
                          display: "block",
                          fontWeight: "bold",
                          fontSize: "13px",
                      }}
                  >
                    Email
                  </span>{" "}
                                                    {paymentDetails.email}
                                                </p>
                                                <p
                                                    style={{
                                                        margin: "0 0 10px 0",
                                                        padding: "0",
                                                        fontSize: "14px",
                                                    }}
                                                >
                  <span
                      style={{
                          display: "block",
                          fontWeight: "bold",
                          fontSize: "13px",
                      }}
                  >
                    Phone
                  </span>{" "}
                                                    {paymentDetails.phone}
                                                </p>
                                                </td>
                                            <td
                                                style={{width: "50%", padding: "20px 20px 0px 20px", verticalAlign: "top"}}
                                            >
                                                <p
                                                    style={{
                                                        margin: "0 0 10px 0",
                                                        padding: "0",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                          <span
                                                              style={{
                                                                  display: "block",
                                                                  fontWeight: "bold",
                                                                  fontSize: "13px",
                                                                  marginBottom: "5px"
                                                              }}
                                                          >
                                                            Member Type
                                                          </span>
                                                    {/* <span style={{ fontSize: "900" }}>{data.member_type}</span> */}
                                                    <span style={{
                                                        background: "linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%)",
                                                        color: "#000",
                                                        padding: "2px 10px 4px 10px",
                                                        borderRadius: "3px",
                                                        fontWidth: "bold",
                                                    }}>{paymentDetails.member_type}</span>
                                                </p>
                                                <p
                                                    style={{
                                                        margin: "0 0 10px 0",
                                                        padding: "0",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                          <span
                                                              style={{
                                                                  display: "block",
                                                                  fontWeight: "bold",
                                                                  fontSize: "13px",
                                                              }}
                                                          >
                                                            Number of Members
                                                          </span>{" "}
                                                    {paymentDetails.number_of_members}
                                                </p>
                                                <p
                                                    style={{
                                                        margin: "0 0 10px 0",
                                                        padding: "0",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                          <span
                                                              style={{
                                                                  display: "block",
                                                                  fontWeight: "bold",
                                                                  fontSize: "13px",
                                                              }}
                                                          >
                                                            Group Name
                                                          </span>{" "}
                                                    {paymentDetails.group_name?.replace(/\d+$/, "")
                                                        .replace(/(.)\1+/g, "$1") }
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{padding: "0 20px 20px 20px", verticalAlign: "top"}}>
                                                <p
                                                    style={{
                                                        margin: "0 0 0 0",
                                                        padding: "0",
                                                        fontSize: "14px",

                                                    }}
                                                >
                                                          <span
                                                              style={{
                                                                  display: "block",
                                                                  fontWeight: "bold",
                                                                  fontSize: "13px",
                                                              }}
                                                          >
                                                            Address
                                                          </span>{" "}
                                                    {paymentDetails.address}
                                                </p>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td
                                                colSpan="2"
                                                style={{fontSize: "14px", padding: "50px 15px 0 15px"}}
                                            >
                                                <strong style={{display: "block", margin: "0 0 10px 0"}}>
                                                    Regards
                                                </strong>
                                                <b>Lohana Milan</b>
                                                <br/>
                                                B/ 512, Titanium city center, B/h Incometax office, <br/>
                                                100 ft Road, Anandnagar Road Satellite, Vejalpur,
                                                Ahmedabad-380015
                                                <br/>
                                                <br/>
                                                <b>Phone:</b> +91 9512004470
                                                <br/>
                                                <b>Email:</b> contact@lohanamilan.in
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                </body>
                            </div>
                        </CardContent>
                    </Card>
                )
            }
        </>
    );
};

export default MembershipDetailsPage;
