import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// toast.configure();

export function errorToast(msg) {
  return toast.error(msg, {
    transition: Slide,
    closeButton: true,
    autoClose: 1000,
    position: 'top-center'
  });
}

export function successToast(msg) {
  return toast.success(msg, {
    transition: Slide,
    closeButton: true,
    autoClose: 1000,
    position: 'top-center'
  });
}

export function warningToast(msg) {
  return toast.warn(msg, {
    transition: Slide,
    closeButton: true,
    autoClose: 1000,
    position: 'top-center'
  });
}
