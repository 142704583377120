import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import PublicRoutes from "./PublicRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

  return useRoutes([LoginRoutes, MainRoutes,PublicRoutes]);
}
