import React, { useEffect, useState } from "react";
import { errorToast } from "../../shared/components/AlertMessages";
import { Grid } from "@mui/material";
import { getStatsCount } from "../../service/membertype.api";
import PendingApprovalStatistics from "./PendingApprovalStatistics";
import MemberStatistics from "./MemberStatistics";
import GroupStatistics from "./GroupStatistics";
import "./dashboard.css";
import UnverifiedMemberStatistics from "./UnverifiedMemberStatistics";
import {useDispatch} from "react-redux";
import {setTabFlag} from "../../store/reducers/dashboard";

export const AdminDashboard = () => {
  const [verifiedMemberCount, setVerifiedMemberCount] = useState(0);
  const [totalMemberCount, setTotalMemberCount] = useState(0);
  const [totalSubMemberCount, setTotalSubMemberCount] = useState(0);
  const [groupName, setGroupName] = useState([]);
  const [isCountLoading, setIsCountLoading] = useState(false);
  const [totalUnverifiedMemberCount, setTotalUnverifiedMemberCount] =
    useState(0);
  const [totalUnverifiedSubMemberCount, setTotalUnverifiedSubMemberCount] =
    useState(0);

  const fetchStatsCount = () => {
    setIsCountLoading(true);
    setTimeout(() => {
      getStatsCount()
        .then((response) => {
          if (response && response.data) {
            setIsCountLoading(false);
            if (
              response?.status_code === 200 ||
              response?.status_code === 201
            ) {
              setVerifiedMemberCount(
                response?.data[10]?.profile_edit_request_count[0]
                  ?.profile_edit_request_count
              );
              setTotalMemberCount(
                response?.data[2]?.verified_and_payment_complete_member[0]
                  ?.total_verified_and_payment_complete_member
              );
              setTotalSubMemberCount(
                response?.data[3]?.verified_and_payment_complete_sub_member[0]
                  ?.total_verified_and_payment_complete_sub_member
              );
              setGroupName(response?.data[0]?.group_wise_counts);
              setTotalUnverifiedMemberCount(
                response?.data[7]?.unverified_member_count[0]
                  .total_unverified_member_count
              );
              setTotalUnverifiedSubMemberCount(
                response?.data[8]?.unverified_sub_member_count[0]
                  .total_unverified_sub_member_count
              );
            }
          }
        })
        .catch((error) => {
          setIsCountLoading(false);
          if (error.response?.data?.status_code === 404) {
            setVerifiedMemberCount(0);
            setGroupName([]);
            setTotalMemberCount(0);
            setTotalSubMemberCount(0);
            setTotalUnverifiedMemberCount(0);
            setTotalUnverifiedSubMemberCount(0);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast("something went wrong");
          }
        });
    }, 400);
  };

  useEffect(() => {
    fetchStatsCount();
  }, []);

  const dispatch = useDispatch();


  return (
    <>
      {!isCountLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={9} onClick={() => {
                dispatch(setTabFlag('active'))}}>
                <MemberStatistics
                  totalMemberCount={totalMemberCount}
                  totalSubMemberCount={totalSubMemberCount}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <PendingApprovalStatistics
                  verifiedMemberCount={verifiedMemberCount}

                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}  onClick={() => {
        dispatch(setTabFlag('all'))}}>
            <UnverifiedMemberStatistics
              totalUnverifiedMemberCount={totalUnverifiedMemberCount}
              totalUnverifiedSubMemberCount={totalUnverifiedSubMemberCount}
            />
          </Grid>

          {groupName?.map((group, index) => (
            <Grid item xs={12} md={3} key={index}>
              <GroupStatistics
                groupName={group?.group_name}
                totalCount={group?.total_member_count}
                vipCount={group?.all_member_type_count?.[0]?.member_type_count || 0}
                nonvipCount={
                  group?.all_member_type_count?.[1]?.member_type_count || 0
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};
