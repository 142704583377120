import React from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";

export const MemberStatusFilter = ({selectedMemberStatus, handleMemberStatusChange}) => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
                <Box sx={{minWidth: 120}}>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 200}}>
                        <InputLabel id="demo-simple-select-standard-label">Select Member Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectedMemberStatus || ""}
                            onChange={handleMemberStatusChange}
                        >

                            <MenuItem value="verified">Verified</MenuItem>
                            <MenuItem value="non-verified">Non-Verified</MenuItem>
                            <MenuItem value="blocked">Blocked</MenuItem>


                        </Select>
                    </FormControl>
                </Box>

            </Grid>

        </Grid>
    );
};
