import {Chip, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import {FormattedMessage} from "react-intl";
import Typography from "@mui/material/Typography";
import {getMemberSubmember, memberPayment} from "../../../service/member.api";
import {errorToast, warningToast} from "../../../shared/components/AlertMessages";
import {setTotalAmount} from "../../../store/reducers/paymentReducer";


const PaymentDetailsPage = () => {
    const memberDetails = useSelector((state) => state.payment.memberData);
    const dispatch = useDispatch();

    const [updatedDetails, setUpdatedDetails] = useState([]);

    const navigate = useNavigate();
    const submembers_amount = updatedDetails.member?.member_type_price * updatedDetails?.submember_details?.length;
    const total_amount = submembers_amount + parseFloat(updatedDetails?.member?.member_type_price);
    const fetchMembersSubmembers = () => {
        getMemberSubmember(memberDetails.member_details[0].member_id)
            .then((response) => {
                if (response) {
                    if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                        setUpdatedDetails(response.data?.data);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setUpdatedDetails([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
    };

    useEffect(() => {
        fetchMembersSubmembers();
    }, [JSON.stringify(memberDetails)]);

    const handleContinueToPayment = () => {
        let data = {
            "member_id": updatedDetails.member.member_id,
            "member_type_id": updatedDetails.member.member_type_id,
            "total_member": updatedDetails.total_members
        }
        memberPayment(data)
            .then((response) => {
                if (response) {
                    if (
                        response?.data?.status_code === 200 ||
                        response?.data?.status_code === 201
                    ) {
                        dispatch(setTotalAmount(total_amount));
                        navigate('/payment/gateway')
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast("something went wrong");
                }
            })
    }


    const handleEdit = () => {
        navigate('/update/register');
    };


    return (
        <div>
            <Grid container columns={{xs: 4, md: 12}}>
                {/* Full Name */}
                <Grid item xs={4}>
                    <span className="fw-bold"><FormattedMessage id="name"/></span>
                    <p className="text-muted">{`${updatedDetails.member?.first_name} ${updatedDetails.member?.middle_name} ${updatedDetails.member?.last_name}`}</p>
                </Grid>

                {/* Date of Birth */}
                <Grid item xs={4}>
                    <span className="fw-bold"><FormattedMessage id="dob"/></span>
                    <p className="text-muted">{updatedDetails.member?.dob}</p>
                </Grid>

                {/* Gender */}
                <Grid item xs={4}>
                    <span className="fw-bold"><FormattedMessage id="gender"/></span>
                    <p className="text-muted">{updatedDetails.member?.gender}</p>
                </Grid>

                {/* Email */}
                <Grid item xs={4}>
                    <span className="fw-bold"><FormattedMessage id="email"/></span>
                    <p className="text-muted">{updatedDetails.member?.email} </p>
                </Grid>

                {/* Whatsapp Mobile Number */}
                <Grid item xs={4}>
                    <span className="fw-bold"><FormattedMessage id="mobile_num"/></span>
                    <p className="text-muted">{updatedDetails.member?.phone}</p>
                </Grid>

                {/* Address */}
                <Grid item xs={4}>
                    <span className="fw-bold"><FormattedMessage id="address"/></span>
                    <p className="text-muted">{updatedDetails.member?.address}</p>
                </Grid>
            </Grid>

            <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 6, sm: 12, md: 12}}>
                <Grid item xs={6}>
          <span className="fw-bold">
              <FormattedMessage id="total_submembers"/>
</span>
                    <TableContainer>
                        {updatedDetails?.submember_details?.length > 0 && (
                            <Table sx={{minWidth: 600}} aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><FormattedMessage id="name"/></TableCell>
                                        <TableCell><FormattedMessage id="gender"/></TableCell>
                                        <TableCell><FormattedMessage id="dob"/></TableCell>
                                        <TableCell><FormattedMessage id="mobile_num"/></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {updatedDetails?.submember_details?.map((submember, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}
                                                      sx={{cursor: 'pointer'}}>
                                                <TableCell>
                                                    {`${submember.first_name} ${submember.middle_name} ${submember.last_name}`}
                                                </TableCell>
                                                <TableCell>{submember.gender}</TableCell>
                                                <TableCell>{submember.dob}</TableCell>
                                                <TableCell>{submember.phone}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </Grid>


                <Grid item xs={6}>
                    <div className="float-end mb-2">
                        <Chip
                            color="primary"
                            label={`${updatedDetails.member?.member_type} ${'Membership'}`}
                            disabled={false}
                            size="medium"
                            className="linear-gradient"
                        />
                    </div>
                    <TableContainer>
                        <Table border="2" aria-labelledby="tableTitle">
                            <TableBody>
                                <TableRow>
                                    <TableCell><FormattedMessage id="main_member"/></TableCell>
                                    <TableCell> 1 </TableCell>
                                    <TableCell>₹{updatedDetails.member?.member_type_price}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell><FormattedMessage id="total_submembers"/></TableCell>
                                    <TableCell>{updatedDetails?.submember_details?.length}</TableCell>
                                    <TableCell>₹{submembers_amount}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <span className="fw-bold"><FormattedMessage id="total"/></span>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <span className="fw-bold">₹{total_amount}</span>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2" color="error" style={{marginTop: '16px'}}>
                    <FormattedMessage id="note"/>
                </Typography>
            </Grid>
            <div className="display-flex justify-content-between h-25 mt-2">
                <Stack direction="row" spacing={2} justifyContent="flex-start">
                    <Button type="button" variant="outlined" color="primary" onClick={() => handleEdit()}>
                        Edit
                    </Button>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Button type="button" variant="contained" color="primary" onClick={() => handleContinueToPayment()}>
                        Continue to Payment
                    </Button>
                </Stack>
            </div>
        </div>
    );
};

export default PaymentDetailsPage;
