import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Grid, TextField, Button, CircularProgress, InputLabel } from "@mui/material";
import { sendOtp } from '../../../service/member.api';
import { errorToast, warningToast } from '../../../shared/components/AlertMessages';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setOtpToken, setUsername } from '../../../store/reducers/forgotPasswordReducer';

const initialValues = {
  userInput: ''
};

const inputSchema = Yup.object({
  userInput: Yup.string()
    .required('Mobile Number, Membership ID, or Email is Required')
    .test('valid-input', 'Invalid Email/Phone/Membership ID', (value) => {
      // Check if the input is a valid mobile number, membership ID, or email
      const isPhone = isPhoneNumber(value);
      const isUuidMembership = isUuid(value);
      const isEmail = isValidEmail(value);

      return isPhone || isUuidMembership || isEmail;
    })
});

const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const isPhoneNumber = (identifier) => /^\d{10}$/.test(identifier);
const isUuid = (identifier) => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(identifier);

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [sendOTPClicked, setSendOTPClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: inputSchema,
    onSubmit: (values) => {
      setLoading(true);
      setSendOTPClicked(true); // Disable send OTP button
      const identifier = values.userInput;
      dispatch(setUsername(identifier)); // Assuming response.data.data contains the OTP token

      sendOtp(identifier, 'Forgot Password')
        .then((response) => {
          if (response) {
            if (response.data?.status_code === 200 || response.data?.status_code === 201) {
              dispatch(setOtpToken(response.data?.data)); // Assuming response.data.data contains the OTP token
              navigate('/forgot-password/otp')
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          }
          else {
            errorToast(error.response?.data?.message);

          }
        })
        .finally(() => {
          setSendOTPClicked(false);
          setLoading(false);
        });
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel >
            Mobile Number/Membership ID/Email<span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <TextField
            fullWidth
            id="userInput"
            name="userInput"
            value={formik.values.userInput}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.userInput && Boolean(formik.errors.userInput)}
            helperText={formik.touched.userInput && formik.errors.userInput}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <Button type="submit" variant="contained" color="primary" disabled={sendOTPClicked} style={{marginTop:"10px"}}>
            {loading ? <CircularProgress size={24} /> : 'Send OTP'}
          </Button>
        </Grid>
      </Grid>
      <ToastContainer />
    </form>
  );
};

export default ForgotPasswordForm;
