import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const EditMemberTypeForm = ({ isOpen, onClose, onEdit, memberTypeData }) => {
  const [editedData, setEditedData] = useState({ ...memberTypeData });
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    setEditedData({ ...memberTypeData });
  }, [memberTypeData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Perform field-level validation using Yup
    const fieldValidationSchema = Yup.object().shape({
      [name]: validationSchema.fields[name],
    });

    fieldValidationSchema
      .validateAt(name, { [name]: value }) // Validate the specific field
      .then(() => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined, // Clear the error for the current field
        }));
      })
      .catch((error) => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message, // Set the error message for the current field
        }));
      });

    setEditedData({ ...editedData, [name]: value });
  };

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .typeError("Enter a valid number")
      .positive("Price must be positive"),
    member_type: Yup.string()
      .matches(/^[^0-9]*$/, "Member Type cannot contain numbers")
      .required("Member Type Required"),
  });
  const handleSubmit = (e) => {
    e.preventDefault();

    validationSchema
      .validate(editedData, { abortEarly: false }) // Validate all fields and collect all errors
      .then(() => {
        setValidationErrors({});
        onEdit(editedData);
      })
      .catch((validationErrors) => {
        const errors = {};
        validationErrors.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        setValidationErrors(errors);
      });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle className="linear-gradient">
          <FormattedMessage id="edit_member_type" />
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TextField
            fullWidth
            name="member_type"
            label=<FormattedMessage id="member_type" />
            value={editedData.member_type}
            onChange={handleChange}
            error={!!validationErrors.member_type}
            helperText={validationErrors.member_type || ""}
          />

          <TextField
            fullWidth
            label=<FormattedMessage id="price" />
            name="price"
            value={editedData.price}
            onChange={handleChange}
            required
            margin="normal"
            error={!!validationErrors.price}
            helperText={validationErrors.price || ""}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: "end" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ margin: 8 }}
          >
            <FormattedMessage id="edit" />
          </Button>
          <Button onClick={onClose} variant="outlined" style={{ margin: 8 }}>
            <FormattedMessage id="cancel" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditMemberTypeForm;
