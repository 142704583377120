import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Button,
  OutlinedInput,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";
import { errorToast, successToast, warningToast } from "./AlertMessages";
import { BulkRegister } from "service/member.api";
import "../css/BulkUploadModal.css";
import { Label } from "../../../node_modules/@mui/icons-material/index";

const BulkUploadModal = ({ isOpen, onClose, handleFetchData }) => {
  const fileInputRef = React.useRef(null);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [fileName, setFileName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const fileSizeLimit = 10 * 1024 * 1024; // 10MB limit
    if (file.size > fileSizeLimit) {
      setFileError("File size should not exceed 10MB.");
      return;
    }
    setFile(file);
    setFileName(file.name);
    setFileError("");
  };

  const handleFileUpload = () => {
    if (!file) {
      setFileError("Please select a file.");
      return;
    }
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("file", file);
    BulkRegister(formData)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);
            onClose()
            handleFetchData()
          }
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
        setIsSubmitting(false);
      });
  };

  // Reset form values when form is closed
  React.useEffect(() => {
    if (!isOpen && isFormOpen) {
      setFile(null);
      setFileName("");
      setFileError("");
      setIsFormOpen(false);
    }
  }, [isOpen, isFormOpen]);

  // Set form open state when form is opened
  React.useEffect(() => {
    if (isOpen) {
      setIsFormOpen(true);
    }
  }, [isOpen]);
  const handleDeleteFile = () => {
    setFile(null);
    setFileName("");
    setFileError("");
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      fullWidth
      
    >
      <DialogTitle className="linear-gradient">
        <FormattedMessage id="imp_member_details" />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <label className="text-muted">
                Select File
              </label>

              <OutlinedInput
                className="file-input"
                error={!!fileError}
                id="outlined-adornment-weight"
                placeholder="Choose a file..."
                value={fileName}
                endAdornment={
                  <InputAdornment position="end">
                    {/* <Grid container alignItems="right"> */}
                    {file && (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={handleDeleteFile}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                    
                        <Button
                          size="medium"
                          // variant="outlined"
                          className="right"
                          component="span"
                          onClick={() => fileInputRef.current.click()}
                        >
                          Browse
                        </Button>
                     
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileInputChange}
                          style={{ display: "none" }}
                        />
                    {/* </Grid> */}
                  </InputAdornment>
                }
              />
              
              {fileError && <FormHelperText error>{fileError}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {/* <p id="modal-description" className="text-muted">
              Download <span style={{ color: "#1890ff" }}>Sample Template</span>
            </p> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "end" }}>
        <Button
          onClick={handleFileUpload}
          variant="contained"
          color="primary"
          style={{ margin: 8 }}
          disabled={isSubmitting}
        >
          <FormattedMessage id="upload" />
        </Button>
        <Button onClick={onClose} variant="outlined" style={{ margin: 8 }}>
          <FormattedMessage id="cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkUploadModal;
