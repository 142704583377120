import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const ComplaintResponse = ({ isOpen, onClose, onEdit, complaintData }) => {
  const [editedData, setEditedData] = useState({ ...complaintData });

  useEffect(() => {
    setEditedData({ ...complaintData });
  }, [complaintData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const handleSubmit = (event) => {
    let data = {
      complaint_id: editedData.complaint_id,
      complaint_response: editedData.complaint_response
    };
    onEdit(data);
    event.preventDefault();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit}>
          <input type="hidden" value={editedData.complaint_id} />

          <Typography variant="subtitle1" component="div" gutterBottom>
            <FormattedMessage id="complaint_response" /> <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            multiline={true}
            name="complaint_response"
            placeholder="Enter your response"
            value={editedData.complaint_response}
            onChange={handleChange}
            rows={4}
            cols={40}
          />

          <br></br>
          <br></br>
          <Button type="submit" variant="contained" color="primary">
            <FormattedMessage id="save_changes" />
          </Button>
          <Button onClick={onClose} variant="contained" style={{ marginLeft: '20px' }}>
            <FormattedMessage id="cancel" />
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default ComplaintResponse;
