import {request, requestForm} from './common.service';
export function createEvent(formData) {
  return requestForm({
    url: '/admin/create-event',
    method: 'POST',
    body: formData
  });
}

export function SeatAllocation(values) {
  return request({
    url: '/admin/event/seat-allocation',
    method: 'POST',
    body: values
  });
}
export function viewEvent(page_number, items_per_page) {
  return request({
    url: `/admin/get-all-events?page_number=${page_number}&items_per_page=${items_per_page}`,
    method: 'GET'
  });
}
export function getEvents() {
  return request({
    url: '/admin/get-all-events',
    method: 'GET'
  });
}

export function getEvent(event_id) {
  return request({
    url: `/admin/get-event-by-id?event_id=${event_id}`,
    method: 'GET'
  });
}

export function deleteEvent(event_id) {
  return request({
    url: `/admin/delete-event?event_id=${event_id}`,
    method: 'DELETE'
  });
}

export function updateEvent(formData) {
  return requestForm({
    url: '/admin/update-event',
    method: 'PUT',
    body: formData
  });
}

export function searchEvent(searching_data) {
  return request({
    url: `/admin/search-event?searching_data=${searching_data}`,
    method: 'GET'
  });
}

export function registeredEventMembers(event_id) {
  return request({
    url: `/admin/get-all-registered-members-by-event-id?event_id=${event_id}`,
    method: 'GET'
  });
}

export function ConfirmBooking(values) {
  return request({
    url: '/admin/event/confirm-booking',
    method: 'POST',
    body: values
  });
}

export function BookingHistory() {
  return request({
    url: `/admin/event/fetch-all-confirm-event-booking?is_filter=${false}`,
    method: 'GET'
  });
}

export function filterBookingHistory(start_date,end_date) {
  return request({
    url: `/admin/event/fetch-all-confirm-event-booking?is_filter=${true}&start_date=${start_date}&end_date=${end_date}`,
    method: 'GET'
  });
}

export function fetchConfirmSeatData(event_id,group_id) {
  return request({
    url: `/admin/event/fetch-confirm-seat-data?event_id=${event_id}&group_id=${group_id}`,
    method: 'GET'
  });
}

export function fetchInvitationData(page_number,items_per_page,event_id,group_id,searching_value,sorting_value) {
  return request({
    url: `/admin/get-all-registered-members-details?page_number=${page_number}&items_per_page=${items_per_page}&event_id=${event_id}&group_id=${group_id}&searching_value=${searching_value}&sorting_value=${sorting_value}`,
    method: 'GET'
  });
}

export function resentTicket(values) {
  return request({
    url: '/admin/event/resent-ticket',
    method: 'POST',
    body: values
  });
}

export function excelFileCreation(event_id,group_id,make_excel) {
  return request({
    url: `/admin/get-all-registered-members-details?event_id=${event_id}&group_id=${group_id}&make_excel=${make_excel}`,
    method: 'GET'
  });
}

export function deletePendingSeatAllocation(event_booking_id) {
  return request({
    url: `/admin/event-booking/delete-pending-seat-allocation?event_booking_id=${event_booking_id}`,
    method: 'DELETE'
  });
}
