import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { createGroup } from "../../../service/group.api";
import {
  successToast,
  errorToast,
  warningToast,
} from "../../../shared/components/AlertMessages";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const AddGroup = ({ isOpen, onClose, onAdd }) => {
  const formik = useFormik({
    initialValues: {
      group_name: "",
      group_limit: "",
      vip_limit: "",
    },

    validationSchema: Yup.object({
      group_name: Yup.string()
        .required("Group Name Required"),
      group_limit: Yup.number()
        .typeError("Enter a valid number")
        .positive("Group Limit must be positive")
        .required("Group Limit Required"),
      vip_limit: Yup.number()
        .typeError("Enter a valid number")
        .positive("VIP Limit must be positive")
        .max(Yup.ref("group_limit"), "VIP Limit can not exceed group Limit")
        .required("VIP Limit Required"),
    }),
    onSubmit: (values) => {
      createGroup(values)
        .then((response) => {
          if (response) {
            if (
              response?.status_code === 200 ||
              response?.status_code === 201
            ) {
              successToast(response?.message);
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast("something went wrong");
          }
        })
        .finally(() => {
          onAdd();
        });
    },
  });

  useEffect(() => {
    if (!isOpen) {
        formik.resetForm();
    }
}, [isOpen]);
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle className="linear-gradient">
          <FormattedMessage id="group_side_menu" />
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} justifyContent="center">
              <Typography variant="subtitle1">
                <FormattedMessage id="group_name" />
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="group_name"
                name="group_name"
                variant="outlined"
                size="small"
                value={formik.values.group_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.group_name && Boolean(formik.errors.group_name)
                }
                helperText={
                  formik.touched.group_name && formik.errors.group_name
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <FormattedMessage id="group_limit" />
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="group_limit"
                name="group_limit"
                variant="outlined"
                size="small"
                value={formik.values.group_limit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // onChange={(e) => {
                //   formik.handleChange(e);
                //   formik.handleBlur(e);
                // }}
                error={
                  formik.touched.group_limit &&
                  Boolean(formik.errors.group_limit)
                }
                helperText={
                  formik.touched.group_limit && formik.errors.group_limit
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <FormattedMessage id="vip_limit" />
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="vip_limit"
                name="vip_limit"
                variant="outlined"
                size="small"
                value={formik.values.vip_limit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // onChange={(e) => {
                //   formik.handleChange(e);
                //   formik.handleBlur(e);
                // }}
                error={
                  formik.touched.vip_limit && Boolean(formik.errors.vip_limit)
                }
                helperText={formik.touched.vip_limit && formik.errors.vip_limit}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "end" }}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            style={{ margin: 8 }}
          >
            <FormattedMessage id="add" />
          </Button>
          <Button onClick={onClose} variant="outlined" style={{ margin: 8 }}>
            <FormattedMessage id="cancel" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddGroup;
