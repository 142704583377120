import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const EditSubMemberForm = ({ isOpen, onClose, onEdit, submemberData }) => {
  const [editedData, setEditedData] = useState({ ...submemberData });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    setEditedData({ ...submemberData });
  }, [submemberData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Perform field-level validation using Yup
    const fieldValidationSchema = Yup.object().shape({
      [name]: validationSchema.fields[name]
    });

    fieldValidationSchema
      .validateAt(name, { [name]: value }) // Validate the specific field
      .then(() => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined // Clear the error for the current field
        }));
      })
      .catch((error) => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message // Set the error message for the current field
        }));
      });

    setEditedData({ ...editedData, [name]: value });
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date); // Format the date as DD-MM-YYYY
    setEditedData({ ...editedData, dob: formattedDate });
  };

  const validationSchema = Yup.object().shape({
    dob: Yup.date().max(new Date(), 'Please select a valid date').required('Dob Required').nullable(),
    gender: Yup.string().required('Gender Required'),
    phone: Yup.string().test({
      name: 'phone',
      test: (value) => {
        // Check if the phone number is provided and has a valid format
        if (value && value.length > 0) {
          return /^\d{10}$/.test(value);
        }
        return true; // If phone number is not provided, it's considered valid
      },
      message: 'Mobile number must be 10 digits'
    }).nullable(),
    first_name: Yup.string()
      .matches(/^[^0-9]*$/, 'First name cannot contain numbers')
      .required('First name Required'),
    last_name: Yup.string()
      .matches(/^[^0-9]*$/, 'Last name cannot contain numbers')
      .required('Last name Required'),
    middle_name: Yup.string()
      .matches(/^[^0-9]*$/, 'Middle name cannot contain numbers')
      .required('Middle name Required')
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    validationSchema
      .validate(editedData, { abortEarly: false }) // Validate all fields and collect all errors
      .then(() => {
        setValidationErrors({});
        let data = {
          member_id: submemberData.member_id,
          submember_id: editedData.submember_id,
          first_name: editedData.first_name,
          middle_name: editedData.middle_name,
          last_name: editedData.last_name,
          gender: editedData.gender,
          phone: editedData.phone,
          dob: dayjs(editedData.dob).format('MM/DD/YYYY')
        };
        onEdit(data);
      })
      .catch((validationErrors) => {
        const errors = {};
        validationErrors.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        setValidationErrors(errors);
      });
  };

  return (
      <Dialog open={isOpen} onClose={onClose}>
        <form onSubmit={handleSubmit}>

          <DialogTitle className="linear-gradient"><FormattedMessage id="edit_submember"/></DialogTitle>

          <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
          >
            <CloseIcon/>
          </IconButton>
          <DialogContent dividers>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="first_name"/> <span style={{color: 'red'}}>*</span>
                </Typography>
                <TextField
                    fullWidth
                    name="first_name"
                    variant="outlined"
                    value={editedData.first_name}
                    onChange={handleChange}
                    margin="normal"
                    error={!!validationErrors.first_name}
                    helperText={validationErrors.first_name || ''}
                    sx={{marginTop: "1px"}}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="middle_name"/> <span style={{color: 'red'}}>*</span>
                </Typography>
                <TextField
                    fullWidth
                    name="middle_name"
                    variant="outlined"
                    value={editedData.middle_name}
                    onChange={handleChange}
                    margin="normal"
                    error={!!validationErrors.middle_name}
                    helperText={validationErrors.middle_name || ''}
                    sx={{marginTop: "1px"}}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="last_name"/> <span style={{color: 'red'}}>*</span>
                </Typography>
                <TextField
                    fullWidth
                    name="last_name"
                    variant="outlined"
                    value={editedData.last_name}
                    onChange={handleChange}
                    margin="normal"
                    error={!!validationErrors.last_name}
                    helperText={validationErrors.last_name || ''}
                    sx={{marginTop: "1px"}}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="gender"/> <span style={{color: 'red'}}>*</span>
                </Typography>
                <Select
                    fullWidth
                    name="gender"
                    variant="outlined"
                    value={editedData.gender || ''}
                    onChange={handleChange}
                    margin="normal"
                    error={!!validationErrors.gender}
                    helperText={validationErrors.gender || ''}
                    sx={{marginTop: "1px"}}

                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="dob"/> <span style={{color: 'red'}}>*</span>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      disableFuture
                      id="dob"
                      name="dob"
                      inputFormat="MM/DD/YYYY"
                      value={dayjs(editedData.dob)}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                      error={!!validationErrors.dob}
                      helperText={validationErrors.dob || ''}
                      sx={{marginTop: "1px"}}

                  />
                </LocalizationProvider>
                {<div style={{color: 'red', fontSize: '12px'}}>{validationErrors.dob || ''}</div>}
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="phone"/>
                </Typography>
                <TextField
                    fullWidth
                    name="phone"
                    variant="outlined"
                    value={editedData.phone || ''}
                    onChange={handleChange}
                    margin="normal"
                    error={!!validationErrors.phone}
                    helperText={validationErrors.phone || ''}
                    sx={{marginTop: "1px"}}

                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: "end" }}>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ margin: 8 }}
            >
              <FormattedMessage id="edit" />
            </Button>
            <Button onClick={onClose} variant="outlined" style={{ margin: 8 }}>
              <FormattedMessage id="cancel" />
            </Button>
          </DialogActions>
        </form>
</Dialog>
)
  ;
};

export default EditSubMemberForm;
