import { createSlice } from '@reduxjs/toolkit';
import { getCookie } from '../../shared/helpers/utils';
import { cookieEnum } from '../../enums/cookie.enum';

const initialState = {
  orderId: getCookie(cookieEnum.ORDER) || null,
  memberData:null,
  memberForm:null,
  submemberDetails:null,
  memberDetails:null,
  totalAmount:null
};

const paymentReducer = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setOrderId(state, action) {
      state.orderId = action.payload;
    },
    setMemberData(state, action) {
      state.memberData = action.payload;
    },
    setMemberForm(state, action) {
      state.memberForm = action.payload;
    },
    setMemberDetails(state, action) {
      state.memberDetails = action.payload;
    },
    setSubmemberDetails(state, action) {
      state.submemberDetails = action.payload;
    },
    setTotalAmount(state, action) {
      state.totalAmount = action.payload;
    }
  }
});

export default paymentReducer.reducer;

export const { setOrderId, setMemberType, setTotalAmount,setMemberData,setMemberForm,setMemberDetails,setSubmemberDetails } = paymentReducer.actions;
