import PublicLayout from "../layout/PublicLayout";
import RegisterMember from "../pages/member/forms/MemberRegisteration";
import PaymentDetails from "../pages/payment/PaymentDetails";
import UpdateMemberForm from "../pages/member/forms/UpdateMemberForm";
import Unauthorized from "../pages/authentication/auth-forms/Unauthorized";
import ExpiredMembership from "../pages/authentication/auth-forms/ExpiredMembership";
import PaymentGateway from "../pages/payment/PaymentGateway";
import UpdateRegister from "../pages/member/UpdateRegister";
import TicketGenerator from "pages/ticket/TicketGenerator";
import TicketUnauthorizedPage from "../pages/ticket/TicketUnauthorizedPage";
import RegistrationClosedPage from "../pages/member/forms/RegistrationClosedPage";

// ==============================|| AUTH ROUTING ||============================== //
const PublicRoutes = {
    path: '/',
    element: <PublicLayout />,
    children: [
        {
            path: '/new-registration',
            element: <RegisterMember />
        },
        {
            path: '/payment/details',
            element: <PaymentDetails/>
        },
        {
            path: '/renew-membership/details',
            element: <UpdateMemberForm/>

        },
        {
            path: '/user/unverified',
            element: <Unauthorized/>
        },

        {
            path: '/user/expired',
            element: <ExpiredMembership/>
        },

        {
            path: '/payment/gateway',
            element: <PaymentGateway/>
        },
        {
            path: '/update/register',
            element: <UpdateRegister/>
        },
        {
            path: '/p',
            element: <TicketGenerator/>
        },
        {
            path: '/ticket/unauthorized',
            element: <TicketUnauthorizedPage/>
        },
        {
            path: '/close-registration',
            element: <RegistrationClosedPage/>
        },
    ]
};

export default PublicRoutes;


