import { useTheme } from '@mui/material/styles';
import {AppBar, Toolbar, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';

// project import
import AppBarStyled from '../MainLayout/Header/AppBarStyled';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// assets
import * as React from "react";
import logo from "../../assets/images/icons/logo.png";
import LanguageSelector from "../../shared/components/LanguageSelector";
import {useDispatch, useSelector} from "react-redux";
import {setCookie} from "../../shared/helpers/utils";
import {cookieEnum} from "../../enums/cookie.enum";
import {setSelectedLocale} from "../../store/reducers/locale";
import {appConfigs} from "../../config/envs/app.config";
const PublicHeader = ({ open }) => {

    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();
    const { selectedLocale } = useSelector((state) => state.locale);
    const handleLocaleChange = (newLocale) => {
        setCookie(cookieEnum.LANGUAGE, newLocale);
        dispatch(setSelectedLocale(newLocale));
    };


    const logoAndTitle = (
        <>
            <Link to={appConfigs.WEBSITE_DOMAIN} style={{textDecoration: 'none'}} target={"_blank"}>

            <img src={logo} alt="Logo" style={{ width: 40, height: 40, marginRight: 16 }} />
            </Link>
            <Typography variant="h4" color="#1E90FF">
                Lohana Milan
            </Typography>
            <LanguageSelector currentLocale={selectedLocale} setLocale={handleLocaleChange} />

        </>
    )

    // Conditionally render the IconButton based on whether a role exists or not
    const iconButton =null;

    // common header
    const mainHeader = (
        <Toolbar>
            {iconButton}
            {logoAndTitle}
        </Toolbar>
    );


    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`
            // boxShadow: theme.customShadows.z1
        }
    };

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

PublicHeader.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};



export default PublicHeader