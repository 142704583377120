import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {
    Button,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack
} from "@mui/material";

// third party
import * as Yup from 'yup';
import {useFormik} from 'formik';

// project import
import {Login} from '../../../service/login.api';
// assets
import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import {errorToast, successToast, warningToast} from '../../../shared/components/AlertMessages';
import {ToastContainer} from 'react-toastify';

import {setCookie} from '../../../shared/helpers/utils';
import {cookieEnum} from '../../../enums/cookie.enum';
import {setMemberData, setTotalAmount} from "../../../store/reducers/paymentReducer";
import {dispatch} from "../../../store";
import Typography from "@mui/material/Typography";
import {appConfigs} from "../../../config/envs/app.config";

const AuthLogin = () => {
    const navigate = useNavigate();
    const passwordInputRef = React.useRef(null);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required('Email/Phone/Membership ID is required')
                .test('username', 'Invalid Email/Phone/Membership ID', (value) => {
                    // Define regular expressions for email, phone number, and membership ID
                    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                    const phoneRegex = /^[0-9]{10}$/;
                    const membershipIdRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

                    if (emailRegex.test(value)) {
                        return true; // Valid email
                    } else if (phoneRegex.test(value)) {
                        return true; // Valid phone number
                    } else if (membershipIdRegex.test(value)) {
                        return true; // Valid membership ID
                    }

                    return false; // Invalid format
                }),

            password: Yup.string().max(255).required('Password is required')
        }),
        onSubmit: (values) => {
            Login(values)
                .then((response) => {
                    if (response) {
                        if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                            setCookie(cookieEnum.ROLE, response.data?.data?.Role);
                            setCookie(cookieEnum.CSRF, response.data?.data?.Access_Token);
                            setCookie(cookieEnum.REFRESH, response.data?.data?.Refresh_Token);
                            if (response.data?.data.user_details.member_id) {
                                setCookie(cookieEnum.USER, response.data?.data?.user_details.member_id);
                                setCookie(cookieEnum.FIRST_NAME, response.data?.data?.user_details.first_name);
                                successToast(response.data?.message);
                                setTimeout(() => {
                                    navigate('/index/member');
                                }, 1000);
                            } else {
                                setCookie(cookieEnum.USER, response.data?.data?.user_details.admin_id);
                                successToast(response.data?.message);
                                setTimeout(() => {
                                    navigate('/admin/dashboard');
                                }, 1000);
                            }
                        }
                    }
                })
                .catch((error) => {
                    if (error.response?.data?.status_code === 404) {
                        warningToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                        setTimeout(() => {
                            navigate('/user/expired');
                        }, 1000);
                    } else if (error.response?.data?.status_code === 401 && error.response?.data?.data?.member_details && error.response?.data?.data?.member_details[0]?.payment_amount) {
                        warningToast(error.response?.data?.message);
                        dispatch(setTotalAmount(error.response.data?.data?.member_details[0]?.payment_amount));
                        setTimeout(() => {
                            navigate('/payment/gateway');
                        }, 1000);

                    } else if (error.response?.data?.status_code === 401 && error.response?.data?.data?.member_details && !error.response?.data?.data?.member_details?.member_payment_status) {
                        warningToast(error.response?.data?.message);
                        dispatch(setMemberData(error.response.data?.data));

                        setTimeout(() => {
                            navigate('/payment/details');
                        }, 1000);

                    } else if (error.response?.data?.status_code === 401 && Object.keys(error.response?.data?.data).length === 0) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 401 && error.response?.data?.data?.is_verified === false) {
                        warningToast(error.response?.data?.message);
                        setTimeout(() => {
                            navigate('/user/unverified');
                        }, 1000);
                    } else {
                        errorToast('something went wrong');
                    }
                });
        }
    });

    const [showPassword, setShowPassword] = React.useState(false);
    /*const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };*/

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);

        // Store the current selection range
        const selectionStart = passwordInputRef.current.selectionStart;
        const selectionEnd = passwordInputRef.current.selectionEnd;

        // Set a timeout to restore the selection range after the render
        setTimeout(() => {
            passwordInputRef.current.setSelectionRange(selectionStart, selectionEnd);
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="email-login">
                                Email/Phone/Membership ID<span style={{color: 'red'}}>*</span>
                            </InputLabel>
                            <OutlinedInput
                                id="email-login"
                                type="username"
                                value={formik.values.username}
                                name="username"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                fullWidth
                                error={Boolean(formik.touched.username && formik.errors.username)}
                            />
                            {formik.touched.username && formik.errors.username && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {formik.errors.username}
                                </FormHelperText>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <InputLabel htmlFor="password-login">
                                    Password<span style={{color: 'red'}}>*</span>
                                </InputLabel>
                                <Link to="/forgot-password" style={{textDecoration: 'none'}}>
                                    <Typography color="primary">
                                        Forgot Password?
                                    </Typography>
                                </Link>
                            </Stack>
                            <OutlinedInput
                                fullWidth
                                error={Boolean(formik.touched.password && formik.errors.password)}
                                id="-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.password}
                                name="password"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                inputRef={passwordInputRef}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {formik.touched.password && formik.errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {formik.errors.password}
                                </FormHelperText>
                            )}
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between" alignItems="baseline"
                               sx={{mb: {xs: -0.5, sm: 0.5}}}>
                            <div style={{display: 'flex', alignItems: 'baseline'}}>

                                <Link to="/new-registration" style={{textDecoration: 'none'}}>
                                    <Typography color="primary">New Membership</Typography>
                                </Link>{' '}

                            </div>
                            <Button color="primary" variant="contained" type="submit">
                                Login
                            </Button>

                        </Stack>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: "0px"}}>
                        <Stack direction="row" justifyContent="center" alignItems="center">
                            <Link to={appConfigs.WEBSITE_DOMAIN} style={{textDecoration: 'none'}} target={"_blank"}>
                                <Typography color="primary">Back To Home Page</Typography>
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
            <ToastContainer/>
        </div>
    );
};

export default AuthLogin;
