import * as React from "react";
import PropTypes from "prop-types";
import {alpha} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import {useEffect, useRef, useState} from "react";
import {
    successToast,
    errorToast,
    warningToast,
} from "../../../shared/components/AlertMessages";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationModal from "../../../shared/components/DeleteConfirmationModal";
import {ToastContainer} from "react-toastify";
import {
    deleteHall, getHall,
    searchHall,
    updateHall,
    viewHall,
} from "../../../service/hall.api";
import EditHallForm from "../forms/EditHallForm";
import {SearchText} from "../../../shared/components/SearchText";
import {FormattedMessage} from "react-intl";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import {AddButton} from "../../../shared/components/AddButton";
import AddHall from "../forms/AddHall";
import {makeStyles} from "@mui/styles";
import {Button, CardHeader} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HallModal from "../forms/HallModal";
import VisibilityIcon from '@mui/icons-material/Visibility';

const headCells = [
    {
        id: "hall_name",
        numeric: false,
        disablePadding: false,
        label: <FormattedMessage id="hall_name"/>,
    },
    {
        id: "total_capacity",
        numeric: true,
        disablePadding: false,
        label: "Capacity",
    },
    {
        id: "preview",
        numeric: true,
        disablePadding: false,
    },

    {
        id: "action",
        numeric: false,
        disablePadding: true,
        label: <FormattedMessage id="action"/>,
    },
];

function EnhancedTableToolbar(props) {
    const {numSelected} = props;

    return (
        <Toolbar
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1},
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{flex: "1 1 100%"}}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{flex: "1 1 100%"}}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    View Events
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon/>
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [hallData, setHallData] = React.useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [selectedRowForDelete, setSelectedRowForDelete] = React.useState(null);

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);

    const [count, setCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const isFirstRender = useRef(true); // Ref to track the initial render
    const [isLoading, setIsLoading] = useState(false);
    const [addHallForm, setAddHallForm] = useState(false);

    const [hallDetails, setHallDetails] = useState([]);
    const [hallModal, setHallModal] = useState(false);
    const[hallname,setHallname] =  useState('');
    const toggleHallForm = () => {
        setAddHallForm(!addHallForm);
    };

    const handleHallModalClose = () => {
        setHallModal(false);
    };

    const handleEditModalClose = () => {
        setIsEditModalOpen(false)
        setHallDetails([]); // Clear hallDetails when modal is closed
    };

    const handleSearchQueryChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchQuery(newSearchQuery); // Update the searchQuery state with the new value
        searchAndDisplayData(newSearchQuery); // Call searchAndDisplayData with the new search query
    };

    const view = (page, rowsPerPage) => {
        setIsLoading(true);
        setTimeout(() => {
            viewHall(page + 1, rowsPerPage)
                .then((response) => {
                    if (response) {
                        setIsLoading(false);
                        if (
                            response?.status_code === 200 ||
                            response?.status_code === 201
                        ) {
                            setHallData(response.data?.hall_list);
                            setCount(response.data?.total_item);
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.response?.data?.status_code === 404) {
                        setHallData([]);
                        setCount(0);
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                    } else {
                        errorToast("something went wrong");
                    }
                });
        }, 400);
    };

    const searchAndDisplayData = (searchQuery) => {
        // setIsLoading(true);
        if (searchQuery === "" || searchQuery === undefined) {
            view(page, rowsPerPage);
        } else {
            setTimeout(() => {
                searchHall(searchQuery)
                    .then((response) => {
                        if (response) {
                            setIsLoading(false);
                            if (
                                response?.status_code === 200 ||
                                response?.status_code === 201
                            ) {
                                // successToast(response?.message);
                                setHallData(response?.data);
                            }
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        if (error.response?.data?.status_code === 404) {
                            setHallData([]);
                            setCount(0);
                        } else if (error.response?.data?.status_code === 403) {
                            errorToast(error.response?.data?.message);
                            setHallData([]);
                        } else if (error.response?.data?.status_code === 400) {
                            errorToast(error.response?.data?.message);
                            setHallData([]);
                        } else {
                            errorToast("something went wrong");
                            setHallData([]);
                        }
                    });
            }, 400);
        }
    };

    useEffect(() => {
        if (isFirstRender.current) {
            view(page, rowsPerPage);
            isFirstRender.current = false; // Set to false after the initial render
        }
    }, [page, rowsPerPage]); // Include page and rowsPerPage in the dependency array

    const handleDeleteClick = (hallData) => {
        // Open the modal when delete icon is clicked
        setSelectedRowForDelete(hallData);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirmed = () => {
        deleteHall(selectedRowForDelete.hall_id)
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        successToast(response?.message);
                        const updatedData = hallData.filter(
                            (row) => row.hall_id !== selectedRowForDelete.hall_id
                        );
                        setHallData(updatedData);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast("something went wrong");
                }
            });

        setIsDeleteModalOpen(false);
    };

    const handleEditClick = (hallData) => {
        getHall(hallData.hall_id)
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        setHallDetails(response.data)
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
        setSelectedRowForEdit(hallData);
        setIsEditModalOpen(true);
    };

    const handlePreviewClick = (hallData) => {
        getHall(hallData.hall_id)
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        setHallDetails(response.data[0].seating_arrangement)
                        setHallname(hallData.hall_name)
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setHallDetails([])
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
        setHallModal(true);
    };


    const handleEditEvent = (editedHallData) => {
        updateHall(editedHallData) // Replace with the actual API call
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        successToast(response?.message);
                        const updatedData = hallData.map((row) =>
                            row.hall_id === selectedRowForEdit.hall_id
                                ? {...row, ...editedHallData}
                                : row
                        );
                        setHallData(updatedData);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast("something went wrong");
                }
            });

        setIsEditModalOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        view(newPage, rowsPerPage);
        setPage(newPage);
    };

    const handleAddHallClose = () => {
        setAddHallForm(false);

        view(page, rowsPerPage);
    };

    const handleClose = () => {
        setAddHallForm(false);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);

        // Ensure that the current page is valid with the new rowsPerPage
        const newPage = Math.floor(page * (rowsPerPage / newRowsPerPage));

        view(newPage, newRowsPerPage); // Fetch data with the new rowsPerPage
        setRowsPerPage(newRowsPerPage); // Update rowsPerPage
        setPage(newPage); // Update the page to the new calculated page
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;
    const useStyles = makeStyles((theme) => ({
        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "flex-start",
            },
        },
        searchContainer: {
            display: "flex",
            alignItems: "center",
            marginRight: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {},
        },

    }));
    const classes = useStyles();

    return (
        <>
            {isLoading ? (
                <LoadingScreen/>
            ) : (
                <Card className="card">
                    <CardHeader
                        title=<FormattedMessage id="hall_details"/>
                    className="linear-gradient"
                    action={
                    <div className={classes.header}>
                        <div className={classes.searchContainer}>
                            <SearchText
                                searchQuery={searchQuery}
                                handleSearchQueryChange={handleSearchQueryChange}
                            />
                        </div>
                        <AddButton
                            handleName={<FormattedMessage id="hall_side_menu"/>}
                            handleClick={toggleHallForm}
                        />
                    </div>
                }
                    />
                    <CardContent>
                        <TableContainer>
                            <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        {headCells.map((headCell) => (
                                            <TableCell key={headCell.id}>{headCell.label}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {hallData.length > 0 ? (
                                        hallData.map((hallData, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={hallData.hall_id}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ maxWidth: 150, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                                    >
                                                        <a href={hallData.hall_address} target="_blank">

                                                            {hallData.hall_name}
                                                        </a>
                                                    </TableCell>

                                                    <TableCell>{hallData.total_capacity}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => handlePreviewClick(hallData)}>
                                                            <VisibilityIcon className="me-2" fontSize="small" />
                                                            Hall Preview
                                                        </Button>

                                                    </TableCell>

                                                    <TableCell>
                                                        <Tooltip title="Edit" arrow>
                                                            <IconButton
                                                                onClick={() => handleEditClick(hallData)}
                                                            >
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete" arrow>
                                                            <IconButton
                                                                color="error"
                                                                onClick={() => handleDeleteClick(hallData)}
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                No data found
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow>
                                            <TableCell colSpan={5}/>
                                        </TableRow>
                                    )}
                                </TableBody>

                                <DeleteConfirmationModal
                                    isOpen={isDeleteModalOpen}
                                    onClose={() => setIsDeleteModalOpen(false)}
                                    onDelete={handleDeleteConfirmed}
                                />

                                <EditHallForm
                                    isOpen={isEditModalOpen}
                                    onClose={handleEditModalClose}
                                    onEdit={handleEditEvent}
                                    hallData={selectedRowForEdit}
                                    hallDetails={hallDetails}
                                />
                                <AddHall isOpen={addHallForm} onClose={handleClose} onAdd={handleAddHallClose}/>
                                <HallModal isOpen={hallModal} onClose={handleHallModalClose} seatingData={hallDetails} hallname={hallname}/>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[ 5, 10, 50,100]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </CardContent>
                </Card>
            )}
            <ToastContainer/>
        </>
    );
}


