import React from 'react';
import {Typography, Box} from '@mui/material';
import {appConfigs} from "../../config/envs/app.config";

const Footer = () => {
    return (
        <Box sx={{
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: 'background.paper',
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        }}>
            <div>

                <Typography variant="body2" color="text.secondary">
                    Copyright © 2024 <a href={`${appConfigs.WEBSITE_DOMAIN}`} target="_blank"
                                        style={{color: 'inherit', textDecoration: 'none'}}>Lohana Milan</a>
                    . All Right Reserved
                </Typography>
            </div>
        </Box>
    );
};
export default Footer;
