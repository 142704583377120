import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, useMediaQuery } from '@mui/material';
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { getRole } from "../../../shared/helpers/authentication";
import IconButton from "@mui/material/IconButton";
import logo from 'assets/images/icons/logo.png'
import * as React from "react";
import LanguageSelector from "../../../shared/components/LanguageSelector";
import {useDispatch, useSelector} from "react-redux";
import {setCookie} from "../../../shared/helpers/utils";
import {cookieEnum} from "../../../enums/cookie.enum";
import {setSelectedLocale} from "../../../store/reducers/locale";


// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const role = getRole();

  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const iconBackColor = 'grey.100';
  const iconBackColorOpen = 'grey.200';
  const dispatch = useDispatch();
  const { selectedLocale } = useSelector((state) => state.locale);
  const handleLocaleChange = (newLocale) => {
    setCookie(cookieEnum.LANGUAGE, newLocale);
    dispatch(setSelectedLocale(newLocale));
  };

  // Conditionally render the logo and title based on whether a role exists or not
  const logoAndTitle = !role ? (
    <>
      <img src={logo} alt="Logo" style={{ width: 40, height: 40, marginRight: 16 }} />
      <Typography variant="h4" color="#1E90FF">
        Lohana Milan
      </Typography>
      <LanguageSelector currentLocale={selectedLocale} setLocale={handleLocaleChange} />

    </>
  ) : null;

  // Conditionally render the IconButton based on whether a role exists or not
  const iconButton = role ? (
    <IconButton
      disableRipple
      aria-label="open drawer"
      onClick={handleDrawerToggle}
      edge="start"
      color="secondary"
      sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
    >
      {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    </IconButton>
  ) : null;

  // Common header with or without IconButton
  const mainHeader = (
    <Toolbar>
      {iconButton}
      {logoAndTitle}
      {role && <HeaderContent />}
    </Toolbar>
  );

  // App-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`
      // boxShadow: theme.customShadows.z1
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

export default Header;


