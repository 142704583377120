import React from "react";
import "./ticket.css";
import logo from "../../assets/images/icons/logo.png";
// import event from "../../assets/images/icons/event.png";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import {Grid} from "@mui/material";

const TicketPrint = ({data, eventImage}) => {
    const displayData = data.data;
    const dateTime = new Date(displayData.event_date);
    const eventDate = dateTime.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
    });
    const eventTime = dateTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
    });

    return (
        <div className="ticket-body">
            <main className="ticket-system">
                <div className="top">
                    <h1 className="title"></h1>
                    <div className="printer"/>
                </div>
                <div className="receipts-wrapper">
                    <div className="receipts">
                        <div className="receipt">
                            <div className="route">
                                {eventImage ? (
                                    <img
                                        src={eventImage}
                                        alt="Event"
                                        style={{width: "100%", marginTop: '-30px'}}
                                    />
                                ) : (
                                    <h3 className="event-title">{displayData.event_name}</h3>
                                )}
                                {/*<img src={eventImage} alt="Event" style={{width: "100%", marginTop: '-30px'}}/>*/}
                                {/*<h3 className=   "event-title">{displayData.event_name}</h3>*/}
                            </div>
                            <div>
                                <div className="logo-container">
                                    <img src={logo} className="logo-watermark" alt="logo"/>
                                </div>
                            </div>
                            <div className="details">
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <div className="item">
                                            <span>Membership No.</span>
                                            <h3>{displayData.membership_id}</h3>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="item" style={{marginLeft: "60px"}}>
                                            <span>Seats</span>
                                            <h3>{displayData.seat_number.replace(/,/g, ', ')}</h3>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="item">
                                            <span>Date</span>
                                            <h3>{eventDate}</h3>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="item" style={{marginLeft: "60px"}}>
                                            <span>Time</span>
                                            <h3>{eventTime}</h3>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="item">
                                            <span>Venue</span>
                                            <h3>
                                                <a
                                                    href={displayData.event_venue}
                                                    target="_blank"
                                                >
                                                    <LocationOnIcon style={{fontSize: "medium"}}/>
                                                    {displayData.hall_name}
                                                </a>
                                            </h3>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="item" style={{marginLeft: "60px"}}>
                                            <span>Group</span>
                                            <h3>{displayData.group_name?.replace(/\d+$/, "")
                                                .replace(/(.)\1+/g, "$1")}</h3>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className="receipt qr-code">
                            <Grid container spacing={2} className="details">
                                <Grid item xs={6}>
                                    <div className="item">
                                        <span>Seats</span>
                                        <h3>{displayData.seat_number.replace(/,/g, ', ')}</h3>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="item" style={{marginLeft: "50px"}}>
                                        <span>Membership No.</span>
                                        <h3>{displayData.membership_id}</h3>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default TicketPrint;
