import React, {useEffect, useState} from 'react';
import {GetRegistrationStatus} from "../../service/settings.api";
import {errorToast} from "../../shared/components/AlertMessages";
import LoadingScreen from "../../shared/components/LoadingScreen";
import RegisterMember from "../member/User";
import RegistrationClosedPage from "../member/forms/RegistrationClosedPage";

const RegisterComponent = () => {
    const [registrationStatus, setRegistrationStatus] = useState(null);

    const fetchRegistrationStatus = () => {
        GetRegistrationStatus()
            .then((response) => {
                if (response) {
                    if (
                        response?.data?.status_code === 200 ||
                        response?.data?.status_code === 201
                    ) {
                        setRegistrationStatus(response.data?.data?.setting_list[0].is_registration_open);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setRegistrationStatus('')
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast("something went wrong");
                }
            })
    }

    useEffect(() => {
        fetchRegistrationStatus();
    }, []);

    if (registrationStatus === null) {
        return <LoadingScreen/>
    }

    return (
        <>
            {registrationStatus ? <RegisterMember/> : <RegistrationClosedPage/>}
        </>

    );
};

export default RegisterComponent;
