import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {getMemberTypes} from "../../../service/membertype.api";
import {
    errorToast,
    warningToast,
} from "../../../shared/components/AlertMessages";
import LoadingScreen from "../../../shared/components/LoadingScreen";

const EditHallForm = ({isOpen, onClose, onEdit, hallDetails}) => {

    const [editedData, setEditedData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const [isModalLoading, setIsModalLoading] = useState(false);

    useEffect(() => {
        setIsModalLoading(true);
        setEditedData({...hallDetails[0]});
        if (hallDetails.length > 0) {
            setIsModalLoading(false);
        }
    }, [hallDetails]);


    const [memberTypes, setMemberTypes] = useState([]);

    const validationSchema = Yup.object().shape({
        hall_address: Yup.string().required("Hall address Required"),
        hall_name: Yup.string()
            .required("Hall Name Required"),
        total_capacity: Yup.number()
            .typeError("Enter a valid number")
            .positive("Total seats Must be an positive")
            .required("Total Capacity Required"),
        seating_arrangement: Yup.array().of(
            Yup.object().shape({
                member_type_id: Yup.string().required("Member Type Required"),
                row_name: Yup.string().required("Row Name Required"),
                column_name: Yup.string().required("Column Name Required"),
                column_from: Yup.number()
                    .typeError("Seats From must be a number")
                    .min(0, "Seats From must be greater than or equal to 0")
                    .required("Seats From Required"),
                column_to: Yup.number()
                    .typeError("Seats To must be a number")
                    .min(0, "Seats To must be greater than or equal to 0")
                    .min(
                        Yup.ref("column_from"),
                        "Seats To must be greater than or equal to Seats From"
                    )
                    .test(
                        "column-range",
                        "Length between Seats From and Seats To should not be more than 40",
                        function (value) {
                            const columnFrom = parseInt(this.parent.column_from, 10);
                            const columnTo = parseInt(value, 10);
                            return (
                                columnTo - columnFrom + 1 <= 40 ||
                                this.createError({
                                    path: "seating_arrangement",
                                    message:
                                        "Length between Seats From and Seats To should not be more than 40",
                                })
                            );
                        }
                    )
                    .required("Seats To Required"),
                reserved_from: Yup.number()
                    .typeError("Reserved From must be a number")
                    .min(0, "Reserved From must be greater than or equal to 0")
                    .min(
                        Yup.ref("column_from"),
                        "Reserved From must be greater than or equal to Column From"
                    )
                    .max(
                        Yup.ref("column_to"),
                        "Reserved From must be less than or equal to Column To"
                    )
                    .transform((value, originalValue) =>
                        originalValue === "" ? undefined : value
                    ),

                reserved_to: Yup.number()
                    .typeError("Reserved To must be a number")
                    .min(0, "Reserved To must be greater than or equal to 0")
                    .min(
                        Yup.ref("column_from"),
                        "Reserved To must be greater than or equal to Column From"
                    )
                    .max(
                        Yup.ref("column_to"),
                        "Reserved To must be less than or equal to Column To"
                    )
                    .min(
                        Yup.ref("reserved_from"),
                        "Reserved To must be greater than or equal to Reserved From"
                    )
                    .transform((value, originalValue) =>
                        originalValue === "" ? undefined : value
                    ),
            })
        ),
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        let updatedValidationErrors = {...validationErrors}; // Copy the current validation errors

        // Update editedData based on the input name
        if (name.startsWith("seating_arrangement")) {
            const [key, field] = name.split(".").slice(1); // Split the name to get the key and field
            setEditedData({
                ...editedData,
                seating_arrangement: {
                    ...editedData.seating_arrangement,
                    [key]: {
                        ...editedData.seating_arrangement[key],
                        [field]: value,
                    },
                },
            });

            // Remove validation error if the corresponding field is corrected
            delete updatedValidationErrors[`seating_arrangement[${key}].${field}`];
        } else {
            setEditedData({
                ...editedData,
                [name]: value,
            });

            // Remove validation error for non-seating arrangement fields if corrected
            delete updatedValidationErrors[name];
        }

        setValidationErrors(updatedValidationErrors); // Update the validation errors state
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const seatingArrangementList = Object.keys(
            editedData.seating_arrangement
        ).map((key) => editedData.seating_arrangement[key]);

        // Create a new object with seating_arrangement as a list and other keys as they are
        const newDataToSend = {
            ...editedData,
            seating_arrangement: seatingArrangementList,
        };

        // Check if the total number of seats exceeds the capacity
        let totalSeats = 0;
        seatingArrangementList.forEach((seat) => {
            totalSeats +=
                parseInt(seat.column_to, 10) - parseInt(seat.column_from, 10) + 1;
        });

        if (totalSeats > parseInt(editedData.total_capacity, 10)) {
            setValidationErrors({
                seating_arrangement: "Total seats exceed hall capacity",
            });
        } else {
            // If total seats don't exceed capacity, proceed with validation
            validationSchema
                .validate(newDataToSend, {abortEarly: false}) // Validate all fields and collect all errors
                .then(() => {
                    setValidationErrors({});
                    onEdit(newDataToSend);
                })
                .catch((validationErrors) => {
                    const errors = {};
                    validationErrors.inner.forEach((error) => {
                        errors[error.path] = error.message;
                    });
                    setValidationErrors(errors);
                });
        }
    };

    const fetchMemberTypes = () => {
        getMemberTypes()
            .then((response) => {
                if (response) {
                    if (
                        response.data?.status_code === 200 ||
                        response.data?.status_code === 201
                    ) {
                        setMemberTypes(response.data?.data.member_type_list);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setMemberTypes([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast("something went wrong");
                }
            });
    };

    useEffect(() => {
        if (isOpen) {
            fetchMemberTypes();
        }
        else {
            setEditedData({})
        }
    }, [isOpen]);

    const handleAddRow = () => {
        const newKey = Object.keys(editedData.seating_arrangement).length;
        setEditedData({
            ...editedData,
            seating_arrangement: {
                ...editedData.seating_arrangement,
                [newKey]: {
                    hall_seating_id: "None",
                    member_type_id: "",
                    row_name: "",
                    column_name: "",
                    column_from: "",
                    column_to: "",
                    reserved_from: "",
                    reserved_to: "",
                },
            },
        });
    };

    // const handleRemoveRow = (keyToRemove) => {
    //     const updatedArrangement = {...editedData.seating_arrangement};
    //     delete updatedArrangement[keyToRemove];
    //     setEditedData({
    //         ...editedData,
    //         seating_arrangement: updatedArrangement,
    //     });
    // };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xl">
            <form onSubmit={handleSubmit}>
                <DialogTitle className="linear-gradient" style={{height:"50px"}}>
                    {editedData.hall_name}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                {isModalLoading ? (
                    <DialogContent dividers style={{height: "80px"}}>
                        <LoadingScreen/>
                    </DialogContent>
                ) : (
                    <>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage id="hall_name"/>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        name="hall_name"
                                        value={editedData.hall_name}
                                        onChange={handleChange}
                                        error={!!validationErrors.hall_name}
                                        helperText={validationErrors.hall_name || ""}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage id="total_capacity"/>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="total_capacity"
                                        value={editedData.total_capacity}
                                        onChange={handleChange}
                                        error={!!validationErrors.total_capacity}
                                        helperText={validationErrors.total_capacity || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage id="hall_address"/>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        name="hall_address"
                                        value={editedData.hall_address}
                                        onChange={handleChange}
                                        required
                                        error={!!validationErrors.hall_address}
                                        helperText={validationErrors.hall_address || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="subtitle1">
                                        Seating Arrangement
                                    </Typography>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        {" "}
                                        {/* Adjust maxHeight as needed */}
                                        <Box border={1} borderRadius={2} p={3}>
                                            <Grid container spacing={1}>
                                                {editedData?.seating_arrangement &&
                                                    Object.keys(editedData?.seating_arrangement).map(
                                                        (key) => (
                                                            <React.Fragment key={key}>
                                                                <Grid item xs={12} md={2}>
                                                                    <FormControl
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        size="small"
                                                                    >
                                                                        <InputLabel id={`member_type_id_${key}`}>
                                                                            Member Type
                                                                        </InputLabel>
                                                                        <Select
                                                                            labelId={`member_type_id_${key}`}
                                                                            id={`member_type_id_${key}`}
                                                                            value={
                                                                                editedData?.seating_arrangement[key]
                                                                                    ?.member_type_id || ""
                                                                            }
                                                                            onChange={handleChange}
                                                                            name={`seating_arrangement.${key}.member_type_id`}
                                                                            error={
                                                                                !!validationErrors?.[
                                                                                    `seating_arrangement[${key}].member_type_id`
                                                                                    ]
                                                                            }
                                                                            helperText={
                                                                                validationErrors?.[
                                                                                    `seating_arrangement[${key}].member_type_id`
                                                                                    ] || ""
                                                                            }
                                                                        >
                                                                            {memberTypes.map((memberType) => (
                                                                                <MenuItem
                                                                                    key={memberType.member_type_id}
                                                                                    value={memberType.member_type_id}
                                                                                >
                                                                                    {memberType.member_type}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    {validationErrors?.[
                                                                        `seating_arrangement[${key}].member_type_id`
                                                                        ] && (
                                                                        <div
                                                                            style={{
                                                                                color: "red",
                                                                                fontSize: "12px",
                                                                            }}
                                                                        >
                                                                            {
                                                                                validationErrors?.[
                                                                                    `seating_arrangement[${key}].member_type_id`
                                                                                    ]
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </Grid>

                                                                <Grid item xs={12} md={2}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={`seating_arrangement_row_name_${key}`}
                                                                        name={`seating_arrangement.${key}.row_name`}
                                                                        label="Row Name"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        value={
                                                                            editedData?.seating_arrangement[key]
                                                                                ?.row_name || ""
                                                                        }
                                                                        onChange={handleChange}
                                                                        error={
                                                                            !!validationErrors?.[
                                                                                `seating_arrangement[${key}].row_name`
                                                                                ]
                                                                        }
                                                                        helperText={
                                                                            validationErrors?.[
                                                                                `seating_arrangement[${key}].row_name`
                                                                                ] || ""
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={`seating_arrangement_column_name_${key}`}
                                                                        name={`seating_arrangement.${key}.column_name`}
                                                                        label="Column Name"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        value={
                                                                            editedData?.seating_arrangement[key]
                                                                                ?.column_name || ""
                                                                        }
                                                                        onChange={handleChange}
                                                                        error={
                                                                            !!validationErrors?.[
                                                                                `seating_arrangement[${key}].column_name`
                                                                                ]
                                                                        }
                                                                        helperText={
                                                                            validationErrors?.[
                                                                                `seating_arrangement[${key}].column_name`
                                                                                ] || ""
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={`seating_arrangement_column_from_${key}`}
                                                                        name={`seating_arrangement.${key}.column_from`}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        label="Seats From"
                                                                        value={
                                                                            editedData?.seating_arrangement[key]
                                                                                ?.column_from || ""
                                                                        }
                                                                        onChange={handleChange}
                                                                        error={
                                                                            !!validationErrors?.[
                                                                                `seating_arrangement[${key}].column_from`
                                                                                ]
                                                                        }
                                                                        helperText={
                                                                            validationErrors?.[
                                                                                `seating_arrangement[${key}].column_from`
                                                                                ] || ""
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={`seating_arrangement_column_to_${key}`}
                                                                        name={`seating_arrangement.${key}.column_to`}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        label="Seats To"
                                                                        value={
                                                                            editedData?.seating_arrangement[key]
                                                                                ?.column_to || ""
                                                                        }
                                                                        onChange={handleChange}
                                                                        error={
                                                                            !!validationErrors?.[
                                                                                `seating_arrangement[${key}].column_to`
                                                                                ]
                                                                        }
                                                                        helperText={
                                                                            validationErrors?.[
                                                                                `seating_arrangement[${key}].column_to`
                                                                                ] || ""
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={1}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={`seating_arrangement_reserved_from_${key}`}
                                                                        name={`seating_arrangement.${key}.reserved_from`}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        label="Reserved From"
                                                                        value={
                                                                            editedData?.seating_arrangement[key]
                                                                                ?.reserved_from || ""
                                                                        }
                                                                        onChange={handleChange}
                                                                        error={
                                                                            !!validationErrors?.[
                                                                                `seating_arrangement[${key}].reserved_from`
                                                                                ]
                                                                        }
                                                                        helperText={
                                                                            validationErrors?.[
                                                                                `seating_arrangement[${key}].reserved_from`
                                                                                ] || ""
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={1}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={`seating_arrangement_reserved_to_${key}`}
                                                                        name={`seating_arrangement.${key}.reserved_to`}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        label="Reserved To"
                                                                        value={
                                                                            editedData?.seating_arrangement[key]
                                                                                ?.reserved_to || ""
                                                                        }
                                                                        onChange={handleChange}
                                                                        error={
                                                                            !!validationErrors?.[
                                                                                `seating_arrangement[${key}].reserved_to`
                                                                                ]
                                                                        }
                                                                        helperText={
                                                                            validationErrors?.[
                                                                                `seating_arrangement[${key}].reserved_to`
                                                                                ] || ""
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                <Grid item xs={false} md={9}/>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={3}
                                                    style={{textAlign: "right"}}
                                                >
                                                    {validationErrors?.seating_arrangement && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "12px",
                                                            }}
                                                        >
                                                            {validationErrors?.seating_arrangement}
                                                        </div>
                                                    )}
                                                </Grid>
                                                <Grid item xs={false} md={9}/>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={3}
                                                    style={{textAlign: "right"}}
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        onClick={handleAddRow}
                                                    >
                                                        Add Row
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions style={{justifyContent: "end"}}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{margin: 8}}
                            >
                                <FormattedMessage id="edit"/>
                            </Button>
                            <Button
                                onClick={onClose}
                                variant="outlined"
                                style={{margin: 8}}
                            >
                                <FormattedMessage id="cancel"/>
                            </Button>
                        </DialogActions>
                    </>
                )}
            </form>
        </Dialog>
    );
};

export default EditHallForm;
