import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { createMemberType } from '../../../service/membertype.api';
import { successToast, errorToast, warningToast } from '../../../shared/components/AlertMessages';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const AddMemberType = ({ isOpen, onClose ,onAdd}) => {
  const formik = useFormik({
    initialValues: {
      member_type: '',
      price: ''
    },

    validationSchema: Yup.object({
      member_type: Yup.string()
        .matches(/^[^0-9]*$/, 'Member Type cannot contain numbers')
        .required('Member Type Required'),
      price: Yup.number().typeError('Enter a valid number').positive('Price must be positive').required('Price Required')
    }),
    onSubmit: (values) => {
      createMemberType(values)
        .then((response) => {
          if (response) {
            if (response?.status_code === 200 || response?.status_code === 201) {
              successToast(response?.message);
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast('something went wrong');
          }
        })
        .finally(() => {
          onAdd();
        });
    }
  });

  useEffect(() => {
    if (!isOpen) {
        formik.resetForm();
    }
}, [isOpen]);

  return (
      <Dialog open={isOpen} onClose={onClose} fullWidth>
          <form onSubmit={formik.handleSubmit}>
              <DialogTitle className="linear-gradient"><FormattedMessage id="memberType_side_menu"/></DialogTitle>
              <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                  }}
              >
                  <CloseIcon/>
              </IconButton>
              <DialogContent dividers>

                  <Grid container spacing={2}>
                      <Grid item xs={12} justifyContent="center">
                          <Typography variant="subtitle1">
                              <FormattedMessage id="member_type"/> <span style={{color: 'red'}}>*</span>
                          </Typography>
                          <TextField
                              fullWidth
                              id="member_type"
                              name="member_type"
                              variant="outlined"
                              size="small"
                              value={formik.values.member_type}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.touched.member_type && Boolean(formik.errors.member_type)}
                              helperText={formik.touched.member_type && formik.errors.member_type}
                          />
                      </Grid>
                      <Grid item xs={12}>
                          <Typography variant="subtitle1">
                              <FormattedMessage id="price"/> <span style={{color: 'red'}}>*</span>
                          </Typography>
                          <TextField
                              fullWidth
                              id="price"
                              name="price"
                              variant="outlined"
                              size="small"
                              value={formik.values.price}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              // onChange={(e) => {
                              //     formik.handleChange(e);
                              //     formik.handleBlur(e);
                              // }}
                              error={formik.touched.price && Boolean(formik.errors.price)}
                              helperText={formik.touched.price && formik.errors.price}
                          />
                      </Grid>
                  </Grid>
              </DialogContent>
              <DialogActions style={{justifyContent: "end"}}>
                  <Button color="primary" variant="contained" type="submit" style={{margin: 8}}>
                      <FormattedMessage id="add"/>
                  </Button>
                  <Button onClick={onClose} variant="outlined" style={{margin: 8}}>
                      <FormattedMessage id="cancel"/>
                  </Button>
              </DialogActions>
          </form>
      </Dialog>

)
    ;
};

export default AddMemberType;
