import {CardHeader} from '@mui/material';
import React from "react";
import PaymentGatewayPage from "./forms/PaymentGatewayPage";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

const PaymentGateway = () => {
    const {selectedLocale} = useSelector((state) => state.locale);
    const total_amount = useSelector((state) => state.payment.totalAmount);

    return (
        <Card className="card" sx={{marginTop: "80px", marginLeft: "20px", marginRight: "20px", marginBottom: "20px"}}>
            <CardHeader
                title={
                    <React.Fragment>
                        {selectedLocale === 'en-US' ? (
                            <FormattedMessage id='payment_title_one'/>
                        ) : (
                            <FormattedMessage id='payment_title_one'/>
                        )}
                        {' '}
                        <u>₹{total_amount}</u>
                        {' '}
                        {selectedLocale === 'en-US' ? (
                            <FormattedMessage id='payment_title_two'/>
                        ) : (
                            <React.Fragment>
                                <FormattedMessage id='payment_title_three'/>
                                {' '}
                                <FormattedMessage id='payment_title_two'/>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                }
                className="text-center"
            />
            <CardContent>
                <PaymentGatewayPage/>
            </CardContent>
        </Card>
    );
};

export default PaymentGateway;
