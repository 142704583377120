import { request, requestLogin } from './common.service';
export function createMemberType(values) {
  return request({
    url: '/admin/create-member-type',
    method: 'POST',
    body: values
  });
}

export function viewMemberType(page_number, items_per_page) {
  return request({
    url: `/admin/get-all-member-type?page_number=${page_number}&items_per_page=${items_per_page}`,
    method: 'GET'
  });
}

export function deleteMemberType(member_type_id) {
  return request({
    url: `/admin/delete-member-type?member_type_id=${member_type_id}`,
    method: 'DELETE'
  });
}

export function updateMemberType(values) {
  return request({
    url: '/admin/update-member-type',
    method: 'PUT',
    body: values
  });
}

export function searchMemberType(searching_data) {
  return request({
    url: `/admin/search-member-type?searching_data=${searching_data}`,
    method: 'GET'
  });
}

export function getMemberTypes() {
  return requestLogin({
    url: '/admin/get-all-member-type',
    method: 'GET'
  });
}

export function getMemberType(member_type_id) {
  return requestLogin({
    url: `/admin/get-member-type-by-id?member_type_id=${member_type_id}`,
    method: 'GET'
  });
}

export function getStatsCount() {
  return request({
    url: '/admin/get-all-stats-count',
    method: 'GET'
  });
}

export function memberTypeFilter(page_number, items_per_page, member_filter,filter_type,filter_value) {
  return request({
    url: `/user/get-all-member?page_number=${page_number}&items_per_page=${items_per_page}&member_filter=${member_filter}&filter_type=${filter_type}&filter_value=${filter_value}`,
    method: 'GET'
  });
}