import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const EditGroupForm = ({ isOpen, onClose, onEdit, groupData }) => {
  const [editedData, setEditedData] = useState({ ...groupData });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    setEditedData({ ...groupData });
  }, [groupData]);

  const validationSchema = Yup.object().shape({
    group_limit: Yup.number()
      .typeError("Enter a valid number")
      .positive("Group Limit must be positive")
      .required("Group Limit Required"),
    vip_limit: Yup.number()
      .typeError("Enter a valid number")
      .positive("VIP Limit must be positive")
      .max(Yup.ref("group_limit"), "VIP Limit can not exceed group Limit")
      .required("VIP Limit Required"),
    group_name: Yup.string()
      .required("Group Name Required"),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validationSchema
      .validate(editedData, { abortEarly: false }) // Validate all fields and collect all errors
      .then(() => {
        setValidationErrors({});
        onEdit(editedData);
      })
      .catch((validationErrors) => {
        const errors = {};
        validationErrors.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        setValidationErrors(errors);
      });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle className="linear-gradient">
          <FormattedMessage id="edit_group_name" />
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TextField
            fullWidth
            name="group_name"
            label=<FormattedMessage id="group_name" />
            value={editedData.group_name}
            onChange={handleChange}
            error={!!validationErrors.group_name}
            helperText={validationErrors.group_name || ""}
          />

          <TextField
            fullWidth
            label=<FormattedMessage id="group_limit" />
            name="group_limit"
            value={editedData.group_limit}
            onChange={handleChange}
            required
            margin="normal"
            error={!!validationErrors.group_limit}
            helperText={validationErrors.group_limit || ""}
          />
          <TextField
            fullWidth
            label=<FormattedMessage id="vip_limit" />
            name="vip_limit"
            value={editedData.vip_limit}
            onChange={handleChange}
            required
            margin="normal"
            error={!!validationErrors.vip_limit}
            helperText={validationErrors.vip_limit || ""}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: "end" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ margin: 8 }}
          >
            <FormattedMessage id="edit" />
          </Button>
          <Button onClick={onClose} variant="outlined" style={{ margin: 8 }}>
            <FormattedMessage id="cancel" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditGroupForm;
