import React, { useEffect, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import { getMemberTypes } from "../../../service/membertype.api";
import { errorToast, warningToast } from "../../../shared/components/AlertMessages";

export const MemberTypeFilter = ({ selectedMemberType, handleMemberTypeChange }) => {

    const [memberTypeData, setMemberTypeData] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(selectedMemberType || "");

    // Fetch member types
    const fetchMemberTypes = () => {
        getMemberTypes()
            .then((response) => {
                if (response && (response.data?.status_code === 200 || response.data?.status_code === 201)) {
                    setMemberTypeData(response.data?.data.member_type_list);
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setMemberTypeData([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast("Something went wrong");
                }
            });
    };

    // Fetch member types when dropdown opens
    useEffect(() => {
        if (dropdownOpen) {
            fetchMemberTypes();
        }
    }, [dropdownOpen]);

    // Update selected value when prop changes
    useEffect(() => {
        setSelectedValue(selectedMemberType || "");
    }, [selectedMemberType]);

    // Handle dropdown change
    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
        handleMemberTypeChange(event);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
                <Box sx={{minWidth: 120}}>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 200}}>
                        <InputLabel id="demo-simple-select-standard-label">Select Member Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectedValue}
                            onChange={handleDropdownChange}
                            onOpen={() => setDropdownOpen(true)}
                            onClose={() => setDropdownOpen(false)}
                        >
                            {memberTypeData.length > 0 && memberTypeData.map((memberType) => (
                                <MenuItem key={memberType.member_type_id} value={memberType.member_type_id}>
                                    {memberType.member_type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    );
};
