import React from 'react';
import TextField from '@mui/material/TextField';

export const SearchText = ({ searchQuery, handleSearchQueryChange }) => {
  return (
<>
        <TextField
          type="search"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
          size="small"
          style={{marginRight: 20}}
        />

</>
  );
};