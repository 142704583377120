import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {FormattedMessage} from 'react-intl';
import {errorToast, successToast, warningToast} from '../../../shared/components/AlertMessages';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import {memberPaymentUpdate} from "../../../service/member.api";
import {formatBirthDate} from "../../../shared/helpers/utils";

const PaymentUpdateModal = ({isOpen, onClose, memberData, onSubmit}) => {

    const [details, setDetails] = useState([]);
    const validationSchema = yup.object({
        paymentMethod: yup.string().required('Payment Method is required'),
        date: yup.date().required('Date is required').nullable(),
        transaction_id: yup.string().required('Transaction Id is required'),

    });

    const formik = useFormik({
        initialValues: {
            paymentMethod: '',
            date: null,
            transaction_id: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // Handle form submission
            let data = {
                "payment_id": details.payment_id,
                "member_id": details.member_id,
                "payment_date": formatBirthDate(values.date.$d),
                "payment_type": values.paymentMethod,
                "payment_status": true,
                "transaction_id": values.transaction_id
            }

            memberPaymentUpdate(data)
                .then((response) => {
                    if (response) {
                        if (
                            response?.status_code === 200 ||
                            response?.status_code === 201
                        ) {
                            successToast(response?.message);
                        }
                    }
                })
                .catch((error) => {
                    if (error.response?.data?.status_code === 404) {
                        warningToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                    } else {
                        errorToast("something went wrong");
                    }
                })
                .finally(() => {
                    onSubmit();
                });
        }
    });

    useEffect(() => {
        setDetails({...memberData});
    }, [memberData]);

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle className="linear-gradient">
                    <FormattedMessage id="payment_update"/>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="method"/>
                            </Typography>
                            <Select
                                fullWidth
                                id="paymentMethod"
                                name="paymentMethod"
                                size="small"
                                value={formik.values.paymentMethod}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.paymentMethod && Boolean(formik.errors.paymentMethod)}
                            >
                                <MenuItem value="UPI">UPI</MenuItem>
                                <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                                <MenuItem value="NEFT">NEFT</MenuItem>
                                <MenuItem value="Cash">Cash</MenuItem>
                                <MenuItem value="Cheque">Cheque</MenuItem>

                            </Select>
                            {formik.touched.paymentMethod && formik.errors.paymentMethod && (
                                <div className="error-message" style={{color: 'red', fontSize: '12px'}}>
                                    {formik.errors.paymentMethod}
                                </div>
                            )}
                        </Grid>


                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="date"/>
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disableFuture
                                    placeholder="Date picker"
                                    id="date"
                                    name="date"
                                    inputFormat="MM/DD/YYYY"
                                    value={formik.values.date}
                                    onChange={(value) => {
                                        formik.setFieldValue('date', value);
                                    }}
                                    onBlur={formik.handleBlur}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </LocalizationProvider>
                            {formik.touched.date && formik.errors.date &&
                                <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.date}</div>}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <>
                                    <FormattedMessage id="transac_id"/>
                                </>
                            </Typography>
                            <TextField
                                fullWidth
                                id="transaction_id"
                                name="transaction_id"
                                variant="outlined"
                                size="small"
                                value={formik.values.transaction_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.transaction_id && Boolean(formik.errors.transaction_id)}

                            />
                            {formik.touched.transaction_id && formik.errors.transaction_id &&
                                <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.transaction_id}</div>}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <>
                                    <FormattedMessage id="amount"/>
                                    (₹)
                                </>
                            </Typography>
                            <TextField
                                fullWidth
                                id="amount"
                                name="amount"
                                variant="outlined"
                                type="text"
                                value={details.payment_amount}
                                InputProps={{
                                    readOnly: true,
                                    style: {color: 'grey'}
                                }}
                            />

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions style={{justifyContent: 'end'}}>
                    <Button type="submit" variant="contained" color="primary" style={{margin: 8}}>
                        <FormattedMessage id="received"/>
                    </Button>
                    <Button onClick={onClose} variant="outlined" style={{margin: 8}}>
                        <FormattedMessage id="cancel"/>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default PaymentUpdateModal;
