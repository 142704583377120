import React from 'react';

const Row = ({rowLetter, startIndex, seatsPerSection, isFirstRow, reservedSeats, seatingData}) => {
    const generateSeats = () => {
        return Array.from({length: seatsPerSection}, (_, i) => {
            const seatNumber = startIndex + i;
            const isReserved = reservedSeats.some(seat => seat.row_name === rowLetter && seatNumber >= seat.reserved_from && seatNumber <= seat.reserved_to);
            const seatData = seatingData.find(seat => seat.row_name === rowLetter && parseInt(seat.column_from) <= seatNumber && parseInt(seat.column_to) >= seatNumber);
            let seatStyle = {};
            if (isReserved) {
                seatStyle.backgroundColor = 'lightgrey'; // Reserved seat color
            } else if (seatData && seatData.member_type_id === 1) {
                seatStyle.backgroundColor = 'lightblue'; // VIP seat color
            } else if (seatData && seatData.member_type_id !== 1) {
                seatStyle.backgroundColor = 'lightgreen'; // Non-VIP seat color
            }
            return (
                <div
                    key={`seat-${startIndex + i}`}
                    className='seat'
                    style={seatStyle}
                >
                    {(startIndex + i)}
                </div>
            );
        });
    };

    return (
        <div className="row" style={{marginLeft: startIndex === 1 ? '0px' : '20px'}}>
            {isFirstRow && <div className="row-label">{rowLetter}</div>}
            {generateSeats()}
        </div>
    );
};

export default Row;
