import React from "react";
import {
    Dialog, DialogContent, DialogTitle,
} from "@mui/material";
import ThakorBhaiHall from "../ThakorBhaiHall";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {FormattedMessage} from "react-intl";

const HallModal = ({isOpen, onClose, seatingData,hallname}) => {

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xl">
            <DialogTitle className="linear-gradient">
                {hallname}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent dividers>
                    <ThakorBhaiHall seatingData={seatingData}/>
            </DialogContent>
        </Dialog>
);
};

export default HallModal;
