import React from 'react';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const DeleteConfirmationModal = ({ isOpen, onClose, onDelete }) => {
  return (
      <Dialog open={isOpen} onClose={onClose} fullWidth>
          <DialogTitle className="linear-gradient"><FormattedMessage id="delete_item"/>
          </DialogTitle>
          <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
              }}
          >
              <CloseIcon/>
          </IconButton>
          <DialogContent dividers>
              <p>
                  <FormattedMessage id="delete_modal"/>
              </p>
          </DialogContent>
          <DialogActions style={{justifyContent: "end"}}>
              <Button color="error" variant="contained" type="submit" onClick={onDelete} style={{margin: 8}}>
                  <FormattedMessage id="delete"/>
              </Button>
              <Button onClick={onClose} variant="outlined" style={{margin: 8}}>
                  <FormattedMessage id="cancel"/>
              </Button>
          </DialogActions>
      </Dialog>
  );
};

export default DeleteConfirmationModal;
