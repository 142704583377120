import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { getEvents } from "../../service/event.api";
import { getMember } from "../../service/member.api";
import { errorToast } from "../../shared/components/AlertMessages";
import { FormattedMessage } from "react-intl";
import LoadingScreen from "../../shared/components/LoadingScreen";
import { getId } from "../../shared/helpers/authentication";
import PeopleIcon from "@mui/icons-material/People";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import TagIcon from "@mui/icons-material/Tag";

const MemberPage = () => {
  const [eventCount, setEventCount] = useState(0);
  const [pastEventCount, setPastEventCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [memberDetails, setMemberDetails] = useState([]);
  const [eventData, setEventData] = useState([]);
  const id = getId();

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const response = await getEvents();
      if (
        response &&
        (response.status_code === 200 || response.status_code === 201)
      ) {
        const currentDate = new Date();
        const upcomingEvents = response.data?.event_list?.filter(
          (event) => new Date(event.date) > currentDate
        );
        setEventCount(upcomingEvents.length);
        const pastEvents = response.data?.event_list?.filter(
          (event) => new Date(event.date) < currentDate
        );
        setPastEventCount(pastEvents.length);
        setEventData(response.data.event_list);
      }
    } catch (error) {
      handleFetchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMemberData = async (id) => {
    try {
      const response = await getMember(id);
      if (
        response &&
        (response.status_code === 200 || response.status_code === 201)
      ) {
        const groupName = response.data[0].group_name;
        const formattedGroupName = groupName
            .replace(/\d+$/, "") // Remove trailing digits
            .replace(/(.)\1+/g, "$1"); // Remove duplicate letters
        setMemberDetails([{ ...response.data, formattedGroupName }]);
      }
    } catch (error) {
      handleFetchError(error);
    }
  };

  
  const handleFetchError = (error) => {
    if (error.response?.data?.status_code === 404) {
      setMemberDetails([]);
    } else if (
      error.response?.data?.status_code === 403 ||
      error.response?.data?.status_code === 400
    ) {
      errorToast(error.response?.data?.message);
    } else {
      errorToast("Something went wrong");
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchMemberData(id);
  }, [id]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {memberDetails.map((memberObject) => (
              <div key={memberObject[0].member_id}>
                <Typography variant="h3" style={{ color: "#1E90FF" }}>
                  {memberObject[0].first_name} {memberObject[0].last_name}!
                </Typography>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="avatar bg-light-success mr-2">
                        <TagIcon />
                        </div>
                        <div>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            <FormattedMessage id="membership_number" />
                          </Typography>
                          <Typography variant="h5" component="div">
                            {memberObject[0].membership_id}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  
                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="avatar bg-light-info mr-2">
                        <MilitaryTechIcon  />
                        </div>
                        <div>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            <FormattedMessage id="member_type" />
                          </Typography>
                          <Typography variant="h5" component="div">
                            {memberObject[0].member_type === 1
                              ? "VIP"
                              : "NON-VIP"}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="avatar bg-light-primary mr-2">

                        <PeopleIcon className="avatar-content"/>
                        </div>
                        <div>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            <FormattedMessage id="group_name" />
                          </Typography>
                          <Typography variant="h5" component="div">
                            {memberDetails[0].formattedGroupName}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            ))}
          </Grid>
        </Grid>

        
      )}
    </>
  );
};

export default MemberPage;


{/* <Grid container spacing={3}>
<Grid
  container
  spacing={2}
  style={{ marginTop: "10px", marginLeft: "10px" }}
>
  <Grid item xs={12}>
    {eventCount > 0 && (
      <TotalCountCard
        count={eventCount}
        title={<FormattedMessage id="total_events" />}
      />
    )}
  </Grid>
  <Grid item xs={12}>
    {pastEventCount > 0 ? (
      <TotalCountCard
        count={pastEventCount}
        title={<FormattedMessage id="past_events" />}
      />
    ) : (
      <TotalCountCard
        count={"No Data Available"}
        title={<FormattedMessage id="past_events" />}
      />
    )}
  </Grid> */}