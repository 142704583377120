import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {FormattedMessage} from 'react-intl';
import {getGroups} from '../../../service/group.api';
import {errorToast, warningToast} from '../../../shared/components/AlertMessages';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const SelectGroupModal = ({isOpen, onClose, memberData, onEdit}) => {
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const fetchGroups = () => {
        getGroups()
            .then((response) => {
                if (response) {
                    if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                        setGroups(response.data?.data.group_list);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setGroups([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            })

    };

    useEffect(() => {
        if (isOpen) {
            fetchGroups();
        }
    }, [isOpen]);



    useEffect(() => {
        if (memberData?.group_id !== selectedGroup) {
            setSelectedGroup(memberData?.group_id || '');
        }
    }, [memberData?.group_id]);


    const handleGroupChange = (event) => {
        const selectedGroupId = event.target.value;
        setSelectedGroup(selectedGroupId);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            member_id: memberData.member_id,
            group_id: selectedGroup,
        };
        onEdit(data);

    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle className="linear-gradient">
                    <FormattedMessage id="assign_group"/>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                        <InputLabel id="demo-simple-select-standard-label">Assign Group</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            sx={{
                                minWidth: {xs: '100%', sm: 700}
                            }}
                        >
                            {groups.map((group) => (
                                <MenuItem key={group.group_id} value={group.group_id}>
                                    {group.group_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions style={{justifyContent: 'end'}}>
                    <Button type="submit" variant="contained" color="primary" style={{margin: 8}}>
                        <FormattedMessage id="assign"/>
                    </Button>
                    <Button onClick={onClose} variant="outlined" style={{margin: 8}}>
                        <FormattedMessage id="cancel"/>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default SelectGroupModal;
