import React, { useEffect } from 'react';
import { Typography, Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Countdown = ({
  seconds,
  minutes,
  onResendOTP,
  showDetails,
}) => {
  useEffect(() => {
    // Start the timer only when onResendOTP is called
    if (seconds > 0 || minutes > 0) {
      const countdown = setInterval(() => {
        onResendOTP(); // Notify the parent component to handle the resend logic
      }, (minutes * 60 + seconds) * 1000);

      return () => clearInterval(countdown);
    }
  }, [seconds, minutes, onResendOTP]);

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
      <div>
        {seconds > 0 || minutes > 0 ? (
          <Typography>
            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
        ) : showDetails ? null : (
          <Typography>
            Didn&apos;t receive code? <span style={{ marginRight: '70px' }}>{/* Add desired spacing */}</span>
            <Link component={RouterLink} underline="hover" color="primary" onClick={onResendOTP}>
              Resend OTP
            </Link>
          </Typography>
        )}
      </div>
    </Stack>
  );
};

export default Countdown;
