import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import Countdown from "../../../shared/components/CountDown";
import { sendOtp } from "../../../service/member.api";
import {
  errorToast,
  warningToast,
} from "../../../shared/components/AlertMessages";
import { SHA256 } from "crypto-js";
import { verifyOtp} from "../../../service/login.api";
import { useSelector } from "react-redux";
import { setOtpToken } from "../../../store/reducers/forgotPasswordReducer";
import { dispatch } from "../../../store";
import { useNavigate } from "react-router-dom";

const ForgotPasswordOtp = () => {
  const navigate = useNavigate();

  const { otpToken } = useSelector((state) => state.forgotPassword);
  const username = useSelector((state) => state.forgotPassword.username);
  const [timer, setTimer] = useState(120);

  useEffect(() => {
    let countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, []);

  const handleResendOTP = () => {
    formik.setValues({
      ...formik.values,
      otp: ["", "", "", "", "", ""],
    });
    setTimer(120);
    sendOtp(username, "Forgot Password")
      .then((response) => {
        if (response) {
          if (
            response.data?.status_code === 200 ||
            response.data?.status_code === 201
          ) {
            dispatch(setOtpToken(response.data?.data)); // Assuming response.data.data contains the OTP token
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast(error.response?.data?.message);
        }
      });
  };


  const otpSchema = Yup.object().shape({
    otp: Yup.array()
      .of(Yup.string().matches(/^\d{0,1}$/, "Must be a single digit"))
      .test("is-complete", "Please enter a 6-digit OTP", (value) => {
        const completedDigits = value.filter((digit) => digit !== "").length;
        return completedDigits === 6;
      })
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      otp: ["", "", "", "", "", ""],
    },
    onSubmit: (values) => {
      otpSchema
        .validate(values, { abortEarly: false })
        .then(() => {
          const otp = values.otp.join("");
          const combinedHash = SHA256(otpToken + otp).toString();
          if (timer > 0) {
            verifyOtp(combinedHash)
              .then((response) => {
                if (response) {
                  if (
                    response.data?.status_code === 200 ||
                    response.data?.status_code === 201
                  ) {
                    navigate("/reset-password");
                  }
                }
              })
              .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                  warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                  errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                  errorToast(error.response?.data?.message);
                }
                else if (error.response?.data?.status_code === 422) {
                  errorToast(error.response?.data?.message);
                }
                else {
                  errorToast("something went wrong");

                }
              });
          } else {
            warningToast(
              "The entered OTP has expired. Please click on resend OTP."
            );
          }
        })
        .catch((errors) => {
          // Handle validation errors
          if (errors.name === "ValidationError") {
            errors.inner.forEach((error) => {
              const fieldName = error.path;
              const errorMessage = error.message;
              formik.setFieldError(fieldName, errorMessage);
            });
          }
        });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex" }}>
              {formik.values.otp.map((digit, index) => (
                <TextField
                  key={index}
                  id={`otp-${index}`}
                  name={`otp-${index}`}
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 1 }}
                  value={digit}
                  onChange={(event) => {
                    formik.setFieldValue(`otp[${index}]`, event.target.value);
                    if (index < 5 && event.target.value !== "") {
                      document.getElementById(`otp-${index + 1}`).focus();
                    }
                  }}
                  style={{
                    width: "30px",
                    marginRight: "5px",
                    marginBottom: "10px",
                  }}
                />
              ))}
            </div>
            {formik.touched.otp && formik.errors.otp && (
              <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                {formik.errors.otp}
              </div>
            )}
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Countdown
              seconds={timer % 60}
              minutes={Math.floor(timer / 60)}
              onResendOTP={handleResendOTP}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button type="submit" variant="contained" color="primary">
                Verify OTP
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ForgotPasswordOtp;
