import React from 'react';
import { Grid, Stack } from '@mui/material';
import UpdateRegisterForm from './forms/UpdateRegisterForm'
const UpdateRegister = () => (
    <Grid container spacing={10}>
        <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}></Stack>
        </Grid>

        <Grid item xs={12}>
            <UpdateRegisterForm />
        </Grid>
    </Grid>
);

export default UpdateRegister;
