import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
     InputAdornment,
    MenuItem, OutlinedInput,
    Select,
} from "@mui/material";
import {errorToast} from "../../../shared/components/AlertMessages";
import {getHalls} from "../../../service/hall.api";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {capitalizeName, formatDate} from "../../../shared/helpers/utils";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

const EditEventFormModal = ({isOpen, onClose, onEdit, eventData}) => {
    const [editedData, setEditedData] = useState({...eventData});
    const [validationErrors, setValidationErrors] = useState({});
    const [halls, setHalls] = useState([]);
    const [selectedHall, setSelectedHall] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    const fetchHalls = () => {
        getHalls()
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        setHalls(response.data?.hall_list);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setHalls([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast("something went wrong");
                }
            });
    };
    useEffect(() => {
        if (isOpen) {
            fetchHalls();
        }
    }, [isOpen]);

    useEffect(() => {
        // const formattedDate = dayjs(...eventData.date).format('DD-MM-YYYY hh:mm:ss'); // Format the date as DD-MM-YYYY
        setEditedData({...eventData});
    }, [eventData]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setEditedData({...editedData, [name]: value});
    };
    const handleHallChange = (event) => {
        const selectedHallId = event.target.value;
        const hallName = halls.find(
            (hall) => hall.hall_id === selectedHallId
        )?.hall_name;

        setSelectedHall(selectedHallId);
        setEditedData({
            ...editedData,
            hall_id: selectedHallId,
            hall_name: hallName,
        });
    };

    const handleDateChange = (date) => {
        const formattedDate = dayjs(date);
        setEditedData({...editedData, date: formattedDate});
    };

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };


    const validationSchema = Yup.object().shape({
        date: Yup.date()
            .required("Date Required")
            .test("is-future-date", "Please select a valid date", (value) => {
                return dayjs(value).isAfter(dayjs()); // Check if the selected date is after the current date
            }),
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('event_id', editedData.event_id);
        formData.append('event_name', capitalizeName(editedData.event_name));
        formData.append('venue', editedData.venue);
        formData.append('date', formatDate(editedData.date));
        formData.append('hall_id', editedData.hall_id);
        formData.append('event_description', editedData.event_description);
        formData.append('is_event_name_visible', editedData.is_event_name_visible);

        if (selectedFile) {
            formData.append('file', selectedFile); // Append the selected file
        } else if (editedData.event_image) {
            formData.append('file', editedData.event_image); // Use the existing event image
        }

        validationSchema
            .validate(editedData, {abortEarly: false}) // Validate all fields and collect all errors
            .then(() => {
                setValidationErrors({});
                onEdit(formData);
            })
            .catch((validationErrors) => {
                const errors = {};
                validationErrors.inner.forEach((error) => {
                    errors[error.path] = error.message;
                });
                setValidationErrors(errors);
            });
    };

    const fileInputRef = React.useRef(null);

    const handleDeleteFile = () => {
        setSelectedFile('')
        setEditedData({...editedData, event_image: ''});

    };
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle className="linear-gradient">
                    <FormattedMessage id="edit_event"/>
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="event_name"/>
                            </Typography>
                            <TextField
                                fullWidth
                                name="event_name"
                                value={editedData.event_name}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{marginTop: "16px"}}>

                            <Checkbox name="eventCheck"
                                      color="primary"
                                      onChange={handleChange}
                                      checked={editedData.is_event_name_visible}/>

                            <label className="me-5">
                                Event Name show in Ticket?
                                <sup>
                                    <Tooltip title="If checked, the event name will be shown in the ticket">
                                        <InfoIcon fontSize="small" color="warning"/>
                                    </Tooltip>
                                </sup>
                            </label>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="date"/>
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    disablePast
                                    id="date"
                                    name="date"
                                    format="MM/DD/YYYY hh:mm:ss"
                                    value={dayjs(editedData.date)}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                    error={!!validationErrors.date}
                                    helperText={validationErrors.date || ""}
                                    sx={{width: "100%"}}
                                />
                            </LocalizationProvider>
                            {
                                <div style={{color: "red", fontSize: "12px"}}>
                                    {validationErrors.date || ""}
                                </div>
                            }

                        </Grid>
                        <Grid item xs={12} md={6}>

                            <Typography variant="subtitle1">
                                <FormattedMessage id="view_hall"/>
                            </Typography>
                            <Select
                                fullWidth
                                value={selectedHall}
                                onChange={handleHallChange}
                                displayEmpty
                            >
                                <MenuItem value="" hidden>
                                    {editedData.hall_name}
                                </MenuItem>

                                {halls.map((hall) => (
                                    <MenuItem key={hall.hall_id} value={hall.hall_id}>
                                        {hall.hall_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>

                            <Typography variant="subtitle1">
                                <FormattedMessage id="venue"/>
                            </Typography>
                            <TextField
                                fullWidth
                                name="venue"
                                placeholder="Enter venue details"
                                value={editedData.venue}
                                onChange={handleChange}
                                InputProps={{
                                    readOnly: true,
                                    style: {color: 'grey'} // Change color here

                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>

                            <Typography variant="subtitle1">
                                <FormattedMessage id="event_details"/>
                            </Typography>
                            <TextField
                                fullWidth
                                multiline={true}
                                name="event_description"
                                value={editedData.event_description}
                                onChange={handleChange}
                                rows={4}
                                cols={40}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <Typography variant="subtitle1">
                                    Image for Ticket
                                    <sup>
                                        <Tooltip title="To display an image in the ticket, please upload an image file">
                                            <InfoIcon fontSize="small" color="warning"/>
                                        </Tooltip>
                                    </sup>
                                </Typography>

                                <OutlinedInput
                                    className="file-input"
                                    id="outlined-adornment-weight"
                                    placeholder="Choose a file..."
                                    value={selectedFile ? selectedFile.name : (editedData.event_image && editedData.event_image !== "0") ? editedData.event_image : ''}
                                    readOnly
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {(selectedFile || (editedData.event_image && editedData.event_image !== "0")) && (
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={handleDeleteFile}
                                                >
                                                    <CloseIcon/>
                                                </IconButton>
                                            )}

                                            <Button
                                                size="medium"
                                                className="right"
                                                component="span"
                                                onClick={() => fileInputRef.current.click()}
                                            >
                                                Browse
                                            </Button>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                onChange={handleFileInputChange}
                                                style={{display: "none"}}
                                            />
                                        </InputAdornment>
                                    }
                                />

                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{justifyContent: "end"}}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{margin: 8}}
                    >
                        <FormattedMessage id="edit"/>
                    </Button>
                    <Button onClick={onClose} variant="outlined" style={{margin: 8}}>
                        <FormattedMessage id="cancel"/>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditEventFormModal;

