import { Grid, Stack } from '@mui/material';
import ProfileChangeRequest from './tables/ProfileChangeRequest';
import React from 'react';

export const ProfileChangeTable = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}></Stack>
      </Grid>

      <Grid item xs={12}>
        <ProfileChangeRequest />
      </Grid>
    </Grid>
  );
};
