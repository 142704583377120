import React from 'react';
import '../css/LoadingScreen.css';

const LoadingScreen = () => {
    return (
        <div>
            <div className="loading-spinner">

            </div>

        </div>

    );
};

export default LoadingScreen;