import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    memberDetails:null
};

const renewMembershipReducer = createSlice({
    name: 'renew',
    initialState,
    reducers: {
        setMemberDetails(state, action) {
            state.memberDetails = action.payload;
        }
    }
});

export default renewMembershipReducer.reducer;

export const { setMemberDetails } = renewMembershipReducer.actions;
