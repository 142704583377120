import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
// assets
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import SettingsIcon from '@mui/icons-material/Settings';
// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ role, handleLogout }) => {
  const navigate = useNavigate();

  const theme = useTheme();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleEditClick = () => {
    navigate('/user/edit/Profile'); // Navigate to the EditMemberPage
  };

  const handleSettingsClick = () => {
    navigate('/admin/settings'); // Navigate to the EditMemberPage
  };

  const linkStyle = {
    textDecoration: 'none', // Remove underline
    color: 'inherit' // Inherit the text color from the parent element
  };
  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
      {role === 'member' ? (
        <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
          <ListItemIcon>
            <EditOutlined onClick={() => handleEditClick()} />
          </ListItemIcon>
          <ListItemText primary=<FormattedMessage id="edit_profile" /> onClick={() => handleEditClick()} />
        </ListItemButton>
      ) : null}

      {role === 'member' ? (
        <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
          <ListItemIcon>
            <Link to="/user/changePassword" style={linkStyle}>
              <PasswordIcon />
            </Link>
          </ListItemIcon>
          <Link to="/user/changePassword" style={linkStyle}>
            <ListItemText primary=<FormattedMessage id="change_password" /> />
          </Link>
        </ListItemButton>
      ) : null}


      {role === 'admin' ? (
          <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 1)}>
            <ListItemIcon>
              <SettingsIcon onClick={() => handleSettingsClick()} />
            </ListItemIcon>
            <ListItemText primary="Settings" onClick={() => handleSettingsClick()} />
          </ListItemButton>
      ) : null}

      <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary=<FormattedMessage id="logout" /> />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
