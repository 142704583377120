import { CCol, CRow } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
// import { useState } from 'react';
// import MemberTypeInfoModal from '../../../pages/membertype/forms/MemberTypeInfoModal';
// import { getMemberTypeMembers } from '../../../service/membertype.api';
// import { errorToast } from '../../../shared/components/AlertMessages';
import Typography from '@mui/material/Typography';
// import GroupCard from '../../../shared/components/GroupCard';
import { FormattedMessage } from 'react-intl';
import TotalCountCard from "../../../shared/components/TotalCountCard";

const MemberTypeInfo = ({ memberTypeData }) => {
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);
  // const [memberCount, setMemberCount] = useState(0);

  // const groupMembers = (member_type_id) => {
  //   getMemberTypeMembers(member_type_id)
  //     .then((response) => {
  //       if (response) {
  //         if (response?.status_code === 200 || response?.status_code === 201) {
  //           setMemberCount(response.data?.length);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log('error', error);
  //       if (error.response?.data?.status_code === 404) {
  //         setMemberCount(0);
  //       } else if (error.response?.data?.status_code === 403) {
  //         errorToast(error.response?.data?.message);
  //       } else if (error.response?.data?.status_code === 400) {
  //         errorToast(error.response?.data?.message);
  //       } else {
  //         errorToast('something went wrong');
  //       }
  //     });
  // };
  // const handleEditClick = (memberTypeData) => {
  //   setSelectedRowForEdit(memberTypeData);
  //   const member_type_id = memberTypeData.member_type_id;
  //   groupMembers(member_type_id);
  //
  //   setIsEditModalOpen(true);
  // };
  return (
    <>
      {memberTypeData.length !== 0 ? (
        <CRow>
          <Typography variant="h5" color="GoldenRod">
            <FormattedMessage id="view_member_type" />
          </Typography>
          {memberTypeData.slice(0, 4).map((memberTypeData) => (
            <CCol key={memberTypeData.member_type_id} xs={6} style={{ marginTop: '10px' }}>
              <TotalCountCard
                title={memberTypeData.member_type}
                count = {memberTypeData.count}
                // link=<FormattedMessage id="view_details" />
                // onParticipateClick={() => handleEditClick(memberTypeData)}
              />
            </CCol>
          ))}
          {/*<MemberTypeInfoModal*/}
          {/*  isOpen={isEditModalOpen}*/}
          {/*  onClose={() => setIsEditModalOpen(false)}*/}
          {/*  membertypes={selectedRowForEdit}*/}
          {/*  memberCount={memberCount}*/}
          {/*/>*/}
        </CRow>
      ) : null}
    </>
  );
};

export default MemberTypeInfo;
