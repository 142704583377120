import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import RenewMember from "../pages/member/RenewMember";
import PasswordForgot from "../pages/authentication/PasswordForgot";
import PasswordReset from "../pages/authentication/PasswordReset";
import PasswordForgotOtp from "../pages/authentication/PasswordForgotOtp";

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: '/renew-membership',
      element: <RenewMember />
    },
    {
      path: '/forgot-password',
      element: <PasswordForgot />
    },
    {
      path: '/reset-password',
      element: <PasswordReset />
    },
    {
      path: '/forgot-password/otp',
      element: <PasswordForgotOtp />
    },

  ]
};

export default LoginRoutes;


