import React, {useEffect} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatBirthDate } from '../../../shared/helpers/utils';
import { AddSubMember } from '../../../service/submember.api';
import { errorToast, successToast, warningToast } from '../../../shared/components/AlertMessages';
import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from "@mui/material";
import {FormattedMessage} from "react-intl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const SubmemberFormPopup = ({ isOpen, onClose, memberDetails, handleAddSubmember }) => {
  const formik = useFormik({
    initialValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      gender: '',
      dob: null,
      phone: ''
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .matches(/^[^0-9]*$/, 'First name cannot contain numbers')
        .required('First name Required'),
      middle_name: Yup.string()
        .matches(/^[^0-9]*$/, 'Middle name cannot contain numbers')
        .required('Middle name Required'),
      last_name: Yup.string()
        .matches(/^[^0-9]*$/, 'Last name cannot contain numbers')
        .required('Last name Required'),
      gender: Yup.string().required('Gender Required'),
      dob: Yup.date().max(new Date(), 'Please select a valid date').required('Dob Required').nullable(),
      phone: Yup.string().matches(/^\d{10}$/, 'Mobile number must be 10 digits')
    }),
    onSubmit: (values) => {
      let data = {
        first_name: values.first_name,
        last_name: values.last_name,
        middle_name: values.middle_name,
        gender: values.gender,
        phone: values.phone,
        dob: formatBirthDate(values.dob.$d),
        member_id: memberDetails.member.member_id
      };

      AddSubMember(data, memberDetails.member.membership_id)
        .then((response) => {
          if (response) {
            if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
              successToast(response?.data?.message);
              onClose();
              handleAddSubmember();
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast('something went wrong');
          }
        });
    }
  });

  const resetForm = () => {
    formik.resetForm();
  };

  // Reset the form when the modal is opened
  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  return (
      <Dialog open={isOpen} onClose={onClose} fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle className="linear-gradient">
          Add Sub-member
        </DialogTitle>
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">
                First Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="first_name"
                name="first_name"
                variant="outlined"
                size="small"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                helperText={formik.touched.first_name && formik.errors.first_name}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">
                Middle Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="middle_name"
                name="middle_name"
                variant="outlined"
                size="small"
                value={formik.values.middle_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.middle_name && Boolean(formik.errors.middle_name)}
                helperText={formik.touched.middle_name && formik.errors.middle_name}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">
                Last Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="last_name"
                name="last_name"
                variant="outlined"
                size="small"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">
                Gender <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Select
                id="gender"
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                size="small"
                error={formik.touched.gender && Boolean(formik.errors.gender)}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
              {formik.touched.gender && formik.errors.gender && (
                <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.gender}</div>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">
                Date of Birth <span style={{ color: 'red' }}>*</span>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  id="dob"
                  name="dob"
                  inputFormat="MM/DD/YYYY"
                  value={formik.values.dob}
                  onChange={(value) => {
                    formik.setFieldValue('dob', value);
                  }}
                  onBlur={formik.handleBlur}
                />
              </LocalizationProvider>
              {formik.touched.dob && formik.errors.dob && <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.dob}</div>}
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">Phone</Typography>
              <TextField
                fullWidth
                id="phone"
                name="phone"
                variant="outlined"
                size="small"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "end" }}>
          <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ margin: 8 }}
          >
            <FormattedMessage id="add" />
          </Button>
          <Button onClick={onClose} variant="outlined" style={{ margin: 8 }}>
            <FormattedMessage id="cancel" />
          </Button>
        </DialogActions>
        </form>
      </Dialog>

  );
};

export default SubmemberFormPopup;
