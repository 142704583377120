import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const Seat = ({ seat ,onSelect, isSelected }) => {
    const { status, seat_id, member_id, member_name,member_type ,phone,membership_id} = seat;
    let seatStatusClass;

    if (status === 'booked' && member_id === 0) {
        seatStatusClass = 'grey-seat';
    }
    else if (member_type === 'vip') {
        seatStatusClass = 'blue-seat';
    }
    else if (member_type === 'non-vip') {
        seatStatusClass = 'green-seat';
    }
    else if (status === 'available') {
        seatStatusClass = 'white-seat';

    }
    else {
        seatStatusClass = '';
    }

    const renderSeatStatus = () => {
        if (member_id === 0 && status === 'booked') {
            return <span>This seat is <b>Reserved</b></span>;
        } else if (member_id === 0 && status === 'available') {
            return  <span>This seat is <b>Available.</b></span>;
        } else {
            return <><span className="fw-bold">No.:</span> {membership_id}</>;
        }
    };
    const popoverContent = (
        <Popover id={`popover-${seat_id}`}>
            <Popover.Header as="h3" className={`popover-header ${seatStatusClass}`}>{seat_id}</Popover.Header>
            <Popover.Body>
                {renderSeatStatus()}<br/>
                {member_name.trim() !== '' && <><span className="fw-bold">Name: </span>{member_name}<br /></>}
                {phone.trim() !== '' && <><span className="fw-bold">Phone: </span>{phone}<br /></>}
            </Popover.Body>
        </Popover>
    );

    const handleClick = () => {
        onSelect(seat_id);
    };


    return (
        <OverlayTrigger trigger={['hover','focus']} placement="auto" overlay={popoverContent} rootClose={true} className="popover">
            <div className={`chair ${seatStatusClass} ${isSelected ? 'selected' : ''}`} onClick={handleClick}>
                {seat_id}
            </div>
        </OverlayTrigger>
    );
};

export default Seat;
