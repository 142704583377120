// // third-party
// import { configureStore } from '@reduxjs/toolkit';
//
// // project import
// import reducers from './reducers';
//
// // ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //
//
// const store = configureStore({
//   reducer: reducers
// });
//
// const { dispatch } = store;
//
// export { store, dispatch };
// third-party
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project import
import reducers from './reducers';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

const { dispatch } = store;

export { store, persistor, dispatch };