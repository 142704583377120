import { request } from "./common.service";
export function createHall(values) {
  return request({
    url: '/admin/create-hall',
    method: 'POST',
    body: values
  });
}

export function viewHall(page_number, items_per_page) {
  return request({
    url: `/admin/get-all-hall?page_number=${page_number}&items_per_page=${items_per_page}`,
    method: 'GET'
  });
}

export function deleteHall(hall_id) {
  return request({
    url: `/admin/delete-hall?hall_id=${hall_id}`,
    method: 'DELETE'
  });
}

export function updateHall(values) {
  return request({
    url: '/admin/update-hall',
    method: 'PUT',
    body: values
  });
}

export function searchHall(searching_data) {
  return request({
    url: `/admin/search-hall?searching_data=${searching_data}`,
    method: 'GET'
  });
}

export function getHalls() {
  return request({
    url: '/admin/get-all-hall',
    method: 'GET'
  });
}

export function getHall(hall_id) {
  return request({
    url: `/admin/get-hall-by-id?hall_id=${hall_id}`,
    method: 'GET'
  });
}




