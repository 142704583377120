// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import locale from './locale';
import forgotPassword from "./forgotPasswordReducer";
import member from "./memberReducer";
import payment from "./paymentReducer";
import submember from "./submemberReducer";
import renew from './renewMembershipReducer'
import dashboard from "./dashboard";


// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, locale, forgotPassword,member,payment,submember ,renew,dashboard});

export default reducers;
