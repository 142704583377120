import React, {useEffect, useState} from "react";
import {
    Dialog, DialogContent, DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {FormattedMessage} from "react-intl";
import AllocationUI from "./index";
import {fetchConfirmSeatData} from "../../../service/event.api";
import {errorToast} from "../../../shared/components/AlertMessages";

const AllocationModal = ({isOpen, onClose, allocationData,handleAllocationClose}) => {

    const [seatingJsonData, setSeatingJsonData] = useState([]);

    const fetchSeatData = () => {
        fetchConfirmSeatData(allocationData.event_booking_event_id, allocationData.event_booking_group_id)
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        setSeatingJsonData(response?.data[0]?.event_booking_json_data)
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    // setSelectedEventData([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
    };

    useEffect(() => {
        if (isOpen) {
            fetchSeatData();
        }
    }, [isOpen]);


    const handleAllocationModalClose = () => {
        handleAllocationClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xl">
            <DialogTitle className="linear-gradient">
                {allocationData?.event_booking_hall_name}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>

            <DialogContent dividers>
                {seatingJsonData ? (<AllocationUI seatingJson={[seatingJsonData]} allocationData={allocationData} handleAllocationModalClose={handleAllocationModalClose}/>
                ) : ("No data found")}
            </DialogContent>
        </Dialog>
    );
};

export default AllocationModal;
