import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CardHeader } from "@mui/material";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../../shared/components/AlertMessages";
import { getMemberType, getMemberTypes } from "../../../service/membertype.api";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import {
  setMemberType,
  setOrderId,
} from "../../../store/reducers/paymentReducer";
import { RenewMemberOrder } from "../../../service/member.api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatBirthDate, setCookie } from "../../../shared/helpers/utils";
import { cookieEnum } from "../../../enums/cookie.enum";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import RenewSubmember from "../tables/RenewSubmember";
import { FormattedMessage } from 'react-intl';
import {setMemberDetails} from "../../../store/reducers/renewMembershipReducer";

const UpdateMemberForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memberDetails = useSelector((state) => state.renew.memberDetails);

  const submemberCount = useSelector((state) => state.submember.submemberCount);
  const [memberTypes, setMemberTypes] = useState([]);

  const [submembersAmount, setSubmembersAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [selectedMemberType, setSelectedMemberType] = useState(
      memberDetails.member.member_type_id || ""
  );

  useEffect(() => {
    const amount = memberDetails?.member?.member_type_price;
    const submembersAmount = memberDetails?.member?.member_type_price * submemberCount;
    const totalAmount = submembersAmount + parseFloat(memberDetails?.member?.member_type_price);
    setAmount(amount);
    setSubmembersAmount(submembersAmount);
    setTotalAmount(totalAmount);

    // Update memberDetails with the new submember count
    const updatedMemberDetails = {
      ...memberDetails,
      submemberCount
    };
    dispatch(setMemberDetails(updatedMemberDetails));
  }, [submemberCount]);

  const fetchMemberTypes = () => {
    getMemberTypes()
      .then((response) => {
        if (response) {
          if (
            response.data?.status_code === 200 ||
            response.data?.status_code === 201
          ) {
            setMemberTypes(response.data?.data.member_type_list);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setMemberTypes([]);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          warningToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
      });
  };

  useEffect(() => {
    fetchMemberTypes();
  }, []);



  const handlememberTypeselect = (memberType) => {
    getMemberType(memberType)
        .then((response) => {
          if (response) {
            if (
                response.data?.status_code === 200 ||
                response.data?.status_code === 201
            ) {
              const amount = response.data.data[0].price;
              setAmount(amount);
              const submembers_amount =
                  response.data.data[0].price *
                  submemberCount;
              const total_amount =
                  submembers_amount +
                  parseFloat(response.data.data[0].price);
              setSubmembersAmount(submembers_amount);
              setTotalAmount(total_amount);

              // Update memberDetails with the new member type
              const updatedMemberDetails = {
                ...memberDetails,
                member: {
                  ...memberDetails.member,
                  member_type_id: memberType,
                  member_type_price: response.data.data[0].price
                }
              };
              dispatch(setMemberDetails(updatedMemberDetails));
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast("something went wrong");
          }
        });
  };


  const formik = useFormik({
    initialValues: {
      first_name: memberDetails.member.first_name || "",
      last_name: memberDetails.member.last_name || "",
      middle_name: memberDetails.member.middle_name || "",
      dob: dayjs(memberDetails.member.dob),
      gender: memberDetails.member.gender || "",
      email: memberDetails.member.email || "",
      phone: memberDetails.member.phone || "",
      address: memberDetails.member.address || "",
      pincode: memberDetails.member.pincode || "",
      member_type: selectedMemberType,
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .matches(/^[^0-9]*$/, "First name cannot contain numbers")
        .required("First name Required"),
      middle_name: Yup.string()
        .matches(/^[^0-9]*$/, "Middle name cannot contain numbers")
        .required("Middle name Required"),
      last_name: Yup.string()
        .matches(/^[^0-9]*$/, "Last name cannot contain numbers")
        .required("Last name Required"),
      dob: Yup.date()
        .max(new Date(), "Please select a valid date")
        .required("Dob Required")
        .nullable(),
      gender: Yup.string().required("Gender Required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email address Required"),
      address: Yup.string().required("Address Required"),
      pincode: Yup.string()
        .matches(/^\d{6}$/, "Pincode must be 6 digits")
        .required("Pincode Required"),
      member_type: Yup.string().required("Member Type Required"),
    }),
    onSubmit: (values) => {
      delete values.price;
      delete values.persons_capacity;
      let data = {
        first_name: values.first_name,
        last_name: values.last_name,
        middle_name: values.middle_name,
        gender: values.gender,
        email: values.email,
        phone: values.phone,
        address: values.address,
        pincode: values.pincode,
        dob: formatBirthDate(values.dob.$d),
        member_type_id: values.member_type,
        member_id: memberDetails.member.member_id,
        total_members: submemberCount + 1,
        order_for: "renew_member",
      };

      RenewMemberOrder(data)
        .then((response) => {
          if (response) {
            if (
              response.data?.status_code === 200 ||
              response.data?.status_code === 201
            ) {
              successToast(response.data?.message);
              dispatch(setOrderId(response.data?.data?.order_id));
              setCookie(cookieEnum.ORDER, response.data?.data?.order_id);
              dispatch(setMemberType(response.data?.data?.member_type));
              dispatch(setTotalAmount(response.data?.data?.total_amount));
              navigate("/payment/details");
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast("something went wrong");
          }
        });
    },
  });

  const handleFormSubmit = () => {
    formik.handleSubmit();
  };
  return (
    <Card className="card" sx={{marginTop:"80px",marginLeft:"20px",marginRight:"20px",marginBottom:"20px"}}>
      <CardHeader title=<FormattedMessage id="renew_member" /> className="linear-gradient" />
      <CardContent>
        <div>
          <Grid container columns={{ xs: 4, md: 12 }}>
            <Grid item xs={4}>
              <span className="fw-bold"><FormattedMessage id="name" /></span>
              <p className="text-muted">{`${memberDetails.member.first_name} ${memberDetails.member.middle_name} ${memberDetails.member.last_name}`}</p>
            </Grid>

            {/* Date of Birth */}
            <Grid item xs={4}>
              <span className="fw-bold"><FormattedMessage id="dob" /></span>
              <p className="text-muted">{memberDetails.member.dob}</p>
            </Grid>

            {/* Gender */}
            <Grid item xs={4}>
              <span className="fw-bold"><FormattedMessage id="gender" /></span>
              <p className="text-muted">{memberDetails.member.gender}</p>
            </Grid>

            {/* Email */}
            <Grid item xs={4}>
              <span className="fw-bold"><FormattedMessage id="email" /></span>
              <p className="text-muted">{memberDetails.member.email} </p>
            </Grid>

            {/* Whatsapp Mobile Number */}
            <Grid item xs={4}>
              <span className="fw-bold"><FormattedMessage id="mobile_num" /></span>
              <p className="text-muted">{memberDetails.member.phone}</p>
            </Grid>

            {/* Address */}
            <Grid item xs={4}>
              <span className="fw-bold"><FormattedMessage id="address" /></span>
              <p className="text-muted">{memberDetails.member.address}</p>
            </Grid>
          </Grid>
          <hr/>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 6, sm: 12, md: 12 }}
          >
            <Grid item xs={7}>
              <RenewSubmember memberDetails={memberDetails} />
            </Grid>
            <Grid item xs={5}>
              <div className="float-end mb-2">
                <span className="fw-bold"><FormattedMessage id="membership" /></span>
                <Select
                    fullWidth
                    id="member_type"
                    name="member_type"
                    variant="outlined"
                    value={formik.values.member_type}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handlememberTypeselect(e.target.value);
                      setSelectedMemberType(<e className="target value"></e>);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.member_type &&
                        Boolean(formik.errors.member_type)
                    }
                    sx={{ width: '100%' }} // Set the width here

                >
                  {memberTypes.map((memberType) => (
                      <MenuItem
                          key={memberType.member_type_id}
                          value={memberType.member_type_id}
                      >
                        {memberType.member_type}
                      </MenuItem>
                  ))}
                </Select>
                {formik.touched.member_type && formik.errors.member_type && (
                    <div
                        className="error-message"
                        style={{color: "red", fontSize: "12px"}}
                    >
                      {formik.errors.member_type}
                    </div>
                )}
              </div>
              <TableContainer style={{marginTop: "60px" }}>
                <Table border="2" aria-labelledby="tableTitle">
                  <TableBody>
                    <TableRow>
                      <TableCell><FormattedMessage id="main_member" />
                      </TableCell>
                      <TableCell> 1 </TableCell>
                      <TableCell>₹{amount}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell> <FormattedMessage id="total_submembers" />
                      </TableCell>
                      <TableCell>{submemberCount}</TableCell>
                      <TableCell>₹{submembersAmount}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <span className="fw-bold"><FormattedMessage id="total" />
</span>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <span className="fw-bold">₹{totalAmount}</span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <div className="display-flex justify-content-between h-25 mt-2">
            <Stack direction="row" spacing={2} justifyContent="flex-start">
              {/*<Button type="button" variant="outlined" color="primary" onClick={() => handleEdit()}>*/}
              {/*  Edit*/}
              {/*</Button>*/}
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button type="button" variant="contained" color="primary">
                Continue to Payment
              </Button>
            </Stack>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default UpdateMemberForm;
