import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import Card from "@mui/material/Card";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CardContent from "@mui/material/CardContent";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {useEffect} from "react";
import {GetRegistrationStatus, UpdateRegistrationStatus} from "../../service/settings.api";
import {errorToast, successToast, warningToast} from "../../shared/components/AlertMessages";
import {ToastContainer} from "react-toastify";

export default function SwitchListSecondary() {
    const [checked, setChecked] = React.useState({
        is_registration_open: false,
        is_renew_membership_open: false,
    });

    const [settingId, setSettingId] = React.useState('');

    const handleToggle = (value) => () => {
        setChecked((prevChecked) => ({
            ...prevChecked,
            [value]: !prevChecked[value],
        }));
    };


    const fetchRegistrationStatus = () => {
        GetRegistrationStatus()
            .then((response) => {
                if (response) {
                    if (
                        response?.data?.status_code === 200 ||
                        response?.data?.status_code === 201
                    ) {
                        setSettingId(response.data?.data?.setting_list[0].setting_id);
                        setChecked({
                            is_registration_open: response.data?.data?.setting_list[0].is_registration_open,
                            is_renew_membership_open: response.data?.data?.setting_list[0].is_renew_membership_open,
                        });
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setChecked({})
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast("something went wrong");
                }
            })
    }


    useEffect(() => {
        fetchRegistrationStatus()
    }, []);

    const handleSave = () => {
        // Logic to save the settings goes here
        let data = {
            "setting_id": settingId,
            "is_registration_open": checked.is_registration_open,
            "is_renew_membership_open": checked.is_renew_membership_open,
        }
        UpdateRegistrationStatus(data)
            .then((response) => {
                if (response) {
                    if (
                        response?.status_code === 200 ||
                        response?.status_code === 201
                    ) {
                        successToast(response?.message);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast("something went wrong");
                }
            })
    };
    return (
        <Card
            className="card"
            sx={{
                width: {
                    xs: '100%',
                    md: '50%',
                },
            }}
        >
            <CardContent>

                <List
                    sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                    subheader={<ListSubheader>Settings</ListSubheader>}
                >
                    <ListItem>
                        <ListItemIcon>
                            <HowToRegIcon style={{marginRight: '10px'}}/>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-membership-register" primary="Membership Registration"/>
                        <Switch
                            edge="end"
                            onChange={handleToggle('is_registration_open')}
                            checked={checked.is_registration_open}
                            inputProps={{
                                'aria-labelledby': 'switch-list-label-membership-register',
                            }}
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <MilitaryTechIcon style={{marginRight: '10px'}}/>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-renew-membership" primary="Renew Membership"/>
                        <Switch
                            edge="end"
                            onChange={handleToggle('is_renew_membership_open')}
                            checked={checked.is_renew_membership_open}
                            inputProps={{
                                'aria-labelledby': 'switch-list-label-renew-membership',
                            }}
                        />
                    </ListItem>
                </List>

                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Box>

            </CardContent>
            <ToastContainer/>
        </Card>
    );
}
