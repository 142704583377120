import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { strengthColor, strengthIndicator } from '../../../utils/password-strength';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { ChangePassword } from '../../../service/login.api';
import { getId, getRole } from '../../../shared/helpers/authentication';
import { errorToast, successToast, warningToast } from '../../../shared/components/AlertMessages';
import { ToastContainer } from 'react-toastify';
import { removeLoginData } from '../../../shared/helpers/utils';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Card from 'react-bootstrap/Card';


const ChangePasswordForm = () => {
  const [level, setLevel] = useState();
  const [showNew, setShowNew] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const member_id = getId();
  const role = getRole();
  const navigate = useNavigate();
  const [currentPasswordRef, newPasswordRef, confirmPasswordRef] = [useRef(null), useRef(null), useRef(null)];

  const restoreCursor = (inputRef) => {
    const selectionStart = inputRef.current.selectionStart;
    const selectionEnd = inputRef.current.selectionEnd;

    setTimeout(() => {
      inputRef.current.setSelectionRange(selectionStart, selectionEnd);
    });
  };
  const handleClickShowNew = () => {
    setShowNew(!showNew);
    restoreCursor(currentPasswordRef);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    restoreCursor(newPasswordRef);
  };

  const handleClickConfirmPassword = () => {
    setShowConfirm(!showConfirm);
    restoreCursor(confirmPasswordRef);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    changePassword('');
  }, []);

  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required('Current Password is required'),
      newPassword: Yup.string()
        .required('Password Required')
        .min(8, 'Password must have at least 8 characters')
          .max(16,'Password must not be more than 16 characters')
          .matches(/[a-z]/, getCharacterValidationError('lowercase'))
        .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
        .matches(/[0-9]/, getCharacterValidationError('digit'))
        .matches(/[\W_]/, getCharacterValidationError('special character')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm New Password is required')
    }),
    onSubmit: (values) => {
      // Handle password change submission here
      let data = {
        old_password: values.currentPassword,
        confirm_password: values.confirmPassword,
        member_id: member_id,
        role: role
      };
      ChangePassword(data)
        .then((response) => {
          if (response) {
            if (response?.status_code === 200 || response?.status_code === 201) {
              successToast(response?.message);
              removeLoginData();
              setTimeout(() => {
                navigate('/');
              }, 1000);
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast('something went wrong');
          }
        });
    }
  });

  return (
    <div>
      <Card>
        <form onSubmit={formik.handleSubmit}>

          <CardHeader title=<FormattedMessage id="change_password"/> className="linear-gradient" />
          <Card.Body>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="current_password"/>
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                <OutlinedInput
                    fullWidth
                    id="currentPassword"
                    name="currentPassword"
                    placeholder="Current Password"
                    type={showNew ? 'text' : 'password'}
                    variant="outlined"
                    size="small"
                    value={formik.values.currentPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    inputRef={currentPasswordRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNew}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                        >
                          {showNew ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{}}
                />
                {formik.touched.currentPassword && formik.errors.currentPassword && (
                    <FormHelperText error id="helper-text-password-signup">
                      {formik.errors.currentPassword}
                    </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={7}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="new_password"/>
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                <OutlinedInput
                    fullWidth
                    id="newPassword"
                    name="newPassword"
                    placeholder="New Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    size="small"
                    value={formik.values.newPassword}
                    onChange={(e) => {
                      formik.handleChange(e);
                      changePassword(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    inputRef={newPasswordRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                        >
                          {showPassword ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{}}
                />
                {formik.touched.newPassword && formik.errors.newPassword && (
                    <FormHelperText error id="helper-text-password-signup">
                      {formik.errors.newPassword}
                    </FormHelperText>
                )}
                <FormControl fullWidth sx={{mt: 2}}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Box sx={{bgcolor: level?.color, width: 85, height: 8, borderRadius: '7px'}}/>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" fontSize="0.75rem">
                        {level?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="confirm_password"/>
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                <OutlinedInput
                    fullWidth
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                    type={showConfirm ? 'text' : 'password'}
                    variant="outlined"
                    size="small"
                    value={formik.values.confirmPassword}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    inputRef={confirmPasswordRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                        >
                          {showConfirm ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{}}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <FormHelperText error id="helper-text-password">
                      {formik.errors.confirmPassword}
                    </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Card.Body>
          <Card.Footer>
            <Button color="primary" variant="contained" type="submit">
              <FormattedMessage id="change_password"/>
            </Button>
          </Card.Footer>
        </form>

      </Card>
  <ToastContainer/>
</div>
  );
};

export default ChangePasswordForm;
