import React from 'react';
import '../css/Page404.css';
import {Button} from "@mui/material";
import {FormattedMessage} from "react-intl";
import { Link } from "react-router-dom";

const ExpiredMembership = () => {
    return (
        <div className="section">

            <div className="page"><h4 className="h4"><FormattedMessage id="expired_membership"/></h4>
                <p><FormattedMessage id="expired_membership_msg"/></p></div>
                <Link to="/renew-membership" style={{ textDecoration: 'none' }}>

                <Button color="primary" variant="contained" type="submit">
                    Renew Membership
                </Button>
                </Link>
        </div>
    );
};

export default ExpiredMembership;
