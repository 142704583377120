import React, {useEffect, useState} from 'react';
import {Box, Button, DialogActions, Grid} from "@mui/material";
import Stage from "./Stage";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Allocation from "./Allocation";
import './allocation.css';
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import {errorToast, successToast, warningToast} from "../../../shared/components/AlertMessages";
import {ConfirmBooking} from "../../../service/event.api";
import {ConfirmBookingModal} from "../BookingUI/ConfirmBookingModal";
const AllocationUI = ({seatingJson,allocationData,handleAllocationModalClose}) => {
    const [seatingData,setSeatingData] = useState(seatingJson)
    const [isEdit,setIsEdit] = useState(false);

    const [isConfirmBookingModal, setIsConfirmBookingModal] = useState(false);

    const customSort = (a, b) => {
        const extractNumber = (rowName) => {
            const rowNameStr = String(rowName); // Ensure rowName is a string
            const match = rowNameStr.match(/\d+/);
            return match ? parseInt(match[0]) : 0;
        };

        // Function to convert row names to numeric equivalents for sorting
        const getNumericValue = (rowName) => {
            const base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            let value = 0;
            for (let i = 0; i < rowName.length; i++) {
                value = value * 26 + base.indexOf(rowName[i]) + 1;
            }
            return value;
        };

        // Function to compare two rows
        const compareRows = (rowA, rowB) => {
            const numericA = getNumericValue(rowA.toUpperCase());
            const numericB = getNumericValue(rowB.toUpperCase());
            return numericB - numericA; // Sort in descending order
        };

        // Sort rows alphabetically, except row A which should come last
        if (a.row === 'Booking A') return 1;
        if (b.row === 'Booking A') return -1;

        // Compare rows using custom function
        const comparison = compareRows(a.row, b.row);

        // If rows are not equal, return the comparison result
        if (comparison !== 0) {
            return comparison;
        }

        // If rows are equal, sort by numeric value
        const aNumber = extractNumber(a.row);
        const bNumber = extractNumber(b.row);
        return bNumber - aNumber;
    };


    const seatingDataArray = Object.keys(seatingData[0]).map((rowKey) => {
        const rowData = seatingData[0][rowKey];
        const columns = Object.keys(rowData).map((columnKey) => {
            const columnData = rowData[columnKey];
            const seats = Object.values(columnData);
            return {name: columnKey, seats};
        });
        return {row: rowKey, columns};
    });

    // Sort rows
    const sortedRows = seatingDataArray.sort(customSort);

    const [zoomLevel, setZoomLevel] = useState(1);
    const [selectedSeats, setSelectedSeats] = useState([]);

    const handleZoomIn = () => {
        setZoomLevel(zoomLevel + 0.1);
    };

    const handleZoomOut = () => {
        if (zoomLevel > 0.2) {
            setZoomLevel(zoomLevel - 0.1);
        }
    };


    useEffect(() => {
        if(allocationData.event_booking_status === 'Pending'){
            setIsEdit(true)
        }
        setSeatingData(seatingJson)
    }, [seatingJson]);

    const handleSeatSelect = (seat_id) => {
        if (!isEdit) {
            // If not in edit mode, do nothing
            return;
        }
        if (selectedSeats.includes(seat_id)) {
            // If seat is already selected, deselect it
            setSelectedSeats(selectedSeats.filter(id => id !== seat_id));
        } else if (selectedSeats.length < 2) {
            setSelectedSeats([...selectedSeats, seat_id]);
        } else {
            // Show validation message for selecting more than two seats
            warningToast("You can only select up to two seats.");
        }
    };

    const handleSaveDraft = () => {

        let data = {
            is_saved : true,
            is_confirmed_booked: false,
            event_id: allocationData.event_booking_event_id,
            group_id: allocationData.event_booking_group_id,
            is_sms_send: false,
            is_whatsapp_send: false,
            confirm_seat_json:[seatingData[0]],
            event_booking_id: allocationData.event_booking_id

        };
        ConfirmBooking(data)
            .then((response) => {
                if (response) {
                    if (response.status_code === 200 || response.status_code === 201) {
                        successToast(response?.message)
                        setTimeout(() => {
                            handleAllocationModalClose();
                        },
                            1000);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            })

    }

    const handleSeatSwap = () => {
        if (selectedSeats.length === 2) {
            const [seatId1, seatId2] = selectedSeats;
            const seatingDataCopy = {...seatingData};

            // Create a seat ID to column key mapping
            const seatIdToColumnKey = {};
            const seatIdToRowKey = {};
            for (const key in seatingDataCopy) {
                for (const rowKey in seatingDataCopy[key]) {
                    for (const columnKey in seatingDataCopy[key][rowKey]) {
                        for (const seatId in seatingDataCopy[key][rowKey][columnKey]) {
                            seatIdToColumnKey[seatId] = columnKey;
                            seatIdToRowKey[seatId] = rowKey;
                        }
                    }
                }
            }

            // Get the column keys and row keys for seatId1 and seatId2
            const column1Key = seatIdToColumnKey[seatId1];
            const column2Key = seatIdToColumnKey[seatId2];
            const row1Key = seatIdToRowKey[seatId1];
            const row2Key = seatIdToRowKey[seatId2];

            if(seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].status === 'available' && seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].status === 'available') {
                warningToast("Can't swap both available seats");
                setSelectedSeats([]);
            }

            else if(seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].seat_for === 'non-vip' && seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].seat_for === 'vip') {
                warningToast("Can't swap  Non-VIP to VIP seats");
                setSelectedSeats([]);
            }
            else if(seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].seat_for === 'vip' && seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].seat_for === 'non-vip') {
                warningToast("Can't swap VIP to Non-VIP seats");
                setSelectedSeats([]);
            }

            else if (column1Key !== undefined && column2Key !== undefined &&
                row1Key !== undefined && row2Key !== undefined &&
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1] &&
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2] && seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].status !== 'booked' && seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].status !== 'booked') {
                // Swap member_id, membership_id, and status between seatId1 and seatId2
                const tempMemberId = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].member_id;
                const tempMembershipId = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].membership_id;
                const tempStatus = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].status;
                const tempName = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].member_name;
                const tempMemberType = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].member_type;
                const tempPhone = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].phone;

                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].member_id = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].member_id;
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].membership_id = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].membership_id;
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].status = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].status;
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].member_name = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].member_name;
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].member_type = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].member_type;
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row1Key][column1Key][seatId1].phone = seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].phone;

                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].member_id = tempMemberId;
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].membership_id = tempMembershipId;
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].status = tempStatus;
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].member_name = tempName;
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].member_type = tempMemberType;
                seatingDataCopy[Object.keys(seatingDataCopy)[0]][row2Key][column2Key][seatId2].phone = tempPhone;

                setSeatingData(seatingDataCopy);
                setSelectedSeats([]);
            } else {
                warningToast("Can't swap reserved seats");
                setSelectedSeats([]);


            }
        }
    };

    const handleModalClose = () => {
        setIsConfirmBookingModal(false);
    }

    return (
        <>
            <Box display="flex" justifyContent="right"
                 style={{marginBottom: "6px"}}>
                <IconButton color="primary" size="small" onClick={handleZoomIn}>
                    <ZoomInIcon/>
                </IconButton>

                <IconButton color="primary" size="small"  onClick={handleZoomOut} style={{marginLeft:"6px"}}>
                    <ZoomOutIcon/>
                </IconButton>

            </Box>
        <div className="card-allocation">
                <div className="card-content-allocation" style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top center' }}>
                    <Card>
                        <Box display="flex" justifyContent="center"
                             style={{marginTop: "20px", paddingLeft: "10px", paddingRight: '10px'}}>
                            <Box display="flex" justifyContent="center" style={{marginBottom: "20px"}}>
                                <Box width={20} height={20} bgcolor="lightBlue" marginRight={1}/>
                                <Typography variant="body1" color="textSecondary">
                                    VIP seats
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" style={{marginBottom: "20px"}}>
                                <Box width={20} height={20} bgcolor="lightgreen" marginRight={1}
                                     style={{marginLeft: "20px"}}/>
                                <Typography variant="body1" color="textSecondary">
                                    Non-VIP seats
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" style={{marginBottom: "20px"}}>
                                <Box width={20} height={20} bgcolor="lightgrey" marginRight={1}
                                     style={{marginLeft: "20px"}}/>
                                <Typography variant="body1" color="textSecondary">
                                    Reserved seats
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" style={{marginBottom: "20px"}}>
                                <Box width={20} height={20} bgcolor="whitesmoke" marginRight={1}
                                     style={{marginLeft: "20px"}}/>
                                <Typography variant="body1" color="textSecondary">
                                    Available seats
                                </Typography>
                            </Box>
                        </Box>


                        <Grid container className="seat-container-booking">
                            <div>
                                {/* Render rows */}
                                {sortedRows.map((row) => (
                                    <RowGroup
                                        key={row.row}
                                        rowData={row}
                                        onSelect={handleSeatSelect}
                                        selectedSeats={selectedSeats}
                                    />
                                ))}
                            </div>


                            <div className="stage-booking">
                                <Stage/>
                            </div>
                        </Grid>






                    </Card>
                </div>
            <ConfirmBookingModal isOpen={isConfirmBookingModal} onClose={handleModalClose}
                                  seatingData={seatingData} group_id={allocationData.event_booking_group_id} event_id={allocationData.event_booking_event_id}  bookingHistory={true} allocationData={allocationData} handleAllocationModalClose={handleAllocationModalClose}/>

            </div>
            {isEdit &&
                <DialogActions sx={{ padding: '16px 24px', justifyContent: 'space-between'  }}>

                    <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft={2} marginRight={2}
                         width="100%">

                        <Button size="medium" variant="contained" color="primary"  style={{marginRight:'10px'}} onClick={handleSaveDraft}>
                            Save as Draft
                        </Button>
                        <Button size="medium" variant="contained" color="primary" onClick={handleSeatSwap} style={{marginRight:'10px'}} disabled={selectedSeats.length<2}>
                            <SwapHorizIcon style={{marginRight:'6px'}}/>
                            Swap Seats
                        </Button>
                        <Button size="medium" variant="contained" color="error" style={{marginRight:'30px'}} onClick={() => setIsConfirmBookingModal(true)}>
                            Confirm Booking
                        </Button>
                    </Box>
                </DialogActions>
            }
        </>
    );
};

const RowGroup = ({
                      rowData,onSelect,selectedSeats
                  }) => {
    const {columns} = rowData;

    return (
        <div className="row-group-booking">
            {/* Render columns */}
            {columns.map((column, index) => (
                <React.Fragment key={column.name}>
                    {index > 0 && <div className="column-space"/>}
                    <Allocation columnData={column} onSelect={onSelect} selectedSeats={selectedSeats} />
                </React.Fragment>
            ))}
        </div>
    );
};


export default AllocationUI;