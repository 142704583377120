import Login from "../../pages/authentication/Login";
import MemberRenew from "../../pages/member/RenewMember";
import { Route, Routes } from 'react-router-dom';
import PasswordForgot from "../../pages/authentication/PasswordForgot";
import PasswordForgotOtp from "../../pages/authentication/PasswordForgotOtp";
import PasswordReset from "../../pages/authentication/PasswordReset";

const MinimalLayout = () => (
  <Routes>
    {/* Define routes and render components conditionally */}
    <Route path="/" element={<Login />} />
    <Route path="/renew-membership" element={<MemberRenew />} />
    <Route path="/forgot-password" element={<PasswordForgot />} />
      <Route path="/forgot-password/otp" element={<PasswordForgotOtp />} />
      <Route path="/reset-password" element={<PasswordReset />} />

  </Routes>
);

export default MinimalLayout;

