import React, {useEffect} from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {createEvent} from '../../../service/event.api';
import {errorToast, successToast, warningToast} from '../../../shared/components/AlertMessages';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {capitalizeName, formatDate} from '../../../shared/helpers/utils';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import {FormattedMessage} from 'react-intl';
import {getHall, getHalls} from "../../../service/hall.api";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
     InputAdornment,
    MenuItem, OutlinedInput,
    Select
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

const CreateEvent = ({isOpen, onClose, onAdd}) => {

    const [hallData, setHallData] = React.useState([]);

    const create = (data) => {
        createEvent(data)
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        successToast(response?.message);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            })
            .finally(() => {
                onAdd();
            });
    };

    const view = () => {
        getHalls()
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        setHallData(response.data?.hall_list);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setHallData([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
    };
    const getHallDetails = (hall) => {
        getHall(hall)
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        const selectedHall = response.data && response.data.length > 0 ? response.data[0] : {};
                        formik.setFieldValue('venue', selectedHall.hall_address || ''); // Update venue directly in formik state
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
    };


    useEffect(() => {
        if (isOpen) {
            view();
        } else {
            formik.resetForm();
        }
    }, [isOpen]); // Include page and rowsPerPage in the dependency array

    const fileInputRef = React.useRef(null);

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue('event_image', file);
    };


    const formik = useFormik({
        initialValues: {
            event_name: '',
            venue: '',
            date: null,
            hall: '',
            event_description: '',
            is_event_name_visible: false,
            event_image: null

        },
        validationSchema: Yup.object({
            event_name: Yup.string().required('Event Name Required'),
            venue: Yup.string().required('Venue Required'),
            date: Yup.date()
                .required('Date Required')
                .nullable()
                .test('is-future-date', 'Please select a valid date ', (value) => {
                    if (value) {
                        return dayjs(value).isAfter(dayjs()); // Check if the selected date is after the current date
                    }
                    return true;
                }),
            hall: Yup.string().required('Please select Hall'),
            // event_description: Yup.string()('Event Description Required'),

        }),

        onSubmit: (values) => {
            const formData = new FormData();

            formData.append('event_name', capitalizeName(values.event_name));
            formData.append('venue', values.venue);
            formData.append('date', formatDate(values.date.$d));
            formData.append('hall_id', values.hall);
            formData.append('event_description', values.event_description);
            formData.append('is_event_name_visible', values.is_event_name_visible);

            if (values.event_image) {
                formData.append('file', values.event_image); // Append the file itself
            }

            create(formData);

        }
    });
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle className="linear-gradient">
                    <FormattedMessage id="create_event"/>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="event_name"/>
                                <span style={{color: 'red'}}>*</span>
                            </Typography>
                            <TextField
                                fullWidth
                                id="event_name"
                                name="event_name"
                                variant="outlined"
                                size="small"
                                value={formik.values.event_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.event_name && Boolean(formik.errors.event_name)}
                                helperText={formik.touched.event_name && formik.errors.event_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{marginTop: "16px"}}>
                            <Checkbox name="is_event_name_visible"
                                      color="primary"
                                      onChange={formik.handleChange}
                                      checked={formik.values.is_event_name_visible}/>
                            <label className="me-5">
                                Event Name show in Ticket?
                                <sup>
                                    <Tooltip title="If checked, the event name will be shown in the ticket">
                                        <InfoIcon fontSize="small" color="warning"/>
                                    </Tooltip>
                                </sup>
                            </label>


                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="date"/>
                                <span style={{color: 'red'}}>*</span>
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    disablePast
                                    placeholder="Date picker"
                                    id="date"
                                    name="date"
                                    inputFormat="MM/DD/YYYY hh:mm:ss"
                                    value={formik.values.date}
                                    onChange={(value) => {
                                        formik.setFieldValue('date', value);
                                    }}
                                    onBlur={formik.handleBlur}
                                    sx={{
                                        width: '100%',
                                        borderRadius: '4px', // Set the border radius to make it round
                                        ...(formik.touched.date && formik.errors.date && {border: '1px solid red'})
                                    }}/>
                            </LocalizationProvider>

                            {formik.touched.date && formik.errors.date &&
                                <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.date}</div>}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="view_hall"/>: <span style={{color: 'red'}}>*</span>
                            </Typography>
                            <Select
                                fullWidth
                                id="hall"
                                name="hall"
                                size="small"
                                value={formik.values.hall}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    getHallDetails(e.target.value);

                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.hall && Boolean(formik.errors.hall)}
                            >
                                {hallData.map((hall) => (
                                    <MenuItem key={hall.hall_id} value={hall.hall_id}>
                                        {hall.hall_name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.hall && formik.errors.hall && (
                                <div className="error-message" style={{color: 'red', fontSize: '12px'}}>
                                    {formik.errors.hall}
                                </div>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="venue"/>
                                <span style={{color: 'red'}}>*</span>
                            </Typography>
                            <TextField
                                fullWidth
                                name="venue"
                                placeholder="Enter venue details"
                                value={formik.values.venue}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                    style: {color: 'grey'} // Change color here

                                }}
                            />

                            {formik.touched.venue && formik.errors.venue &&
                                <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.venue}</div>}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="event_details"/>
                            </Typography>
                            <TextField
                                fullWidth
                                multiline={true}
                                name="event_description"
                                placeholder="Enter Event Description"
                                value={formik.values.event_description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                rows={4}
                                cols={40}
                                size="small"
                            />

                            {formik.touched.event_description && formik.errors.event_description &&
                                <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.event_description}</div>}
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <Typography variant="subtitle1">
                                    Image for Ticket
                                    <sup>
                                        <Tooltip title="To display an image in the ticket, please upload an image file">
                                            <InfoIcon fontSize="small" color="warning"/>
                                        </Tooltip>
                                    </sup>
                                </Typography>

                                <OutlinedInput
                                    className="file-input"
                                    id="outlined-adornment-weight"
                                    placeholder="Choose a file..."
                                    value={formik.values.event_image ? formik.values.event_image.name : ''}
                                    readOnly
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {formik.values.event_image && (
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => formik.setFieldValue('event_image', '')}
                                                >
                                                    <CloseIcon/>
                                                </IconButton>
                                            )}
                                            <Button
                                                size="medium"
                                                className="right"
                                                component="span"
                                                onClick={() => fileInputRef.current.click()}
                                            >
                                                Browse
                                            </Button>
                                            <input
                                                accept="image/*"
                                                id="event_image"
                                                name="event_image"
                                                type="file"
                                                onChange={handleFileInputChange}
                                                ref={fileInputRef}
                                                style={{display: 'none'}}
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions style={{justifyContent: "end"}}>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        style={{margin: 8}}
                    >
                        <FormattedMessage id="add"/>
                    </Button>
                    <Button onClick={onClose} variant="outlined" style={{margin: 8}}>
                        <FormattedMessage id="cancel"/>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateEvent;