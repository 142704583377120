// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { LOCALES } from './i18n/locales';
import { messages } from './i18n/messages';
import { useSelector } from 'react-redux';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const {selectedLocale} = useSelector((state) => state.locale);

  return (

    <IntlProvider messages={messages[selectedLocale]} locale={selectedLocale} defaultLocale={LOCALES.ENGLISH}>
      <ThemeCustomization>
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </ThemeCustomization>
    </IntlProvider>

  );
};

export default App;