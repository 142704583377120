import {admin, member} from './utilities';

// ==============================|| MENU ITEMS ||============================== //
import { getRole } from '../shared/helpers/authentication';

const MenuItems = () => {
  const role = getRole();

  if (role === 'admin') {
    return {
      items: [admin]
    };
  } else if (role === 'member') {
    return {
      items: [member]
    };
  } else {
    return null;
  }
};

export default MenuItems;
