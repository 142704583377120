// assets

// ==============================|| MENU ITEMS - UTILITIES ||============================== //
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import LoginIcon from "@mui/icons-material/Login";
import GroupIcon from "@mui/icons-material/Group";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {FormattedMessage} from "react-intl";
import React from "react";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



export const admin = {

    id: "admin",
    type: "group",

    children: [
        {
            id: "indexadmin",
            title: <FormattedMessage id="dashboard"/>,
            type: "item",
            url: "/admin/dashboard",
            icon: DashboardIcon,
        },
        {
            id: "membertypetable",
            title: <FormattedMessage id="view_member_type"/>,
            type: "item",
            url: "/admin/view/membertype",
            icon: MilitaryTechIcon,
        },
        {
            id: "grouptable",
            title: <FormattedMessage id="manage_groups"/>,
            type: "item",
            url: "/admin/group/view",
            icon: GroupIcon,
        },
       
        {
            id: "members",
            title: <FormattedMessage id="members"/>,
            type: "item",
            url: "/admin/member",
            icon: ManageAccountsIcon,

        },
        {
            id: "approval",
            title: <FormattedMessage id="approve"/>,
            type: "item",
            url: "/admin/approve",
            icon: CheckCircleIcon,
        },

        {
            id: "halltable",
            title: <FormattedMessage id="view_hall"/>,
            type: "item",
            url: "/admin/hall/view",
            icon: AccountBalanceIcon,
        },

        {
            id: "eventtable",
            title: <FormattedMessage id="view_events"/>,
            type: "item",
            url: "/admin/event/view",
            icon: TheaterComedyIcon,
        },

        // {
        //     id: "complainttable",
        //     title: <FormattedMessage id="complaints"/>,
        //     type: "item",
        //     url: "/admin/complaint",
        //     icon: SupportAgentIcon,
        // },
        {
            id: "ticketallocation",
            title: <FormattedMessage id="allocate_tickets"/>,
            type: "item",
            url: "/admin/ticket/allocation",
            icon: EventSeatIcon,
        },
        {
            id: "registeredevent",
            title: <FormattedMessage id="booking_history"/>,
            type: "item",
            url: "/admin/booking/history",
            icon: LocalActivityIcon,
        },


    ],
};

export const member = {
    id: "member",
    type: "group",
    children: [
        {
            id: "indexmember",
            title: <FormattedMessage id="home"/>,
            type: "item",
            url: "/index/member",
            icon: DashboardIcon,
        },

        {
            id: "viewsubmember",
            title: <FormattedMessage id="manage_submembers"/>,
            type: "item",
            url: "/user/submember/view",
            icon: ManageAccountsIcon,
        },
        {
            id: "membershipdetails",
            title: <FormattedMessage id="payment_details"/>,
            type: "item",
            url: "/user/membership/details",
            icon: MilitaryTechIcon,
        },
        // {
        //     id: "raisecomplaint",
        //     title: <FormattedMessage id="raise_complaint"/>,
        //     type: "item",
        //     url: "/user/complaint/create",
        //     icon: HeadsetMicIcon,
        // },
    ],
};

export const login = {
    id: "login",
    title: "Login",
    type: "group",
    children: [
        {
            id: "login",
            title: "Login",
            type: "item",
            url: "/",
            icon: LoginIcon,
        },
    ],
};
