import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  successToast,
  errorToast,
  warningToast,
} from "../../../shared/components/AlertMessages";
import { ToastContainer } from "react-toastify";
import { SearchText } from "../../../shared/components/SearchText";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteConfirmationModal from "../../../shared/components/DeleteConfirmationModal";
import {
  deleteSubmember,
  searchSubmember,
  updateSubmember,
  viewSubmember,
} from "../../../service/submember.api";
import EditSubmemberForm from "../forms/EditSubmemberForm";
import { getId } from "../../../shared/helpers/authentication";
import { FormattedMessage } from "react-intl";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import SubMemberRegistration from "../forms/SubmemberRegisteration";
import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@mui/styles";
import { AddButton } from "../../../shared/components/AddButton";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="name" />,
  },

  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: <FormattedMessage id="gender" />,
  },
  {
    id: "dob",
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage id="dob" />,
  },

  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage id="phone" />,
  },
  {
    id: "payment_status",
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="payment_status" />,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="action" />,
  },
];

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          View Events
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [submemberData, setSubMemberData] = React.useState([]);
  const [count, setCount] = useState(0);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = React.useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const isFirstRender = useRef(true); // Ref to track the initial render
  const [isLoading, setIsLoading] = useState(false);
  const [addSubmemberForm, setAddSubmemberForm] = useState(false);
  const toggleSubmemberForm = () => {
    setAddSubmemberForm(!addSubmemberForm);
  };
  const member_id = getId();

  const handleSearchQueryChange = (event) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery); // Update the searchQuery state with the new value
    searchAndDisplayData(newSearchQuery); // Call searchAndDisplayData with the new search query
  };

  const searchAndDisplayData = (searchQuery) => {
    // setIsLoading(true);
    if (searchQuery === "" || searchQuery === undefined) {
      view(page, rowsPerPage);
    } else {
      setTimeout(() => {
        searchSubmember(searchQuery, member_id)
          .then((response) => {
            if (response) {
              setIsLoading(false);
              if (
                response?.status_code === 200 ||
                response?.status_code === 201
              ) {
                // successToast(response?.message);
                setSubMemberData(response?.data);
              }
            }
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.response?.data?.status_code === 404) {
              // warningToast(error.response?.data?.message);
              setSubMemberData([]);
            } else if (error.response?.data?.status_code === 403) {
              errorToast(error.response?.data?.message);
              setSubMemberData([]);
            } else if (error.response?.data?.status_code === 400) {
              errorToast(error.response?.data?.message);
              setSubMemberData([]);
            } else {
              errorToast("something went wrong");
              setSubMemberData([]);
            }
          });
      }, 400);
    }
  };

  const view = useCallback(
    (page, rowsPerPage) => {
      setIsLoading(true);
      setTimeout(() => {
        viewSubmember(member_id, page + 1, rowsPerPage)
          .then((response) => {
            if (response) {
              setIsLoading(false);
              if (
                response?.status_code === 200 ||
                response?.status_code === 201
              ) {
                setSubMemberData(response.data?.submember_list_by_member_id);
                setCount(response.data?.total_item);
              }
            }
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.response?.data?.status_code === 404) {
              // warningToast(error.response?.data?.message);
              setCount(0);
            } else if (error.response?.data?.status_code === 403) {
              errorToast(error.response?.data?.message);
            } else if (error.response?.data?.status_code === 400) {
              errorToast(error.response?.data?.message);
            } else {
              errorToast("something went wrong");
            }
          });
      }, 400);
    },
    [member_id, setSubMemberData, setCount]
  );

  useEffect(() => {
    if (isFirstRender.current) {
      view(page, rowsPerPage);
      isFirstRender.current = false; // Set to false after the initial render
    }
  }, [page, rowsPerPage, view]); // Include page and rowsPerPage in the dependency array

  const handleChangePage = (event, newPage) => {
    view(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);

    // Ensure that the current page is valid with the new rowsPerPage
    const newPage = Math.floor(page * (rowsPerPage / newRowsPerPage));

    view(newPage, newRowsPerPage); // Fetch data with the new rowsPerPage
    setRowsPerPage(newRowsPerPage); // Update rowsPerPage
    setPage(newPage); // Update the page to the new calculated page
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;
  const handleDeleteClick = (submemberData) => {
    // Open the modal when delete icon is clicked
    setSelectedRowForDelete(submemberData);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirmed = () => {
    deleteSubmember(selectedRowForDelete.submember_id)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);
            const updatedData = submemberData.filter(
              (row) => row.submember_id !== selectedRowForDelete.submember_id
            );
            setSubMemberData(updatedData);
            view(page, rowsPerPage);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
      });

    setIsDeleteModalOpen(false);
  };

  const handleEditClick = (submemberData) => {
    setSelectedRowForEdit(submemberData);
    setIsEditModalOpen(true);
  };

  const handleEditEvent = (editedSubmemberData) => {
    updateSubmember(editedSubmemberData) // Replace with the actual API call
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);
            const updatedData = submemberData.map((row) =>
              row.submember_id === selectedRowForEdit.submember_id
                ? { ...row, ...editedSubmemberData }
                : row
            );
            setSubMemberData(updatedData);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
      });

    setIsEditModalOpen(false);
  };

  const handleAddSubmemberClose = () => {
    setAddSubmemberForm(false);

    view(page, rowsPerPage);
  };
  const useStyles = makeStyles((theme) => ({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    // searchContainer: {
    //   display: "flex",
    //   alignItems: "center",
    //   marginRight: theme.spacing(1),
    //   [theme.breakpoints.down("sm")]: {},
    // },
  }));
  const classes = useStyles();

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Card className="card">
          <CardHeader
            title={<FormattedMessage id="manage_submembers" />}
            className="linear-gradient"
            action={
              <div className={classes.header}>
                {/*<div className={classes.searchContainer}>*/}
                {/*  <SearchText*/}
                {/*    searchQuery={searchQuery}*/}
                {/*    handleSearchQueryChange={handleSearchQueryChange}*/}
                {/*  />*/}
                {/*</div>*/}
                {/*<AddButton
                  handleName={<FormattedMessage id="add_submember" />}
                  handleClick={toggleSubmemberForm}
                  disabled={count >= 5}
                />*/}
              </div>
            }
          ></CardHeader>
          <CardContent>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id}>{headCell.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submemberData.length > 0 ? (
                    submemberData.map((submemberData, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={submemberData.submember_id}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {`${submemberData.first_name} ${submemberData.middle_name} ${submemberData.last_name}`}{" "}
                          </TableCell>

                          <TableCell>{submemberData.gender}</TableCell>
                          <TableCell>{submemberData.dob}</TableCell>
                          <TableCell>{submemberData.phone || "-"}</TableCell>
                          <TableCell>
                            {submemberData.is_verified === false ? (
                                <Chip
                                    label="Approval Pending"
                                    color="warning"
                                    size="small"
                                />
                            ) : (
                                submemberData.sub_member_payment_status ? (
                                    <Chip
                                        label="Payment Successful"
                                        color="success"
                                        size="small"

                                    />
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                    >
                                      Proceed To Payment
                                    </Button>
                                )
                            )}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Edit" arrow>
                              <IconButton
                                onClick={() => handleEditClick(submemberData)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <DeleteConfirmationModal
                  isOpen={isDeleteModalOpen}
                  onClose={() => setIsDeleteModalOpen(false)}
                  onDelete={handleDeleteConfirmed}
                />
                <EditSubmemberForm
                  isOpen={isEditModalOpen}
                  onClose={() => setIsEditModalOpen(false)}
                  onEdit={handleEditEvent}
                  submemberData={selectedRowForEdit}
                />
              </Table>
              <SubMemberRegistration
                isOpen={addSubmemberForm}
                onClose={handleAddSubmemberClose}
                count={count}
              />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[3, 5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Card>
      )}
      <ToastContainer />
    </>
  );
}
