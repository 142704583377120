import React from 'react';
import Seat from "./Seat";
import './allocation.css';

const Allocation = ({ columnData ,onSelect ,selectedSeats }) => {
    return (
        <div className="row">

            {columnData.seats.map((seat) => (
                <Seat
                    key={seat.seat_id}
                    seat={seat}
                    onSelect={onSelect}
                    isSelected={selectedSeats.includes(seat.seat_id)}
                />
            ))}
        </div>
    );
};

export default Allocation;
