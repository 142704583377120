import { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// assets
import { BellOutlined, CloseOutlined, } from '@ant-design/icons';
import { errorToast, successToast } from "../../../../shared/components/AlertMessages";
import { ProfileNotification, updateChangeRequest } from "../../../../service/member.api";
import AccountBoxIcon from '@mui/icons-material/AccountBox';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

// ... (previous imports)

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [profileChangeRequests, setProfileChangeRequests] = useState([]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseIconClick = (notificationId) => {
    setOpen(false);
    // const notificationIds = profileChangeRequests.map(request => request.notification_id);
    let data = {
      "notification_id": notificationId,
      "is_read": true
    };
    updateChangeRequest(data)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);
            const updatedProfileChangeRequests = profileChangeRequests.filter(request => request.notification_id !== notificationId);
            setProfileChangeRequests(updatedProfileChangeRequests);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setProfileChangeRequests([]);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast('something went wrong');
        }
      });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);

  };

  const iconBackColorOpen = 'grey.300';
  const iconBackColor = 'grey.100';

  const view = () => {
    ProfileNotification()
        .then((response) => {
          if (response) {
            if (response?.status_code === 200 || response?.status_code === 201) {
              setProfileChangeRequests(response.data?.notification_list.slice(0, 4));
            }
          }
        })

        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            setProfileChangeRequests([]);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast('something went wrong');
          }
        });
  };

  const viewAll = () => {
    ProfileNotification()
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            setProfileChangeRequests(response.data?.notification_list);
          }
        }
      })

      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setProfileChangeRequests([]);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast('something went wrong');
        }
      });
  };

  useEffect(() => {
    view();
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        disableRipple
        color="secondary"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={profileChangeRequests.length} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notification"
                  elevation={0}
                  border={false}
                  content={false}
                  // secondary={
                  //   <IconButton size="small" onClick={handleCloseIconClick}>
                  //     <CloseOutlined />
                  //   </IconButton>
                  // }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    {profileChangeRequests.map((request, index) => (
                      <ListItemButton key={index}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter'
                            }}
                          >
                            <AccountBoxIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              <Typography component="span" variant="subtitle1">
                                {request.notification_message}
                              </Typography>{' '}
                            </Typography>
                          }
                          secondary={request.time}
                        />
                        <IconButton size="small" onClick={() => handleCloseIconClick(request.notification_id)}>
                          <CloseOutlined />
                        </IconButton>
                        <ListItemSecondaryAction>
                          <Typography variant="caption" noWrap>
                            {request.time}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    ))}
                    <Divider />
                    {profileChangeRequests.length > 4 && (
                      <ListItemButton
                        sx={{ textAlign: 'center', py: `${12}px !important` }}
                        onClick={() => {
                          viewAll();
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h6" color="primary">
                              View All
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    )}

                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;

