import Typography from '@mui/material/Typography';
import { CardHeader} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

const TotalCountCard = ({ count, title }) => (
<Card>
  <CardHeader title={title} className="linear-gradient"/>
  <CardContent>
    <Typography variant="h5" color="inherit">
      {count}
    </Typography>
  </CardContent>
</Card>


);

export default TotalCountCard;
