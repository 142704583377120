// material-ui
import {Grid, Stack, Typography} from '@mui/material';

// project import
import ResetPassword from './auth-forms/ResetPassword';
import React from 'react';
import logo from "../../assets/images/icons/logo.png";
import {FormattedMessage} from "react-intl";
import AuthWrapper from "./AuthWrapper";

// ================================|| LOGIN ||================================ //

const PasswordReset = () => (
    <AuthWrapper>
        <Grid container spacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' ,marginBottom:"10px"}}>
                    <img src={logo} alt="Logo" style={{ width: '60px', height: '60px' }} />
                    <Typography variant="h4" color="#1E90FF" sx={{ marginLeft: '10px', fontSize: 'xx-large' }}>
                        <FormattedMessage id="Lohana Milan" />
                    </Typography>
                </div>
            </Grid>

            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                    <Typography variant="h4"  color="text.secondary">Reset Password</Typography>

                </Stack>

            </Grid>
            <Grid item xs={12}>
                <ResetPassword />
            </Grid>

        </Grid>
    </AuthWrapper>
);

export default PasswordReset;
