import React from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";

export const PaymentStatusFilter = ({selectedPaymentStatus, handlePaymentStatusChange}) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Box sx={{minWidth: 120}}>
            <FormControl variant="standard" sx={{m: 1, minWidth: 200}}>
              <InputLabel id="demo-simple-select-standard-label">Select Payment Status</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedPaymentStatus }
                onChange={handlePaymentStatusChange}
              >
  
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
  
              </Select>
            </FormControl>
          </Box>
  
        </Grid>
  
      </Grid>
    );
  };