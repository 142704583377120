import {Grid, Stack} from "@mui/material";
import React from "react";
import BasicTable from "./tables/BookingHistory";

export const BookingHistoryTable = () => {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <BasicTable />
            </Grid>
        </Grid>
    )
}