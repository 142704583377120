import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from '@mui/material/Button';
import {Dialog, DialogActions, DialogContent, DialogTitle, TableSortLabel} from '@mui/material';
import {errorToast, successToast, warningToast} from '../../../shared/components/AlertMessages';
import {
    excelFileCreation,
    fetchInvitationData,
    resentTicket,
} from "../../../service/event.api";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import {SearchText} from "../../../shared/components/SearchText";
import Chip from "@mui/material/Chip";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from "@mui/material/IconButton";
import SmsIcon from '@mui/icons-material/Sms';
import {snakeCase} from "lodash";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {saveAs} from 'file-saver';
import * as XLSX from 'xlsx';
import {formatDate} from "../../../shared/helpers/utils";

const BookingDetailsModal = ({isOpen, onClose, bookingDetailsData}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setCount] = useState(0);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [orderBy, setOrderBy] = useState('member_num');
    const [order, setOrder] = React.useState('asc');
    const [bookingDetails, setBookingDetails] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [excelData, setExcelData] = useState([]);
    const headCells = [
        {
            id: "seat_number",
            numeric: false,
            disablePadding: false,
            label: <FormattedMessage id="seat_num"/>,
        },
        {
            id: "member_num",
            numeric: false,
            disablePadding: false,
            label: <FormattedMessage id="membership_number"/>,
            sortable: true,
        },
        {
            id: "member_name",
            numeric: false,
            disablePadding: false,
            label: <FormattedMessage id="member_name_column"/>,
        },
        {
            id: "member_phone",
            numeric: false,
            disablePadding: false,
            label: <FormattedMessage id="member_phone_column"/>,
        },
        {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: <FormattedMessage id="invitation_status"/>,
        },
        {
            id: "wp_status",
            numeric: false,
            disablePadding: false,
            label: <FormattedMessage id="whatsapp_status_column"/>,
        },

    ];

    const view = (page, rowsPerPage) => {
        setIsModalLoading(true);
        setTimeout(() => {
            fetchInvitationData(page + 1, rowsPerPage, bookingDetailsData.event_booking_event_id, bookingDetailsData.event_booking_group_id, searchQuery, convertToUpperSnakeCase(order))
                .then((response) => {
                    if (response) {
                        setIsModalLoading(false);
                        if (
                            response?.status_code === 200 ||
                            response?.status_code === 201
                        ) {
                            setBookingDetails(response?.data?.booking_list)
                            setCount(response.data?.total_item);
                        }
                    }
                })
                .catch((error) => {
                    setIsModalLoading(false);
                    if (error.response?.data?.status_code === 404) {
                        setBookingDetails([]);
                        setCount(0);
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                    } else {
                        errorToast("something went wrong");
                    }
                });
        }, 400);
    };


    useEffect(() => {
        if (isOpen) {
            setPage(0);
            setRowsPerPage(10);// Reset page to 0 when bookingDetailsData changes
            view(0, rowsPerPage);
        }
    }, [bookingDetailsData]);

    const handleChangePage = (event, newPage) => {
        view(newPage, rowsPerPage);
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);

        // Ensure that the current page is valid with the new rowsPerPage
        const newPage = Math.floor(page * (rowsPerPage / newRowsPerPage));

        view(newPage, newRowsPerPage); // Fetch data with the new rowsPerPage
        setRowsPerPage(newRowsPerPage); // Update rowsPerPage
        setPage(newPage); // Update the page to the new calculated page


    };
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

    const convertToUpperSnakeCase = (value) => {
        return snakeCase(value).toUpperCase();
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);

        fetchInvitationData(page + 1, rowsPerPage, bookingDetailsData.event_booking_event_id, bookingDetailsData.event_booking_group_id, searchQuery, convertToUpperSnakeCase(newOrder))
            .then((response) => {
                if (response) {
                    setIsModalLoading(false);
                    if (
                        response?.status_code === 200 ||
                        response?.status_code === 201
                    ) {
                        setBookingDetails(response?.data?.booking_list);
                        setCount(response.data?.total_item);

                    }
                }
            })
            .catch((error) => {
                setIsModalLoading(false);
                if (error.response?.data?.status_code === 404) {
                    setBookingDetails([]);
                    setCount(0);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                    setBookingDetails([]);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                    setBookingDetails([]);
                } else {
                    errorToast("something went wrong");
                    setBookingDetails([]);
                }
            });


    };

    const resendInvitation = (bookingDetails) => {
        let data = {
            "event_register_id": bookingDetails.event_register_id
        }
        resentTicket(data)
            .then((response) => {
                if (response) {
                    if (response.status_code === 200 || response.status_code === 201) {
                        successToast(response?.message)
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);

                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            })

    };

    const handleSearchQueryChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchQuery(newSearchQuery); // Update the searchQuery state with the new value
        searchAndDisplayData(newSearchQuery); // Call searchAndDisplayData with the new search query
    };

    const searchAndDisplayData = (searchQuery) => {
        setPage(0); // Reset the page to 0
        setRowsPerPage(10);
        setTimeout(() => {
            fetchInvitationData(page + 1, rowsPerPage, bookingDetailsData.event_booking_event_id, bookingDetailsData.event_booking_group_id, searchQuery, convertToUpperSnakeCase(order))
                .then((response) => {
                    if (response) {
                        setIsModalLoading(false);
                        if (
                            response?.status_code === 200 ||
                            response?.status_code === 201
                        ) {
                            setBookingDetails(response?.data?.booking_list);
                            setCount(response.data?.total_item);

                        }
                    }
                })
                .catch((error) => {
                    setIsModalLoading(false);
                    if (error.response?.data?.status_code === 404) {
                        setBookingDetails([]);
                        setCount(0);
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                        setBookingDetails([]);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                        setBookingDetails([]);
                    } else {
                        errorToast("something went wrong");
                        setBookingDetails([]);
                    }
                });
        }, 400);

    };

    function EnhancedTableHead(props) {
        const {order, orderBy, onRequestSort} =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.sortable ? (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            ) : (
                                headCell.label
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }


    const handleExport = async () => {
        try {
            const response = await excelFileCreation(bookingDetailsData.event_booking_event_id, bookingDetailsData.event_booking_group_id, true);
            if (response && (response.status_code === 200 || response.status_code === 201)) {
                setExcelData(response.data);
                const data = response.data.map((booking) => ({
                    Seat_No: booking.ticket_no,
                    Group: booking.group_id,
                    Membership_Type: booking.member_type_id,
                    Membership_No: booking.membership_id,
                    Name: booking.member_name,
                    Phone_No: booking.member_phone,
                }));
                const worksheet = XLSX.utils.json_to_sheet(data);
                // Make headers bold
                worksheet['!cols'] = [{wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}];
                worksheet['A1'].s = {font: {bold: true}};
                worksheet['B1'].s = {font: {bold: true}};
                worksheet['C1'].s = {font: {bold: true}};
                worksheet['D1'].s = {font: {bold: true}};
                worksheet['E1'].s = {font: {bold: true}};
                worksheet['F1'].s = {font: {bold: true}};

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Booking Details');
                const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
                const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                saveAs(excelBlob, 'BookingDetails.xlsx');
            }
        } catch (error) {
            if (error.response?.data?.status_code === 404) {
                setExcelData([]);
            } else if (error.response?.data?.status_code === 403) {
                errorToast(error.response?.data?.message);
            } else if (error.response?.data?.status_code === 400) {
                errorToast(error.response?.data?.message);
            } else {
                errorToast("Something went wrong");
            }
        }
    };
    const currentDate = new Date();
    const eventDate = bookingDetails[0]?.event_date;
    if (eventDate > formatDate(currentDate)) {
        headCells.push({
            id: "action",
            numeric: false,
            disablePadding: true,
            label: <FormattedMessage id="action"/>,
        });
    }

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={"xl"}>
                <DialogTitle className="linear-gradient"
                             sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>Booking Details</span>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <SearchText searchQuery={searchQuery}
                                    handleSearchQueryChange={handleSearchQueryChange}/>
                        <Button variant="contained" size="small" onClick={handleExport}>
                            <IconButton size="small">
                                <FileDownloadIcon style={{color: "white"}} fontSize="small"/>
                            </IconButton>
                            Export
                        </Button>
                    </div>
                </DialogTitle>
                {isModalLoading ? (
                    <LoadingScreen/>
                ) : (
                    <DialogContent dividers>
                        <TableContainer>
                            <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {bookingDetails.length > 0 ? (
                                        bookingDetails.map((bookingDetails, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={bookingDetails.event_register_id}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {bookingDetails.ticket_no}
                                                    </TableCell>
                                                    <TableCell>{bookingDetails.membership_id}</TableCell>
                                                    <TableCell>{bookingDetails.member_name}</TableCell>
                                                    <TableCell>{bookingDetails.member_phone}</TableCell>
                                                    <TableCell>{bookingDetails.is_sms_send === true ? (
                                                        <Chip
                                                            label={"Delivered"}
                                                            color="success"
                                                            variant="contained"
                                                            size="small"
                                                        />
                                                    ) : (
                                                        <Chip
                                                            label={"Failed"}
                                                            color="error"
                                                            variant="contained"
                                                            size="small"
                                                        />
                                                    )}</TableCell>
                                                    <TableCell>{bookingDetails.is_whatsapp_send === true ? (
                                                        <Chip
                                                            label={"Delivered"}
                                                            color="success"
                                                            variant="contained"
                                                            size="small"
                                                        />
                                                    ) : (
                                                        <Chip
                                                            label={"Failed"}
                                                            color="error"
                                                            variant="contained"
                                                            size="small"
                                                        />
                                                    )}</TableCell>
                                                    {bookingDetails.event_date > formatDate(currentDate) &&
                                                        <TableCell>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                style={{marginLeft: "10px"}}
                                                                onClick={() => resendInvitation(bookingDetails)}
                                                            >
                                                                <IconButton color="primary" size="small">
                                                                    <SmsIcon style={{marginRight: "6px"}}
                                                                             fontSize="small"/>
                                                                </IconButton>
                                                                Resend SMS
                                                            </Button>
                                                            <Button
                                                                variant="outlined"
                                                                color="success"
                                                                size="small"
                                                                style={{marginLeft: "10px"}}
                                                            >
                                                                <IconButton color="success" size="small">
                                                                    <WhatsAppIcon style={{marginRight: "6px"}}
                                                                                  fontSize="small"/>
                                                                </IconButton>
                                                                Resend WhatsApp
                                                            </Button>
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                No data found
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow>
                                            <TableCell colSpan={5}/>
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 50, 100]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </DialogContent>
                )}
                <DialogActions style={{justifyContent: 'end'}}>
                    <Button onClick={onClose} variant="outlined" style={{margin: 8}}>
                        <FormattedMessage id="close"/>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default BookingDetailsModal;
