import React from 'react';
import PaymentDetailsPage from './forms/PaymentDetailsPage';
import { CardHeader } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import {FormattedMessage} from "react-intl";

const PaymentDetails = () => (
  <Card className="card" sx={{marginTop:"80px",marginLeft:"20px",marginRight:"20px",marginBottom:"20px"}}>
    <CardHeader title=<FormattedMessage id="membership_details"/> className="linear-gradient" />
    <CardContent>
      <PaymentDetailsPage />
    </CardContent>
  </Card>
);

export default PaymentDetails;
