import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {useEffect, useRef, useState} from "react";
import {
    errorToast, successToast, warningToast,
} from "../../../shared/components/AlertMessages";
import {ToastContainer} from "react-toastify";
import {FormattedMessage} from "react-intl";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import {Button, CardHeader, Typography} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import EventModal from "../forms/EventModal";
import dayjs from "dayjs";
import AllocationModal from "../AllocationUI/AllocationModal";
import BookingDetailsModal from "./BookingDetailsModal";
import IconButton from "@mui/material/IconButton";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import {
    BookingHistory,
    deletePendingSeatAllocation,
    filterBookingHistory
} from "../../../service/event.api";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationModal from "../../../shared/components/DeleteConfirmationModal";
import {DatePicker, Space} from 'antd';

const {RangePicker} = DatePicker;

const headCells = [
    {
        id: "event_name",
        numeric: false,
        disablePadding: false,
        label: <FormattedMessage id="event_name"/>,
    },
    {
        id: "group",
        numeric: true,
        disablePadding: false,
        label: <FormattedMessage id="group"/>
    },

    {
        id: "allocation",
        numeric: false,
        disablePadding: false,
        label: <FormattedMessage id="allocation"/>,
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: <FormattedMessage id="booking_status"/>,
    },
    {
        id: "action",
        numeric: false,
        disablePadding: true,
        label: <FormattedMessage id="action"/>,
    },
];

export default function EnhancedTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [count, setCount] = useState(0);
    const isFirstRender = useRef(true); // Ref to track the initial render
    const [isLoading, setIsLoading] = useState(false);
    const [allocationModal, setAllocationModal] = useState(false);
    const [isEventModalOpen, setIsEventModalOpen] = useState(false);
    const [selectedRowForEvent, setSelectedRowForEvent] = useState(null);
    const [selectedRowForBooking, setSelectedRowForBooking] = useState(null);
    const [selectedRowForAllocation, setSelectedRowForAllocation] = useState(null);

    const [bookingDetailModal, setBookingDetailModal] = useState(false);
    const [bookingHistoryData, setBookingHistoryData] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('year').add(3, 'month').startOf('month')); // Set default start date
    const [endDate, setEndDate] = useState(dayjs().add(1, 'year').month(2).date(31)); // Set default end date
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [selectedRowForDelete, setSelectedRowForDelete] = React.useState(null);
    const view = () => {
        setIsLoading(true);
        setTimeout(() => {
            BookingHistory()
                .then((response) => {
                    if (response) {
                        setIsLoading(false);
                        if (
                            response?.status_code === 200 ||
                            response?.status_code === 201
                        ) {
                            setBookingHistoryData(response?.data);
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.response?.data?.status_code === 404) {
                        setBookingHistoryData([]);
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                    } else {
                        errorToast("something went wrong");
                    }
                });
        }, 400);
    };

    const filterBookingData = (startDate, endDate) => {
        const formattedStartDate = dayjs(startDate).format('MM/DD/YYYY 00:00:00');
        const formattedEndDate = dayjs(endDate).format('MM/DD/YYYY 00:00:00');
        setIsLoading(true);
        setTimeout(() => {
            filterBookingHistory(formattedStartDate, formattedEndDate)
                .then((response) => {
                    if (response) {
                        setIsLoading(false);
                        if (
                            response?.status_code === 200 ||
                            response?.status_code === 201
                        ) {
                            setBookingHistoryData(response?.data);
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.response?.data?.status_code === 404) {
                        setBookingHistoryData([])
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                    } else {
                        errorToast("something went wrong");
                    }
                });
        }, 400);
    };

    useEffect(() => {
        if (isFirstRender.current) {
            view(page, rowsPerPage);
            isFirstRender.current = false; // Set to false after the initial render
        }
    }, [page, rowsPerPage]); // Include page and rowsPerPage in the dependency array


    const handleAllocationModalClose = () => {
        setAllocationModal(false);
    };

    const handleAllocationClose = () => {
        setAllocationModal(false);
        view(page, rowsPerPage)
    };

    const handleBookingDetailsModalClose = () => {
        setBookingDetailModal(false);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;


    const handleEventClick = (eventData) => {
        setSelectedRowForEvent(eventData)
        setIsEventModalOpen(true);
    };

    const handleBookingClick = (eventData) => {
        setSelectedRowForBooking(eventData)
        setBookingDetailModal(true);
    };

    const handleAllocationClick = (bookingHistoryData) => {
        setSelectedRowForAllocation(bookingHistoryData)
        setAllocationModal(true);
    };

    const handleResetFilter = () => {
        setStartDate(dayjs().startOf('year').add(3, 'month').startOf('month'))
        setEndDate(dayjs().add(1, 'year').month(2).date(31))
        view(page, rowsPerPage);
    };

    const handleDeleteClick = (eventData) => {
        setSelectedRowForDelete(eventData);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirmed = () => {
        deletePendingSeatAllocation(selectedRowForDelete.event_booking_id)
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        successToast(response?.message);
                        const updatedData = bookingHistoryData.filter((row) => row.event_booking_id !== selectedRowForDelete.event_booking_id);
                        setBookingHistoryData(updatedData);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });

        setIsDeleteModalOpen(false);
    };


    return (
        <>
            {isLoading ? (
                <LoadingScreen/>
            ) : (
                <Card className="card">
                    <CardHeader
                        title=<FormattedMessage id="booking_history"/>
                    className="linear-gradient"
                    sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    alignItems: {xs: 'stretch', md: 'center'},
                    justifyContent: {xs: 'center', md: 'space-between'},
                }}
                    action={
                    <>
                        <Space direction="vertical" size={12}>
                            <RangePicker
                                id={{
                                    start: 'startInput',
                                    end: 'endInput',
                                }}
                                value={[startDate, endDate]} // Set the selected range
                                onChange={(dates) => {
                                    if (dates && dates.length === 2) {
                                        setStartDate(dates[0]);
                                        setEndDate(dates[1]);
                                        filterBookingData(dates[0], dates[1]); // Pass the selected dates to the filterBookingData function
                                    }
                                }}
                            />
                        </Space>

                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            style={{marginLeft: 20, marginRight: 10}}
                            onClick={handleResetFilter}>
                            Reset
                        </Button>
                    </>


                }
                    />
                    <CardContent>
                        <TableContainer>
                            <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        {headCells.map((headCell) => (
                                            <TableCell key={headCell.id}>{headCell.label}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bookingHistoryData.length > 0 ? (
                                        bookingHistoryData.map((bookingHistoryData, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={bookingHistoryData.event_booking_id}
                                                >
                                                    <TableCell sx={{
                                                        maxWidth: 150,
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap"
                                                    }}>
                                                        <a
                                                            href="#"
                                                            onClick={() => handleEventClick(bookingHistoryData)}
                                                        >
                                                            {bookingHistoryData.event_booking_event_name}
                                                        </a>
                                                    </TableCell>
                                                    <TableCell>{bookingHistoryData.event_booking_group_name}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => handleAllocationClick(bookingHistoryData)}
                                                        >
                                                            <IconButton color="primary" size="small"
                                                                        onClick={() => setAllocationModal(true)}>
                                                                <EventSeatIcon fontSize="small"/>
                                                            </IconButton>
                                                            Seating View
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        {bookingHistoryData.event_booking_status === 'Pending' ? (
                                                            <Chip
                                                                label="Pending"
                                                                color="warning"
                                                                size="small"
                                                            />
                                                        ) : (
                                                            <Chip
                                                                label="Confirmed"
                                                                color="success"
                                                                size="small"
                                                            />
                                                        )}

                                                    </TableCell>
                                                    <TableCell>
                                                        {bookingHistoryData.event_booking_status === 'Pending' ? (
                                                            <Button
                                                                variant="outlined"
                                                                color="error"
                                                                size="small"
                                                                onClick={() => handleDeleteClick(bookingHistoryData)}
                                                            >
                                                                <IconButton color="error" size="small">
                                                                    <DeleteIcon fontSize="small"/>
                                                                </IconButton>
                                                                Delete
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => handleBookingClick(bookingHistoryData)}
                                                            >
                                                                <IconButton color="primary" size="small">
                                                                    <SmartphoneIcon fontSize="small"/>
                                                                </IconButton>
                                                                Invitation(SMS) Report
                                                            </Button>
                                                        )}

                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                No data found
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow>
                                            <TableCell colSpan={5}/>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <AllocationModal isOpen={allocationModal} onClose={handleAllocationModalClose}
                                                 handleAllocationClose={handleAllocationClose}
                                                 allocationData={selectedRowForAllocation}/>
                                <EventModal
                                    isOpen={isEventModalOpen}
                                    onClose={() => setIsEventModalOpen(false)}
                                    eventData={selectedRowForEvent}
                                />
                                <BookingDetailsModal isOpen={bookingDetailModal}
                                                     onClose={handleBookingDetailsModalClose}
                                                     bookingDetailsData={selectedRowForBooking}/>
                                <DeleteConfirmationModal
                                    isOpen={isDeleteModalOpen}
                                    onClose={() => setIsDeleteModalOpen(false)}
                                    onDelete={handleDeleteConfirmed}
                                />
                            </Table>
                        </TableContainer>

                    </CardContent>
                </Card>
            )}
            <ToastContainer/>
        </>
    );
}
