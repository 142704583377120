import {request, requestForm, requestLogin} from './common.service';
import {appConfigs} from '../config/envs/app.config';

export function RegisterMember(values) {
    return requestLogin({
        url: '/user/member-registration',
        method: 'POST',
        body: values
    });
}


export function viewMember(params) {
    return request({
        url: '/user/get-all-member',
        method: 'POST',
        body: params
    });
}


export function getMembers() {
    return request({
        url: `/user/get-all-member`,
        method: 'GET'
    });
}

export function getMember(member_id) {
    return request({
        url: `/user/get-member-by-id?member_id=${member_id}`,
        method: 'GET'
    });
}

export function updateMember(values) {
    return request({
        url: '/user/update-member',
        method: 'PUT',
        body: values
    });
}

export function updateProfileRequest(values, token) {
    const headers = {};

    // Include memberId in headers if provided
    if (token) {
        headers['token'] = token;
    }

    return request({
        url: '/user/profile-update-change-request-for-member',
        method: 'POST',
        body: values,
        headers: headers,
    });
}

export function VerifyMember(values) {
    return request({
        url: '/user/admin/verify-member',
        method: 'POST',
        body: values
    });
}

export function BulkRegister(values) {
    return requestForm({
        url: '/user/member-bulk-registration',
        method: 'POST',
        body: values
    });
}

export function BlockMember(values) {
    return request({
        url: '/user/admin/block-member',
        method: 'POST',
        body: values
    });
}

export function sendOtp(new_phone, sms_for, member_id = null) {
    let url = `/auth/send-otp?new_phone=${new_phone}&sms_for=${sms_for}`;
    if (member_id) {
        url += `&member_id=${member_id}`;
    }
    return requestLogin({
        url: url,
        method: 'GET'
    });
}

export function renewMember(token) {
    return requestLogin({
        url: '/user/renew-member',
        method: 'GET',
        headers: {
            Token: token
        }
    });
}

export function RenewMemberOrder(values) {
    return requestLogin({
        url: '/user/renew-member-order',
        method: 'PUT',
        body: values
    });
}

export function getProfileChangeRequests(page_number, items_per_page) {
    return request({
        url: `/user/get-all-change-requested-member?page_number=${page_number}&items_per_page=${items_per_page}`,
        method: 'GET'
    });
}


export function ProfileNotification() {
    return request({
        url: '/admin/get-all-change-request-notification',
        method: 'GET'
    });
}

export function updateChangeRequest(values) {
    return request({
        url: '/admin/update-change-request-notification',
        method: 'PUT',
        body: values
    });
}

export function PhoneEmailCheck(user_data,checking_field,checking_for) {
    return requestLogin({
        url: `/user/check-phone-email-is-exist?user_data=${user_data}&checking_field=${checking_field}&checking_for=${checking_for}`,
        method: 'GET',

    });
}


export function SendInvoiceEmail(values) {
    return request({
        url: '/send_mail/invoice',
        method: 'POST',
        body: values
    });
}

export function viewMembershipDetails(member_id) {
    return request({
        url: `/user/get-membership-details?member_id=${member_id}`,
        method: 'GET'
    });
}

export function getMemberSubmember(member_id) {
    return requestLogin({
        url: `/user/get-member-submember-by-member-id?member_id=${member_id}`,
        method: 'GET',
        headers: {
            'x-api-key': appConfigs.X_API_KEY
        }
    });
}


export function memberPayment(values) {
    return requestLogin({
        url: '/payment/member-payment',
        method: 'POST',
        body: values,
        headers: {
            'x-api-key': appConfigs.X_API_KEY
        }
    });
}

export function downloadInvoice(member_id) {
    return request({
        url: `/user/get-invoice-payment-details?member_id=${member_id}`,
        method: 'GET'
    });
}

export function memberPaymentUpdate(values) {
    return request({
        url: '/payment/admin-approve/member-payment-update',
        method: 'PUT',
        body: values
    });
}

export function profileUpdateRegister(values) {
    return requestLogin({
        url: '/user/profile-update-after-registration',
        method: 'PUT',
        body: values,
        headers: {
            'x-api-key': appConfigs.X_API_KEY
        }
    });
}