import {LOCALES} from './locales';

export const messages = {
    [LOCALES.ENGLISH]: {
        memberType_side_menu: 'Add Member Type',
        group_side_menu: 'Add Group',
        member_type: 'Member Type',
        group_name: 'Group Name',
        group_limit: 'Group Limit',
        price: 'Price',
        persons_capacity: 'Person Capacity',
        submit_button_text: 'Submit',
        view_member_type: 'Member Types',
        view_group: 'Groups',
        edit: 'Update',
        delete: 'Delete',
        delete_item: 'Delete Item',
        search: 'Search',
        dashboard: 'Dashboard',
        total_member_types: 'Total Member Types',
        total_groups: 'Total Groups',
        total_events: 'Upcoming Events',
        total_members: 'Total Members',
        view_events: 'Events',
        create_event: 'Create Event',
        event_name: 'Event Name',
        total_capacity: 'Total Capacity',
        date: 'Date',
        venue: 'Venue',
        edit_member_type: 'Edit Member Type',
        edit_group_name: 'Edit Group',
        save_changes: 'Save Changes',
        cancel: 'Cancel',
        delete_modal: 'Are you sure you want to delete this item?',
        total_capacity_read_only: 'Total Capacity (Read-Only)',
        complaints: 'Complaints',
        complaint_subject: 'Tell me about your query',
        complaint_description: 'Complaint Description',
        complaint_file: 'File',
        response: 'Response',
        complaint_response: 'Complaint Response',
        members: 'Members',
        active_members: "Active Members",
        first_name: 'First Name',
        name: 'Name',
        middle_name: 'Middle Name',
        last_name: 'Last Name',
        email: 'Email',
        phone: 'Phone',
        gender: 'Gender',
        dob: 'Date of Birth',
        address: 'Address',
        city: 'City',
        pincode: 'Pincode',
        accept: 'Accept',
        booking_history: 'Booking History',
        event_name_column: 'Event Name',
        member_name_column: 'Member Name',
        member_email_column: 'Member Email',
        member_phone_column: 'Member Phone',
        group_size_column: 'Group Size',
        ticket_numbers_column: 'Ticket Numbers',
        whatsapp_status_column: 'WhatsApp Message Status',
        event_management: 'Lohana Milan',
        hello: 'Admin',
        logout: 'Logout',
        total_submembers: 'Sub-members',
        upcoming_events: 'Upcoming Events',
        participate: 'Participate',
        participate_event: 'Participate in Event',
        event_details: 'Event Description',
        select_submembers: 'Select Submembers',
        register_for_event: 'Register For Event',
        raise_complaint: 'Support',
        add_submember: 'Add Sub-member',
        manage_submembers: 'Sub-members',
        submit_button: 'Submit',
        edit_profile: 'Profile',
        password: 'Password',
        change_password: 'Change Password',
        current_password: 'Current Password',
        new_password: 'New Password',
        confirm_password: 'Confirm Password',
        edit_member: 'Edit Member',
        view_details: 'View Details',
        registered_members: 'Total Registered Members',
        member_type_details: 'Member Type Details',
        group_details: 'Group Details',
        hello_member: 'Hello {name}',
        status: 'Member Status',
        action: 'Action',
        mobile_num: 'Mobile Number',
        allow_guests: 'Allow Guests',
        num_of_guests: 'Number of Guests',
        vip_limit: 'VIP Limit',
        allocate_tickets: 'Allocate Tickets',
        preview: 'View Allocation',
        confirm_booking: 'Confirm Booking',
        reject_booking: 'Reject Booking',
        group: 'Group',
        hall_name: 'Hall Name',
        hall_address: 'Hall Address',
        view_hall: 'Halls',
        hall_side_menu: 'Add Hall',
        change_request: 'Request for Update',
        profile_change: 'Profile Edit Requests',
        edit_hall_name: 'Edit Hall',
        payment_status: 'Payment Status',
        member_details: 'Member Details',
        edit_submember: 'Edit Sub-member',
        send_for_approval: 'Send for Approval',
        submember_approval: 'Sub-member Approval',
        submember_name: 'Sub-member Name',
        close: 'Close',
        add: 'Add',
        submember_details: 'Sub-Member Details',
        assign_group: 'Assign Group',
        manage_groups: 'Manage Groups',
        assign: 'Assign',
        past_events: 'Past Events',
        num_of_submembers: 'No.of Sub-members',
        new_member: 'New Member Registration',
        renew_member: 'Renew Membership',
        membership: 'Membership',
        membership_details: 'Membership Details',
        main_member: 'Main Member',
        total: 'Total',
        edit_event: 'Edit Event',
        unauthorized_title: 'Unauthorized',
        unauthorized_msg: 'Your Membership Payment is complete but Verification is pending, your verification will complete within some days. Please contact support team or mail on - ',
        active_member_details: 'Active Member Details',
        note: 'Note:- Member or submember details can be edited after completing the payment by logging in to your dashboard.',
        expired_membership: 'Membership Expired',
        expired_membership_msg: 'Your membership has expired. Kindly renew to continue enjoying our benefits and services',
        transac_id: 'Transaction Id/Receipt No.',
        membership_type: 'Membership Type',
        method: 'Method',
        no_of_members: 'No.of Members',
        amount: 'Amount',
        payment_update:"Payment Update",
        first_payment_text:'You can make the payment through the following bank details:',
        sec_payment_text:'Alternatively, you can simply scan the QR code via UPI for a seamless transaction',
        third_payment_text:'Once the payment is made, kindly share the screenshot of the transaction via WhatsApp on the following number:',
        fourth_payment_text:'Upon receipt of your payment confirmation, your account will be activated within 2 business days. You can then proceed to log in using your credentials.',
        fifth_payment_text:'Thank you for choosing Lohana Milan digital services. Should you have any questions or need further assistance, feel free to contact us at',
        payment_title_one:'Your payable amount is ' ,
        payment_title_two: 'To facilitate your payment, we offer multiple convenient options.',
        fee_one:'The fee for',
        fee_two:'category is',
        fee_three:'The fee for individual Sub-member in',
        received:'Received',
        select_member:'Select Member',
        reference_name:'Reference Name',
        old_group:'Select Old Group',
        old_number:'Enter Old Member Number',
        membership_number:'Membership Number',
        paymentMethod:'Payment Method',
        paymentAmount:'Payment Amount',
        paymentDate:'Payment Date',
        payment_details:'Payment Details',
        hall_details:'Hall Details',
        event_details_title:'Event Details',
        event_status:'Status',
        allocation:'Allocation',
        seat_num:'Seat Number',
        invitation_status:'SMS Status',
        bulk_upload:'Import',
        imp_member_details:'Import Member Details',
        upload:'Upload',
        approve:'Approval',
        booking_status :'Status',
        home:'Home'

    },


    [LOCALES.GUJARATI]: {
        memberType_side_menu: 'સભ્ય પ્રકાર ઉમેરો',
        member_type: 'સભ્ય પ્રકાર',
        price: 'મૂલ્ય',
        persons_capacity: 'વ્યક્તિ ક્ષમતા',
        submit_button_text: 'સબમિટ',
        view_member_type: 'સભ્ય પ્રકારો',
        edit: 'ફેરફાર કરો',
        delete: 'ડેટા કાઢી નાખો',
        delete_item: 'આઇટમ કાઢી નાખો',
        search: 'ડેટા શોધો',
        dashboard: 'ડેશબોર્ડ',
        total_member_types: 'કુલ સભ્ય પ્રકારો',
        total_events: 'આગામી ઈવેન્ટ્સ',
        total_members: 'કુલ સભ્યો',
        view_events: 'ઈવેન્ટ્સ',
        create_event: 'ઈવેન્ટ્ બનાવો',
        event_name: 'ઈવેન્ટ્નું નામ',
        total_capacity: 'કુલ ક્ષમતા',
        date: 'તારીખ',
        venue: 'સ્થળ',
        edit_member_type: 'સભ્ય પ્રકાર સંપાદિત કરો',
        save_changes: 'સબમિટ',
        cancel: 'રદ',
        delete_modal: 'શું તમે ખરેખર આ આઇટમ કાઢી નાખવા માંગો છો?',
        total_capacity_read_only: 'કુલ ક્ષમતા (ફક્ત વાંચવા માટે)',
        complaints: 'ફરિયાદો',
        complaint_subject: 'તમારી ક્વેરી વિશે મને કહો',
        complaint_description: 'ફરિયાદનો વર્ણન',
        complaint_file: 'ફાઇલ',
        response: 'પ્રતિભાવ',
        complaint_response: 'ફરિયાદ પ્રતિભાવ',
        members: 'સભ્યો',
        first_name: 'પ્રથમ નામ',
        name: 'નામ',
        middle_name: 'મધ્ય નામ',
        last_name: 'છેલ્લું નામ',
        email: 'ઇમેઇલ',
        phone: 'ફોન',
        gender: 'લિંગ',
        dob: 'જન્મ ની તારીખ',
        address: 'સરનામું',
        city: 'શહેર',
        pincode: 'પિનકોડ',
        accept: 'સ્વીકારો',
        upload:'અપલોડ',
        booking_history: 'બુકિંગ ઇતિહાસ',
        event_name_column: 'ઈવેન્ટ્નું નામ',
        member_name_column: 'સભ્યનું નામ',
        member_email_column: 'સભ્યનું ઇમેઇલ',
        member_phone_column: 'સભ્યનો ફોન',
        group_size_column: 'જૂથ કાકો',
        ticket_numbers_column: 'ટિકટ નંબર્સ',
        whatsapp_status_column: 'વોટ્સેપ સંદેશ સ્થિતિ',
        event_management: 'લોહાણા મિલન',
        hello: 'નમસ્તે એડમિન',
        logout: 'લૉગ આઉટ',
        total_submembers: 'પેટા સભ્યો',
        upcoming_events: 'આગામી ઈવેન્ટ્સ',
        participate: 'ભાગ લેવો',
        participate_event: 'ઇવેન્ટમાં ભાગ લેવો',
        event_details: 'ઇવેન્ટ વિગતો',
        select_submembers: 'સબભ્યો પસંદ કરો',
        register_for_event: 'ઇવેન્ટ માટે નોંધણી કરો',
        select_event: 'ઇવેન્ટ પસંદ કરો',
        raise_complaint: 'મદદ',
        add_submember: 'પેટા સભ્ય ઉમેરો',
        manage_submembers: 'પેટા સભ્યનું ',
        submit_button: 'સબમિટ',
        edit_profile: 'પ્રોફાઇલ',
        password: 'પાસવર્ડ',
        change_password: 'પાસવર્ડ બદલો',
        current_password: 'વર્તમાન પાસવર્ડ',
        new_password: 'નવો પાસવર્ડ',
        confirm_password: 'નવા પાસવર્ડની પુષ્ટિ કરો',
        edit_member: 'સભ્ય સંપાદિત કરો',
        view_details: 'વિગતો જુઓ',
        registered_members: 'કુલ નોંધાયેલા સભ્યો',
        member_type_details: 'સભ્ય પ્રકાર વિગતો',
        status: 'સભ્ય સ્થિતિ',
        action: 'ક્રિયા',
        mobile_num: 'મોબાઈલ નંબર',
        group_side_menu: 'જૂથ ઉમેરો',
        group_name: 'જૂથ નામ',
        group_limit: 'જૂથ મર્યાદા',
        view_group: 'જૂથો',
        total_groups: 'કુલ જૂથો',
        edit_group_name: 'જૂથ સંપાદિત કરો',
        group_details: 'જૂથ વિગતો',
        allow_guests: 'મહેમાનોની પરવાહ',
        num_of_guests: 'મહેમાનોની સંખ્યા',
        vip_limit: 'વી.આઈ.પી મર્યાદા',
        allocate_tickets: 'ટિકિટ ફાળવો',
        preview: 'ફાળવણી જુઓ',
        confirm_booking: 'બુકિંગ કન્ફર્મ કરો',
        reject_booking: 'બુકિંગ નકારી કાઢો',
        group: 'જૂથ',
        hall_name: 'હોલનું નામ',
        hall_address: 'હોલ સરનામું',
        view_hall: 'હોલ',
        hall_side_menu: 'હોલ ઉમેરો',
        change_request: 'અપડેટ માટે વિનંતી',
        profile_change: ' પ્રોફાઇલ સંપાદિત વિનંતીઓ',
        edit_hall_name: 'સંપાદન હોલ',
        payment_status: 'પેમેન્ટ સ્થિતિ',
        member_details: 'સભ્ય વિગતો',
        edit_submember: 'સબસદસ્ય સંપાદિત કરો',
        submember_approval: 'સબસદસ્યની મંજૂરી',
        submember_name: 'સબસદસ્યની નામ',
        close: 'બંધ',
        add: 'ઉમેરો',
        submember_details: 'સબસદસ્ય વિગતો',
        assign_group: 'જૂથ સોંપો',
        manage_groups: 'જૂથોનું સંચાલન કરો',
        assign: 'સોંપો',
        past_events: 'ભૂતકાળ ઈવેન્ટ્સ',
        num_of_submembers: 'પેટા સભ્યોની સંખ્યા',
        new_member: 'નવા સભ્ય નોંધણી',
        renew_member: 'સભ્યપદ રિન્યૂ કરો',
        membership: 'સભ્યપદ',
        main_member: 'મુખ્ય સભ્ય',
        total: 'કુલ',
        edit_event: 'ઇવેન્ટ સંપાદિત કરોं',
        membership_details: 'સભ્યપદ વિગતો',
        active_members: "સક્રિય સભ્યો",
        unauthorized_title: 'અનધિકૃત',
        unauthorized_msg: 'તમારી સભ્યપદ પેમેન્ટ પૂર્ણ થઈ ગઈ છે પરંતુ ચકાસણી બાકી છે, તમારી ચકાસણી થોડા દિવસોમાં પૂર્ણ થશે. કૃપા કરીને સપોર્ટ ટીમનો સંપર્ક કરો અથવા તેના પર મેઇલ કરો - ',
        active_member_details: 'સક્રિય સભ્ય વિગતો',
        note: 'નોંધ:- તમારા ડેશબોર્ડમાં લૉગ ઇન કરીને પેમેન્ટ પૂર્ણ કર્યા પછી સભ્ય અથવા સબમેમ્બરની વિગતો સુધારી શકાશે.',
        expired_membership: 'સભ્યપદ સમાપ્ત',
        expired_membership_msg: 'તમારી સદસ્યતા સમાપ્ત થઈ ગઈ છે. અમારા લાભો અને સેવાઓનો આનંદ માણવાનું ચાલુ રાખવા માટે કૃપા કરીને નવીકરણ કરો',
        transac_id: 'ટ્રાન્ઝેક્શન આઈડી/રસીદ નંબર',
        membership_type: 'સભ્યપદ પ્રકાર',
        method: 'પદ્ધતિ',
        no_of_members: 'સભ્યોની સંખ્યા',
        amount: 'રકમ',
        payment_update:"પેમેન્ટ અપડેટ",
        first_payment_text: 'તમે નીચેની બેંક વિગતો દ્વારા ચુકવણી કરી શકો છો:',
        sec_payment_text: ' વૈકલ્પિક રીતે, તમે સીમલેસ ટ્રાન્ઝેક્શન માટે QR કોડને સ્કેન કરી UPI દ્વારા પેમેન્ટ શકો છો.',
        third_payment_text: 'એકવાર ચુકવણી થઈ જાય, કૃપા કરીને લોહાણા મિલન ના WhatsApp નંબર પર પેમેન્ટ નો સ્ક્રીનશૉટ શેર કરો.',
        fourth_payment_text: 'તમારી ચુકવણીની પુષ્ટિ મળ્યા પછી, તમારું એકાઉન્ટ 2 કામકાજી દિવસોમાં સક્રિય થઈ જશે. પછી તમે તમારા રેજિસ્ટર્ડ ફોન નંબર અને પાસવર્ડ દ્વારા લૉગ ઇન થઈ શકો છો અને લોહાણા મિલન ની માહિતી અને અન્ય સુવિધા નો લાભ લઇ શકશો.',
        fifth_payment_text: 'લોહાણા મિલન ડિજિટલ સેવાઓ પસંદ કરવા બદલ આભાર. જો તમને કોઈ પ્રશ્નો હોય અથવા વધુ સહાયની જરૂર હોય, તો ઈ-મેલ કરો',
        payment_title_one:'તમારી ચૂકવવાપાત્ર રકમ' ,
        payment_title_three:'છે.' ,
        payment_title_two: 'તમારી ચુકવણીની સુવિધા માટે, અમે બહુવિધ અનુકૂળ વિકલ્પો ઓફર કરીએ છીએ.',
        received:'પ્રાપ્ત',
        fee_one:'The fee for',
        fee_two:'category is',
        fee_three:'The fee for individual Sub-member in',
        select_member: 'સભ્ય પસંદ કરો',
        reference_name: 'સંદર્ભ નામ',
        old_group: 'જૂનું ગ્રુપ પસંદ કરો',
        old_number: 'જૂનો સભ્ય નંબર દાખલ કરો',
        membership_number:'સભ્યપદ નંબર',
        paymentMethod: 'પેમેન્ટ પદ્ધતિ',
        paymentAmount: 'પેમેન્ટ રકમ',
        paymentDate: 'પેમેન્ટ તારીખ',
        payment_details:'પેમેન્ટ વિગતો',
        hall_details:'હોલ વિગતો',
        event_details_title:'ઇવેન્ટ વિગતો',
        event_status:'ઈવેન્ટ્ સ્થિતિ',
        allocation:'ફાળવણી',
        seat_num:'બેઠક નંબર',
        invitation_status:'SMS સ્થિતિ',
        bulk_upload:'બલ્ક અપલોડ',
        approve:'મંજૂરી',
        booking_status:'સ્થિતિ',
        home:'હોમ'



    },

};
