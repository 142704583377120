import React from 'react';
import '../css/unauthorized.css';
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Page404 = () => {
    return (
        <div className="section">
            <div className="page">
                <h3>
                    <FormattedMessage id="unauthorized_title" />
                </h3>
                <div className="content">
                    <p>
                        <FormattedMessage id="unauthorized_msg" />
                    </p>
                    <a href="mailto:contact@lohanamilan.in">
                        contact@lohanamilan.in
                    </a>
                </div>
            </div>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <Button color="primary" variant="contained" type="submit">
                    Back To Login
                </Button>
            </Link>
        </div>
    );
};

export default Page404;
