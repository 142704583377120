import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tabFlag:'active',
};

const dashboardReducer = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setTabFlag(state, action) {
            state.tabFlag = action.payload;
        },

    }
});

export default dashboardReducer.reducer;

export const { setTabFlag} = dashboardReducer.actions;
