import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {Button, CardHeader, Checkbox, FormControlLabel, Typography} from '@mui/material';
import {errorToast, successToast, warningToast} from '../../../shared/components/AlertMessages';
import {getMemberSubmember, PhoneEmailCheck, profileUpdateRegister} from '../../../service/member.api';
import {capitalizeName, formatBirthDate, setCookie} from '../../../shared/helpers/utils';
import {ToastContainer} from 'react-toastify';
import {Link, useNavigate} from 'react-router-dom';
import {getMemberType, getMemberTypes} from '../../../service/membertype.api';
import SubmemberForm from './SubmemberForm';
import {setMemberData, setOrderId} from '../../../store/reducers/paymentReducer';
import {cookieEnum} from '../../../enums/cookie.enum';
import {useDispatch, useSelector} from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../../../App.css';
import dayjs from 'dayjs';
import {FormattedMessage} from 'react-intl';
import {appConfigs} from "../../../config/envs/app.config";
import {getGroups} from "../../../service/group.api";

const UpdateRegisterForm = () => {
    const [memberTypes, setMemberTypes] = useState([]);
    const [sendOTPClicked, setSendOTPClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [memberTypeDetails, setMemberTypeDetails] = useState(null);
    const [groups, setGroups] = useState([]);
    const [isGroupSelected, setIsGroupSelected] = useState(false);
    const [updatedDetails, setUpdatedDetails] = useState([]);
    const memberDetails = useSelector((state) => state.payment.memberData);
    const removedSubmemberId = useSelector((state) => state.submember.removedSubmemberId);

    const fetchMembersSubmembers = () => {
        getMemberSubmember(memberDetails.member_details[0].member_id)
            .then((response) => {
                if (response) {
                    if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                        setUpdatedDetails(response.data?.data);
                        const details = response.data?.data
                        formik.setValues({
                            first_name: details?.member?.first_name || '',
                            last_name: details?.member?.last_name || '',
                            middle_name: details?.member?.middle_name || '',
                            dob: details?.member?.dob ? dayjs(details?.member?.dob) : null,
                            gender: details?.member?.gender || '',
                            email: details?.member?.email || '',
                            phone: details?.member?.phone || '',
                            address: details?.member?.address || '',
                            pincode: details?.member?.pincode || '',
                            password: '',
                            confirm_password: '',
                            member_type: '',
                            select_member: details?.member?.membership_status || '',
                            reference_name: details?.member?.reference_name || '',
                            old_group: '',
                            old_member_number: details?.member?.membership_id || '',
                            submembers: details ? details.submember_details.map(submember => ({
                                first_name: submember.first_name || '',
                                middle_name: submember.middle_name || '',
                                last_name: submember.last_name || '',
                                gender: submember.gender || '',
                                dob: dayjs(submember.dob) || null,
                                mobile_number: submember.phone || '',
                                submember_id: submember.submember_id || ''
                            })) : [ // Default to an empty array if memberDetails is not available
                                {
                                    first_name: '',
                                    middle_name: '',
                                    last_name: '',
                                    gender: '',
                                    dob: null,
                                    mobile_number: ''
                                }
                            ],
                            termsAndConditions: false,
                        })
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setUpdatedDetails([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
    };

    useEffect(() => {
        if (memberDetails) {
            fetchMembersSubmembers();
        }
    }, [memberDetails]);

    const fetchMemberTypes = () => {
        getMemberTypes()
            .then((response) => {
                const memberList = [];
                if (response) {
                    if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                        let member_data = []
                        for (let i in response.data?.data.member_type_list) {
                            member_data.push({
                                title: response.data?.data.member_type_list[i].member_type,
                                value: response.data?.data.member_type_list[i].member_type_id,

                            })
                        }
                        setMemberTypes(member_data);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setMemberTypes([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
    };

    const fetchGroups = () => {
        getGroups()
            .then((response) => {
                if (response) {
                    if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                        setGroups(response.data?.data.group_list);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setGroups([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
    };

    useEffect(() => {
        fetchMemberTypes();
        fetchGroups();
    }, []);


    const handleMemberTypeSelect = (memberType) => {
        getMemberType(memberType)
            .then((response) => {
                if (response) {
                    if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                        setMemberTypeDetails(response.data?.data[0]);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
    };


    const getMemberTypeName = (memberTypeId) => {
        const selectedMemberType = memberTypes.find((type) => type.member_type_id === memberTypeId);
        return selectedMemberType ? selectedMemberType.member_type : '';
    };

    const handleCheck = (userData, type) => {
        let data = {
            "user_data": userData,
            "is_exist": type
        }
        PhoneEmailCheck(data)
            .then((response) => {
                if (response && (response.data?.status_code === 200 || response.data?.status_code === 201)) {
                    formik.setFieldError(type, '');
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    formik.setFieldError(type, error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            })
    }

    const handlePhoneCheck = () => {
        handleCheck(formik.values.phone, 'phone');
    }

    const handleEmailCheck = () => {
        handleCheck(formik.values.email, 'email');
    }


    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            middle_name: '',
            dob: null,
            gender: '',
            email: '',
            phone: '',
            address: '',
            pincode: '',
            password: '',
            confirm_password: '',
            member_type: '',
            select_member: '',
            reference_name: '',
            old_group: '',
            old_member_number: '',
            submembers: [
                {
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    gender: '',
                    dob: null,
                    mobile_number: ''
                }
            ],
            termsAndConditions: false,
            submember_delete: []
        },
        validationSchema: Yup.object({
            first_name: Yup.string()
                .matches(/^[^0-9]*$/, 'First name cannot contain numbers')
                .required('First name Required'),
            last_name: Yup.string()
                .matches(/^[^0-9]*$/, 'Last name cannot contain numbers')
                .required('Last name Required'),
            middle_name: Yup.string()
                .matches(/^[^0-9]*$/, 'Middle name cannot contain numbers')
                .required('Middle name Required'),
            dob: Yup.date().max(new Date(), 'Please select a valid date').required('Date of Birth Required').nullable(),
            gender: Yup.string().required('Gender Required'),
            email: Yup.string().email('Invalid email address'),
            phone: Yup.string()
                .matches(/^\d{10}$/, 'Phone number must be 10 digits')
                .required('Phone number Required'),
            address: Yup.string().required('Address Required'),
            pincode: Yup.string()
                .matches(/^\d{6}$/, 'Pincode must be 6 digits')
                .required('Pincode Required'),
            member_type: Yup.string().required('Member Type Required'),
            termsAndConditions: Yup.bool().oneOf([true], 'You must accept the Terms and Conditions and Privacy Policy').required('You must accept the Terms and Conditions'),
            select_member: Yup.string().required('Select Member is required'),
            reference_name: Yup.string()
                .matches(/^[^0-9]*$/, 'Reference Name cannot contain numbers'),
            old_group: Yup.string().when('select_member', {
                is: 'Old',
                then: Yup.string().required('Old Group Required'),
                otherwise: Yup.string()
            }),
            old_member_number: Yup.string().when('select_member', {
                is: 'Old',
                then: Yup.string().required('Old Member Number Required'),
                otherwise: Yup.string()
            }),
            submembers: Yup.array().of(
                Yup.object().shape({
                    gender: Yup.string().required('Gender Required'),
                    dob: Yup.date().max(new Date(), 'Please select a valid date').required('Dob Required').nullable(),
                    mobile_number: Yup.string().matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
                    first_name: Yup.string()
                        .matches(/^[^0-9]*$/, 'First name cannot contain numbers')
                        .required('First name Required'),
                    middle_name: Yup.string()
                        .matches(/^[^0-9]*$/, 'Middle name cannot contain numbers')
                        .required('Middle name Required'),
                    last_name: Yup.string()
                        .matches(/^[^0-9]*$/, 'Last name cannot contain numbers')
                        .required('Last name Required')
                })
            )
        }),

        onSubmit: (values) => {
            setLoading(true);
            setSendOTPClicked(true); // Disable send OTP button
            const submembersNotEmpty = values.submembers.some((submember) =>
                Object.values(submember).some((value) => value !== null && value !== '')
            );
            if (!submembersNotEmpty) {
                const initialSubmembers = Array.from({length: values.submembers_number}, () => ({
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    gender: '',
                    dob: null,
                    mobile_number: ''
                }));

                formik.setFieldValue('submembers', initialSubmembers);
            }

            delete values.price;
            delete values.persons_capacity;
            const submembersData = values.submembers.map((submember) => ({
                first_name: submember.first_name,
                middle_name: submember.middle_name,
                last_name: submember.last_name,
                gender: submember.gender,
                dob: formatBirthDate(submember.dob.$d),
                phone: submember.mobile_number,
                submember_id: submember.submember_id
            }));


            let data = {
                first_name: capitalizeName(values.first_name),
                last_name: capitalizeName(values.last_name),
                middle_name: capitalizeName(values.middle_name),
                gender: values.gender,
                email: values.email,
                phone: values.phone,
                address: values.address,
                pincode: values.pincode,
                password: values.password,
                dob: formatBirthDate(values.dob.$d),
                member_type_id: values.member_type,
                submember_details: submembersData,
                total_members: submembersData.length + 1,
                group_id: values.old_group,
                membership_id: values.old_member_number,
                reference_name: values.reference_name,
                membership_status: values.select_member,
                member_id: memberDetails.member_details[0].member_id,
                submember_delete: removedSubmemberId,

            };


            if (formik?.values?.select_member === 'Old') {
                // Modify data object for 'Old' members
                delete data?.reference_name;

            }

            if (formik.values.select_member === 'New') {

                delete data.group_id;
                delete data.membership_id;

            }

            profileUpdateRegister(data)
                .then((response) => {
                    if (response) {
                        if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                            successToast(response.data?.message);
                            dispatch(setOrderId(response.data?.data?.order_id));
                            setCookie(cookieEnum.ORDER, response.data?.data?.order_id);
                            dispatch(setMemberData(response.data?.data));
                            navigate('/payment/details');
                        }
                    }
                })
                .catch((error) => {
                    if (error.response?.data?.status_code === 404) {
                        warningToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                    } else {
                        errorToast('something went wrong');
                    }
                })

        }


    });

    useEffect(() => {
        formik.validateForm();
    }, [formik.values.submembers_number, formik.errors.submembers]);


    return (
        <div style={{marginTop: '-30px'}}>

            <Card className="card"
                  sx={{marginLeft: "20px", marginRight: "20px", marginTop: "20px", marginBottom: "20px"}}>
                <CardHeader title=<FormattedMessage id="new_member"/> className="linear-gradient" />
                <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="first_name"/> <span style={{color: 'red'}}>*</span>
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="first_name"
                                    name="first_name"
                                    variant="outlined"
                                    size="small"
                                    value={formik.values.first_name}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        // formik.handleBlur(e);
                                    }}
                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="middle_name"/> <span style={{color: 'red'}}>*</span>
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="middle_name"
                                    name="middle_name"
                                    variant="outlined"
                                    size="small"
                                    value={formik.values.middle_name}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        // formik.handleBlur(e);
                                    }}
                                    error={formik.touched.middle_name && Boolean(formik.errors.middle_name)}
                                    helperText={formik.touched.middle_name && formik.errors.middle_name}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="last_name"/><span style={{color: 'red'}}>*</span>
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="last_name"
                                    name="last_name"
                                    variant="outlined"
                                    size="small"
                                    value={formik.values.last_name}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        // formik.handleBlur(e);
                                    }}
                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="gender"/> <span style={{color: 'red'}}>*</span>
                                </Typography>

                                <Select
                                    id="gender"
                                    name="gender"
                                    placeholder="Select Gender"
                                    value={formik.values.gender}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                                >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                </Select>
                                {formik.touched.gender && formik.errors.gender && (
                                    <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.gender}</div>
                                )}
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="dob"/><span style={{color: 'red'}}>*</span>
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disableFuture
                                        placeholder="Date picker"
                                        id="dob"
                                        name="dob"
                                        inputFormat="MM/DD/YYYY"
                                        value={formik.values.dob}
                                        onChange={(value) => {
                                            formik.setFieldValue('dob', value);
                                        }}
                                        onBlur={formik.handleBlur}
                                        sx={{
                                            width: '100%',
                                            borderRadius: '4px', // Set the border radius to make it round
                                            ...(formik.touched.dob && formik.errors.dob && {border: '1px solid red'})
                                        }}
                                    />
                                </LocalizationProvider>
                                {formik.touched.dob && formik.errors.dob &&
                                    <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.dob}</div>}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="email"/>
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    variant="outlined"
                                    size="small"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={(e) => {
                                        formik.handleBlur(e);
                                        handleEmailCheck(e.target.name);
                                    }}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-12">
                                                <Typography variant="subtitle1">
                                                    <FormattedMessage id="mobile_num"/><span
                                                    style={{color: 'red'}}>*</span>
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    id="phone"
                                                    name="phone"
                                                    variant="outlined"
                                                    size="small"
                                                    value={formik.values.phone}
                                                    onChange={formik.handleChange}
                                                    onBlur={(e) => {
                                                        formik.handleBlur(e);
                                                        handlePhoneCheck(e.target.name);
                                                    }}
                                                    error={formik.touched.phone && formik.errors.phone}
                                                    helperText={formik.touched.phone && formik.errors.phone}
                                                    InputProps={{
                                                        readOnly: !!memberDetails, // Set readOnly to true if memberDetails are found
                                                        style: {color: memberDetails ? 'grey' : 'inherit'} // Change color here
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <Typography variant="subtitle1">
                                                    <FormattedMessage id="pincode"/><span
                                                    style={{color: 'red'}}>*</span>
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    id="pincode"
                                                    name="pincode"
                                                    variant="outlined"
                                                    value={formik.values.pincode}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.pincode && formik.errors.pincode}
                                                    helperText={formik.touched.pincode && formik.errors.pincode}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Right column */}
                                    <div className="col-lg-6">
                                        <Typography variant="subtitle1">
                                            <FormattedMessage id="address"/><span style={{color: 'red'}}>*</span>
                                        </Typography>
                                        <TextField
                                            multiline={true}
                                            name="address"
                                            size="small"
                                            value={formik.values.address}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.address && formik.errors.address}
                                            helperText={formik.touched.address && formik.errors.address}
                                            rows={4} // Adjust the number of rows as needed
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    {/* Left column */}
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-12">
                                                <Typography variant="subtitle1">
                                                    <FormattedMessage id="member_type"/><span
                                                    style={{color: 'red'}}>*</span>
                                                </Typography>

                                                <Select
                                                    fullWidth
                                                    id="member_type"
                                                    name="member_type"
                                                    variant="outlined"
                                                    size="small"
                                                    value={formik.values.member_type} // Set the default value
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        handleMemberTypeSelect(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.member_type && Boolean(formik.errors.member_type)}
                                                >
                                                    {memberTypes.map((memberType) => (
                                                        <MenuItem key={memberType.value}
                                                                  value={memberType.value}>
                                                            {memberType.title}
                                                        </MenuItem>
                                                    ))}
                                                    {/*<MenuItem value="1">VIP*/}
                                                    {/* </MenuItem>*/}
                                                </Select>
                                                {formik.touched.member_type && formik.errors.member_type && (
                                                    <div className="error-message"
                                                         style={{color: 'red', fontSize: '12px'}}>
                                                        {formik.errors.member_type}
                                                    </div>
                                                )}
                                            </div>
                                            {memberTypeDetails && (
                                                <Typography variant="body2" className="text-muted">
                                                    *<FormattedMessage
                                                    id="fee_one"/> {getMemberTypeName(formik.values.member_type)}
                                                    <FormattedMessage id="fee_two"/> ₹{memberTypeDetails.price}/-
                                                </Typography>
                                            )}
                                            <div className="col-12" style={{marginTop: '25px'}}>
                                                {memberTypeDetails && (
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="price"
                                                            name="price"
                                                            label="Price(Read-Only)"
                                                            variant="outlined"
                                                            type="text"
                                                            value={memberTypeDetails.price}
                                                            InputProps={{
                                                                readOnly: true,
                                                                style: {color: 'grey'} // Change color here
                                                            }}
                                                        />
                                                    </Grid>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-12">
                                                <Typography variant="subtitle1">
                                                    Select Member <span style={{color: 'red'}}>*</span>
                                                </Typography>
                                                <Select
                                                    fullWidth
                                                    id="select_member"
                                                    name="select_member"
                                                    variant="outlined"
                                                    size="small"
                                                    value={formik.values.select_member}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.select_member && Boolean(formik.errors.select_member)}
                                                >
                                                    <MenuItem value="New">New</MenuItem>
                                                    <MenuItem value="Old">Old</MenuItem>
                                                </Select>
                                                {formik.touched.select_member && formik.errors.select_member && (
                                                    <div className="error-message"
                                                         style={{color: 'red', fontSize: '12px'}}>
                                                        {formik.errors.select_member}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-12" style={{marginTop: "10px"}}>

                                                {formik.values.select_member === 'New' && (
                                                    <>
                                                        <Typography variant="subtitle1">
                                                            Reference Name
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            id="reference_name"
                                                            name="reference_name"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formik.values.reference_name}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            error={formik.touched.reference_name && Boolean(formik.errors.reference_name)}
                                                        />
                                                        {formik.touched.reference_name && formik.errors.reference_name && (
                                                            <div className="error-message"
                                                                 style={{color: 'red', fontSize: '12px'}}>
                                                                {formik.errors.reference_name}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-6" style={{marginTop: "10px"}}>
                                                {formik.values.select_member === 'Old' && (
                                                    <>
                                                        <Typography variant="subtitle1">
                                                            <FormattedMessage id="old_group"/><span
                                                            style={{color: 'red'}}>*</span>
                                                        </Typography>
                                                        <Select
                                                            fullWidth
                                                            id="old_group"
                                                            name="old_group"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formik.values.old_group}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                                setIsGroupSelected(!!e.target.value); // Set isGroupSelected based on whether a value is selected
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            error={formik.touched.old_group && Boolean(formik.errors.old_group)}
                                                        >

                                                            {groups.map((group) => (
                                                                <MenuItem key={group.group_id}
                                                                          value={group.group_id}>
                                                                    {group.group_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {formik.touched.old_group && formik.errors.old_group && (
                                                            <div className="error-message"
                                                                 style={{color: 'red', fontSize: '12px'}}>
                                                                {formik.errors.old_group}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>

                                            <div className="col-6" style={{marginTop: "10px"}}>
                                                {formik.values.select_member === 'Old' && (
                                                    <>
                                                        <Typography variant="subtitle1">
                                                            <FormattedMessage id="old_number"/><span
                                                            style={{color: 'red'}}>*</span>
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            id="old_member_number"
                                                            name="old_member_number"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formik.values.old_member_number}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                                if (!e.target.value) { // If old_member_number becomes empty, set old_group to null
                                                                    formik.setFieldValue('old_group', '');
                                                                }
                                                            }}
                                                            error={formik.touched.old_member_number && Boolean(formik.errors.old_member_number)}
                                                            helperText={formik.touched.old_member_number && formik.errors.old_member_number}
                                                        />
                                                    </>
                                                )}
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>


                            <Grid container spacing={2} style={{marginTop: "10px"}}>

                                {formik.values.submembers.length === 0 && (
                                    <Grid item xs={12} sx={{width: '100%'}}>
                                        <SubmemberForm index={0} formik={formik}/>
                                    </Grid>
                                )}

                                {formik.values.submembers.map((submember, index) => (
                                    <Grid item xs={12} key={index} sx={{width: '100%'}}>
                                        <SubmemberForm index={index} formik={formik}/>
                                    </Grid>
                                ))}
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            formik.setFieldValue(
                                                'submembers',
                                                [...formik.values.submembers, {}] // Create a new object for the new submember
                                            );
                                        }}
                                        disabled={formik.values.submembers.length >= 5}
                                    >
                                        Add more sub-members
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{mt: 2}}>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="termsAndConditions"
                                            color="primary"
                                            onChange={formik.handleChange}
                                            checked={formik.values.termsAndConditions}
                                        />
                                    }
                                    label={
                                        <>
                                            I agree to the{' '}
                                            <a href={`${appConfigs.WEBSITE_DOMAIN}TermsAndConditions.html`}
                                               target="_blank">
                                                Terms & Conditions
                                            </a>{' '}
                                            and {' '}
                                            <a href={`${appConfigs.WEBSITE_DOMAIN}PrivacyPolicy.html`} target="_blank">
                                                Privacy Policy
                                            </a>
                                        </>
                                    }
                                    helperText={formik.touched.termsAndConditions ? formik.errors.termsAndConditions : null}

                                />
                                {formik.touched.termsAndConditions && formik.errors.termsAndConditions && (
                                    <div style={{
                                        color: 'red',
                                        fontSize: '12px'
                                    }}>{formik.errors.termsAndConditions}</div>
                                )}
                            </Grid>
                            <Grid item xs={12}
                                  sx={{mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Link to="/" style={{textDecoration: 'none'}}>
                                    <Typography color="primary">Already registered?</Typography>
                                </Link>

                                <Button type="submit" variant="contained" color="primary">
                                    Register
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <ToastContainer/>
        </div>
    );
};

export default UpdateRegisterForm;
