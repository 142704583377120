import React from 'react';
import '../../ticket/ticketUnauthorized.css';
import {Button} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import demo from "../../../assets/images/icons/demo.jpg";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import {appConfigs} from "../../../config/envs/app.config";

const RegistrationClosedPage = () => {

    return (
        <div className="container">
            <div className="message">
                <h3>
                    Registrations are closed !! <HowToRegIcon style={{marginLeft: '8px'}}/>
                </h3>
            </div>
            <Link to={appConfigs.WEBSITE_DOMAIN} style={{textDecoration: 'none'}} target={"_blank"}>
                <Button color="primary" variant="contained" type="submit">
                    Back To Home
                </Button>
            </Link>
            <img src={demo} alt="Descriptive Alt Text" className="image"/>

        </div>
    );
};

export default RegistrationClosedPage;
