import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  otpToken: null,
  username: null
};

const forgotPasswordReducer = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setOtpToken(state, action) {
      state.otpToken = action.payload;
    },
    setUsername(state, action) {
      state.username = action.payload;
    }
  }
});

export default forgotPasswordReducer.reducer;

export const { setOtpToken, setUsername } = forgotPasswordReducer.actions;
