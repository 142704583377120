import { Button } from '@mui/material';
import React from 'react';

export const AddButton = ({ handleName, handleClick, disabled }) => {

  return (
        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={handleClick}
          disabled={disabled}
        >
          {handleName}
        </Button>

  );
};
