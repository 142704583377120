import React from 'react';
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import './ticketUnauthorized.css'; // Import the CSS file
import demo from "../../assets/images/icons/demo.jpg";
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';

const TicketUnauthorizedPage = () => {
    return (
        <div className="container">
            <div className="message">
                <h3>
                    The event has already ended !! <TheaterComedyIcon style={{marginLeft:'8px'}}/>
                </h3>
            </div>
            <Link to="/" style={{textDecoration: 'none'}}>
                <Button color="primary" variant="contained" type="submit">
                    Back To Home
                </Button>
            </Link>
            <img src={demo} alt="Descriptive Alt Text" className="image"/>

        </div>
    );
};

export default TicketUnauthorizedPage;
