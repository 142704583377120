import React, {useEffect, useState} from "react";
import TicketPrint from "./TicketPrint";
import {useLocation, useNavigate} from "react-router-dom";
import {TicketApi} from "service/ticket.api";
import {errorToast, warningToast} from "../../shared/components/AlertMessages";

const TicketGenerator = () => {
    const [ticketData, setTicketData] = useState([]);
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [eventImage, setEventImage] = useState(null);


    useEffect(() => {
        const token = new URLSearchParams(location.search).get("t");
        if (!token) {
            navigate("/");
            return;
        }
        fetchTicketData(token);
    }, [location.search]);


    const fetchTicketData = (token) => {
        TicketApi(token)
            .then((response) => {
                if (response) {
                    if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                        const data = response.data;
                        setTicketData([data]);
                        setEventImage(response?.data?.data?.event_image)
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                    setTimeout(() => {
                        navigate('/ticket/unauthorized');
                    }, 1000);
                } else {
                    setError(error.message);
                }
            })

    };

    return (
        <>
            {error ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100vh",
                    }}
                >
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        <div
                            style={{
                                flexBasis: "100%",
                                maxWidth: "100%",
                                textAlign: "center",
                            }}
                        >
                            <h2>Error fetching ticket details</h2>
                            <p>{error}</p>
                        </div>
                    </div>
                </div>
            ) : (
                ticketData.map((ticketData, index) => (
                    <TicketPrint key={index} data={ticketData} eventImage={eventImage}/>
                ))
            )}
        </>


    );
};

export default TicketGenerator;