import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import OTPModal from '../../../shared/components/OtpModal';
import { getMember, sendOtp, updateProfileRequest } from "../../../service/member.api";
import { errorToast, successToast, warningToast } from "../../../shared/components/AlertMessages";
import {CardHeader, CircularProgress, Grid, MenuItem, Select} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { setIdentifier, setResponseToken, setShowOtpModal } from '../../../store/reducers/memberReducer';
import { getId } from "../../../shared/helpers/authentication";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import 'dayjs/locale/en-gb';


const EditMemberForm = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [sendOTPClicked, setSendOTPClicked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showOTPModal = useSelector((state) => state.member.showOtpModal);

  const [memberDetails, setMemberDetails] = useState([]); // Initialize with an empty string
  const id = getId();

  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);

  const [prevPhoneNumber, setPrevPhoneNumber] = useState('');

  const fetchMemberData = (id) => {
    getMember(id)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            setMemberDetails(response?.data[0]);
            setPrevPhoneNumber(response?.data[0].phone);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setMemberDetails([]);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast('something went wrong');
        }
      });
  };
  const handleEditEvent = (editedMemberData,token) => {
    if(token){
      updateProfileRequest(editedMemberData,token) // Replace with the actual API call
        .then((response) => {
          if (response) {
            if (response?.status_code === 200 || response?.status_code === 201) {
              successToast(response?.message);
              dispatch(setShowOtpModal(false));
              setTimeout(() => {
                navigate('/index/member')
              }, 1000);
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
            dispatch(setShowOtpModal(true));
          } else {
            errorToast('something went wrong');
          }
        });
    }
    else{
      updateProfileRequest(editedMemberData) // Replace with the actual API call
        .then((response) => {
          if (response) {
            if (response?.status_code === 200 || response?.status_code === 201) {
              successToast(response?.message);
              setTimeout(() => {
                navigate('/index/member')
              }, 1000);
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast('something went wrong');
          }
        });
    }

  };
  useEffect(() => {
      fetchMemberData(id); // Fetch member data when the component mounts

  }, []);


  const handleChange = (e) => {

    const { name, value } = e.target;

    // Perform field-level validation using Yup
    const fieldValidationSchema = Yup.object().shape({
      [name]: validationSchema.fields[name]
    });

    fieldValidationSchema
      .validateAt(name, { [name]: value }) // Validate the specific field
      .then(() => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined // Clear the error for the current field
        }));
      })
      .catch((error) => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message // Set the error message for the current field
        }));
      });

    setMemberDetails({ ...memberDetails, [name]: value });
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date);
    setMemberDetails({ ...memberDetails, dob: formattedDate });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email'),
    phone: Yup.string().matches(/^\d{10}$/, 'Phone number must be 10 digits'),
    pincode: Yup.string().matches(/^\d{6}$/, 'Pincode must be 6 digits'),
    address: Yup.string().required('Address Required'),
    dob: Yup.date().max(new Date(), 'Please select a valid date').required('Dob Required').nullable(),
    first_name: Yup.string()
      .matches(/^[^0-9]*$/, 'First name cannot contain numbers')
      .required('First name Required'),
    last_name: Yup.string()
      .matches(/^[^0-9]*$/, 'Last name cannot contain numbers')
      .required('Last name Required'),
    middle_name: Yup.string()
      .matches(/^[^0-9]*$/, 'Middle name cannot contain numbers')
      .required('Middle name Required'),
    gender: Yup.string().required('Gender Required'),

  });

  const handlePhoneChange = (e) => {

    const { name, value } = e.target;
    const phoneNumberRegex = /^\d{10}$/;

    // Perform field-level validation
    if (!phoneNumberRegex.test(value)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Phone number must be 10 digits'
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined
      }));
    }


    setMemberDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));

    if (value !== memberDetails.phone) {
      setPhoneNumberChanged(true);
    } else {
      setPhoneNumberChanged(false);
    }
  };
  const otpSend = () => {
    setLoading(true);
    setSendOTPClicked(true); // Disable send OTP button

    const identifier = memberDetails.phone;

    sendOtp(identifier, 'Edit Profile' ,id)
        .then((response) => {
          if (response) {
            if (response.data?.status_code === 200 || response.data?.status_code === 201) {
              dispatch(setShowOtpModal(true));
              dispatch(setResponseToken(response.data?.data));
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast(error.response?.data?.message);
          }
        })
        .finally(() => {
          setSendOTPClicked(false);
          setLoading(false);
        });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    validationSchema
      .validate(memberDetails, { abortEarly: false }) // Validate all fields and collect all errors
      .then(() => {
        setValidationErrors({});
        if (phoneNumberChanged) {
          dispatch(setIdentifier(prevPhoneNumber));
          otpSend();
        }  else {
          let data = {
            member_id: memberDetails.member_id,
            first_name: memberDetails.first_name,
            middle_name: memberDetails.middle_name,
            last_name: memberDetails.last_name,
            gender: memberDetails.gender,
            email: memberDetails.email,
            phone: memberDetails.phone,
            address: memberDetails.address,
            pincode: memberDetails.pincode,
            dob: dayjs(memberDetails.dob).format('MM/DD/YYYY'),
            is_phone_updated: false,
          };
          handleEditEvent(data);
        }
      })
      .catch((validationErrors) => {
        const errors = {};
        validationErrors.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        setValidationErrors(errors);
      });
  };

  const handleOTPSubmit = (combinedHash) => {
    let data = {
      member_id: memberDetails.member_id,
      first_name: memberDetails.first_name,
      middle_name: memberDetails.middle_name,
      last_name: memberDetails.last_name,
      gender: memberDetails.gender,
      email: memberDetails.email,
      phone: memberDetails.phone,
      address: memberDetails.address,
      pincode: memberDetails.pincode,
      dob: dayjs(memberDetails.dob).format('MM/DD/YYYY'),
      is_phone_updated: true
    };
    handleEditEvent(data, combinedHash);
  };

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit}>
          <CardHeader title=<FormattedMessage id="edit_profile"/> className="linear-gradient" />
          <Card.Body>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="first_name"/> <span style={{color: 'red'}}>*</span>
                </Typography>
                <TextField
                    fullWidth
                    name="first_name"
                    variant="outlined"
                    value={memberDetails.first_name}
                    onChange={handleChange}
                    margin="normal"
                    size="small"
                    error={!!validationErrors.first_name}
                    helperText={validationErrors.first_name || ''}
                    sx={{marginTop: "1px"}}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="middle_name"/>
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                <TextField
                    fullWidth
                    name="middle_name"
                    variant="outlined"
                    value={memberDetails.middle_name}
                    onChange={handleChange}
                    margin="normal"
                    size="small"
                    error={!!validationErrors.middle_name}
                    helperText={validationErrors.middle_name || ''}
                    sx={{marginTop: "1px"}}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="last_name"/>
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                <TextField
                    fullWidth
                    name="last_name"
                    variant="outlined"
                    size="small"
                    value={memberDetails.last_name}
                    onChange={handleChange}
                    margin="normal"
                    error={!!validationErrors.last_name}
                    helperText={validationErrors.last_name || ''}
                    sx={{marginTop: "1px"}}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="gender"/> <span style={{color: 'red'}}>*</span>
                </Typography>
                <Select
                    fullWidth
                    name="gender"
                    value={memberDetails.gender || ''}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    sx={{marginTop: "1px"}}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="dob"/>
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                      disableFuture
                      id="dob"
                      name="dob"
                      // inputFormat="MM/DD/YYYY"
                      value={dayjs(memberDetails.dob)}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                      error={!!validationErrors.dob}
                      helperText={validationErrors.dob || ''}
                      sx={{width: '100%', marginTop: "1px"}}
                  />
                </LocalizationProvider>
                {<div style={{color: 'red', fontSize: '12px'}}>{validationErrors.dob || ''}</div>}
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="email"/>
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                <TextField
                    fullWidth
                    name="email"
                    variant="outlined"
                    value={memberDetails.email}
                    onChange={handleChange}
                    margin="normal"
                    error={!!validationErrors.email}
                    helperText={validationErrors.email || ''}
                    sx={{marginTop: "1px"}}

                />
              </Grid>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-12">
                        <Typography variant="subtitle1">
                          <FormattedMessage id="mobile_num"/>
                          <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <TextField
                            fullWidth
                            name="phone"
                            variant="outlined"
                            value={memberDetails.phone || ''}
                            onChange={handlePhoneChange}
                            margin="normal"
                            error={!!validationErrors.phone}
                            helperText={validationErrors.phone || ''}
                            sx={{marginTop: "1px"}}

                        />
                      </div>
                      <div className="col-12">
                        <Typography variant="subtitle1">
                          <FormattedMessage id="pincode"/>
                          <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <TextField
                            fullWidth
                            name="pincode"
                            variant="outlined"
                            value={memberDetails.pincode}
                            onChange={handleChange}
                            margin="normal"
                            error={!!validationErrors.pincode}
                            helperText={validationErrors.pincode || ''}
                            sx={{marginTop: "1px"}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Typography variant="subtitle1">
                      <FormattedMessage id="address"/>
                      <span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        multiline={true}
                        name="address"
                        size="small"
                        value={memberDetails.address}
                        onChange={handleChange}
                        rows={4}
                        fullWidth
                        variant="outlined"
                        sx={{marginTop: "1px"}}

                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Card.Body>
          <Card.Footer>
            <Button type="submit" variant="contained" color="primary" disabled={sendOTPClicked}>
              {loading ? <CircularProgress size={24}/> : <FormattedMessage id="change_request"/>}
            </Button>
          </Card.Footer>


        </form>
        <ToastContainer/>
      </Card>

      <OTPModal isOpen={showOTPModal} onClose={() => dispatch(setShowOtpModal(false))} onSubmit={handleOTPSubmit} />
    </>
  );
};

export default EditMemberForm;
