import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import {SubmemberApproveTable} from "../submember/SubmemberApproveTable";
import {ProfileChangeTable} from "../member/ProfileChangeTable"; // Import TabContext

const ApprovalComponent = () => {
    const [value, setValue] = React.useState("profile_change"); // Default value set to "all"

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <TabContext value={value}>
                <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <Tabs value={value} onChange={handleTabChange}>
                        <Tab value="profile_change" label="Profile Edit Requests" />
                        <Tab value="submember_approve" label="Sub-Member Approval" />
                    </Tabs>

                    <TabPanel value="profile_change">
                        <ProfileChangeTable />
                    </TabPanel>
                    <TabPanel value="submember_approve">
                        <SubmemberApproveTable />
                    </TabPanel>
                </Box>
            </TabContext>
        </div>
    );
};

export default ApprovalComponent;
