import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Search } from "../../../node_modules/@mui/icons-material/index";
import ClearIcon from '@mui/icons-material/Clear';
import { Grid } from "../../../node_modules/@mui/material/index";

export const MemberSearch = ({
  placeholder,
  buttonLabel,
  handleResetSearch,
  onSearch = () => {},
  // handleFetchData={handleFetchData}
}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value)
    onSearch(e.target.value);
  };
  


  return (
    <>
       <Grid container spacing={1} alignItems="center">
      <Grid item>
        <TextField
          type="search"
          placeholder={placeholder || "Search..."}
          value={searchValue}
          // onChange={(e) => setSearchValue(e.target.value)}
          onChange={handleSearch}
          size="small"
        />
      </Grid>
     
       
      </Grid>
    </>
  );
};