import React from 'react';
import PeopleIcon from '@mui/icons-material/People';
import {Link} from 'react-router-dom'; // Import Link from react-router-dom

const PendingApprovalStatistics = ({verifiedMemberCount}) => {
    return (
        <div className="col-lg-12 col-12">
            <div className="card card-statistics">
                <h4 className="card-title">Pending Approval</h4>
                <div className="card-body statistics-body">
                    <div className="column">
                        <Link to="/admin/approve" style={{color: 'inherit'}}
                              className="col-md-12 col-sm-6 col-12 mb-2 mb-md-0">
                            <div className="media">
                                <div className="avatar bg-light-primary mr-2">
                                    <div className="avatar-content">
                                        <PeopleIcon/>
                                    </div>
                                </div>
                                <div className="media-body my-auto">
                                    <h4 className="font-weight-bolder mb-0">Profile Update</h4>
                                    <p className="card-text font-small-3 mb-0 d-flex justify-content-between align-items-center">{verifiedMemberCount}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PendingApprovalStatistics;
