import {useFormik} from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {createHall} from '../../../service/hall.api';
import {successToast, errorToast, warningToast} from '../../../shared/components/AlertMessages';
import Typography from '@mui/material/Typography';
import {FormattedMessage} from 'react-intl';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {getMemberTypes} from "../../../service/membertype.api";
import HallModal from "./HallModal";
import {capitalizeName} from "../../../shared/helpers/utils";

const AddHall = ({isOpen, onClose, onAdd}) => {
    const [memberTypes, setMemberTypes] = useState([]);

    const [hallModal, setHallModal] = useState(false);


    const toggleHallModal = () => {
        setHallModal(!hallModal);
    };

    const handleHallModalClose = () => {
        setHallModal(false);
    };

    const formik = useFormik({
        initialValues: {
            hall_name: '',
            hall_address: '',
            total_capacity: '',
            seating_arrangement: [{
                member_type_id: '',
                row_name: '',
                column_name: '',
                column_from: '',
                column_to: '',
                reserved_from: '',
                reserved_to: ''
            }]
        },

        validationSchema: Yup.object({
            hall_name: Yup.string()
                .required('Hall Name Required'),
            hall_address: Yup.string().required('Hall address Required'),
            total_capacity: Yup.number()
                .typeError('Enter a valid number')
                .positive('Total seats Must be an positive')
                .required('Total Capacity Required'),
            seating_arrangement: Yup.array().of(
                Yup.object().shape({
                    member_type_id: Yup.string().required('Member Type Required'),
                    row_name: Yup.string().required('Row Name Required'),
                    column_name: Yup.string().required('Column Name Required'),
                    column_from: Yup.number()
                        .typeError('Seats From must be a number')
                        .min(0, 'Seats From must be greater than or equal to 0')
                        .required('Seats From Required'),
                    column_to: Yup.number()
                        .typeError('Seats To must be a number')
                        .min(0, 'Seats To must be greater than or equal to 0')
                        .min(Yup.ref('column_from'), 'Seats To must be greater than or equal to Seats From')
                        .test('column-range', 'Length between Seats From and Seats To should not be more than 50', function (value) {
                            const columnFrom = parseInt(this.parent.column_from, 10);
                            const columnTo = parseInt(value, 10);
                            return (columnTo - columnFrom + 1) <= 50 || this.createError({
                                path: 'seating_arrangement',
                                message: 'Length between Seats From and Seats To should not be more than 50'
                            });
                        })
                        .required('Seats To Required'),
                    reserved_from: Yup.number()
                        .typeError('Reserved From must be a number')
                        .min(0, 'Reserved From must be greater than or equal to 0')
                        .min(Yup.ref('column_from'), 'Reserved From must be greater than or equal to Column From')
                        .max(Yup.ref('column_to'), 'Reserved From must be less than or equal to Column To'),
                    reserved_to: Yup.number()
                        .typeError('Reserved To must be a number')
                        .min(0, 'Reserved To must be greater than or equal to 0')
                        .min(Yup.ref('column_from'), 'Reserved To must be greater than or equal to Column From')
                        .max(Yup.ref('column_to'), 'Reserved To must be less than or equal to Column To')
                        .min(Yup.ref('reserved_from'), 'Reserved To must be greater than or equal to Reserved From'),


                })
            )
                .test('total-seats', 'Total seats exceed hall capacity', function (value) {
                    let totalSeats = 0;
                    value.forEach(seat => {
                        totalSeats += parseInt(seat.column_to, 10) - parseInt(seat.column_from, 10) + 1;
                    });
                    return totalSeats <= parseInt(this.parent.total_capacity, 10) || this.createError({
                        path: 'seating_arrangement',
                        message: 'Total seats exceed hall capacity'
                    });
                }),
        }),
        onSubmit: (values) => {
            values.hall_name = capitalizeName(values.hall_name); // Capitalize the first letter of the hall name
            createHall(values)
                .then((response) => {
                    if (response) {
                        if (response?.status_code === 200 || response?.status_code === 201) {
                            successToast(response?.message);
                        }
                    }
                })
                .catch((error) => {
                    if (error.response?.data?.status_code === 404) {
                        warningToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                    } else {
                        errorToast('something went wrong');
                    }
                })
                .finally(() => {
                    onAdd();
                });
        }
    });


    const handleAddRow = () => {
        formik.setValues((values) => ({
            ...values,
            seating_arrangement: [
                ...values.seating_arrangement,
                {
                    member_type_id: '',
                    row_name: '',
                    column_name: '',
                    column_from: '',
                    column_to: '',
                    reserved_from: '',
                    reserved_to: ''
                }
            ]
        }));
    };

    const fetchMemberTypes = () => {
        getMemberTypes()
            .then((response) => {
                if (response) {
                    if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                        setMemberTypes(response.data?.data.member_type_list);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setMemberTypes([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            })

    };

    useEffect(() => {
        if (isOpen) {
            fetchMemberTypes();
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isOpen) {
            formik.resetForm();
        }
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xl">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle className="linear-gradient">
                    <FormattedMessage id="hall_side_menu"/>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="hall_name"/>
                                <span style={{color: 'red'}}>*</span>
                            </Typography>
                            <TextField
                                fullWidth
                                id="hall_name"
                                name="hall_name"
                                variant="outlined"
                                size="small"
                                value={formik.values.hall_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.hall_name && Boolean(formik.errors.hall_name)}
                                helperText={formik.touched.hall_name && formik.errors.hall_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="total_capacity"/>
                                <span style={{color: 'red'}}>*</span>
                            </Typography>
                            <TextField
                                fullWidth
                                id="total_capacity"
                                name="total_capacity"
                                placeholder="Total Capacity"
                                variant="outlined"
                                size="small"
                                value={formik.values.total_capacity}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.total_capacity && Boolean(formik.errors.total_capacity)}
                                helperText={formik.touched.total_capacity && formik.errors.total_capacity}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="hall_address"/>(Enter Google Map Link)
                                <span style={{color: 'red'}}>*</span>
                            </Typography>
                            <TextField
                                fullWidth
                                id="hall_address"
                                name="hall_address"
                                variant="outlined"
                                size="small"
                                value={formik.values.hall_address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.hall_address && Boolean(formik.errors.hall_address)}
                                helperText={formik.touched.hall_address && formik.errors.hall_address}
                            />
                        </Grid>
                        {/* Seating Arrangement */}

                        <Grid item xs={12} md={12}>
                            <Typography variant="subtitle1">
                                Seating Arrangement
                            </Typography>
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}> {/* Adjust maxHeight as needed */}
                                <Box border={1} borderRadius={2} p={3}>
                                <Grid container spacing={1}>
                                    {Object.keys(formik.values.seating_arrangement).map((key) => (
                                        <React.Fragment key={key}>
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth variant="outlined" size="small">
                                                    <InputLabel id={`member_type_id_${key}`}>Member Type</InputLabel>
                                                    <Select
                                                        labelId={`member_type_id_${key}`}
                                                        id={`member_type_id_${key}`}
                                                        value={formik.values.seating_arrangement[key].member_type_id}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        name={`seating_arrangement.${key}.member_type_id`}
                                                        error={formik.touched[`seating_arrangement`]?.[key]?.member_type_id && Boolean(formik.errors.seating_arrangement?.[key]?.member_type_id)}
                                                        helperText={formik.touched[`seating_arrangement`]?.[key]?.member_type_id && formik.errors.seating_arrangement?.[key]?.member_type_id}
                                                    >
                                                        {memberTypes.map((memberType) => (
                                                            <MenuItem key={memberType.member_type_id}
                                                                      value={memberType.member_type_id}>
                                                                {memberType.member_type}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {formik.touched[`seating_arrangement`]?.[key]?.member_type_id && formik.errors.seating_arrangement?.[key]?.member_type_id && (
                                                    <div style={{
                                                        color: 'red',
                                                        fontSize: '12px'
                                                    }}>{formik.errors.seating_arrangement?.[key]?.member_type_id}</div>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    fullWidth
                                                    id={`seating_arrangement_row_name_${key}`}
                                                    name={`seating_arrangement.${key}.row_name`}
                                                    label="Row Name"
                                                    variant="outlined"
                                                    size="small"
                                                    value={formik.values.seating_arrangement[key].row_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched[`seating_arrangement`]?.[key]?.row_name && Boolean(formik.errors.seating_arrangement?.[key]?.row_name)}
                                                    helperText={formik.touched[`seating_arrangement`]?.[key]?.row_name && formik.errors.seating_arrangement?.[key]?.row_name}

                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    fullWidth
                                                    id={`seating_arrangement_column_name_${key}`}
                                                    name={`seating_arrangement.${key}.column_name`}
                                                    label="Column Name"
                                                    variant="outlined"
                                                    size="small"
                                                    value={formik.values.seating_arrangement[key].column_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched[`seating_arrangement`]?.[key]?.column_name && Boolean(formik.errors.seating_arrangement?.[key]?.column_name)}
                                                    helperText={formik.touched[`seating_arrangement`]?.[key]?.column_name && formik.errors.seating_arrangement?.[key]?.column_name}

                                                />
                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    fullWidth
                                                    id={`seating_arrangement_column_from_${key}`}
                                                    name={`seating_arrangement.${key}.column_from`}
                                                    label="Seats From"
                                                    variant="outlined"
                                                    size="small"
                                                    value={formik.values.seating_arrangement[key].column_from}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched[`seating_arrangement`]?.[key]?.column_from && Boolean(formik.errors.seating_arrangement?.[key]?.column_from)}
                                                    helperText={formik.touched[`seating_arrangement`]?.[key]?.column_from && formik.errors.seating_arrangement?.[key]?.column_from}

                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    fullWidth
                                                    id={`seating_arrangement_column_to_${key}`}
                                                    name={`seating_arrangement.${key}.column_to`}
                                                    label="Seats To"
                                                    variant="outlined"
                                                    size="small"
                                                    value={formik.values.seating_arrangement[key].column_to}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched[`seating_arrangement`]?.[key]?.column_to && Boolean(formik.errors.seating_arrangement?.[key]?.column_to)}
                                                    helperText={formik.touched[`seating_arrangement`]?.[key]?.column_to && formik.errors.seating_arrangement?.[key]?.column_to}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={1}>
                                                <TextField
                                                    fullWidth
                                                    id={`seating_arrangement_reserved_from_${key}`}
                                                    name={`seating_arrangement.${key}.reserved_from`}
                                                    label="Reserved From"
                                                    variant="outlined"
                                                    size="small"
                                                    value={formik.values.seating_arrangement[key].reserved_from}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched[`seating_arrangement`]?.[key]?.reserved_from && Boolean(formik.errors.seating_arrangement?.[key]?.reserved_from)}
                                                    helperText={formik.touched[`seating_arrangement`]?.[key]?.reserved_from && formik.errors.seating_arrangement?.[key]?.reserved_from}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <TextField
                                                    fullWidth
                                                    id={`seating_arrangement_reserved_to_${key}`}
                                                    name={`seating_arrangement.${key}.reserved_to`}
                                                    label="Reserved To"
                                                    variant="outlined"
                                                    size="small"
                                                    value={formik.values.seating_arrangement[key].reserved_to}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched[`seating_arrangement`]?.[key]?.reserved_to && Boolean(formik.errors.seating_arrangement?.[key]?.reserved_to)}
                                                    helperText={formik.touched[`seating_arrangement`]?.[key]?.reserved_to && formik.errors.seating_arrangement?.[key]?.reserved_to}
                                                />

                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                    <Grid item xs={false} md={9}/>
                                    <Grid item xs={12} md={3} style={{textAlign: 'right'}}>
                                        {formik.errors.seating_arrangement && typeof formik.errors.seating_arrangement === 'string' && (
                                            <div style={{
                                                color: 'red',
                                                fontSize: '12px'
                                            }}>{formik.errors.seating_arrangement}</div>
                                        )}
                                    </Grid>
                                    <Grid item xs={false} md={9}/>
                                    <Grid item xs={12} md={3} style={{textAlign: 'right'}}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleAddRow}
                                            size="small"
                                        >
                                            Add Row
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            </div>
                            <Button onClick={toggleHallModal} variant="outlined" style={{margin: 8, float: 'right'}}>
                                Preview
                            </Button>
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions style={{justifyContent: "end"}}>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        style={{margin: 8}}
                    >
                        <FormattedMessage id="add"/>
                    </Button>
                    <Button onClick={onClose} variant="outlined" style={{margin: 8}}>
                        <FormattedMessage id="cancel"/>
                    </Button>
                </DialogActions>
                <HallModal isOpen={hallModal} onClose={handleHallModalClose}
                           seatingData={formik.values.seating_arrangement} hallname={formik.values.hall_name}/>

            </form>
        </Dialog>

    );
};

export default AddHall;
