import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from '@mui/material/Button';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import {errorToast} from '../../../shared/components/AlertMessages';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {getEvent} from "../../../service/event.api";

const EventModal = ({isOpen, onClose, eventData}) => {
    const [selectedEventData, setSelectedEventData] = useState([]);
    const event_id  = eventData?.event_id || eventData?.event_booking_event_id

    const handleEventDetailsView = () => {
        getEvent(event_id)
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        setSelectedEventData(response?.data[0]);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setSelectedEventData([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
    };
    useEffect(() => {
        if (eventData && event_id) {
            handleEventDetailsView(eventData);
        }
    }, [eventData]);
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <DialogTitle className="linear-gradient">
                <FormattedMessage id="event_details_title"/>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent dividers>
                <Grid container columns={{xs: 4, md: 12}}>
                    {/* Event Name */}
                    <Grid item xs={6}>
            <span className="fw-bold">
              {' '}
                <FormattedMessage id="event_name_column"/>
            </span>
                        <p className="text-muted">{selectedEventData.event_name}</p>
                    </Grid>

                    {/* Date  */}
                    <Grid item xs={6}>
            <span className="fw-bold">
              {' '}
                <FormattedMessage id="date"/>
            </span>
                        <p className="text-muted">{selectedEventData.date}</p>
                    </Grid>

                    {/* Hall */}
                    <Grid item xs={6}>
            <span className="fw-bold">
              {' '}
                <FormattedMessage id="hall_name"/>
            </span>
                        <p className="text-muted">{selectedEventData.hall_name}</p>
                    </Grid>

                    {/* Venue */}
                    <Grid item xs={6}>
            <span className="fw-bold">
              {' '}
                <FormattedMessage id="venue"/>
            </span>
                        <p className="text-muted">
                            <a href={selectedEventData.venue} target="_blank">{selectedEventData.venue} </a>
                        </p>
                    </Grid>

                    {/* Description */}
                    <Grid item xs={12}>
            <span className="fw-bold">
              {' '}
                <FormattedMessage id="event_details"/>
            </span>
                        <p className="text-muted">{selectedEventData.event_description}</p>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{justifyContent: 'end'}}>
                <Button onClick={onClose} variant="outlined" style={{margin: 8}}>
                    <FormattedMessage id="close"/>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EventModal;
