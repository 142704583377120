import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {Avatar, Box, ButtonBase, ClickAwayListener, Paper, Popper, Stack, Typography} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import ProfileTab from './ProfileTab';

import { getMember } from '../../../../../service/member.api';
import { getId, getRole } from '../../../../../shared/helpers/authentication';
import { useNavigate } from 'react-router-dom';
import { removeLoginData, setCookie } from '../../../../../shared/helpers/utils';
import { errorToast, successToast } from '../../../../../shared/components/AlertMessages';
import * as React from 'react';
import LanguageSelector from '../../../../../shared/components/LanguageSelector';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedLocale } from '../../../../../store/reducers/locale';
import { cookieEnum } from '../../../../../enums/cookie.enum';
import { FormattedMessage } from 'react-intl';
import Notification from "../Notification";
import avatar1 from "assets/images/icons/avatar1.jpg"

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const role = getRole();
  const dispatch = useDispatch();
  const { selectedLocale } = useSelector((state) => state.locale);
  const id = getId();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleLogout = () => {
    removeLoginData();
    successToast('Logged Out Successfully');
    setTimeout(() => {
      navigate('/');
    }, 1000);
  };

  const [memberDetails, setMemberDetails] = useState([]); // Initialize with an empty string

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const fetchMemberData = (id) => {
    getMember(id)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            setMemberDetails(response?.data[0]); // Set the member's name in the state
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setMemberDetails([]);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast('something went wrong');
        }
      });
  };

  useEffect(() => {
    if (role === 'member') {
      fetchMemberData(id); // Fetch member data when the component mounts
    }
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value] = useState(0);

  const handleLocaleChange = (newLocale) => {
    setCookie(cookieEnum.LANGUAGE, newLocale);
    dispatch(setSelectedLocale(newLocale));
  };
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const iconBackColorOpen = 'grey.300';
  if (role) {
    return (
      <>
        {/*<div id="google_translate_element" >*/}
        {/*</div>*/}
        <LanguageSelector currentLocale={selectedLocale} setLocale={handleLocaleChange} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
            ml: 'auto',
          }}
        >
          {role === 'admin' && <Notification />}

          <ButtonBase
            sx={{
              p: 0.25,
              bgcolor: open ? iconBackColorOpen : 'transparent',
              borderRadius: 1,
              '&:hover': { bgcolor: 'secondary.lighter' }
            }}
            aria-label="open profile"
            ref={anchorRef}
            aria-controls={open ? 'profile-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>

              {role === 'member' ? (
                <Typography variant="h5">{memberDetails.first_name} </Typography>
              ) : (
                <Typography variant="h5">
                  <FormattedMessage id="hello" />
                </Typography>
              )}
              <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} />

            </Stack>
          </ButtonBase>
          <Popper
            placement="bottom-end"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 9]
                  }
                }
              ]
            }}
          >
            {({ TransitionProps }) => (
              <Transitions type="fade" in={open} {...TransitionProps}>
                {open && (
                  <Paper
                    sx={{
                      boxShadow: theme.customShadows.z1,
                      width: 290,
                      minWidth: 240,
                      maxWidth: 290,
                      [theme.breakpoints.down('md')]: {
                        maxWidth: 250
                      }
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <MainCard elevation={0} border={false} content={false}>
                        {open && (
                          <>
                            <TabPanel value={value} index={0} dir={theme.direction}>
                              <ProfileTab role={role} handleLogout={handleLogout} />
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                              {/*<SettingTab />*/}
                            </TabPanel>
                          </>
                        )}
                      </MainCard>
                    </ClickAwayListener>
                  </Paper>
                )}
              </Transitions>
            )}
          </Popper>
        </Box>

      </>
    );
  }
};

export default Profile;
