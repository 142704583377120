import React from 'react';
import { Grid, Stack, Typography } from "@mui/material";
import RenewMemberForm from './forms/RenewMemberForm';
// import Paper from '@mui/material/Paper';
import AuthWrapper from "../authentication/AuthWrapper";
import logo from "../../assets/images/icons/logo.png";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const MemberRenew = () => (
  <AuthWrapper>
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' ,marginBottom:"10px"}}>
          <img src={logo} alt="Logo" style={{ width: '60px', height: '60px' }} />
          <Typography variant="h4" color="#1E90FF" sx={{ marginLeft: '10px', fontSize: 'xx-large' }}>
            <FormattedMessage id="Lohana Milan" />
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
          <Typography variant="h4"  color="text.secondary">Renew Membership</Typography>
        </Stack>
        <Typography variant="body1" color="text.secondary" style={{marginTop:"10px"}}>Enter your Mobile Number/Membership ID/Email and we&apos;ll send you otp to renew your membership.</Typography>

      </Grid>
      <Grid item xs={12}>
        <RenewMemberForm />
      </Grid>

      <Link to="/" style={{ textDecoration: 'none' }}>
        {' '}
        <Typography variant="body1" sx={{ textDecoration: 'none', marginTop: '-30px', marginLeft: '20px' }} color="primary">
          Back To Login
        </Typography>
      </Link>
    </Grid>
  </AuthWrapper>
);

export default MemberRenew;
