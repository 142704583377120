// material-ui
import { Grid, Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom

// project import
import AuthLogin from './auth-forms/AuthLogin';
import AuthWrapper from './AuthWrapper';
import { isLoggedIn } from '../../shared/helpers/authentication';
import { useEffect } from 'react';
import logo from "../../assets/images/icons/logo.png";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {appConfigs} from "../../config/envs/app.config";

// ================================|| LOGIN ||================================ //
const Login = () => {
  const navigate = useNavigate();
  const loginRole = isLoggedIn();
  useEffect(() => {
    if (loginRole) {
      if (loginRole === 'admin') {
        navigate('/admin/dashboard');
      }
      if (loginRole === 'member') {
        navigate('/index/member');
      }
    }
  }, [navigate]);
  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' ,marginBottom:"10px"}}>
            <Link to={appConfigs.WEBSITE_DOMAIN} style={{textDecoration: 'none'}} target={"_blank"}>
            <img src={logo} alt="Logo" style={{ width: '60px', height: '60px' }} />
            </Link>
            <Typography variant="h4" color="#1E90FF" sx={{ marginLeft: '10px', fontSize: 'xx-large' }}>
              <FormattedMessage id="Lohana Milan" />
            </Typography>
          </div>
        </Grid>


        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline"
                 sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h4" color="text.secondary">Please Sign-in to your Account</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <AuthLogin />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Login;
