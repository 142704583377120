import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  FormHelperText,
  FormControl,
  Box,
  Stack,
  OutlinedInput,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { strengthColor, strengthIndicator } from '../../../utils/password-strength';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { ToastContainer } from 'react-toastify';
import { errorToast, successToast, warningToast } from '../../../shared/components/AlertMessages';
import { forgotPassword } from '../../../service/login.api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const ResetPassword = () => {
  const navigate = useNavigate();

  const username = useSelector((state) => state.forgotPassword.username);
  const [level, setLevel] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [newPasswordRef, confirmPasswordRef] = [useRef(null), useRef(null)];

  const restoreCursor = (inputRef) => {
    const selectionStart = inputRef.current.selectionStart;
    const selectionEnd = inputRef.current.selectionEnd;

    setTimeout(() => {
      inputRef.current.setSelectionRange(selectionStart, selectionEnd);
    });
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    restoreCursor(newPasswordRef);
  };

  const handleClickConfirmPassword = () => {
    setShowConfirm(!showConfirm);
    restoreCursor(confirmPasswordRef);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    changePassword('');
  }, []);





  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };



  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required('Password Required')
        .min(8, 'Password must have at least 8 characters')
          .max(16,'Password must not be more than 16 characters')
          .matches(/[a-z]/, getCharacterValidationError('lowercase'))
        .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
        .matches(/[0-9]/, getCharacterValidationError('digit'))
        .matches(/[\W_]/, getCharacterValidationError('special character')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm New Password is required')
    }),
    onSubmit: (values) => {
      let data = {
        username:username,
        password: values.confirmPassword
      }
      forgotPassword(data)
          .then((response) => {
            if (response) {
              if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                successToast(response.data?.message);
                setTimeout(() => {
                  navigate('/');
                }, 1000);
              }
            }
          })
          .catch((error) => {
            if (error.response?.data?.status_code === 404) {
              warningToast(error.response?.data?.message);
            } else if (error.response?.data?.status_code === 403) {
              errorToast(error.response?.data?.message);
            } else if (error.response?.data?.status_code === 400) {
              errorToast(error.response?.data?.message);
            } else {
              errorToast('something went wrong');
            }
          });
      }
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="text.secondary">
              {'Password'}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <OutlinedInput
              fullWidth
              id="newPassword"
              name="newPassword"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              value={formik.values.newPassword}
              onChange={(e) => {
                formik.handleChange(e);
                changePassword(e.target.value);
              }}
              inputRef={newPasswordRef}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{}}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <FormHelperText error id="helper-text-password-signup">
                {formik.errors.newPassword}
              </FormHelperText>
            )}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Box sx={{ bgcolor: level?.color, width: 85, height: 8, borderRadius: '7px' }} />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" fontSize="0.75rem">
                    {level?.label}
                  </Typography>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="text.secondary">
              {'Re-enter Password'}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <OutlinedInput
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              type={showConfirm ? 'text' : 'password'}
              variant="outlined"
              value={formik.values.confirmPassword}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              inputRef={confirmPasswordRef}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showConfirm ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{}}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <FormHelperText error id="helper-text-password">
                {formik.errors.confirmPassword}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button type="submit" variant="contained" color="primary">
                Reset Password
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
