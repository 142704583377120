import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MemberTable } from "pages/member/Table";
import { ActiveTable } from "pages/member/ActiveTable";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import {useDispatch, useSelector} from "react-redux";
import {setTabFlag} from "../../store/reducers/dashboard"; // Import TabContext

const NewMemberComponent = () => {
  const tabFlag = useSelector((state) => state.dashboard.tabFlag);
  const [value, setValue] = React.useState(tabFlag === 'active' ? 'active':'all'); // Use tabDetails as initial value

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    // Reset tab state when navigating to Members page directly
    const pathname = window.location.pathname;
    if (pathname.includes('/admin/member')) {
      dispatch(setTabFlag('active'));
    }
  }, [dispatch]);

  return (
    <div>
      <TabContext value={value}>
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Tabs value={value} onChange={handleTabChange}>
            <Tab value="active" label="Active" />
            <Tab value="all" label="All" />
          </Tabs>
        
          <TabPanel value="active">
            <ActiveTable />
          </TabPanel>
          <TabPanel value="all">
            <MemberTable />
          </TabPanel>
        </Box>
      </TabContext>
    </div>
  );
};

export default NewMemberComponent;
