import { requestLogin, request } from './common.service';
export function Login(values) {
  return requestLogin({
    url: '/auth/login',
    method: 'POST',
    body: values
  });
}

export function Refresh(values) {
  return requestLogin({
    url: '/auth/get-new-access-token',
    method: 'POST',
    body: values
  });
}

export function ChangePassword(values) {

  return request({
    url: '/auth/change-password',
    method: 'POST',
    body: values
  });
}

export function forgotPassword(values) {
  return requestLogin({
    url: '/auth/forgot-password',
    method: 'POST',
    body: values,
  });
}

export function verifyOtp(token) {
  return requestLogin({
    url: '/auth/verify-otp',
    method: 'POST',
    headers: {
      Token: token
    }
  });
}

