import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useEffect, useRef, useState } from 'react';
import { errorToast, successToast, warningToast } from "../../../shared/components/AlertMessages";
import { ToastContainer } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import LoadingScreen from '../../../shared/components/LoadingScreen';
import { getProfileChangeRequests, updateMember } from "../../../service/member.api";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from "@mui/icons-material/Delete";
import {CardHeader} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const headCells = [
  {
    id: 'first_name',
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="first_name" />
  },
  {
    id: 'middle_name',
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="middle_name" />
  },
  {
    id: 'last_name',
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="last_name" />
  },
  {
    id: 'gender',
    numeric: false,
    disablePadding: false,
    label: <FormattedMessage id="gender" />
  },
  {
    id: 'dob',
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage id="dob" />
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: <FormattedMessage id="email" />
  },

  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage id="phone" />
  },

  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: <FormattedMessage id="address" />
  },

  {
    id: 'pincode',
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage id="pincode" />
  },

  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="action" />
  }
];

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          View Events
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

export default function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [profileData, setProfileData] = React.useState([]);
  const [count, setCount] = useState(0);
  const isFirstRender = useRef(true); // Ref to track the initial render
  const [isLoading, setIsLoading] = useState(false);


  const view = (page, rowsPerPage) => {
    setIsLoading(true);
    setTimeout(() => {
      getProfileChangeRequests(page + 1, rowsPerPage)
        .then((response) => {
          if (response) {
            setIsLoading(false);
            if (response?.status_code === 200 || response?.status_code === 201) {
              setProfileData(response.data?.member_list);
              setCount(response.data?.total_item);
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response?.data?.status_code === 404) {
            setProfileData([]);
            setCount(0);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast('something went wrong');
          }
        });
    }, 400);
  };


  useEffect(() => {
    if (isFirstRender.current) {
      view(page, rowsPerPage);
      isFirstRender.current = false; // Set to false after the initial render
    }
  }, [page, rowsPerPage]); // Include page and rowsPerPage in the dependency array




  const handleChangePage = (event, newPage) => {
    view(newPage, rowsPerPage);
    setPage(newPage);
  };



  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);

    // Ensure that the current page is valid with the new rowsPerPage
    const newPage = Math.floor(page * (rowsPerPage / newRowsPerPage));

    view(newPage, newRowsPerPage); // Fetch data with the new rowsPerPage
    setRowsPerPage(newRowsPerPage); // Update rowsPerPage
    setPage(newPage); // Update the page to the new calculated page
  };

  const handleProfileApprove = (selectedMember) => {
    let data = {
      change_request_id: selectedMember.change_request_id,
      is_update_confirm: true
    };
    updateMember(data)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);
            const updatedMemberData = profileData.filter((row) => row.member_id !== selectedMember.member_id);
            setProfileData(updatedMemberData);

          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast('something went wrong');
        }
      });
  }

    const handleProfileReject = (selectedMember) => {
      let data = {
        change_request_id: selectedMember.change_request_id,
        is_update_confirm: false
      };
      updateMember(data)
        .then((response) => {
          if (response) {
            if (response?.status_code === 200 || response?.status_code === 201) {
              successToast(response?.message);
              const updatedMemberData = profileData.filter((row) => row.member_id !== selectedMember.member_id);
              setProfileData(updatedMemberData);
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast('something went wrong');
          }
        });
    }
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const useStyles = makeStyles((theme) => ({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
          <Card className="card">
            <CardHeader
                title=<FormattedMessage id="profile_change" />
            className="linear-gradient"
            action={
            <div className={classes.header}>
            </div>
          }
            />
            <CardContent>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id}>{headCell.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {profileData.length > 0 ? (

                      profileData.map((profileData, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={profileData.change_request_id} sx={{ cursor: 'pointer' }}>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {profileData.first_name}
                        </TableCell>
                        <TableCell>{profileData.middle_name}</TableCell>
                        <TableCell>{profileData.last_name}</TableCell>
                        <TableCell>{profileData.gender}</TableCell>
                        <TableCell>{profileData.dob}</TableCell>
                        <TableCell>{profileData.email}</TableCell>
                        <TableCell>{profileData.phone}</TableCell>
                        <TableCell>{profileData.address}</TableCell>
                        <TableCell>{profileData.pincode}</TableCell>
                        <TableCell>
                          <Tooltip title="Edit" arrow>
                            <IconButton color="success" onClick={() => handleProfileApprove(profileData)}>
                              <CheckIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" arrow>
                            <IconButton color="error" onClick={() => handleProfileReject(profileData)}>
                              <CancelIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                  ) : (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          No data found
                        </TableCell>
                      </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

              </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[ 5, 10, 50,100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </CardContent>
          </Card>
      )}
      <ToastContainer />
    </>
  );
}
