import { request, requestLogin } from "./common.service";
export function RegisterSubMember(values) {
  return request({
    url: '/sub_member/sub-member-registration',
    method: 'POST',
    body: values
  });
}

export function getSubmembers(member_id) {
  return request({
    url: `/sub_member/get-sub-member-by-member-id?member_id=${member_id}`,
    method: 'GET'
  });
}

export function viewSubmember(member_id, page_number, items_per_page) {
  return request({
    url: `/sub_member/get-sub-member-by-member-id?member_id=${member_id}&page_number=${page_number}&items_per_page=${items_per_page}`,
    method: 'GET'
  });
}

export function getNonVerifySubmembers( page_number, items_per_page) {
  return request({
    url: `/sub_member/get-all-non-verify-sub-member?page_number=${page_number}&items_per_page=${items_per_page}`,
    method: 'GET'
  });
}

export function updateSubmember(values) {
  return request({
    url: '/sub_member/update-sub-member',
    method: 'PUT',
    body: values
  });
}

export function deleteSubmember(submember_id) {
  return request({
    url: `/sub_member/delete-sub-member?submember_id=${submember_id}`,
    method: 'DELETE'
  });
}

export function searchSubmember(searching_data,member_id) {
  return request({
    url: `/sub_member/search-sub-member?searching_data=${searching_data}&member_id=${member_id}`,
    method: 'GET'
  });
}

export function getAllSubmembers(member_id,membership_id) {
  return requestLogin({
    url: `/sub_member/get-sub-member-by-member-id?member_id=${member_id}`,
    method: 'GET',
    headers: {
      Membership_Id: membership_id
    }
  });
}

export function editSubmember(values,membership_id) {
  return requestLogin({
    url: '/sub_member/update-sub-member/',
    method: 'PUT',
    body: values,
    headers: {
      Membership_Id: membership_id
    }
  });
}

export function AddSubMember(values,membership_id) {
  return requestLogin({
    url: '/sub_member/sub-member-registration',
    method: 'POST',
    body: values,
    headers: {
      Membership_Id: membership_id
    }
  });
}

export function removeSubmember(submember_id,membership_id) {
  return requestLogin({
    url: `/sub_member/delete-sub-member?submember_id=${submember_id}`,
    method: 'DELETE',
    headers: {
      Membership_Id: membership_id
    }
  });
}

export function VerifySubMember(values) {
  return request({
    url: '/sub_member/admin/verify-sub-member',
    method: 'POST',
    body: values
  });
}