import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  submemberCount: 0,
  removedSubmemberId:[]
};

const submemberReducer = createSlice({
  name: 'submember',
  initialState,
  reducers: {
    setSubmemberCount(state, action) {
      state.submemberCount = action.payload;
    },
    setRemovedSubmemberId(state, action) {
      state.removedSubmemberId.push(action.payload); // Append the new ID to the array
    }
  }
});

export default submemberReducer.reducer;

export const { setSubmemberCount,setRemovedSubmemberId } = submemberReducer.actions;
