// project import
import MainLayout from 'layout/MainLayout';
import {MemberType} from '../pages/membertype/Admin';
import {EventCreate} from '../pages/event/Admin';
import {AllocateTicket} from '../pages/event/AllocateTicket';
import {ComplaintRaise} from '../pages/complaint/User';
import {ResponseComplaint} from '../pages/complaint/Admin';
import Protected from './Protected';
import {MemberTypeTable} from '../pages/membertype/Table';
import {EventTable} from '../pages/event/Table';
import {ComplaintTable} from '../pages/complaint/Table';
import {MemberTable} from '../pages/member/Table';
import RegisterSubMember from '../pages/submember/User';
import PasswordChange from '../pages/authentication/PasswordChange';
import {SubmemberTable} from '../pages/submember/Table';
import MemberPage from '../pages/dashboard/MemberPage';
import {AdminPage} from '../pages/dashboard/AdminPage';
import {BookingHistoryTable} from '../pages/event/BookingHistoryTable';
import {GroupTable} from '../pages/group/Table';
import {GroupAdd} from '../pages/group/Admin';
import {HallTable} from 'pages/hall/Table';
import {ProfileChangeTable} from '../pages/member/ProfileChangeTable';
import EditMemberForm from '../pages/member/forms/EditMemberForm';
import {SubmemberApproveTable} from '../pages/submember/SubmemberApproveTable';
import {AdminDashboard} from "../pages/dashboard/AdminDashboard";
import {ActiveTable} from "../pages/member/ActiveTable";
import MembershipDetails from "../pages/payment/MembershipDetails";
import NewMemberComponent from 'pages/newMember/NewMemberComponent';
import ApprovalComponent from "../pages/approval/ApprovalComponent";
import SwitchListSecondary from "../pages/settings/SettingsPage";


const MainRoutes = {

    path: '/',
    element: <MainLayout/>,
    children: [
        {
            path: '/admin/create/membertype',
            element: (
                <Protected routeRole={['admin']}>
                    <MemberType/>
                </Protected>
            )
        },

        {
            path: '/admin/view/membertype',
            element: (
                <Protected routeRole={['admin']}>
                    <MemberTypeTable/>
                </Protected>
            )
        },
        {
            path: '/admin/group/create',
            element: (
                <Protected routeRole={['admin']}>
                    <GroupAdd/>
                </Protected>
            )
        },
        {
            path: '/admin/group/view',
            element: (
                <Protected routeRole={['admin']}>
                    <GroupTable/>
                </Protected>
            )
        },
        {
            path: '/admin/hall/view',
            element: (
                <Protected routeRole={['admin']}>
                    <HallTable/>
                </Protected>
            )
        },
        {
            path: '/admin/profile/view',
            element: (
                <Protected routeRole={['admin']}>
                    <ProfileChangeTable/>
                </Protected>
            )
        },
        {
            path: '/admin/event/create',
            element: (
                <Protected routeRole={['admin']}>
                    <EventCreate/>
                </Protected>
            )
        },
        {
            path: '/admin/ticket/allocation',
            element: (
                <Protected routeRole={['admin']}>
                    <AllocateTicket/>
                </Protected>
            )
        },
        {
            path: '/admin/event/view',
            element: (
                <Protected routeRole={['admin']}>
                    <EventTable/>
                </Protected>
            )
        },
        {
            path: '/admin/submember/approve',
            element: (
                <Protected routeRole={['admin']}>
                    <SubmemberApproveTable/>
                </Protected>
            )
        },
        {
            path: '/user/complaint/create',
            element: (
                <Protected routeRole={['member']}>
                    <ComplaintRaise/>
                </Protected>
            )
        },
        {
            path: '/admin/complaint/view',
            element: (
                <Protected routeRole={['admin']}>
                    <ResponseComplaint/>
                </Protected>
            )
        },
        {
            path: '/admin/complaint',
            element: (
                <Protected routeRole={['admin']}>
                    <ComplaintTable/>
                </Protected>
            )
        },


        {
            path: '/admin/member/all',
            element: (
                <Protected routeRole={['admin']}>
                    <MemberTable/>
                </Protected>
            )
        },
        {
            path: '/admin/member',
            element: (
                <Protected routeRole={['admin']}>
                    <NewMemberComponent/>
                </Protected>
            )
        },
        {
            path: '/admin/approve',
            element: (
                <Protected routeRole={['admin']}>
                    <ApprovalComponent/>
                </Protected>
            )
        },
        {
            path: '/admin/member/active',
            element: (
                <Protected routeRole={['admin']}>
                    <ActiveTable/>
                </Protected>
            )
        },
        {
            path: '/user/submember/register',
            element: (
                <Protected routeRole={['member']}>
                    <RegisterSubMember/>
                </Protected>
            )
        },
        {
            path: '/user/submember/view',
            element: (
                <Protected routeRole={['member']}>
                    <SubmemberTable/>
                </Protected>
            )
        },
        {
            path: '/user/changePassword',
            element: (
                <Protected routeRole={['member']}>
                    <PasswordChange/>
                </Protected>
            )
        },
        {
            path: '/index/member',
            element: (
                <Protected routeRole={['member']}>
                    <MemberPage/>
                </Protected>
            )
        },
        {
            path: '/index/admin',
            element: (
                <Protected routeRole={['admin']}>
                    <AdminPage/>
                </Protected>
            )
        },
        {
            path: '/admin/dashboard',
            element: (
                <Protected routeRole={['admin']}>
                    <AdminDashboard/>
                </Protected>
            )
        },
        {
            path: '/admin/booking/history',
            element: (
                <Protected routeRole={['admin']}>
                    <BookingHistoryTable/>
                </Protected>
            )
        },

        {
            path: '/user/edit/profile',
            element: (
                <Protected routeRole={['member']}>
                    <EditMemberForm/>
                </Protected>
            )
        },
        {
            path: '/user/membership/details',
            element: (
                <Protected routeRole={['member']}>
                    <MembershipDetails/>
                </Protected>
            )
        },
        {
            path: '/admin/settings',
            element: (
                <Protected routeRole={['admin']}>
                    <SwitchListSecondary/>
                </Protected>
            )
        },

    ]
};

export default MainRoutes;
