import { getRole } from '../shared/helpers/authentication';
import { errorToast, warningToast } from '../shared/components/AlertMessages';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Protected({ children, routeRole }) {
  const role = getRole();
  const navigate = useNavigate();
  if (role) {
    if (role.indexOf(routeRole) !== -1) {
      return children;
    } else {
      warningToast('Access Denied');
      return (
        <div>
          <ToastContainer />
        </div>
      );
    }
  } else {
    errorToast('Please Login To Access');
    setTimeout(() => {
      navigate('/');
    }, 1000);
    return (
      <div>
        <ToastContainer />
      </div>
    );
  }
}

export default Protected;
