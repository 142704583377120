import React, { useEffect, useState } from "react";
import { errorToast } from "../../../shared/components/AlertMessages";
import { getSubmembers } from "../../../service/submember.api";
import { FormattedMessage } from "react-intl";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Chip from "@mui/material/Chip";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import LoadingScreen from "../../../shared/components/LoadingScreen";

const ViewSubmemberModal = ({  memberData }) => {
  const [submemberData, setSubmemberData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmemberView = (memberData) => {
    setIsLoading(true);
    getSubmembers(memberData.member_id)
        .then((response) => {
          if (response && (response.status_code === 200 || response.status_code === 201)) {
            setIsLoading(false);
            setSubmemberData(response.data.submember_list_by_member_id);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (error.response.data.status_code === 404) {
              setSubmemberData([]);
            } else if (error.response.data.status_code === 403 || error.response.data.status_code === 400) {
              errorToast(error.response.data.message);
            } else {
              errorToast("Something went wrong");
            }
          }
        });
  };

  useEffect(() => {
    if (memberData && memberData.member_id) {
      handleSubmemberView(memberData);
    }
  }, [memberData]);

  return (
      <>
      {isLoading ? (
                <LoadingScreen />
            ) :(
                <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="gender" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="dob" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="phone" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="payment_status" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submemberData.length > 0 ? (
                  submemberData.map((submember) => (
                      <TableRow key={submember.submember_id}>
                        <TableCell>{`${submember.first_name} ${submember.middle_name} ${submember.last_name}`}</TableCell>
                        <TableCell>{submember.gender}</TableCell>
                        <TableCell>{submember.dob}</TableCell>
                        <TableCell>
                          {submember.phone || <HorizontalRuleIcon />}
                        </TableCell>
                        <TableCell>
                          {submember.sub_member_payment_status === false ? (
                              <Chip label="Pending" color="warning" size="small" />
                          ) : (
                              <Chip label="Complete" color="success" size="small" />
                          )}
                        </TableCell>
                      </TableRow>
                  ))
              ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

          )

      }
      </>

  );
};

export default ViewSubmemberModal;
