import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { openDrawer } from 'store/reducers/menu';
import { getRole } from "../../shared/helpers/authentication";
import Footer from "../../pages/footer/Footer";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const role = getRole();
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);


  // Render without drawer if there's no role
  if (!role) {
    return (
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header />
        <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
          <Toolbar />
          <Breadcrumbs navigation={navigation} title />
          <Outlet />
        </Box>
      </Box>
    );
  }




  return (
      <Box sx={{ display: 'flex', flexDirection: 'column',minHeight:'100vh'}}>
          <Header open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
              <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
              <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                  <Toolbar />
                  <Breadcrumbs navigation={navigation} title />
                  <Outlet />
              </Box>
          </Box>
          {role === 'member' && <Footer />}
      </Box>
  );
};

export default MainLayout;
