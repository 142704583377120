import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { errorToast } from "../../../shared/components/AlertMessages";
import { getMember } from "../../../service/member.api";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "../../../../node_modules/@mui/material/index";
import ViewSubmemberModal from "pages/submember/tables/ViewSubmemberModal";

const ViewMemberModal = ({ isOpen, onClose, memberData }) => {
  const [selectedMemberData, setSelectedMemberData] = useState([]);
  const [tabValue, setTabValue] = useState("1");

  const handleMemberView = (memberData) => {
    getMember(memberData.member_id)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            setSelectedMemberData(response?.data[0]);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setSelectedMemberData([]);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
      });
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (isOpen) {
      handleMemberView(memberData);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle className="linear-gradient">
        <FormattedMessage id="member_details" />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="member details tabs"
            >
              <Tab label="Personal Information" value="1" />
              <Tab label="Submember Details" value="3" />
              <Tab label="Payment Details" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container columns={{ xs: 4, md: 12 }}>
              {/* Membership number  */}
              <Grid item xs={4}>
                <span className="fw-bold">
                  <FormattedMessage id="membership_number" />
                </span>
                <p className="text-muted">{selectedMemberData.membership_id}</p>
              </Grid>
              {/* Group */}
              <Grid item xs={4}>
                <span className="fw-bold">
                  <FormattedMessage id="group" />
                </span>
                <p className="text-muted">{selectedMemberData.group_name || <HorizontalRuleIcon />}</p>
              </Grid>
              {/* Member Type */}
              <Grid item xs={4}>
                <span className="fw-bold">
                  <FormattedMessage id="member_type" />
                </span>
                <p className="text-muted">{selectedMemberData.member_type=== 1 ? "VIP":"NON VIP"}</p>
              </Grid>
              {/* Full Name */}
              <Grid item xs={4}>
                <span className="fw-bold">
                  <FormattedMessage id="name" />
                </span>
                <p className="text-muted">{selectedMemberData.Name}</p>
              </Grid>

              {/* Whatsapp Mobile Number */}
              <Grid item xs={4}>
                <span className="fw-bold">
                  <FormattedMessage id="phone" />
                </span>
                <p className="text-muted">{selectedMemberData.phone}</p>
              </Grid>

              {/* Email */}
              <Grid item xs={4}>
                <span className="fw-bold">
                  <FormattedMessage id="email" />
                </span>
                <p className="text-muted">
                  {selectedMemberData.email || <HorizontalRuleIcon />}{" "}
                </p>
              </Grid>
              {/*********** Reference Name ***********/}
              <Grid item xs={4}>
                <span className="fw-bold">
                  <FormattedMessage id="reference_name" />
                </span>
                <p className="text-muted">
                  {selectedMemberData.reference_name || <HorizontalRuleIcon />}
                </p>
              </Grid>
              {/* Date of Birth */}
              <Grid item xs={4}>
                <span className="fw-bold">
                  <FormattedMessage id="dob" />
                </span>
                <p className="text-muted">{selectedMemberData.dob}</p>
              </Grid>

              {/* Gender */}
              <Grid item xs={4}>
                <span className="fw-bold">
                  <FormattedMessage id="gender" />
                </span>
                <p className="text-muted">{selectedMemberData.gender}</p>
              </Grid>

            

              <Grid item xs={12}>
                <span className="fw-bold">
                  <FormattedMessage id="address" />
                </span>
                <p className="text-muted">{selectedMemberData.address},{selectedMemberData.city}{" - "}{selectedMemberData.pincode}</p>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value="3">
            <ViewSubmemberModal memberData={memberData} />{" "}
          </TabPanel>

          <TabPanel value="2">
            <Grid
              container
              // style={{padding: "10px 20px" }}
            >
              <Grid item xs={12} md={6}>
                <span className="fw-bold">
                  {" "}
                  <FormattedMessage id="paymentMethod" />
                </span>
                <p className="text-muted">
                  {selectedMemberData.payment_type || <HorizontalRuleIcon />}{" "}
                </p>
              </Grid>
              {/*********** Payment Amount ***********/}
              <Grid item xs={12} md={6}>
                <span className="fw-bold">
                  {" "}
                  <FormattedMessage id="paymentAmount" />
                </span>
                <p className="text-muted">
                  {selectedMemberData.payment_amount || <HorizontalRuleIcon />}
                </p>
              </Grid>

              {/*********** Payment Date ***********/}
              <Grid item xs={12} md={6}>
                <span className="fw-bold">
                  {" "}
                  <FormattedMessage id="paymentDate" />
                </span>
                <p className="text-muted">
                  {selectedMemberData.payment_date || <HorizontalRuleIcon />}
                </p>
              </Grid>

              <Grid item xs={12} md={6}>
                <span className="fw-bold">
                  {" "}
                  <FormattedMessage id="transac_id" />
                </span>
                <p className="text-muted">
                  {selectedMemberData.transaction_id || <HorizontalRuleIcon />}
                </p>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions style={{ justifyContent: "end" }}>
        <Button onClick={onClose} variant="outlined" style={{ margin: 8 }}>
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewMemberModal;
