import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import Countdown from './CountDown';
import { SHA256 } from "crypto-js";
import { errorToast, warningToast } from "./AlertMessages";
import { sendOtp } from "../../service/member.api";
import { useDispatch, useSelector } from "react-redux";
import { setResponseToken } from "../../store/reducers/memberReducer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {FormattedMessage} from "react-intl";

const otpSchema = Yup.object().shape({
  otp: Yup.array()
    .of(Yup.string().matches(/^\d{0,1}$/, 'Must be a single digit'))
    .test('is-complete', 'Please enter a 6-digit OTP', (value) => {
      const completedDigits = value.filter((digit) => digit !== '').length;
      return completedDigits === 6;
    })
    .required('Required')
});

const OTPModal = ({ isOpen, onClose, onSubmit}) => {
  const [timer, setTimer] = useState(140);
  const identifier = useSelector((state) => state.member.identifier);
  const responseToken =  useSelector((state) => state.member.responseToken)
  const dispatch = useDispatch();



  useEffect(() => {
    let countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, []);


  const handleResendOTP = () => {
    setTimer(120);

    sendOtp(identifier, 'Edit Profile')
      .then((response) => {
        if (response) {
          if (response.data?.status_code === 200 || response.data?.status_code === 201) {
            dispatch(setResponseToken(response.data?.data));
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast(error.response?.data?.message);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      otp: ['', '', '', '', '', '']
    },

    onSubmit: (values) => {
      otpSchema
        .validate(values, { abortEarly: false })
        .then(() => {
          const otp = values.otp.join('');
          const combinedHash = SHA256(responseToken + otp).toString();
          if (timer > 0){
            onSubmit(combinedHash);
          }
          else {
            warningToast('The entered OTP has expired. Please click on resend OTP.');
            formik.setValues({
              ...formik.values,
              otp: ['', '', '', '', '', '']
            });
          }
        })
        .catch((errors) => {
          // Handle validation errors
          if (errors.name === 'ValidationError') {
            errors.inner.forEach((error) => {
              const fieldName = error.path;
              const errorMessage = error.message;
              formik.setFieldError(fieldName, errorMessage);
            });
          }
        });
    }
  });

  return (
      <Dialog open={isOpen} onClose={onClose} fullWidth>
        <form onSubmit={formik.handleSubmit}>
            <DialogTitle className="linear-gradient">
                Enter Otp
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>

            <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Enter OTP</Typography>
              <div style={{ display: 'flex' }}>
                {formik.values.otp.map((digit, index) => (
                  <TextField
                    key={index}
                    id={`otp-${index}`}
                    name={`otp-${index}`}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 1 }}
                    value={digit}
                    onChange={(event) => {
                      formik.setFieldValue(`otp[${index}]`, event.target.value);
                      if (index < 5 && event.target.value !== '') {
                        document.getElementById(`otp-${index + 1}`).focus();
                      }
                    }}
                    style={{ width: '30px', marginRight: '5px', marginBottom: '10px' }}
                  />
                ))}
              </div>
              {formik.touched.otp && formik.errors.otp && (
                <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{formik.errors.otp}</div>
              )}
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <Countdown seconds={timer % 60} minutes={Math.floor(timer / 60)} onResendOTP={handleResendOTP}   // Pass setTimerCallback to Countdown
              />
            </Grid>
            </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: "end" }}>
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    style={{ margin: 8 }}
                >
                    Submit
                </Button>
                <Button onClick={onClose} variant="outlined" style={{ margin: 8 }}>
                    <FormattedMessage id="cancel" />
                </Button>
            </DialogActions>
        </form>
      </Dialog>

  );
};

export default OTPModal;
