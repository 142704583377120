import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from "@mui/material/IconButton";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {Badge, ListItemText} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EmailIcon from '@mui/icons-material/Email';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

export default function BasicMenu({
                                      memberData,
                                      handleEditClick,
                                    //   handleSubmemberClick,
                                      handleAccept,
                                      handleBlock,
                                      handleUnblock,
                                      showMenu,
                                      handleSendInvoiceEmail,
                                      handlePaymentClick
                                  }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {showMenu && (
                    <MenuItem onClick={handleClose} disabled={memberData.member_payment_status}
                    >
                        <IconButton
                            onClick={() => handlePaymentClick(memberData)}
                            sx={{marginRight: '6px'}} // Add margin-right for spacing

                        >
                            <CurrencyRupeeIcon/>
                        </IconButton>
                        <ListItemText onClick={() => handlePaymentClick(memberData)}>
                            Payment Update
                        </ListItemText>

                    </MenuItem>
                    )}
                <MenuItem onClick={handleClose} disabled={!memberData.member_payment_status}
                >
                    <IconButton
                        onClick={() => handleEditClick(memberData)}
                        sx={{marginRight: '6px'}} // Add margin-right for spacing

                    >
                        <GroupAddIcon/>
                    </IconButton>
                    <ListItemText onClick={() => handleEditClick(memberData)}>
                        Assign Group
                    </ListItemText>

                </MenuItem>
                <MenuItem onClick={handleClose}>
                    {/* <IconButton
                        onClick={() =>
                            handleSubmemberClick(memberData)
                        }
                        sx={{marginRight: '6px'}} // Add margin-right for spacing

                    >
                        <Badge
                            badgeContent={memberData.total_submembers}
                            color="primary"
                        >
                            <PeopleIcon/>
                        </Badge>
                    </IconButton>
                    <ListItemText onClick={() =>
                        handleSubmemberClick(memberData)
                    }
                                  sx={{marginRight: '6px'}}

                    >
                        Show Submembers
                    </ListItemText> */}
                </MenuItem>
                {
                    !showMenu && (
                        <MenuItem onClick={handleClose}>
                            <IconButton onClick={() => handleSendInvoiceEmail(memberData)}
                                        sx={{marginRight: '6px'}} // Add margin-right for spacing
                            >
                                <EmailIcon/>
                            </IconButton>
                            <ListItemText onClick={() => handleSendInvoiceEmail(memberData)}
                            >
                                Send Invoice Email
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {showMenu && (
                    <>

                        <MenuItem onClick={handleClose}
                                  disabled={!memberData.group_name || memberData.is_verified || !memberData.member_status || !memberData.member_payment_status}>
                            <IconButton color="success" onClick={() => handleAccept(memberData)}
                                        sx={{marginRight: '6px'}}
                            >
                                <CheckIcon/>
                            </IconButton>
                            <ListItemText onClick={() => handleAccept(memberData)}
                            >
                                Verify
                            </ListItemText>
                        </MenuItem>
                        <MenuItem disabled={memberData.is_verified === false}>
                            {memberData.member_status ? (
                                <>
                                    <IconButton color="warning" onClick={() => handleBlock(memberData)}
                                                sx={{marginRight: '6px'}}
                                    >
                                        <BlockIcon/>
                                    </IconButton>
                                    <ListItemText onClick={() => handleBlock(memberData)}
                                    >
                                        Block
                                    </ListItemText>
                                </>
                            ) : (
                                <>
                                    <IconButton color="info" onClick={() => handleUnblock(memberData)}
                                                sx={{marginRight: '6px'}}>
                                        <CheckCircleOutlineIcon/>
                                    </IconButton>
                                    <ListItemText onClick={() => handleUnblock(memberData)}
                                    >
                                        Unblock
                                    </ListItemText>
                                </>
                            )}
                        </MenuItem>

                    </>
                )}

            </Menu>
        </div>
    );
}