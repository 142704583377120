import React from 'react';
import PeopleIcon from '@mui/icons-material/People';
import FunctionsIcon from '@mui/icons-material/Functions';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import {Link} from 'react-router-dom'; // Import Link from react-router-dom

const UnverifiedMemberStatistics = ({totalUnverifiedMemberCount, totalUnverifiedSubMemberCount}) => {
    const total = totalUnverifiedMemberCount + totalUnverifiedSubMemberCount;

    return (
        <div className="col-lg-9 col-9">
            <div className="card card-statistics">
                <h4 className="card-title d-flex justify-content-between align-items-center">
                    All Registered Members
                </h4>

                <div className="card-body statistics-body">
                    <div className="row">
                        <Link to="/admin/member" style={{color: 'inherit'}}
                              className="col-md-4 col-sm-6 col-12 mb-2 mb-md-0">
                            <div className="media">
                                <div className="avatar bg-light-primary mr-2">
                                    <div className="avatar-content">
                                        <FunctionsIcon/>
                                    </div>
                                </div>
                                <div className="media-body my-auto">
                                    <h4 className="font-weight-bolder mb-0">Total</h4>
                                    <p className="card-text font-small-3 mb-0 d-flex justify-content-between align-items-center">{total}</p>
                                </div>
                            </div>
                        </Link>
                        <Link to="/admin/member" style={{color: 'inherit'}}
                              className="col-md-4 col-sm-6 col-12 mb-2 mb-md-0">
                            <div className="media">
                                <div className="avatar bg-light-info mr-2">
                                    <div className="avatar-content">
                                        <PeopleIcon/>

                                    </div>
                                </div>
                                <div className="media-body my-auto">
                                    <h4 className="font-weight-bolder mb-0">
                                        Members
                                    </h4>
                                    <p className="card-text font-small-3 mb-0 d-flex justify-content-between align-items-center">{totalUnverifiedMemberCount}</p>
                                </div>
                            </div>
                        </Link>
                        <Link to="/admin/member" style={{color: 'inherit'}}
                              className="col-md-4 col-sm-6 col-12 mb-2 mb-md-0">
                            <div className="media">
                                <div className="avatar bg-light-danger mr-2">
                                    <div className="avatar-content">
                                        <PeopleOutlineIcon/>
                                    </div>
                                </div>
                                <div className="media-body my-auto">
                                    <h4 className="font-weight-bolder mb-0">Sub-members</h4>
                                    <p className="card-text font-small-3 mb-0 d-flex justify-content-between align-items-center">{totalUnverifiedSubMemberCount}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnverifiedMemberStatistics;
