import { CCol, CRow } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import { useState } from 'react';
import { errorToast } from '../../../shared/components/AlertMessages';
import { registeredEventMembers } from '../../../service/event.api';
// import AdminEventInfoModal from '../../../pages/event/forms/AdminEventInfoModal';
import Typography from '@mui/material/Typography';
import GroupCard from '../../../shared/components/GroupCard';
import { FormattedMessage } from 'react-intl';

const AdminEventInfo = ({ eventData }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);
  const [memberCount, setMemberCount] = useState(0);
  const registeredMembers = (event_id) => {
    registeredEventMembers(event_id)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            setMemberCount(response.data?.length);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setMemberCount(0);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast('something went wrong');
        }
      });
  };
  const handleEditClick = (eventData) => {
    setSelectedRowForEdit(eventData);
    const event_id = eventData.event_id;
    registeredMembers(event_id);
    setIsEditModalOpen(true);
  };

  const upcomingEvents = eventData
    .filter((eventData) => new Date(eventData.date) > new Date())
    .sort((a, b) => new Date(a.date) - new Date(b.date))
  return (
    <>
      {upcomingEvents.length !== 0 ? (
        <CRow>
          <Typography variant="h5" color="GoldenRod">
            <FormattedMessage id="view_events" />
          </Typography>
          {upcomingEvents.map((eventData) => (
            <CCol key={eventData.event_id} xs={6} style={{ marginTop: '10px' }}>
              <GroupCard
                title={eventData.event_name}
                link=<FormattedMessage id="view_details" />
                onParticipateClick={() => handleEditClick(eventData)}
              />
            </CCol>
          ))}
          {/*<AdminEventInfoModal*/}
          {/*  isOpen={isEditModalOpen}*/}
          {/*  onClose={() => setIsEditModalOpen(false)}*/}
          {/*  eventData={selectedRowForEdit}*/}
          {/*  memberCount={memberCount}*/}
          {/*/>*/}
        </CRow>
      ) : null}
    </>
  );
};

export default AdminEventInfo;
