import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useEffect, useState } from "react";
import {
  errorToast,
  warningToast,
} from "../../../shared/components/AlertMessages";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteConfirmationModal from "../../../shared/components/DeleteConfirmationModal";
import {
  editSubmember,
  getAllSubmembers,
  removeSubmember,
} from "../../../service/submember.api";
import { FormattedMessage } from "react-intl";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import EditSubmemberForm from "../../submember/forms/EditSubmemberForm";
import Button from "@mui/material/Button";
import SubmemberFormPopup from "../forms/SubmemberFormPopup";
import { useDispatch, useSelector } from "react-redux";
import { setSubmemberCount } from "../../../store/reducers/submemberReducer";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="name" />,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: <FormattedMessage id="gender" />,
  },
  {
    id: "dob",
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage id="dob" />,
  },

  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage id="mobile_num" />,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    // label: <FormattedMessage id="action" />,
  },
];

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          View Events
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function RenewSubmember({ memberDetails }) {
  const dispatch = useDispatch();
  const submemberCount = useSelector((state) => state.submember.submemberCount);
  const [submemberData, setSubMemberData] = React.useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = React.useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [addSubmemberForm, setAddSubmemberForm] = useState(false);
  const toggleSubmemberForm = () => {
    setAddSubmemberForm(!addSubmemberForm);
  };

  const view = () => {
    setIsLoading(true);
    setTimeout(() => {
      getAllSubmembers(
        memberDetails.member.member_id,
        memberDetails.member.membership_id
      )
        .then((response) => {
          if (response) {
            setIsLoading(false);
            if (
              response?.data.status_code === 200 ||
              response?.data.status_code === 201
            ) {
              setSubMemberData(
                response.data?.data?.submember_list_by_member_id
              );
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response?.data?.status_code === 404) {
            setSubMemberData([]);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast("something went wrong");
          }
        });
    }, 400);
  };

  useEffect(() => {
    view();
  }, []);

  useEffect(() => {
    dispatch(setSubmemberCount(submemberData.length));
  }, [submemberData, dispatch]);

  const handleAddSubmember = () => {
    view();
  };

  const handleDeleteClick = (submemberData) => {
    // Open the modal when delete icon is clicked
    setSelectedRowForDelete(submemberData);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirmed = () => {
    removeSubmember(
      selectedRowForDelete.submember_id,
      memberDetails.member.membership_id
    )
      .then((response) => {
        if (response) {
          if (
            response?.data?.status_code === 200 ||
            response?.data?.status_code === 201
          ) {
            const updatedData = submemberData.filter(
              (row) => row.submember_id !== selectedRowForDelete.submember_id
            );
            setSubMemberData(updatedData);
            // view();
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
      });

    setIsDeleteModalOpen(false);
  };

  const handleEditClick = (submemberData) => {
    setSelectedRowForEdit(submemberData);
    setIsEditModalOpen(true);
  };

  const handleEditEvent = (editedSubmemberData) => {
    editSubmember(editedSubmemberData, memberDetails.member.membership_id)
      .then((response) => {
        if (response) {
          if (
            response?.data?.status_code === 200 ||
            response?.data?.status_code === 201
          ) {
            const updatedData = submemberData.map((row) =>
              row.submember_id === selectedRowForEdit.submember_id
                ? { ...row, ...editedSubmemberData }
                : row
            );
            setSubMemberData(updatedData);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
      });

    setIsEditModalOpen(false);
  };
  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (

          <div className="fw-bold">
            <FormattedMessage id="total_submembers" />
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={toggleSubmemberForm}
                disabled={submemberCount >= 5}
                sx={{
                  marginLeft: { xs: 0, md: "30px" }, // Responsive margin left
                }}
            >
              Add new
            </Button>
          </div>
      )}

      <TableContainer>
        {submemberData.length > 0 && (
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                      <TableCell key={headCell.id}>{headCell.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {submemberData.map((submemberData, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                      <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={submemberData.submember_id}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {`${submemberData.first_name} ${submemberData.middle_name} ${submemberData.last_name}`}
                        </TableCell>

                        <TableCell>{submemberData.gender}</TableCell>
                        <TableCell>{submemberData.dob}</TableCell>
                        <TableCell>{submemberData.phone}</TableCell>
                        <TableCell>
                          <Tooltip title="Edit" arrow>
                            <IconButton onClick={() => handleEditClick(submemberData)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" arrow>
                            <IconButton color="error" onClick={() => handleDeleteClick(submemberData)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                  );
                })}
              </TableBody>
            </Table>
        )}
      </TableContainer>

      <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={handleDeleteConfirmed}
      />

      <EditSubmemberForm
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onEdit={handleEditEvent}
          submemberData={selectedRowForEdit}
      />
      <SubmemberFormPopup
          isOpen={addSubmemberForm}
          onClose={()=>setAddSubmemberForm(false)}
          memberDetails={memberDetails}
          handleAddSubmember={handleAddSubmember}
      />
    </>
  );
}
