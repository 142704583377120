// material-ui
import { Grid, Stack } from '@mui/material';

// project import
import ChangePassword from './auth-forms/ChangePassword';

// ================================|| LOGIN ||================================ //

const PasswordChange = () => (
  <Grid container spacing={0}>
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}></Stack>
    </Grid>
    <Grid item xs={12}>
      <ChangePassword />
    </Grid>
  </Grid>
);

export default PasswordChange;
