// material-ui
import { Container, Stack, useMediaQuery } from "@mui/material";

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = () => {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="xl">
      <Stack
        direction={matchDownSM ? 'column' : 'row'}
        spacing={matchDownSM ? 1 : 3}
        textAlign={matchDownSM ? 'center' : 'inherit'}
        sx={{ justifyContent: 'flex-end' }} // Align items to the right
      >
        {/*<Typography*/}
        {/*  variant="subtitle2"*/}
        {/*  color="secondary"*/}
        {/*  component={Link}*/}
        {/*  href="/user/TermsAndConditions"*/}
        {/*  underline="hover"*/}
        {/*>*/}
        {/*  Terms and Conditions*/}
        {/*</Typography>*/}
        {/*<Typography*/}
        {/*  variant="subtitle2"*/}
        {/*  color="secondary"*/}
        {/*  component={Link}*/}
        {/*  href="/user/PrivacyPolicy"*/}
        {/*  underline="hover"*/}
        {/*>*/}
        {/*  Privacy Policy*/}
        {/*</Typography>*/}

        {/*<Typography*/}
        {/*  variant="subtitle2"*/}
        {/*  color="secondary"*/}
        {/*  component={Link}*/}
        {/*  href="/user/aboutUs"*/}
        {/*  underline="hover"*/}
        {/*>*/}
        {/*  About Us*/}
        {/*</Typography>*/}
        {/*<Typography*/}
        {/*  variant="subtitle2"*/}
        {/*  color="secondary"*/}
        {/*  component={Link}*/}
        {/*  href="/user/contactUs"*/}
        {/*  underline="hover"*/}
        {/*>*/}
        {/*  Contact Us*/}
        {/*</Typography>*/}
      </Stack>
    </Container>
  );
};

export default AuthFooter;
