import axios from 'axios';
import {appConfigs} from '../config/envs/app.config';
import { getAccessToken } from '../shared/helpers/authentication';

const createAxios = (baseURL) => {
  const newInstance = axios.create({ baseURL });

  newInstance.interceptors.request.use(async (config) => {
    const { headers } = config,
      accessToken = await getAccessToken();

    headers.Authorization = accessToken;

    return config;
  });

  newInstance.interceptors.response.use(
    (response) => {
      if (response) {
        return response.data;
      }
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          // const accessToken = await getAccessToken(),
          const newAccessToken = await getAccessToken();
          originalRequest.headers.Authorization = `${newAccessToken}`;
          return axios(originalRequest);
        } catch (error) {
          console.log('error:::', error);
        }
      } else if (error?.message === 'Invalid token specified') {
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      }
      return Promise.reject(error);
    }
  );

  return newInstance;

};

const api = createAxios(appConfigs.APP_IP);
// const api = createAxios('http://192.168.10.32:8000/');
const LoginApi = axios.create({
    baseURL: appConfigs.APP_IP
    // baseURL: 'http://192.168.10.32:8000/'
});

const requestConfig = (options) => {
  const config = {
    headers: { 'Content-Type': 'application/json' },
    url: options.url,
    method: options.method
  };
  if (options.headers) {
    config.headers = options.headers;
  }

  if (options.body) {
    config.data = options.body;
  }

  if (options.params) {
    config.params = options.params;
  }
  if (options.cancelToken) {
    config.cancelToken = options.cancelToken;
  }
  return config;
};

const requestConfigForm = (options) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: options.url,
    method: options.method
  };

  if (options.body) {
    config.data = options.body;
  }
  if (options.params) {
    config.params = options.params;
  }
  if (options.cancelToken) {
    config.cancelToken = options.cancelToken;
  }
  return config;
};

export const request = (options) => {
  const config = requestConfig(options);
  return api.request(config);
};

export const requestLogin = (options) => {
  const config = requestConfig(options);
  return LoginApi.request(config);
};

export const requestForm = (options) => {
  const config = requestConfigForm(options);
  return api.request(config);
};
