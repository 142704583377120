import React, { useEffect, useState } from 'react';
import { getEvents } from '../../service/event.api';
import { errorToast } from '../../shared/components/AlertMessages';
import { Grid } from '@mui/material';
import { getMemberTypes } from '../../service/membertype.api';
import AdminEventInfo from '../../components/cards/statistics/AdminEventInfo';
import { getMembers } from '../../service/member.api';
import TotalCountCard from '../../shared/components/TotalCountCard';
import { FormattedMessage } from 'react-intl';
import LoadingScreen from '../../shared/components/LoadingScreen';
import { ToastContainer } from 'react-toastify';
import MemberTypeInfo from '../../components/cards/statistics/MemberTypeInfo';

export const AdminPage = () => {
  const [eventCount, setEventCount] = useState(0);
  const [memberTypeCount, setmemberTypeCount] = useState(0);
  const [memberTypeData, setMemberTypeData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [memberCount, setMemberCount] = useState(0);
  const [isEventLoading, setIsEventLoading] = useState(false);
  const [isMemberTypeLoading, setisMemberTypeLoading] = useState(false);
  const [isMemberLoading, setIsMemberLoading] = useState(false);
  const fetchEvents = () => {
    setIsEventLoading(true);
    setTimeout(() => {
      getEvents()
        .then((response) => {
          if (response) {
            setIsEventLoading(false);
            if (response.status_code === 200 || response.status_code === 201) {
              setEventCount(response.data?.event_list?.length);
              setEventData(response.data?.event_list);
            }
          }
        })
        .catch((error) => {
          setIsEventLoading(false);
          if (error.response?.data?.status_code === 404) {
            setEventCount(0);
            setEventData([]);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast('something went wrong');
          }
        });
    }, 400);
  };

  const fetchMemberTypes = () => {
    setisMemberTypeLoading(true);
    setTimeout(() => {
      getMemberTypes()
        .then((response) => {
          if (response) {
            setisMemberTypeLoading(false);
            if (response.data?.status_code === 200 || response.data?.status_code === 201) {
              setmemberTypeCount(response.data?.data.total_item);
              setMemberTypeData(response.data?.data.member_type_list);
            }
          }
        })
        .catch((error) => {
          setisMemberTypeLoading(false);
          if (error.response?.data?.status_code === 404) {
            setmemberTypeCount(0);
            setMemberTypeData([]);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast('something went wrong');
          }
        });
    }, 400);
  };

  const fetchMembers = () => {
    setIsMemberLoading(true);
    setTimeout(() => {
      getMembers()
        .then((response) => {
          if (response) {
            setIsMemberLoading(false);
            if (response.status_code === 200 || response.status_code === 201) {
              setMemberCount(response.data?.total_item);
            }
          }
        })
        .catch((error) => {
          setIsMemberLoading(false);
          if (error.response?.data?.status_code === 404) {
            setMemberCount(0);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast('something went wrong');
          }
        });
    }, 400);
  };

  useEffect(() => {
    fetchEvents();
    fetchMemberTypes();
    fetchMembers();
  }, []);

  return (
      <>
        {isEventLoading && isMemberTypeLoading && isMemberLoading ? (
            <LoadingScreen />
        ) : (
            <Grid container spacing={3}>
              <Grid container spacing={2} style={{ marginTop: '10px', marginLeft: '10px' }}>
                <Grid item xs={4}>
                  <TotalCountCard count={memberTypeCount} title=<FormattedMessage id="total_member_types" /> />
                </Grid>
                <Grid item xs={4}>
                  <TotalCountCard count={eventCount} title=<FormattedMessage id="total_events" /> />
                </Grid>
                <Grid item xs={4}>
                  <TotalCountCard count={memberCount} title=<FormattedMessage id="total_members" /> />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginLeft: '10px', marginTop: '20px' }}>
                <Grid item xs={6}>
                  <MemberTypeInfo memberTypeData={memberTypeData} />
                </Grid>

                <Grid item xs={6}>
                  <AdminEventInfo eventData={eventData} />
                </Grid>
              </Grid>
            </Grid>
        )}
        <ToastContainer />
      </>
  );
};
