import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import {Select, MenuItem, Typography, Grid, Button} from '@mui/material';
import {ConfirmBooking, getEvent, getEvents, SeatAllocation} from '../../../service/event.api';
import {errorToast, successToast, warningToast} from '../../../shared/components/AlertMessages';
import {ToastContainer} from 'react-toastify';
import * as Yup from 'yup';
import {FormattedMessage} from 'react-intl';
import {getGroups} from '../../../service/group.api';
import LoadingScreen from '../../../shared/components/LoadingScreen';
import BookingUI from "../BookingUI";
import Card from "@mui/material/Card";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from "@mui/material/IconButton";
import RefreshIcon from '@mui/icons-material/Refresh';

const initialValues = {
    selectedEvent: '',
    selectedGroup: ''
};

const TicketAllocation = () => {
    const [events, setEvents] = useState([]);
    const [groups, setGroups] = useState([]);
    const [bookedSeats, setBookedSeats] = useState([]); // State to store booked seats
    const [isAllocateDisabled, setIsAllocateDisabled] = useState(false);
    const [isReAllocateDisabled, setIsReAllocateDisabled] = useState(true);
    const [isCancelDisabled, setIsCancelDisabled] = useState(true);
    const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bookingConfirmed, setBookingConfirmed] = useState(false);
    const [apiError, setApiError] = useState(null);
    const[hallName,setHallName] = useState('');

    const fetchEvents = () => {
        getEvents()
            .then((response) => {
                if (response) {
                    if (response?.status_code === 200 || response?.status_code === 201) {
                        const currentDate = new Date();
                        const upcomingEvents = response.data.event_list.filter(event => {
                            const eventDate = new Date(event.date);
                            return eventDate >= currentDate;
                        });
                        setEvents(upcomingEvents);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setEvents([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    errorToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
    };
    const fetchGroups = () => {
        getGroups()
            .then((response) => {
                if (response) {
                    if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                        setGroups(response.data?.data.group_list);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    setGroups([]);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            });
    };


    const RegisterforEvent = (data) => {
        setIsLoading(true);
        setIsAllocateDisabled(true);
        setIsDropdownDisabled(true);
        SeatAllocation(data)
            .then((response) => {
                if (response) {
                    setIsLoading(false);
                    if (response.status_code === 200 || response.status_code === 201) {
                        setBookedSeats(response.data);
                        setIsReAllocateDisabled(false);
                        setIsCancelDisabled(false);
                        setApiError('')
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response?.data?.status_code === 404) {
                    setApiError(error.response?.data?.message);
                    setIsDropdownDisabled(false);
                    setIsAllocateDisabled(false);
                    setIsReAllocateDisabled(true);
                    setIsCancelDisabled(true);
                } else if (error.response?.data?.status_code === 403) {
                    warningToast(error.response?.data?.message)
                    setApiError(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message)
                    setApiError(error.response?.data?.message);
                    setIsDropdownDisabled(false);
                    setIsAllocateDisabled(false);
                    setIsReAllocateDisabled(true);
                    setIsCancelDisabled(true);
                } else {
                    setApiError('Something went wrong');
                }
            });
    };


    useEffect(() => {
        fetchEvents();
        fetchGroups();
    }, []);


    const formik = useFormik({
        initialValues,

        validationSchema: Yup.object({
            selectedEvent: Yup.string().required('Please Select Event'),
            selectedGroup: Yup.string().required('Please Select Group')
        }),
        onSubmit: (values) => {
            let data = {
                event_id: values.selectedEvent,
                group_id: values.selectedGroup
            };
            RegisterforEvent(data);
        }
    });

    const handleRejectClick = () => {
        let data = {
            is_saved:false,
            is_confirmed_booked: false,
            event_id: formik.values.selectedEvent,
            group_id: formik.values.selectedGroup,
            is_sms_send: false,
            is_whatsapp_send: false,
            confirm_seat_json:[{}]

        };

        ConfirmBooking(data)
            .then((response) => {
                if (response) {
                    if (response.status_code === 200 || response.status_code === 201) {
                        setBookingConfirmed(true);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);

                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            })
        if (bookingConfirmed) {
            let reallocation_data = {
                event_id: formik.values.selectedEvent,
                group_id: formik.values.selectedGroup,
            };
            RegisterforEvent(reallocation_data);
            setBookingConfirmed(false);
        }
    };

    const handleCancelClick = () => {
        setIsDropdownDisabled(false);
        let data = {
            is_saved:false,
            is_confirmed_booked: false,
            event_id: formik.values.selectedEvent,
            group_id: formik.values.selectedGroup,
            is_sms_send: false,
            is_whatsapp_send: false,
            confirm_seat_json:[{}]
        };

        ConfirmBooking(data)
            .then((response) => {
                if (response) {
                    if (response.status_code === 200 || response.status_code === 201) {
                        successToast(response?.message)
                        formik.resetForm();
                        setBookedSeats([]);
                        setIsAllocateDisabled(false);
                        setIsReAllocateDisabled(true);
                        setIsCancelDisabled(true);
                    }
                }
            })
            .catch((error) => {
                if (error.response?.data?.status_code === 404) {
                    warningToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 403) {
                    errorToast(error.response?.data?.message);
                } else if (error.response?.data?.status_code === 400) {
                    warningToast(error.response?.data?.message);
                } else {
                    errorToast('something went wrong');
                }
            })
    };

    useEffect(() => {
        if(formik.values.selectedEvent) {
            getEvent(formik.values.selectedEvent)
                .then((response) => {
                    if (response) {
                        if (response?.status_code === 200 || response?.status_code === 201) {
                            setHallName(response?.data[0]?.hall_name);
                        }
                    }
                })
                .catch((error) => {
                    if (error.response?.data?.status_code === 404) {
                        setHallName('');
                    } else if (error.response?.data?.status_code === 403) {
                        errorToast(error.response?.data?.message);
                    } else if (error.response?.data?.status_code === 400) {
                        errorToast(error.response?.data?.message);
                    } else {
                        errorToast('something went wrong');
                    }
                });
        }
    }, [formik.values.selectedEvent]);

    return (
        <>
            <Card style={{marginTop: '-30px', padding: '20px', width: '100%'}}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="view_events"/>: <span style={{color: 'red'}}>*</span>
                            </Typography>
                            <Select
                                fullWidth
                                id="selectedEvent"
                                placeholder="Select Event"
                                name="selectedEvent"
                                size="small"
                                value={formik.values.selectedEvent}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.selectedEvent && Boolean(formik.errors.selectedEvent)}
                                disabled={isDropdownDisabled}
                            >
                                {events.map((event) => (
                                    <MenuItem key={event.event_id} value={event.event_id}>
                                        {event.event_name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.selectedEvent && formik.errors.selectedEvent && (
                                <div className="error-message" style={{color: 'red', fontSize: '12px'}}>
                                    {formik.errors.selectedEvent}
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="view_group"/>: <span style={{color: 'red'}}>*</span>
                            </Typography>
                            <Select
                                fullWidth
                                id="selectedGroup"
                                placeholder="Select Event"
                                name="selectedGroup"
                                size="small"
                                value={formik.values.selectedGroup}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.selectedGroup && Boolean(formik.errors.selectedGroup)}
                                disabled={isDropdownDisabled}

                            >
                                {groups.map((group) => (
                                    <MenuItem key={group.group_id} value={group.group_id}>
                                        {group.group_name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.selectedGroup && formik.errors.selectedGroup && (
                                <div className="error-message" style={{color: 'red', fontSize: '12px'}}>
                                    {formik.errors.selectedGroup}
                                </div>
                            )}
                        </Grid>

                        <Grid item xs={2} style={{marginTop: '20px'}}>
                            <Button color="primary" variant="outlined" type="submit" disabled={isAllocateDisabled}
                                    style={{height: "40px"}}>
                                <IconButton color="primary" disabled={isAllocateDisabled}>
                                    <EventSeatIcon style={{marginRight: "6px"}} fontSize="small"/>
                                </IconButton>
                                Start Allocation
                            </Button>
                        </Grid>

                        <Grid item xs={2} style={{marginTop: '20px'}}>

                            <Button color="success" variant="outlined" type="submit" onClick={handleRejectClick}
                                    disabled={isReAllocateDisabled} style={{height: "40px"}}>
                                <IconButton color="success" disabled={isReAllocateDisabled}>
                                    <RefreshIcon style={{marginRight: "6px"}} fontSize="small"/>
                                </IconButton>
                                Re-Allocate
                            </Button>
                        </Grid>


                        <Grid item xs={2} style={{marginTop: '20px'}}>
                            <Button color="error" variant="outlined" onClick={handleCancelClick}
                                    disabled={isCancelDisabled} style={{height: "40px"}}>
                                <IconButton color="error" disabled={isCancelDisabled}>
                                    <CancelIcon style={{marginRight: "6px"}} fontSize="small"/>
                                </IconButton>
                                Cancel
                            </Button>
                        </Grid>


                    </Grid>
                </form>
            </Card>

            {isLoading ? (
                <LoadingScreen/>
            ) : (
                <>
                    {apiError && (
                        <Card style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '90vh',
                            marginTop: "10px",
                            backgroundColor: "#E5E4E2"
                        }}>
                            <div className="error-message" style={{color: 'black', fontSize: '20px'}}>
                                {apiError}
                            </div>
                        </Card>
                    )}

                    {bookedSeats.length > 0 && (
                        <BookingUI
                            seatingJson={bookedSeats}
                            event_id={formik.values.selectedEvent}
                            group_id={formik.values.selectedGroup}
                            hallName={hallName}
                        />
                    )}
                </>
            )}

            <ToastContainer/>
        </>
    );
};

export default TicketAllocation;
