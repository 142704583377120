import { Grid, Stack } from '@mui/material';
import React from 'react';
import SubmemberApproval from './tables/SubmemberApproval';
export const SubmemberApproveTable = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}></Stack>
      </Grid>

      <Grid item xs={12}>
        <SubmemberApproval />
      </Grid>
    </Grid>
  );
};
