import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const ViewSubMemberModal = ({  submemberData }) => {
  const [editedData, setEditedData] = useState({ ...submemberData });

  useEffect(() => {
    setEditedData({ ...submemberData });
  }, [submemberData]);

  return (
    <Dialog  fullWidth>
      <DialogTitle className="linear-gradient">
        <FormattedMessage id="manage_submembers" />
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container columns={{ xs: 4, md: 12 }}>
          {/* Full Name */}
          <Grid item xs={6}>
            <span className="fw-bold">
              {' '}
              <FormattedMessage id="name" />
            </span>
            <p className="text-muted">{`${editedData.first_name} ${editedData.middle_name} ${editedData.last_name}`}</p>
          </Grid>
          {/* Gender */}
          <Grid item xs={6}>
            <span className="fw-bold">
              {' '}
              <FormattedMessage id="gender" />
            </span>
            <p className="text-muted">{editedData.gender}</p>
          </Grid>
          {/* Whatsapp Mobile Number */}
          <Grid item xs={6}>
            <span className="fw-bold">
              {' '}
              <FormattedMessage id="phone" />
            </span>
            <p className="text-muted">{editedData.phone || <HorizontalRuleIcon />}</p>
          </Grid>

          {/* Date of Birth */}
          <Grid item xs={6}>
            <span className="fw-bold">
              {' '}
              <FormattedMessage id="dob" />
            </span>
            <p className="text-muted">{editedData.dob}</p>
          </Grid>

        </Grid>
      </DialogContent>

        <DialogActions style={{justifyContent: "end"}}>
            <Button  variant="outlined" style={{margin: 8}}>
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
    </Dialog>
  );
};

export default ViewSubMemberModal;
