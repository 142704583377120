import React from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";

export const GroupSelect = ({
  selectedGroup,
  handleGroupChange,
  groups,
  showUnassigned,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <Box sx={{ minWidth: 120 }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Select Group
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedGroup || ""}
              onChange={handleGroupChange}
            >
              {showUnassigned && (
                <MenuItem value="unassigned">Unassigned</MenuItem>
              )}

              {groups.map((group) => (
                <MenuItem key={group.group_id} value={group.group_id}>
                  {group.group_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};
