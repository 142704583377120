import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Grid, TextField, Button, Typography, CircularProgress, InputLabel } from "@mui/material";
import { renewMember, sendOtp } from '../../../service/member.api';
import { errorToast, warningToast } from '../../../shared/components/AlertMessages';
import { ToastContainer } from 'react-toastify';
import Countdown from '../../../shared/components/CountDown';
import { SHA256 } from 'crypto-js'; // Import SHA256 from crypto-js
import {useNavigate} from "react-router-dom";
import {setMemberDetails} from "../../../store/reducers/renewMembershipReducer";
import {useDispatch} from "react-redux";

const initialValues = {
  userInput: '',
  otp: ['', '', '', '', '', '']
};

const inputSchema = Yup.object({
  userInput: Yup.string()
    .required('Mobile Number, Membership ID, or Email is Required')
    .test('valid-input', 'Invalid Email/Phone/Membership ID', (value) => {
      // Check if the input is a valid mobile number, membership ID, or email
      const isPhone = isPhoneNumber(value);
      const isUuidMembership = isUuid(value);
      const isEmail = isValidEmail(value);

      return isPhone || isUuidMembership || isEmail;
    })
});

const otpSchema = Yup.object().shape({
  otp: Yup.array()
    .of(Yup.string().matches(/^\d{0,1}$/, 'Must be a single digit'))
    .test('is-complete', 'Please enter a 6-digit OTP', (value) => {
      const completedDigits = value.filter((digit) => digit !== '').length;
      return completedDigits === 6;
    })
    .required('Required')
});
const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const isPhoneNumber = (identifier) => /^\d{10}$/.test(identifier);
const isUuid = (identifier) => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(identifier);

const RenewMemberForm = () => {
  const [showOTP, setShowOTP] = useState(false);
  const [showDetails, setShowDetails] = useState();
  const [responseToken, setResponseToken] = useState();

  const [timer, setTimer] = useState(120);
  const [sendOTPClicked, setSendOTPClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: showOTP ? otpSchema : inputSchema,
    onSubmit: (values) => {
      setLoading(true);
      setSendOTPClicked(true); // Disable send OTP button
      const identifier = values.userInput;
      sendOtp(identifier, 'Renew Memberhip')
        .then((response) => {
          if (response) {
            if (response.data?.status_code === 200 || response.data?.status_code === 201) {
              setShowOTP(true);
              setTimer(120);
              setResponseToken(response.data?.data);
            }
          }
        })
        .catch((error) => {
          if (error.response?.data?.status_code === 404) {
            warningToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 403) {
            errorToast(error.response?.data?.message);
          } else if (error.response?.data?.status_code === 400) {
            errorToast(error.response?.data?.message);
          } else {
            errorToast(error.response?.data?.message);
          }
        })
        .finally(() => {
          setSendOTPClicked(false);
          setLoading(false);
        });
    }
  });
  const handleOTPSubmit = () => {
    otpSchema
      .validate(formik.values)
      .then(() => {
        const otp = formik.values.otp.join('');
        const combinedHash = SHA256(responseToken + otp).toString();
        if (timer > 0) {
          renewMember(combinedHash)
            .then((response) => {
              if (response) {
                if (response.data?.status_code === 200 || response.data?.status_code === 201) {
                  dispatch(setMemberDetails(response.data?.data));
                  navigate('/renew-membership/details')
                }
              }
            })
            .catch((error) => {
              if (error.response?.data?.status_code === 404) {
                warningToast(error.response?.data?.message);
              } else if (error.response?.data?.status_code === 403) {
                errorToast(error.response?.data?.message);
              } else if (error.response?.data?.status_code === 400) {
                errorToast(error.response?.data?.message);
              } else {
                errorToast('something went wrong');

              }
            });
        } else {
          warningToast('The entered OTP has expired. Please click on resend OTP.');
          setShowOTP(true);
        }
      })
      .catch((error) => {
        // Handle validation errors
        formik.setFieldError('otp', error.message);
      });
  };
  const handleResendOTP = () => {
    formik.setValues({
      ...formik.values,
      otp: ['', '', '', '', '', '']
    });
    setTimer(120);
    const identifier = formik.values.userInput;

    sendOtp(identifier, 'Renew Membership')
      .then((response) => {
        if (response) {
          if (response.data?.status_code === 200 || response.data?.status_code === 201) {
            setShowOTP(true);
            setResponseToken(response.data?.data);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          warningToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast(error.response?.data?.message);
        }
      })
      .finally(() => {
        setSendOTPClicked(false);
      });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {!showDetails && (
            <>
              <Grid item xs={12}>
                <InputLabel >
                  Mobile Number/Membership ID/Email<span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField
                  fullWidth
                  id="userInput"
                  name="userInput"
                  value={formik.values.userInput}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.userInput && Boolean(formik.errors.userInput)}
                  helperText={formik.touched.userInput && formik.errors.userInput}
                />
              </Grid>
              {showOTP && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Enter OTP</Typography>
                  <div style={{ display: 'flex' }}>
                    {formik.values.otp.map((digit, index) => (
                      <TextField
                        key={index}
                        id={`otp-${index}`}
                        name={`otp-${index}`}
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 1 }}
                        value={digit}
                        onChange={(event) => {
                          formik.setFieldValue(`otp[${index}]`, event.target.value);
                          if (index < 5 && event.target.value !== '') {
                            document.getElementById(`otp-${index + 1}`).focus();
                          }
                        }}
                        style={{ width: '30px', marginRight: '5px', marginBottom: '10px' }}
                      />
                    ))}
                  </div>
                  {formik.touched.otp && formik.errors.otp && (
                    <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{formik.errors.otp}</div>
                  )}
                </Grid>
              )}
            </>
          )}
          {showOTP && !showDetails && (
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <Countdown seconds={timer % 60} minutes={Math.floor(timer / 60)} onResendOTP={handleResendOTP} showDetails={showDetails} />
            </Grid>
          )}
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            {/* Conditionally render the appropriate button based on showOTP and !showDetails */}
            {showOTP && !showDetails && (
              <Button variant="contained" color="primary" onClick={handleOTPSubmit}>
                Verify OTP
              </Button>
            )}
            {!showOTP && (
              <Button type="submit" variant="contained" color="primary" disabled={sendOTPClicked} style={{marginTop:"10px"}}>
                {loading ? <CircularProgress size={24} /> : 'Send OTP'}
              </Button>
            )}
          </Grid>
        </Grid>
        <ToastContainer />
      </form>

      {/*{showDetails && (*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <UpdateMemberForm memberDetails={showDetails} />*/}
      {/*  </Grid>*/}
      {/*)}*/}

    </>
  );
};

export default RenewMemberForm;
