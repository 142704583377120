import React, { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";
import { FormattedMessage } from 'react-intl';
import BulkUploadModal from './BulkUploadModal';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export const BulkUpload = ({  handleFetchData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
    
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} onClick={openModal}>
          <FormattedMessage id="bulk_upload" />
          
          {/* <VisuallyHiddenInput type="file" id="file" name="file" accept=".pdf,.xlsx" onChange={handleFileUpload} /> */}
        </Button>
        <BulkUploadModal isOpen={isModalOpen} onClose={closeModal} handleFetchData={handleFetchData} />
    </>
  );
};
