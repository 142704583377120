//member table with filter
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import { snakeCase } from "lodash";

import {
  errorToast,
  successToast,
  warningToast,
} from "../../../shared/components/AlertMessages";
import { ToastContainer } from "react-toastify";
import {
  BlockMember,
  VerifyMember,
  viewMember,
} from "../../../service/member.api";
import DeleteIcon from "@mui/icons-material/Delete";
import { BulkUpload } from "../../../shared/components/BulkUpload";
import { FormattedMessage } from "react-intl";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import {
  getGroups,
  updateGroupType,
} from "../../../service/group.api";
import { GroupSelect } from "../../../shared/components/GroupSelect";
import SelectGroupModal from "../../group/forms/SelectGroupModal";
import { CardHeader, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ViewMemberModal from "../forms/ViewMemberModal";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import { makeStyles } from "@mui/styles";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Button from "@mui/material/Button";
import BasicMenu from "../../../shared/components/BasicMenu";
import PaymentUpdateModal from "../../payment/forms/PaymentUpdateModal";
import { capitalizeName } from "../../../shared/helpers/utils";
import { MemberTypeFilter } from "../../membertype/forms/MemberTypeFilter";
import { MemberStatusFilter } from "../../member/forms/MemberStatusFilter";
import { PaymentStatusFilter } from "../../payment/forms/PaymentStatusFilter";
import "./memberTable.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableSortLabel,
} from "../../../../node_modules/@mui/material/index";
import { MemberSearch } from "shared/components/MemberSearch";

const headCells = [
  {
    id: "membership_number",
    numeric: false,
    sortable: true,
    disablePadding: true,
    label: <FormattedMessage id="membership_number" />,
  },
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="name" />,
  },

  {
    id: "member_type",
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage id="member_type" />,
  },
  {
    id: "group",
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage id="group" />,
  },

  {
    id: "member_status",
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="status" />,
  },
  {
    id: "payment_status",
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="payment_status" />,
  },

  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage id="action" />,
  },
];

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          View Events
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [memberData, setMemberData] = React.useState([]);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const isFirstRender = useRef(true); // Ref to track the initial render
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groups, setGroups] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);
  const [selectedRowForMember, setSelectedRowForMember] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedRowForPayment, setSelectedRowForPayment] = useState(null);
  const [selectedMemberType, setSelectedMemberType] = useState("");
  const [selectedMemberStatus, setSelectedMemberStatus] = useState("");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const [orderBy, setOrderBy] = useState("member_num");
  const [order, setOrder] = useState("desc");

  let newFiltersObj = {};

// Check and assign selectedGroup if it exists
  if (selectedGroup) {
    newFiltersObj.group_choice = selectedGroup;
  }

// Check and assign selectedMemberType if it exists
  if (selectedMemberType) {
    newFiltersObj.member_type_id = selectedMemberType;
  }

// Check if selectedMemberStatus is "verified", "non-verified", or "blocked" and assign accordingly
  if (selectedMemberStatus === "verified" || selectedMemberStatus === "non-verified") {
    newFiltersObj.is_verified = selectedMemberStatus === "verified" ? 1 : 0;
  } else if (selectedMemberStatus === "blocked") {
    newFiltersObj.member_status = selectedMemberStatus;
  }

// Check if selectedPaymentStatus is "pending" or "completed" and assign accordingly
  if (selectedPaymentStatus === "pending" || selectedPaymentStatus === "completed") {
    newFiltersObj.member_payment_status = selectedPaymentStatus === "completed" ? 1 : 0;
  }



  const convertToUpperSnakeCase = (value) => {
    return snakeCase(value).toUpperCase();
  };
  const handleRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);

    const params = {
      page_number: page + 1,
      items_per_page: rowsPerPage,
      sort_by: "membership_id",
      sort_value: convertToUpperSnakeCase(newOrder),
      search_value: searchQuery,
      filter_dict: newFiltersObj,

    };

    try {
      const response = await viewMember(params);
      if (response) {
        if (response?.status_code === 200 || response?.status_code === 201) {
          setMemberData(response?.data?.member_list);
          setCount(response.data?.total_item);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response?.data?.status_code === 404) {
        setCount(0);
        setMemberData("");
      } else if (error.response?.data?.status_code === 403) {
        errorToast(error.response?.data?.message);
      } else if (error.response?.data?.status_code === 400) {
        setCount(0);

        errorToast(error.response?.data?.message);
      } else {
        errorToast("Something went wrong");
      }
    }
  };

  const handleAccordionChange = (event, isExpanded) => {
    setAccordionExpanded(isExpanded);
  };

  const handleFetchData =async() => {
   viewData(page,rowsPerPage)
  }

  const handleEditClick = (groupData) => {
    setSelectedRowForEdit(groupData);
    setIsEditModalOpen(true);
  };


  const handleMemberClick = (memberData) => {
    setSelectedRowForMember(memberData);
    setIsMemberModalOpen(true);
  };

  const handlePaymentClick = (memberData) => {
    setSelectedRowForPayment(memberData);
    setIsPaymentModalOpen(true);
  };


    const handleSearch = async (query) => {
    try {

      setPage(page);
      setRowsPerPage(10);
      const params = {
        page_number: page + 1,
        items_per_page: rowsPerPage,
        sort_by: "membership_id",
        sort_value: convertToUpperSnakeCase(order),
        search_value: query, // Use the query parameter instead of searchQuery
        filter_dict: newFiltersObj,
      };

      const response = await viewMember(params);

      if (response && (response.status_code === 200 || response.status_code === 201)) {
        setMemberData(response.data.member_list);
        setCount(response.data?.total_item);
      }


      }
    
      
     

    
      
     catch (error) {
      setIsLoading(false);
      if (error.response?.data?.status_code === 404) {
        setMemberData("");
        setCount(0);

      } else if (error.response?.data?.status_code === 403) {
        errorToast(error.response?.data?.message);
      } else if (error.response?.data?.status_code === 400) {
        errorToast(error.response?.data?.message);
        setCount(0);
      } else {
        errorToast("Something went wrong");
      }
    }
  };

  const handleResetSearch = async () => {
    setSearchQuery("");
    newFiltersObj={}
    viewData(page, rowsPerPage);
   }

  const viewData = async (
    page,
    rowsPerPage,
    searchQuery,
    sortBy,
    order,

  ) => {
    setIsLoading(true);
    try {
      const params = {
        page_number: page + 1,
        items_per_page: rowsPerPage,
        sort_by: "membership_id",
        sort_value: convertToUpperSnakeCase(order),
        search_value: searchQuery,
        filter_dict: newFiltersObj,
      };
      const response = await viewMember(params);

      setIsLoading(false);

      if (response?.status_code === 200 || response?.status_code === 201) {
        setMemberData(response.data?.member_list);
        setCount(response.data?.total_item);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response?.data?.status_code === 404) {
        setCount(0);

      } else if (error.response?.data?.status_code === 403) {
        errorToast(error.response?.data?.message);
      } else if (error.response?.data?.status_code === 400) {
        errorToast(error.response?.data?.message);
        setCount(0);

      } else {
        errorToast("Something went wrong");
      }
    }
  };

  const handlePaymentModal = () => {
    setIsPaymentModalOpen(false);
    viewData(page, rowsPerPage);
  };

  const handleGroupModal = () => {
    setIsEditModalOpen(false);
    viewData(page, rowsPerPage);
  };
  const fetchGroups = () => {
    getGroups()
      .then((response) => {
        if (response) {
          if (
            response.data?.status_code === 200 ||
            response.data?.status_code === 201
          ) {
            setGroups(response.data?.data.group_list);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setCount(0);
          setGroups([]);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          warningToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
      });
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  useEffect(() => {
    if (isFirstRender.current) {
      viewData(page, rowsPerPage);
      isFirstRender.current = false; // Set to false after the initial render
    }
  }, [page, rowsPerPage]); // Include page and rowsPerPage in the dependency array

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleAccept = (selectedMember) => {
    let data = {
      member_id: selectedMember.member_id,
      is_verified: true,
    };
    VerifyMember(data)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);
            const updatedMemberData = memberData.map((member) =>
              member.member_id === selectedMember.member_id
                ? { ...member, is_verified: true }
                : member
            );
            setMemberData(updatedMemberData);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setCount(0);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
      });
  };
  const handleBlock = (selectedMember) => {
    let data = {
      member_id: selectedMember.member_id,
      member_status: false,
    };
    BlockMember(data)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);
            const updatedMemberData = memberData.map((member) =>
              member.member_id === selectedMember.member_id
                ? { ...member, member_status: false }
                : member
            );
            setMemberData(updatedMemberData);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setCount(0);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
      });
  };

  const handleChangePage = (event, newPage) => {

    viewData(newPage,rowsPerPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);

    // Ensure that the current page is valid with the new rowsPerPage
    const newPage = Math.floor(page * (rowsPerPage / newRowsPerPage));
    viewData(newPage,newRowsPerPage)
    setRowsPerPage(newRowsPerPage); // Update rowsPerPage
    setPage(newPage); // Update the page to the new calculated page
  };

  




  
  const handleUnblock = (selectedMember) => {
    let data = {
      member_id: selectedMember.member_id,
      member_status: true,
    };
    BlockMember(data)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);
            const updatedMemberData = memberData.map((member) =>
              member.member_id === selectedMember.member_id
                ? { ...member, member_status: true }
                : member
            );
            setMemberData(updatedMemberData);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setCount(0);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
      });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const handleGroupChange = (event) => {
    const newGroup = event.target.value;
    setSelectedGroup((prevSelectedGroup) => newGroup);
  };

  const handleEditEvent = (editedMemberData) => {
    updateGroupType(editedMemberData)
      .then((response) => {
        if (response) {
          if (response?.status_code === 200 || response?.status_code === 201) {
            successToast(response?.message);
            const updatedMemberData = memberData.map((member) =>
              member.member_id === editedMemberData.member_id
                ? {
                    ...member,
                    group_name:
                      groups.find(
                        (group) => group.group_id === editedMemberData.group_id
                      )?.group_name || "-",
                  } // Assuming editedMemberData contains the new group_choice
                : member
            );
            setMemberData(updatedMemberData);
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 404) {
          setCount(0);
        } else if (error.response?.data?.status_code === 403) {
          errorToast(error.response?.data?.message);
        } else if (error.response?.data?.status_code === 400) {
          errorToast(error.response?.data?.message);
        } else {
          errorToast("something went wrong");
        }
      });
    setIsEditModalOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    searchContainer: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {},
    },
  }));
  const classes = useStyles();

  const handleApplyFilter = async () => {
    try {
    

      const params = {
        page_number: page + 1,
        items_per_page: rowsPerPage,
        sort_by: "membership_id",
        sort_value: convertToUpperSnakeCase(order),
        search_value: searchQuery,
        filter_dict: newFiltersObj,
      };

      const response = await viewMember(params);

      if (response) {
        if (response?.status_code === 200 || response?.status_code === 201) {
          setMemberData(response.data.member_list);
          setCount(response.data?.total_item);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response?.data?.status_code === 404) {
        setCount(0);
        setMemberData("");
      } else if (error.response?.data?.status_code === 403) {
        errorToast(error.response?.data?.message);
      } else if (error.response?.data?.status_code === 400) {
        errorToast(error.response?.data?.message);
      } else {
        errorToast("Something went wrong");
      }
    }
  };

  const handleResetFilter = () => {
    setSelectedGroup("");
    setSelectedMemberType("");
    setSelectedMemberStatus("");
    setSelectedPaymentStatus("");
    newFiltersObj = {}
    viewData(page, rowsPerPage);
  };

  const handleMemberTypeChange = (event) => {
    const newStatus = event.target.value;
    setSelectedMemberType((prevState) => {
     
      return newStatus;
    });

  };

  const handleMemberStatusChange = (event) => {
    const newStatus = event.target.value;
    setSelectedMemberStatus((prevState) => {
     
      return newStatus;
    });
  };

  const handlePaymentStatusChange = (event) => {
    const newStatus = event.target.value;
    setSelectedPaymentStatus(newStatus);

  };



  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div style={{ marginBottom: "10px" }}>
            <Accordion
              expanded={accordionExpanded}
              onChange={handleAccordionChange}
            >
              <AccordionSummary
                expandIcon={<KeyboardArrowDownIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  style={{
                    // marginTop: accordionExpanded ? "20px" : "0",
                    fontWeight: "bold",
                  }}
                  className="mt-0"
                >
                  Filter
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12} sm={6} md={2}>
                    <GroupSelect
                      selectedGroup={selectedGroup}
                      handleGroupChange={(event) => handleGroupChange(event)}
                      groups={groups}
                      showUnassigned={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <MemberTypeFilter
                      selectedMemberType={selectedMemberType}
                      handleMemberTypeChange={(event) =>
                        handleMemberTypeChange(event)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <MemberStatusFilter
                      selectedMemberStatus={selectedMemberStatus}
                      handleMemberStatusChange={(event) =>
                        handleMemberStatusChange(event)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <PaymentStatusFilter
                      selectedPaymentStatus={selectedPaymentStatus}
                      handlePaymentStatusChange={(event) =>
                        handlePaymentStatusChange(event)
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    style={{ marginTop: "20px" }}
                    xs={12}
                    sm={6}
                    md={2}
                  >
                    <Button
                      style={{ marginRight: "10px" }}
                      variant="contained"
                      color="primary"
                      onClick={handleApplyFilter}
                      size="small"
                    >
                      Apply
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleResetFilter}
                      size="small"
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>

          <Card className="card">
            <CardHeader
              title=<FormattedMessage id="member_details" />
              className="linear-gradient"
              action={
                <div className={classes.header}>
                  <div className={classes.searchContainer}>
                  <MemberSearch
        placeholder="Search..."
        buttonLabel="Search"
        onSearch={handleSearch}
        handleResetSearch={handleResetSearch}
        // handleFetchData={handleFetchData}
      />
                  </div>
                  {/* <BulkUpload handleFileUpload={handleFileUpload} /> */}
                  <BulkUpload handleFetchData={handleFetchData} />
                </div>
              }
            />
            <CardContent>
              <TableContainer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "16px",
                    marginTop: "-10px",
                  }}
                ></div>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {memberData.length > 0 ? (
                      memberData.map((memberData) => {
                        return (
                          <React.Fragment key={memberData.member_id}>
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={memberData.member_id}
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell>{memberData.membership_id}</TableCell>
                              <TableCell  sx={{ maxWidth: 150, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                              >
                                <a
                                  href="#"
                                  onClick={() => handleMemberClick(memberData)}
                                >
                                  {capitalizeName(memberData.Name)}
                                </a>
                              </TableCell>

                              <TableCell>{memberData.member_type}</TableCell>
                              <TableCell>
                                {memberData.group_name || (
                                  <HorizontalRuleIcon />
                                )}
                              </TableCell>

                              <TableCell>
                                {memberData.member_status === true ? (
                                  memberData.is_verified === true ? (
                                    <Chip
                                      label="Verified"
                                      color="success"
                                      variant="outlined"
                                      size="small"
                                      style={{ borderRadius: "16px" }}
                                      icon={<CheckIcon />}
                                    />
                                  ) : (
                                    <Chip
                                      label="Non-Verified"
                                      color="error"
                                      variant="outlined"
                                      size="small"
                                      style={{ borderRadius: "16px" }}
                                      icon={<CancelIcon />}
                                    />
                                  )
                                ) : (
                                  <Chip
                                    label="Blocked"
                                    color="error"
                                    variant="outlined"
                                    size="small"
                                    style={{ borderRadius: "16px" }}
                                    icon={<BlockIcon />}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                {memberData.member_payment_status === false ? (
                                  <Chip
                                    label="Pending"
                                    color="warning"
                                    size="small"
                                  />
                                ) : (
                                  <Chip
                                    label="Complete"
                                    color="success"
                                    size="small"
                                  />
                                )}
                              </TableCell>

                              <TableCell>
                                <BasicMenu
                                  memberData={memberData}
                                  handleEditClick={handleEditClick}
                                  // handleSubmemberClick={handleSubmemberClick}
                                  handleAccept={handleAccept}
                                  handleBlock={handleBlock}
                                  handleUnblock={handleUnblock}
                                  showMenu={true}
                                  handlePaymentClick={handlePaymentClick}
                                />
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No data found
                        </TableCell>
                      </TableRow>
                    )}
                    {emptyRows > 0 && (
                      <TableRow>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <SelectGroupModal
                    isOpen={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    onEdit={handleEditEvent}
                    memberData={selectedRowForEdit}
                    onSubmit={handleGroupModal}
                  />
                 
                  <ViewMemberModal
                    isOpen={isMemberModalOpen}
                    onClose={() => setIsMemberModalOpen(false)}
                    memberData={selectedRowForMember}
                  />
                  <PaymentUpdateModal
                    isOpen={isPaymentModalOpen}
                    onClose={() => setIsPaymentModalOpen(false)}
                    memberData={selectedRowForPayment}
                    onSubmit={handlePaymentModal}
                  />
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[ 5, 10, 50,100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </CardContent>
          </Card>
        </>
      )}

      <ToastContainer />
    </>
  );
}
