export const appConfigs = {
    APP_IP: 'https://api.lohanamilan.in/',
    REACT_APP_DOMAIN: '.lohanamilan.in',
    WEBSITE_DOMAIN:"https://lohanamilan.in/",
    X_API_KEY:'2e37a9dcc45cd30224a13ec53056a24ad7b586dda956a262cb0baa698d04f957f13395b68306ed4a567417a5a06fae7410a8ec455d6c78e2f858ed593d0eb544c96d448f82e17d8020fa8f819fa1e39fcba1df2b87999d6543ad368c5114a7b4de326111286b668842673cde1fd2f2405bde65e123c0949726922cc1bfd41377'
};


// export const appConfigs = {
//     APP_IP: 'http://192.168.10.49:8000/',
//     REACT_APP_DOMAIN: '192.168.10.49',
//     WEBSITE_DOMAIN:"http://192.168.10.49:8081/",
//     X_API_KEY:'2e37a9dcc45cd30224a13ec53056a24ad7b586dda956a262cb0baa698d04f957f13395b68306ed4a567417a5a06fae7410a8ec455d6c78e2f858ed593d0eb544c96d448f82e17d8020fa8f819fa1e39fcba1df2b87999d6543ad368c5114a7b4de326111286b668842673cde1fd2f2405bde65e123c0949726922cc1bfd41377'
// };
